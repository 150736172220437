import React, { memo } from 'react';
import TicketsHistoryRow from './TicketsHistoryRow';

const TicketHistoryList = ({ list, showTicketsItem }) => {
  return (
    <>
      {list.map(t => (
        <TicketsHistoryRow key={t.id} ticket={t} showTicketsItem={showTicketsItem} />
      ))}
    </>
  );
};

export default memo(TicketHistoryList);

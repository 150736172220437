import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#24272A'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
  transform: rotate(${props => props.rotation || '0deg'});
`;

export const SelectArrow = ({ defaultColor, spacing, hoverColor, rotation }) => {
  return (
    <StyledSvg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
      rotation={rotation}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.96991 8.46967C4.26281 8.17678 4.73768 8.17678 5.03057 8.46967L12.0002 15.4393L18.9699 8.46967C19.2628 8.17678 19.7377 8.17678 20.0306 8.46967C20.3235 8.76256 20.3235 9.23744 20.0306 9.53033L12.5306 17.0303C12.2377 17.3232 11.7628 17.3232 11.4699 17.0303L3.96991 9.53033C3.67702 9.23744 3.67702 8.76256 3.96991 8.46967Z'
        fill='#24272A'
      />
    </StyledSvg>
  );
};

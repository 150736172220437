/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { isArray, isString, includes } from 'lodash';
import { customerSelector } from './store/selectors/DefaultSelectors';

export const roles = {
  root: 'root',
  child: 'child',
  guest: 'guest'
};

const mapStateToProps = state => {
  const customer = customerSelector(state);
  return {
    currentRole: customer && customer.role
  };
};

export const allowTo = (incomingRoles, AlternativeComponent) => ChildComponent => {
  class AllowToRolesContainer extends Component {
    render() {
      const { currentRole } = this.props;
      const internalRoles = isString(incomingRoles) ? [incomingRoles] : incomingRoles;
      if (!isArray(internalRoles)) {
        throw new Error('Roles component decorator error: "roles" argument is not array or string');
      }
      if (!currentRole) {
        return null;
      }
      if (!includes(internalRoles, currentRole)) {
        if (!AlternativeComponent) {
          return null;
        }
        return <AlternativeComponent {...this.props} />;
      }
      return <ChildComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(AllowToRolesContainer);
};

export const denyTo = (incomingRoles, AlternativeComponent) => ChildComponent => {
  class DenyToRolesContainer extends Component {
    render() {
      const { currentRole } = this.props;
      const internalRoles = isString(incomingRoles) ? [incomingRoles] : incomingRoles;
      if (!isArray(internalRoles)) {
        throw new Error('Roles component decorator error: firs argument is not array or string');
      }
      if (!currentRole) {
        return null;
      }
      if (includes(internalRoles, currentRole)) {
        if (!AlternativeComponent) {
          return null;
        }
        return <AlternativeComponent {...this.props} />;
      }
      return <ChildComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(DenyToRolesContainer);
};

export const redirectIfHasRole = (incomingRoles, path) => ChildComponent => {
  class redirectIfHasRoleContainer extends Component {
    render() {
      const { currentRole, history } = this.props;
      const internalRoles = isString(incomingRoles) ? [incomingRoles] : incomingRoles;
      if (!isArray(internalRoles)) {
        throw new Error('Roles component decorator error: firs argument is not array or string');
      }
      if (includes(internalRoles, currentRole)) {
        history.push(path);
        return null;
      }
      return <ChildComponent {...this.props} />;
    }
  }

  return compose(
    withRouter,
    connect(mapStateToProps)
  )(redirectIfHasRoleContainer);
};

import React, { Component, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import FillPaymentsModal from './FillPaymentsModal';
import svg from '../../../../media/images/visa-mastercard.svg';
import { roles } from '../../../roles';

const MODAL_WINDOW_TITLES = {
  'bank-transfer': `Пополнить банковским переводом`,
  'visa': 'Пополнить картой Visa'
};

class BalancePanel extends Component {
  constructor(props) {
    super(props);
    this.bankHandler = this.bankHandler.bind(this);
    this.visaHandler = this.visaHandler.bind(this);
    this.state = {
      isModalWindowOpen: false,
      currentModalWindowTitle: '',
      handler: this.visaHandler
    };
  }

  componentDidMount() {
    this.setState(prevState => ({
      payment: {
        ...prevState.payment,
        currency_id: 3,
        office_id: 1,
        amount: '20000',
        payment_system_id: 24
      }
    }));
  }

  openModalWindow = evt => {
    evt.preventDefault();
    const { customer, history } = this.props;
    if (!customer) {
      return;
    }
    if (customer.role === roles.guest) {
      history.push({
        pathname: '/prefill-profile',
        state: {
          message: 'Пожалуйста, заполните профиль для пользования полным функционалом личного кабинета.'
        }
      });
      return;
    }
    const title = MODAL_WINDOW_TITLES[evt.currentTarget.name];
    this.setState({
      isModalWindowOpen: true,
      currentModalWindowTitle: title,
      handler: evt.currentTarget.name === 'visa' ? this.visaHandler : this.bankHandler
    });
  };

  closeModalWindow = () => {
    this.setState({ isModalWindowOpen: false, currentModalWindowTitle: '' });
  };

  bankHandler({ replenishment, isSecurityPayment }) {
    const { createDeposit } = this.props;
    const updatedPayment = {
      ...this.state.payment,
      amount: replenishment === '' ? this.props.checkoutDifference : replenishment,
      payment_system_id: 3,
      payment_system_item_id: 1,
      is_security_payment: Number(isSecurityPayment)
    };

    this.setState({ payment: updatedPayment }, () => {
      createDeposit(this.state.payment);
    });
  }

  visaHandler({ replenishment, isSecurityPayment }) {
    const { createDeposit } = this.props;
    const updatedPayment = {
      ...this.state.payment,
      amount: replenishment === '' ? this.props.checkoutDifference : replenishment,
      payment_system_id: 24,
      is_security_payment: Number(isSecurityPayment)
    };

    this.setState({ payment: updatedPayment }, () => {
      createDeposit(this.state.payment);
    });
  }

  render() {
    const { account, customer } = this.props;
    const { isModalWindowOpen, currentModalWindowTitle, handler } = this.state;
    return (
      <Fragment>
        <section className='page__section balance-panel'>
          <div className='balance-panel__inner'>
            <div className='balance-panel__state'>
              <div className='balance-panel__state'>
                <span className='balance-panel__subhead'>Общий баланс:</span>
                {account ? (
                  <span className='balance-panel__integer'>{account.amount}</span>
                ) : (
                  <div className='balance-panel__loader'>
                    <Loader type='ThreeDots' color='red' height='10' width='20' />
                  </div>
                )}
                {/* <span className="balance-panel__fraction">,50</span> */}
                <span className='balance-panel__currency'>&#8381;</span>
              </div>
              <div className='balance-panel__state'>
                <span className='balance-panel__subhead--security'>Обеспечительный баланс:</span>
                {account ? (
                  <span className='balance-panel__integer--security'>{account.security_payment_amount}</span>
                ) : (
                  <div className='balance-panel__loader'>
                    <Loader type='ThreeDots' color='red' height='10' width='20' />
                  </div>
                )}
                {/* <span className="balance-panel__fraction">,50</span> */}
                <span className='balance-panel__currency--security'>&#8381;</span>
              </div>
            </div>

            <div className='balance-panel__action'>
              {customer && customer.is_company ? (
                <span className='balance-panel__subhead'>Пополнить:</span>
              ) : (
                <span className='balance-panel__subhead'>Пополнить картой:</span>
              )}
              {customer && customer.is_company ? (
                <button
                  type='button'
                  className='balance-panel__link btn btn--solid'
                  name='bank-transfer'
                  onClick={this.openModalWindow}
                >
                  <span className='balance-panel__link-text'>Банковским переводом</span>
                </button>
              ) : (
                <button
                  type='button'
                  className='balance-panel__link btn btn--solid'
                  name='visa'
                  onClick={this.openModalWindow}
                >
                  <img className='balance-panel__btn-img' src={svg} alt='' width='75' height='20' />
                </button>
              )}
            </div>
          </div>
        </section>
        <FillPaymentsModal
          isModalWindowOpen={isModalWindowOpen}
          closeModalWindow={this.closeModalWindow}
          additionalClasses={['modal-window--fill-payments']}
          modalWindowTitle={currentModalWindowTitle}
          handler={handler}
          customer={customer}
          checkoutDifference={this.props.checkoutDifference}
        />
      </Fragment>
    );
  }
}

export default BalancePanel;

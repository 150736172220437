import axios from 'axios';
import { includes, isArray, isObject } from 'lodash';
import { error, update } from 'react-toastify-redux';
import { removeToken } from '../../utils/token';
import { logout } from '../../store/actions';
import ResponseError from '../../errors/ResponseError';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import RequestError from '../../errors/RequestError';
import { customerSelector } from '../../store/selectors/DefaultSelectors';
import getAxiosConfig from './getAxiosConfig';
import sendError from './sendError';
import logger from '../../utils/logger';
import { getActualCustomerCurrent } from './CustomerActions';

const log = logger('request');

const errors = {
  403: {
    message:
      'У Вас не достаточно прав для обращения к запрашиваемому ресурсу. Обратитесь к комьюнити-менеджеру. (Код ошибки: 403)',
    toastId: 'toast_error_403'
  },
  404: {
    message: 'Сервис не доступен. Обратитесь к комьюнити-менеджеру. (Код ошибки: 404)',
    toastId: 'toast_error_404'
  },
  413: {
    message: 'Размер загружаемого содержимого превышает максимально допустимый. (Код ошибки: 413)',
    toastId: 'toast_error_413'
  },
  500: {
    message:
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже.  (Код ошибки: 500)',
    toastId: 'toast_error_500'
  },
  networkError: {
    message: 'Ошибка сетевого взаимодействия. Проверьте сетевое соединение или попробуйте осуществить запрос позже.',
    toastId: 'toast_error_network'
  },
  appError: {
    message: 'Ошибка работы приложения',
    toastId: 'toast_error_app'
  },
  notPermitted: { message: 'Operation not permitted' },
  profileNotFilled: {
    message: 'Вам необходимо заполнить профиль, чтобы воспользоваться сервисом.',
    toastId: 'toast_empty_profile'
  },
  checkCreationError: { message: 'Этот депозит не существует или он не завершен.' },
  checkIsNotExist: { message: 'Не удалось получить чек, обратитесь к комьюнити-менеджеру.' },
  checkExpired: { message: 'Срок хранения чека истек, обратитесь к комьюнити-менеджеру' }
};

const errorMessages = {
  'Deposit not found': 'checkCreationError',
  'Check not found': 'checkIsNotExist',
  'The check has expired': 'checkExpired'
};

export const showToast = (errorId, dispatch, getState) => {
  const state = getState();
  if (!state.isAuthenticated) {
    return;
  }
  const { toastId, message } = errors[errorId] || errors.appError;
  if (state.toasts && state.toasts.find(toast => toast.id === toastId)) {
    dispatch(update(toastId, { message }));
    return;
  }
  dispatch(error(message, { id: toastId, hideProgressBar: true }));
};

/**
 *Response handlers
 */

const responseHandler = async (dispatch, getState, response) => {
  log(
    'Got response from: %O %o, status: %o, params: %O',
    response && response.config ? response.config.method.toUpperCase() : '',
    response && response.config ? response.config.url.replace('https://apiworkki.dev.aic.ru/1.0', '') : '...',
    response ? response.status : '...',
    response && response.config ? response.config.params : null
  );

  if (response.status >= 200 && response.status < 300) {
    log('Response data: %O, headers: %O', response.data, response.headers);
    return response;
  }

  /*
   In response.Data, JSON with a property message may be passed, which will be used to create an error message.
   It is necessary to handle situations when JSON is passed in Blob, as well as when response.data is not an object.
  */
  let jsonData = response.data;
  if (response.data instanceof Blob) {
    try {
      jsonData = JSON.parse(await response.data.text());
    } catch {}
  }

  if (!(jsonData instanceof Object)) {
    jsonData = {};
  }

  if (errorMessages[jsonData.message]) {
    showToast(errorMessages[jsonData.message], dispatch, getState);
    log('Will throw (in Promise) response error with showed message');
    throw new ErrorWithShowedMessage(response.message, response);
  }

  if (response.status === 401) {
    const state = getState();
    removeToken();
    if (state.isAuthenticated) {
      log('User is authenticated, dispatch logout');
      dispatch(logout());
    } else {
      log('User is not authenticated, remove bad token only');
    }
  }

  if (response.status === 403) {
    dispatch(getActualCustomerCurrent()).then(resp => {
      console.log(resp.data.contract.length, 'length');
      if (resp.data.contract.length === 0) {
        showToast('profileNotFilled', dispatch, getState);
        log('Will throw (in Promise) response error with showed message');
      } else {
        showToast(403, dispatch, getState);
        log('Will throw (in Promise) response error with showed message');
      }
    });
    throw new ErrorWithShowedMessage(response.message, response);
  }

  if (response.status === 404) {
    showToast(404, dispatch, getState);
    log('Will throw (in Promise) response error with showed message');
    throw new ErrorWithShowedMessage(response.message, response);
  }

  if (response.status === 413) {
    showToast(413, dispatch, getState);
    log('Will throw (in Promise) response error with showed message');
    throw new ErrorWithShowedMessage(response.message, response);
  }

  if (response.status === 500) {
    showToast(500, dispatch, getState);
    log('Will throw (in Promise) response error with showed message');
    throw new ErrorWithShowedMessage(response.message, response);
  }

  log('Will throw (in Promise) response error');
  throw new ResponseError(response);
};

const handleSuccess = (dispatch, actionCreator) => response => {
  log('Handle success trucked response, dispatch success action');
  dispatch(actionCreator(response));
  return response;
};

const handleError = (dispatch, getState, actionCreator, shouldCatchError, requestConfig) => err => {
  log('Got trucked response error: %O', err);
  try {
    if (err.response || err instanceof ErrorWithShowedMessage) {
      log('It is handleable error, dispatch error action');
      throw err;
    }

    if (err.request) {
      log('It is network error, dispatch error action');
      showToast('networkError', dispatch, getState);
      const { request } = err;
      throw new ErrorWithShowedMessage(errors.networkError.message, request);
    }

    log('It is application error, send it to server & throw error with showed message');
    showToast('appError', dispatch, getState);
    sendError({
      error: err,
      location: 'tracked request',
      type: 'application error',
      request: requestConfig
    });
    throw new ErrorWithShowedMessage(errors.appError.message, err.request);
  } catch (caughtErr) {
    dispatch(actionCreator(caughtErr));

    if (!shouldCatchError) {
      log('ShouldCatchError is false, will throw error (in Promise)');
      throw caughtErr;
    }

    log('ShouldCatchError is true, stop error');
    return caughtErr;
  }
};

const handleErrorForUntrackedRequest = requestConfig => err => {
  log('Got trucked response error: %O', err);
  if (err instanceof ErrorWithShowedMessage) {
    log('It is response error, throw it (in Promise)');
    throw err.payload;
  }
  if (err.response) {
    log('It is response error, throw it (in Promise)');
    throw err;
  }
  if (err.request) {
    log('It is request error, throw new request (network) error (in Promise)');
    const { request } = err;
    throw new RequestError(request);
  }
  log('It is application error, throw new (app) error (in Promise)');
  sendError({
    error: err,
    location: 'untracked request',
    type: 'application error',
    request: requestConfig
  });
  throw new Error(errors.appError.message);
};

/**
 * Role handling
 */

const getCurrentRole = state => {
  const customer = customerSelector(state);
  return customer && customer.role;
};

const isPermitted = (currentRole, permissions) => {
  if (!permissions) {
    return true; // !
  }

  if (!isObject(permissions)) {
    throw new Error('permissions must be object');
  }

  const { allowTo, denyTo } = permissions;

  if (denyTo) {
    if (!isArray(denyTo)) {
      throw new Error('denyTo must be array');
    }
    return !includes(denyTo, currentRole);
  }

  if (allowTo) {
    if (!isArray(allowTo)) {
      throw new Error('allowTo must be array');
    }
    return includes(allowTo, currentRole);
  }

  return true;
};

/**
 * Request
 */

const trackedRequest = (requestConfig, actionCreators, shouldCatchError) => {
  const { permissions, ...restConfig } = requestConfig;
  log('Tracked %o request to: %o, data: %O', requestConfig.method, requestConfig.url, requestConfig.data || null);
  return (dispatch, getState) => {
    const state = getState();
    const role = getCurrentRole(state);

    if (!isPermitted(role, permissions)) {
      log('Operation not permitted, user role: %o', role);
      if (!shouldCatchError) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(errors.notPermitted.message);
      }
      return null;
    }

    dispatch(actionCreators[0]());
    return axios(restConfig)
      .then(response => responseHandler(dispatch, getState, response))
      .then(handleSuccess(dispatch, actionCreators[1]))
      .catch(handleError(dispatch, getState, actionCreators[2], shouldCatchError, requestConfig));
  };
};

const untrackedRequest = requestConfig => {
  const { permissions, ...restConfig } = requestConfig;
  log('Untracked %o request to: %o, data: %O', requestConfig.method, requestConfig.url, requestConfig.data);
  return (dispatch, getState) => {
    const state = getState();
    const role = getCurrentRole(state);

    if (!isPermitted(role, permissions)) {
      log('Operation not permitted, user role: %o', role);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(errors.notPermitted.message);
    }

    return axios(restConfig)
      .then(response => responseHandler(dispatch, getState, response))
      .catch(handleErrorForUntrackedRequest(requestConfig));
  };
};

const request = (method, url, config = {}, actions, shouldCatchError = true) => {
  const requestConfig = getAxiosConfig(method, url, config);
  if (!actions) {
    return untrackedRequest(requestConfig);
  }
  return trackedRequest(requestConfig, actions, shouldCatchError);
};

export default {
  get: (...args) => request('GET', ...args),
  put: (...args) => request('PUT', ...args),
  post: (...args) => request('POST', ...args),
  delete: (...args) => request('DELETE', ...args)
};

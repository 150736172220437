import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationElement } from '../../shared/ui/components/Navigation/NavigationElement';
import { menuItems } from '../../shared/utils/menuItems/menuItems';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  padding: 0;
  border-radius: 8px;
  width: 220px;
  margin-bottom: 8px;
  & > a {
    text-decoration: none;
  }
`;

const CustomAccordionSummary = styled(AccordionSummary)(({ theme, currentRoute }) => ({
  'padding': '0 18px',
  'borderRadius': '8px',
  'marginBottom': '8px',
  'display': 'flex',
  'alignItems': 'center',
  '&:hover': {
    backgroundColor: '#f4f4f4'
  },
  'backgroundColor': currentRoute ? '#f4f4f4' : 'white',
  '&.Mui-expanded': {
    minHeight: '44px'
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0
  },
  '.MuiAccordionSummary-contentGutters': {
    margin: 0
  },
  '.css-o4b71y-MuiAccordionSummary-content': {
    margin: 0
  },
  '.css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
    margin: 0
  }
}));

const CustomAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;
const ExpandIconWrapper = styled('div')`
  margin-right: 18px;
`;

const MainMenuWidget = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(localStorage.getItem('expanded') || false);
  const [currentRoute, setCurrentRoute] = useState(localStorage.getItem('currentRoute') || '');

  useEffect(() => {
    const selectedItem = menuItems.find(item => item.route === location.pathname);
    setCurrentRoute(selectedItem ? selectedItem.route : '');
    localStorage.setItem('currentRoute', selectedItem ? selectedItem.route : '');

    const parent = menuItems.find(
      item =>
        item.role === 'parent' &&
        menuItems.some(child => child.parentGroup === item.parentGroup && child.route === location.pathname)
    );
    if (parent) {
      setExpanded(parent.route);
      localStorage.setItem('expanded', parent.route);
    } else {
      setExpanded(false);
      localStorage.removeItem('expanded');
    }
  }, [location.pathname]);

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      localStorage.setItem('expanded', panel);
    } else {
      localStorage.removeItem('expanded');
    }
  };

  const renderMenuContent = (menuItem, index) => {
    const children = menuItems.filter(child => child.parentGroup === menuItem.parentGroup && child.role === 'child');

    if (menuItem.role === 'independent') {
      return (
        <NavigationElement
          role={menuItem.role}
          name={menuItem.name}
          route={menuItem.route}
          text={menuItem.text}
          key={index}
          currentRoute={currentRoute}
          isMobile={false}
        />
      );
    } else if (menuItem.role === 'parent') {
      return (
        <CustomAccordion
          key={index}
          expanded={expanded === menuItem.route}
          onChange={handleAccordionChange(menuItem.route)}
        >
          {/*<Link to={menuItem.route}>*/}
          <CustomAccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            currentRoute={currentRoute === menuItem.route}
          >
            <NavigationElement
              role={menuItem.role}
              name={menuItem.name}
              route={menuItem.route}
              text={menuItem.text}
              currentRoute={currentRoute}
              isMobile={false}
            />
          </CustomAccordionSummary>
          {/*</Link>*/}
          <CustomAccordionDetails>
            {children.map((child, childIndex) => (
              <NavigationElement
                key={`${index}-${childIndex}`}
                role={child.role}
                name={child.name}
                route={child.route}
                text={child.text}
                currentRoute={currentRoute}
                isMobile={false}
              />
            ))}
          </CustomAccordionDetails>
        </CustomAccordion>
      );
    }
    return null;
  };

  return (
    <>
      {menuItems
        .filter(menuItem => menuItem.role !== 'child' && menuItem.includeDesktop)
        .map((menuItem, index) => (
          <div key={menuItem.route}>{renderMenuContent(menuItem, index)}</div>
        ))}
    </>
  );
};

export default MainMenuWidget;

import { createSelector } from 'redux-orm';
import orm from '../models/orm';

const dbStateSelector = state => state.entities;

export const depositsSelector = createSelector(
  orm,
  // The first input selector should always select the db-state.
  // Behind the scenes, `createSelector` begins a Redux-ORM session
  // with the value returned by `dbStateSelector` and passes
  // that Session instance as an argument instead.
  dbStateSelector,
  session => {
    return session.Deposit.all()
      .toModelArray()
      .sort((a, b) => Number(b.id || 0) - Number(a.id || 0))
      .map(deposit => {
        //   console.log('selector payments', deposit.payment_system_id.ref)
        // console.log('currency', deposit.currency_id.ref)
        // console.log('selector deposit_status', deposit.status_id.ref)
        //    console.log('troool',deposit.test)
        const obj = deposit.ref;
        const payment = deposit.payment_system_id ? deposit.payment_system_id.ref : null;
        const currency = deposit.currency_id ? deposit.currency_id.ref : null;
        const deposit_status = deposit.status_id ? deposit.status_id.ref : null;
        //  console.log('troool',obj.test)
        // console.log('selector payments', deposit.payment_system_id.ref)
        //   console.log('currency', deposit.currency_id.ref)
        //   console.log('selector deposit_status', deposit.status_id.ref)
        return Object.assign({}, obj, {
          payment,
          currency,
          deposit_status
        });
      });
  }
);

export const currencySelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.CurrencyType.all()
      .toModelArray()
      .map(currency => {
        const obj = currency.ref;
        return Object.assign({}, obj);
      });
  }
);

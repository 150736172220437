import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationElement } from '../../shared/ui/components/Navigation/NavigationElement';
import { menuItems } from '../../shared/utils/menuItems/menuItems';
import { styled } from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';

const MobileMenuBackground = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 12;
  background-color: #ffffff;
  color: $workki-dark-main;
  background-attachment: fixed;
  position: fixed;
  left: 0;
  height: 56px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  bottom: 0;
  transform: translateZ(0);

  @media (min-width: ${screenRessolutions.laptop}px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  @media (min-width: 320px) {
    margin: 0;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 638px;
    height: 56px;
  }
`;
const MobileMenuWidget = () => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(localStorage.getItem('currentRoute') || '');

  useEffect(() => {
    const selectedItem = menuItems.find(item => item.route === location.pathname);
    setCurrentRoute(selectedItem ? selectedItem.route : '');
    localStorage.setItem('currentRoute', selectedItem ? selectedItem.route : '');
  }, [location.pathname]);

  const renderMainMenuContent = () => {
    const parentRoutes = menuItems.filter(item => item.includeMobile);

    return parentRoutes.map((menuItem, index) => (
      <NavigationElement
        key={menuItem.route}
        role={menuItem.role}
        name={menuItem.name}
        route={menuItem.route}
        text={menuItem.text}
        currentRoute={currentRoute}
        isMobile={true}
      />
    ));
  };

  return (
    <MobileMenuBackground>
      <MobileMenu>{renderMainMenuContent()}</MobileMenu>
    </MobileMenuBackground>
  );
};

export default MobileMenuWidget;

import api from '../../../../services/workki/Request';
import { getRequestStatusActions } from '../../../../utils/createRequestActions';
import * as actions from '../../../../store/actions';

export const receiveHints = company => {
  const data = { company: company };
  return api.post(
    '/customer/info/company/identifiers/hints',
    { data },
    getRequestStatusActions(actions.requestServiceRequestCreate),
    false
  );
};
export const receiveAddressHints = address => {
  // const data = { company: company };
  return api.get(`/customer/info/address?address=${address}`);
};

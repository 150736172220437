import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
const LoaderWrapper = styled.div`
  background: ${props => (props.wrapperType ? props.wrapperType : `rgba(73, 71, 71, 0.4)`)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`;
export const Loader = ({ wrapperType }) => {
  return (
    <LoaderWrapper wrapperType={wrapperType}>
      <>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id='my_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset='0%' stopColor='#575b60' />
              <stop offset='100%' stopColor='#8f9295' />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{
            'position': 'absolute',
            'top': '400px',
            'svg circle': { stroke: 'url(#my_gradient)', strokeLinecap: 'round' }
          }}
          size={40}
          thickness={5}
        />
      </>
    </LoaderWrapper>
  );
};

import { fk, attr, Model } from 'redux-orm';
import * as actions from '../actions';

class ServiceRequest extends Model {
  static reducer(action, serviceRequest) {
    switch (action.type) {
      case actions.requestServicesRequests.success.toString():
        action.payload.map(elem => serviceRequest.upsert(elem));
        break;
      case actions.requestServiceRequestCreate.success.toString():
        serviceRequest.upsert(action.payload);
        break;
      case actions.requestServicesSubscriptionSuccess.toString():
        serviceRequest.withId(action.payload.id).update(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `ServiceRequest: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
ServiceRequest.modelName = 'ServiceRequest';

// Declare your related fields.
ServiceRequest.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  //  name: attr(),
  service_id: fk('Service', 'reservation_place')
};

export default ServiceRequest;

import { createSelector } from 'redux-orm';
import moment from 'moment';
import { isNil } from 'lodash';
import orm from '../models/orm';

const dbStateSelector = state => state.entities;

export const ticketsSelector = createSelector(
  orm,
  dbStateSelector,
  session =>
    session.Ticket.all()
      .toModelArray()
      .sort((a, b) => {
        const timeA = moment(a.created_at, 'YYYY-MM-DD HH:mm:ss');
        const timeB = moment(b.created_at, 'YYYY-MM-DD HH:mm:ss');
        return timeA.isBefore(timeB) ? 1 : -1;
      })
      .map(item => {
        const category = !isNil(item.category_id) ? item.category_id.ref : null;
        const place = !isNil(item.service_id) ? item.service_id.ref : null;
        const office = !isNil(place) && !isNil(place.office_id) ? item.service_id.office_id.ref : null;
        return { ...item.ref, category, place, office };
      })
);

export const ticketCategoriesSelector = createSelector(
  orm,
  dbStateSelector,
  session => session.TicketCategory.all().toRefArray()
);

export const getTicketSelector = id => state => state.entities.Ticket.itemsById[id];

import EventActionWorkkiMiddleware from './EventActionWorkkiMiddleware';
import reqCustomerFiles from './reqCustomerFiles';
import reqCustomerProfileSettings from './reqCustomerProfileSettings';
import reqTicketCommentsDeferred from './reqTicketCommentsDeferred';
import reqTicketCommentsIfCheckReturnTrue from './reqTicketCommentsIfCheckReturnTrue';

export default [
  EventActionWorkkiMiddleware,
  reqCustomerProfileSettings,
  reqCustomerFiles,
  reqTicketCommentsDeferred,
  reqTicketCommentsIfCheckReturnTrue
];

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ServicesDialog } from '../../widgets/Services/ServicesDialog';
import { CommonServiceSuccessDialog } from '../../widgets/Services/CommonServiceSuccessDialog';

const InputTMCPanel = props => {
  const [openDialog, setOpenDialog] = useState({ tmc: false, success: false });
  useEffect(() => {
    if (props.outerModalOpen) {
      setOpenDialog(prevState => ({ ...prevState, tmc: true }));
    }
  }, [props.outerModalOpen]);

  useEffect(() => {
    if (props.tmcInputCreateRequestStatus.isSuccess) {
      setOpenDialog(prevState => ({ ...prevState, tmc: false, success: true }));
      props.setOuterModalOpen(false, 'tmc');
    }
  }, [props.tmcInputCreateRequestStatus]);
  const loadingError = useMemo(() => {
    if (props.tmcInputListRequestStatus && !props.tmcInputListRequestStatus.isFailure) {
      return null;
    }

    const loadingError = props.getError();

    return loadingError !== '' ? loadingError : null;
  }, [props.getError, props.tmcInputListRequestStatus]);

  return (
    <>
      {openDialog.tmc && (
        <ServicesDialog
          title='Пропуск на внос/вынос ТМЦ'
          serviceProps={{ ...props, loadingError }}
          workplaces={props.locations}
          dialogType='tmc'
          handleSubmit={() => {
            setOpenDialog(prevState => ({ ...prevState, tmc: false }));
            props.setOuterModalOpen(false, 'tmc');
          }}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, tmc: false }));
            props.setOuterModalOpen(false, 'tmc');
          }}
        />
      )}
      {openDialog.success && (
        <CommonServiceSuccessDialog
          dialogType='commomServiceSuccess'
          title='Заявка отправлена'
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, success: false }));
          }}
        />
      )}
    </>
  );
};

InputTMCPanel.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  tmcInputListRequestStatus: PropTypes.object.isRequired
};

export default InputTMCPanel;

import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' filter='url(#filter0_d_4641_51304)'>
      <path
        d='M1260 1270H1570L1570 9.99997L1240 10V0L1215 2.18557e-06V10L1185 10V4.80825e-06L1160 6.99382e-06V10L1040 10V1.74846e-05L1015 1.96701e-05V10L985 10V2.22928e-05L960 2.44784e-05V10L640 10.0001V5.24537e-05L615 5.46392e-05V10.0001L585 10.0001V5.72619e-05L560 5.94475e-05V10.0001L440 10.0001V6.99382e-05L415 7.21238e-05V10.0001L385 10.0001V7.47465e-05L360 7.6932e-05V10.0001L240 10.0001V8.74228e-05L215 8.96083e-05V10.0001L185 10.0001V9.2231e-05L160 9.44166e-05V10.0001L30 10.0001V0.000105782L15 0.000107093L15 10.0001L0 10.0001L9.61651e-05 1110H210V1085L1260 1085V1270ZM670 480H470V600H670V480Z'
        fill='white'
      />
    </g>
  );
};

export const Neo_Geo_A_Floor = memo(SVGComponents);

import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { officeSelector, customerSelector } from '../../store/selectors/DefaultSelectors';
import { procedure } from '../../store/actions/procedure';
import { cleanErrors } from '../../store/actions';
import {
  getSchedule,
  reserveRoom,
  getServicePaidUnitsCount,
  getLocationPaidUnitsCount
} from '../../services/workki/ServicesActions';
import { roles } from '../../roles';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import { customerContractsSelector } from '../../store/selectors/DefaultSelectors';
import { getCustomerContracts } from '../../services/workki/CustomerActions';
import { getDay } from '../../services/workki/TypesActions';

moment.tz.setDefault('Europe/Moscow');

const mapStateToProps = state => {
  const customer = customerSelector(state);
  const offices = officeSelector(state)
    .filter(office => office.active)
    .filter(function(location) {
      let disabledFloors = location.officeFloors.filter(floor => !floor.active);
      return disabledFloors.length !== location.officeFloors.length;
    });

  return {
    offices,
    customerUI: state.ui.customerCurrent,
    customer,
    contracts: customerContractsSelector(state),
    isGuest: customer && customer.role === roles.guest,
    reserveRoomUI: state.ui.serviceReserveRoom,
    getError: () => {
      if (state.ui.serviceReserveRoom.isError) {
        const { error } = state.ui.serviceReserveRoom;
        if (!error || error instanceof ErrorWithShowedMessage) {
          return '';
        }
        return (
          (error.response &&
            error.response.data &&
            Array.isArray(error.response.data) &&
            error.response.data[0].message) ||
          'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
        );
      }
      return '';
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLocationPaidUnitsCount: (...args) => dispatch(getLocationPaidUnitsCount(...args)),
    getServicePaidUnitsCount: (...args) => dispatch(getServicePaidUnitsCount(...args)),
    getContracts: () => dispatch(getCustomerContracts()),
    getDay: () => dispatch(getDay()),
    dispatchReserveRoom: (service_id, start_at, finish_at, contract) => {
      const func = [
        () => reserveRoom(service_id, start_at, finish_at, contract),
        () =>
          getSchedule(
            moment()
              .subtract(2, 'days')
              .format('YYYY-MM-DD')
          )
      ];
      return dispatch(procedure(func));
    },
    cleanErrors: () => dispatch(cleanErrors())
  };
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component);

import React from 'react';

const NotHistory = ({ text }) => {
  return (
    <div className='not-history'>
      <p>{text}</p>
    </div>
  );
};

export default NotHistory;

/**
 * Возвращает блтжайшую к value точку из интервала [low, high]
 * @param value
 * @param low
 * @param high
 */
export function clamp(value, low, high) {
  let result = value;
  if (low > result) {
    result = low;
  }
  if (high && result > high) {
    result = high;
  }
  return result;
}

import React from 'react';
import Loader from 'react-loader-spinner';

const SimpleBalancePanel = ({ account }) => {
  return (
    <section className='page__section balance-panel balance-panel_sm'>
      <div className='balance-panel__inner'>
        <div className='balance-panel__state'>
          <div className='balance-panel__state'>
            <span className='balance-panel__subhead'>Общий баланс:</span>
            {account ? (
              <span className='balance-panel__integer'>{account.amount}</span>
            ) : (
              <div className='balance-panel__loader'>
                <Loader type='ThreeDots' color='red' height='10' width='20' />
              </div>
            )}
            <span className='balance-panel__currency'>&#8381;</span>
          </div>
          <div className='balance-panel__state'>
            <span className='balance-panel__subhead--security'>Обеспечительный баланс:</span>
            {account ? (
              <span className='balance-panel__integer--security'>{account.security_payment_amount}</span>
            ) : (
              <div className='balance-panel__loader'>
                <Loader type='ThreeDots' color='red' height='10' width='20' />
              </div>
            )}
            <span className='balance-panel__currency--security'>&#8381;</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleBalancePanel;

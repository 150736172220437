import axios from 'axios';
import getAxiosConfig from './getAxiosConfig';
import logger from '../../utils/logger';

const log = logger('send error');

export default ({ error, info, ...restPayload }) => {
  const errorMessage = error && error.message;
  log('Send error. Error: %o, Info: %o', errorMessage, info);
  const config = getAxiosConfig('POST', '/customer/error', {
    data: { error: errorMessage, info, ...restPayload }
  });
  return axios(config).catch(() => {});
};

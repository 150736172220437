import * as actions from '../actions';
import { getTicketComments } from '../../services/workki/TicketsActions';

export default store => next => action => {
  if (actions.requestCheckHasTicketNewComment.success.toString() === action.type && action.payload.result) {
    store.dispatch(getTicketComments(Number(action.payload.ticketId)));
  }

  return next(action);
};

import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';
import { PhoneMask } from '../../shared/ui/icons/core/PhoneMask';

export const ChangeUserDataDialog = props => {
  //TODO по сути тут можно заменить идентичные поля на единообразные и в компоненте ниже
  const phoneConfirmationFields = [
    {
      view: 'field',
      type: 'tel',
      name: 'phone',
      label: 'Телефон',
      valueKey: 'phone',
      errorKey: 'phone',
      context: '',
      autoFocus: true,
      placeholder: '999 999-99-99',
      startAdornment: <PhoneMask spacing='0' defaultColor='#24272A' />,
      complexValueKey: false
    },
    {
      view: 'field',
      type: 'email',
      name: 'email',
      label: 'E-mail',
      valueKey: 'register',
      errorKey: 'register',
      context: 'register',
      autoFocus: false,
      complexValueKey: true
    }
  ];
  const phoneAuthfields = [
    {
      view: 'field',
      type: 'tel',
      name: 'phone',
      label: 'Телефон',
      valueKey: 'phone',
      errorKey: 'phone',
      context: '',
      autoFocus: true,
      placeholder: '999 999-99-99',
      startAdornment: <PhoneMask spacing='0' defaultColor='#24272A' />,
      complexValueKey: false
    }
  ];

  return (
    <Dialog
      title={props.dialogType === 'phoneConfirmation' ? 'Изменить введенные данные' : 'Изменить номер'}
      dialogType={props.dialogType}
      fields={props.dialogType === 'phoneConfirmation' ? phoneConfirmationFields : phoneAuthfields}
      buttonLabel='Изменить'
      requestSentMessage=''
      requestSentDescription=''
      {...props}
    />
  );
};

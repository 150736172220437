import React from 'react';
import AuthLayout from '../layouts/AuthLayout';

// For checking link for payment via yandex
const PaymentProcessErrorPage = props => {
  return (
    <AuthLayout>
      <main className='main'>
        <div className='registration process-error'>
          <div className='registration__final-data'>
            <p className='registration__final-data-notice'>
              Срок действия ссылки для осуществления платежа&nbsp;истек.
              <br />
              <span>Вам необходимо запросить новую.</span>
            </p>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
};

export default PaymentProcessErrorPage;

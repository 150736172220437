import React from 'react';
import * as analytics from '../analytics';

const AuthLayout = ({ children }) => {
  analytics.pageView(window.location);
  return (
    <div>
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const Declined = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        d='M19.1057 19.1057C19.7403 18.4711 20.7692 18.4711 21.4038 19.1057L26 23.7019L30.5962 19.1057C31.2308 18.4711 32.2597 18.4711 32.8943 19.1057C33.5289 19.7403 33.5289 20.7692 32.8943 21.4038L28.2981 26L32.8943 30.5962C33.5289 31.2308 33.5289 32.2597 32.8943 32.8943C32.2597 33.5289 31.2308 33.5289 30.5962 32.8943L26 28.2981L21.4038 32.8943C20.7692 33.5289 19.7403 33.5289 19.1057 32.8943C18.4711 32.2597 18.4711 31.2308 19.1057 30.5962L23.7019 26L19.1057 21.4038C18.4711 20.7692 18.4711 19.7403 19.1057 19.1057Z'
        fill='#DB4344'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.2114 39.7886C4.59619 32.1734 4.59619 19.8266 12.2114 12.2114C19.8266 4.59619 32.1734 4.59619 39.7886 12.2114C47.4038 19.8266 47.4038 32.1734 39.7886 39.7886C32.1734 47.4038 19.8266 47.4038 12.2114 39.7886ZM14.5095 14.5095C8.1635 20.8555 8.1635 31.1445 14.5095 37.4905C20.8555 43.8365 31.1445 43.8365 37.4905 37.4905C43.8365 31.1445 43.8365 20.8555 37.4905 14.5095C31.1445 8.1635 20.8555 8.16349 14.5095 14.5095Z'
        fill='#DB4344'
      />
    </StyledSvg>
  );
};

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import AlternativeLogin from './AlternativeLogin';

const AuthLogin = () => {
  return (
    <section className='authorization'>
      <Switch>
        <Redirect exact from='/authorization' to='/authorization/login' />
        <Route exact path='/authorization/login' component={AlternativeLogin} />
      </Switch>
    </section>
  );
};

export default AuthLogin;

import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class DepositBank extends Model {
  static reducer(action, depositBank) {
    switch (action.type) {
      case actions.requestDepositBanks.success.toString():
        action.payload.map(elem => depositBank.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `DepositBank: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
DepositBank.modelName = 'DepositBank';

// Declare your related fields.
DepositBank.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr()
};

export default DepositBank;

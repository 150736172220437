import { useMemo } from 'react';
import { debounce } from 'lodash';

import { useEventListener } from '../hooks/useEventListener';

/**
 * useResize - хук, который отслеживает изменение разрешения экрана
 * Оборачивает callback в debounce
 * @param callback
 * @param wait - задержка в секундах
 * @returns {void}
 */
export const useResize = (callback, wait = 250) => {
  const handleResize = useMemo(
    () => (wait !== 0 ? debounce(event => callback(event), wait) : event => callback(event)),
    [wait, callback]
  );

  useEventListener({
    type: 'resize',
    listener: handleResize,
    options: { passive: true }
  });
};

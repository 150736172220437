import { defaultsDeep } from 'lodash';
import { getToken } from '../../utils/token';
import { API_URL } from '../../constants';

const defaultConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  timeout: 60000,
  validateStatus: () => true // default: response.status >= 200 && response.status < 300
};

const accessToken = () => {
  const token = getToken();
  return token ? `Bearer ${token.access_token}` : null;
};

export default (method, url, config) => {
  const preparedConfig = defaultsDeep(
    { ...config, method, url },
    {
      headers: { Authorization: accessToken() }
    }
  );
  return defaultsDeep(preparedConfig, defaultConfig);
};

import { omitBy, isNull } from 'lodash';
import * as actions from '../../../store/actions';
import api from '../../../services/workki/Request';
import { getRequestStatusActions } from '../../../utils/createRequestActions';

export const getDeposits = page => {
  return api.get(
    '/deposit',
    { params: { page: page || 1, sort: '-id' } },
    getRequestStatusActions(actions.requestDeposits)
  );
};

export const createDeposit = ({
  payment_system_id,
  amount,
  currency_id,
  office_id = 1,
  is_temporary_booking = false,
  is_security_payment = 0,
  payment_system_item_id = null
}) => {
  const data = omitBy(
    {
      payment_system_id,
      is_temporary_booking,
      amount,
      currency_id,
      office_id,
      is_security_payment,
      payment_system_item_id,
      return_url: 'https://my.workki.co/payments'
    },
    isNull
  );
  return api.post('/deposit', { data }, getRequestStatusActions(actions.requestCreateDeposit));
};

export const getDepositBanks = () => {
  return api.get(
    '/deposit/bank',
    { params: { _disablePagination: 1 } },
    getRequestStatusActions(actions.requestDepositBanks)
  );
};

export const getDepositStatuses = () => {
  return api.get('/deposit/status', { _disablePagination: 1 }, getRequestStatusActions(actions.requestDepositStatuses));
};

export const getBinaryFileDeposit = id =>
  api.get(`/deposit/pdf/${id}`, {
    responseType: 'blob',
    timeout: 60000
  });

export const getBinaryFileDepositReceipt = id =>
  api.get(`/deposit/pdf-receipt/${id}`, {
    responseType: 'blob',
    timeout: 60000
  });
export const getPaymentDocument = id =>
  api.get(`service/request/file?id=${id}`, {
    responseType: 'blob',
    timeout: 60000
  });

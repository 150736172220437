import { fk, attr, Model } from 'redux-orm';
import * as actions from '../actions';

class ServiceFile extends Model {
  static reducer(action, serviceFile) {
    switch (action.type) {
      case actions.requestServicesFiles.success.toString():
        action.payload.map(elem => serviceFile.upsert(elem));
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `ServiceFile: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
ServiceFile.modelName = 'ServiceFile';

ServiceFile.options = {
  // idAttribute: 'service_id',
};
// Declare your related fields.
ServiceFile.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  //  name: attr(),
  service_id: fk('Service', 'files'),
  type_id: fk('ServiceFileType', 'type')
};

export default ServiceFile;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const ProfileIcon = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <g clip-path='url(#clip0_3367_3847)'>
        <circle cx='16' cy='16' r='16' fill='#C8C9CA' />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.25 8C13.3505 8 11 10.3505 11 13.25C11 16.1495 13.3505 18.5 16.25 18.5C19.1495 18.5 21.5 16.1495 21.5 13.25C21.5 10.3505 19.1495 8 16.25 8ZM12.5 13.25C12.5 11.1789 14.1789 9.5 16.25 9.5C18.3211 9.5 20 11.1789 20 13.25C20 15.3211 18.3211 17 16.25 17C14.1789 17 12.5 15.3211 12.5 13.25Z'
          fill='white'
        />
        <path
          d='M9.32578 24.2306C10.647 22.6479 13.2097 21.5 16.25 21.5C19.2904 21.5 21.853 22.6479 23.1743 24.2306C23.4397 24.5486 23.9127 24.5912 24.2306 24.3258C24.5486 24.0603 24.5912 23.5874 24.3258 23.2694C22.6529 21.2653 19.6193 20 16.25 20C12.8807 20 9.84717 21.2653 8.17426 23.2694C7.90882 23.5874 7.95141 24.0603 8.26939 24.3258C8.58738 24.5912 9.06034 24.5486 9.32578 24.2306Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3367_3847'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};

import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styleConstants';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || colors.workkiDarkMiddle};
    :hover {
      ${props => props.hoverColor || colors.workkiDarkMiddle};
    }
  }
  cursor: pointer;
  transform: rotate(${props => props.rotation || '0deg'});
`;
export const Hint = ({ defaultColor, hoverColor, rotation, bigArrow, onClick, ...props }) => {
  return (
    <StyledSvg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      hoverColor={hoverColor}
      rotation={rotation}
      onClick={onClick}
      {...props}
    >
      <path d='M8 11C8.27614 11 8.5 10.7761 8.5 10.5C8.5 10.2239 8.27614 10 8 10C7.72386 10 7.5 10.2239 7.5 10.5C7.5 10.7761 7.72386 11 8 11Z' />
      <path d='M8 6C7.44772 6 7 6.44772 7 7C7 7.27614 6.77614 7.5 6.5 7.5C6.22386 7.5 6 7.27614 6 7C6 5.89543 6.89543 5 8 5C9.10457 5 10 5.89543 10 7C10 7.77232 9.411 8.16208 9.07148 8.38675L9.02735 8.41603C8.60694 8.6963 8.5 8.80698 8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9C7.5 8.22768 8.089 7.83792 8.42852 7.61325L8.47265 7.58397C8.89306 7.3037 9 7.19302 9 7C9 6.44772 8.55228 6 8 6Z' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8Z'
      />
    </StyledSvg>
  );
};

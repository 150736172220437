import React from 'react';
import { InputBoxes } from '../../shared/ui/components/InputBoxes';
import { ProfileIcon } from '../../shared/ui/icons/core/ProfileIcon';

const PhoneAuthForm = ({
  phoneNumber,
  email,
  messageType,
  handleSendCode,
  handleResendMessage,
  handleRegisterSubmit,
  setIsChangeData,
  setRegistrationStep,
  infoMessage,
  errors,
  entranceFormClass,
  RESEND_INTERVAL,
  setTimer,
  divider,
  handlePhoneAuthClick
}) => {
  const formatFlashCallPhone = phone => {
    if (phone && phone.length === 11) {
      return `${'+' +
        phone[0] +
        ' ' +
        phone.slice(1, 4) +
        ' ' +
        phone.slice(4, 7) +
        '-' +
        phone.slice(7, 9) +
        '-' +
        phone.slice(9, 11)}`;
    } else return phone;
  };
  return (
    <div>
      <div className='authorization__phone-caption'>
        <p>
          Вход по номеру
          <br /> телефона
        </p>
        <div className='authorization__phone-caption__phoneAuthWrapper'>
          <span>+7 {formatFlashCallPhone(phoneNumber)}</span>
          <a onClick={() => setIsChangeData(prevState => ({ ...prevState, open: true, type: 'phoneAuth' }))}>
            <span>Изменить</span>
          </a>
        </div>
      </div>
      <div className='authorization__phone-main'>
        <p>
          Введите <span>{messageType === 'call' ? 'последние 4 цифры номера' : 'код из СМС'}</span>
          <br />
          {messageType === 'call' && 'с которого вам звонили'}
        </p>
        {messageType === 'call' && (
          <div className='authorization__phone-main__bubble'>
            <div className='authorization__phone-main__bubble__wrapper'>
              <div className='authorization__phone-main__call'>
                <p>
                  +7 XXX XXX-<span>XX-XX</span>
                </p>
                <p>Входящий вызов</p>
              </div>
              <div className='authorization__phone-main__profile'>
                <ProfileIcon spacing={'4px'} />
              </div>
            </div>
          </div>
        )}
        <div className='authorization__phone-main__phone-fields'>
          <InputBoxes
            length={4}
            error={errors.code}
            handleRequest={value => {
              handleSendCode(value);
            }}
          />
        </div>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={handleRegisterSubmit}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <div className='authorization__footer'>
          <div className='authorization__footer__links-wrapper'>
            <a
              onClick={() => {
                if (!infoMessage.isDisabled) {
                  handleResendMessage();
                  setRegistrationStep(prevStep => ({
                    ...prevStep,
                    destination: 'sms'
                  }));
                  setTimer(RESEND_INTERVAL);
                }
              }}
            >
              <span
                className={`authorization__footer__links-wrapper__${
                  infoMessage.isDisabled ? 'timer-disabled' : 'timer-active'
                }`}
              >
                {infoMessage.message}
              </span>
            </a>
            <img src={divider} alt='divider' />
            <a href='#' onClick={handlePhoneAuthClick}>
              Войти по e-mail и паролю
            </a>
            <p className='authorization__footer__policy'>
              Выполняя вход, вы соглашаетесь{' '}
              <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
                с договором публичной оферты
              </a>
              &#160;и&#160;
              <a href='https://workki.co/docs/Personal-data.pdf' target='_blank'>
                правилами обработки данных
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PhoneAuthForm;

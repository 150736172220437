import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  // margin-right: ${props => props.spacing || '16px'};
  width: ${props => props.width || '12px'};
  height: ${props => props.height || '12px'};
`;

export const LinkArrow = ({ defaultColor, spacing, width, height, type, ...props }) => {
  return (
    <StyledSvg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      {...props}
    >
      {type === 'small' ? (
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.72737 2.56063C3.94704 2.34096 4.3032 2.34096 4.52287 2.56063L8.27287 6.31063C8.49254 6.5303 8.49254 6.88645 8.27287 7.10612L4.52287 10.8561C4.3032 11.0758 3.94704 11.0758 3.72737 10.8561C3.5077 10.6365 3.5077 10.2803 3.72737 10.0606L7.07963 6.70837L3.72737 3.35612C3.5077 3.13645 3.5077 2.7803 3.72737 2.56063Z'
          fill='#BA393A'
        />
      ) : (
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4.96979 3.30317C5.26269 3.01027 5.73756 3.01027 6.03045 3.30317L11.0305 8.30317C11.3233 8.59606 11.3233 9.07093 11.0305 9.36383L6.03045 14.3638C5.73756 14.6567 5.26269 14.6567 4.96979 14.3638C4.6769 14.0709 4.6769 13.5961 4.96979 13.3032L9.43946 8.8335L4.96979 4.36383C4.6769 4.07093 4.6769 3.59606 4.96979 3.30317Z'
          fill='#8F9295'
        />
      )}
    </StyledSvg>
  );
};

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
export const procedure = (func = [], errorHandler = null) => async dispatch => {
  try {
    for (const i in func) {
      if ({}.hasOwnProperty.call(func, i)) {
        await dispatch(func[i]());
      }
    }
  } catch (err) {
    if (typeof errorHandler === 'function') {
      dispatch(errorHandler(err));
    }
  }
};

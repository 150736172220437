import { combineReducers } from 'redux';

function save_state(state, payload, isFetching = false, isSuccess = false, isError = false, initialData = false) {
  return { ...state, isFetching, isSuccess, isError, initialData, ...payload };
}

function requestWithNamedType(requestName = '') {
  return (state = { initialData: true, isFetching: false, isSuccess: false, isError: false }, action) => {
    switch (action.type) {
      case `${requestName}_PENDING`:
        return save_state(state, { error: {} }, true, false, false, false);
      case `${requestName}_REQUEST`:
        return save_state(state, { error: {} }, true, false, false, false);
      case `${requestName}_SAVE_SUCCESS`:
        return save_state(state, { ...action.payload, error: {} }, false, true, false);
      case `${requestName}_SUCCESS`:
        return save_state(state, { error: {} }, false, true, false);
      case `${requestName}_FAILURE`:
        return save_state(state, { error: action.payload }, false, false, true);
      case `CLEAN_ERRORS`:
        return save_state(state, { error: {} }, false, false, false);
      default:
        return state;
    }
  };
}

export default combineReducers({
  customerCurrent: requestWithNamedType('CUSTOMER_CURRENT'),
  customerFiles: requestWithNamedType('CUSTOMER_FILES'),
  paymentSystem: requestWithNamedType('PAYMENT_SYSTEM'),
  serviceFileType: requestWithNamedType('SERVICE_FILE_TYPE'),
  serviceType: requestWithNamedType('SERVICE_TYPE'),
  office: requestWithNamedType('OFFICE'),
  createCustomerIndividualProfile: requestWithNamedType('CREATE_CUSTOMER_INDIVIDUAL'),
  createCustomerCompany: requestWithNamedType('CREATE_CUSTOMER_COMPANY'),
  account: requestWithNamedType('ACCOUNT'),
  services: requestWithNamedType('SERVICES'),
  servicesSchedule: requestWithNamedType('SERVICES_SCHEDULE'),
  serviceReserveRoom: requestWithNamedType('SERVICES_RESERVE_ROOM'),
  serviceAddConsume: requestWithNamedType('SERVICES_CONSUME_ADD')
});

import React, { Component } from 'react';
import { pickBy, keys, isEqual, isArray, isString } from 'lodash';
import ErrorWithShowedMessage from '../errors/ErrorWithShowedMessage';

const statuses = {
  init: 'init',
  changed: 'changed',
  pending: 'pending',
  success: 'success',
  onError: 'onError'
};

const defaultErrorMessage =
  'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже';

export default function FormWrapper(initState, validators = {}) {
  const requiredFields = keys(pickBy(validators, v => v.required));
  const regexValidators = pickBy(validators, v => v.regex);

  return function Wrapper(WrappedComponent) {
    return class WrappedForm extends Component {
      state = {
        status: statuses.init,
        data: { ...initState },
        initData: { ...initState },
        errors: [],
        apiResponse: null
      };

      initForm = initData => {
        this.setState({
          status: statuses.init,
          data: { ...initData },
          initData: { ...initData }
        });
      };

      setValue = (name, value) => {
        this.setState(({ data }) => ({
          status: statuses.changed,
          data: {
            ...data,
            [name]: value
          }
        }));
      };

      handleInput = e => {
        e.preventDefault();
        const { status } = this.state;
        if (status === statuses.pending) {
          return;
        }
        const { name, value } = e.target;
        this.setValue(name, value);
      };

      setCustomError = error => {
        this.setState({
          status: statuses.onError,
          errors: [{ message: error }]
        });
      };

      getHandleSubmit = apiMethod => e => {
        e.preventDefault();
        const { data, initData } = this.state;

        if (!apiMethod) {
          // eslint-disable-next-line no-console
          console.error('Отсутствует метод отправки формы');
          return;
        }

        const isValidRequired = requiredFields.reduce(
          (acc, key) => acc && !!(isString(data[key]) ? data[key].trim() : data[key]),
          true
        );
        if (!isValidRequired) {
          this.setState({
            status: statuses.onError,
            errors: [{ message: 'Заполнены не все поля' }]
          });
          return;
        }

        if (isEqual(data, initData)) {
          this.setState({
            status: statuses.onError,
            errors: [{ message: 'Изменения отсутствуют' }]
          });
          return;
        }

        const result = keys(regexValidators).reduce(
          (acc, key) => {
            // if (!acc.isValid) {
            //   return acc;
            // }
            const { regex, msg } = regexValidators[key];
            const isValid = regex.test(data[key]);
            if (isValid) {
              return acc;
            }
            const errorMessage = msg || `Поле "${key}" заполнено неверно`;
            return { isValid, errors: [...acc.errors, { message: errorMessage }] };
          },
          { isValid: true, errors: [] }
        );
        if (!result.isValid) {
          this.setState({
            status: statuses.onError,
            errors: result.errors
          });
          return;
        }
        this.setState({
          status: statuses.pending,
          errors: []
        });
        apiMethod(data)
          .then(response => {
            this.setState({
              status: statuses.success,
              errors: [],
              apiResponse: response
            });
          })
          .catch(err => {
            if (err instanceof ErrorWithShowedMessage) {
              this.setState({ errors: [], status: statuses.onError });
              return;
            }

            if (!!err.response) {
              const errors = isArray(err.response.data)
                ? err.response.data
                : [{ message: 'Форма заполнена не верно.' }];
              this.setState({ errors, status: statuses.onError });
              return;
            }

            this.setState({ errors: [{ message: defaultErrorMessage }], status: statuses.onError });
          });
      };

      render() {
        const { status, ...restState } = this.state;
        const { props } = this;
        const formStatuses = {
          hasError: status === statuses.onError,
          isChanged: status === statuses.changed,
          onPending: status === statuses.pending,
          onSuccess: status === statuses.success,
          onInit: status === statuses.init
        };
        return (
          <WrappedComponent
            {...restState}
            {...formStatuses}
            {...props}
            handleInput={this.handleInput}
            setCustomError={this.setCustomError}
            getHandleSubmit={this.getHandleSubmit}
            initForm={this.initForm}
            setValue={this.setValue}
          />
        );
      }
    };
  };
}

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const Pending = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        d='M27.625 14.625C27.625 13.7275 26.8975 13 26 13C25.1025 13 24.375 13.7275 24.375 14.625V26C24.375 26.8975 25.1025 27.625 26 27.625H37.375C38.2725 27.625 39 26.8975 39 26C39 25.1025 38.2725 24.375 37.375 24.375H27.625V14.625Z'
        fill='#C8C9CA'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M26 6.5C15.2304 6.5 6.5 15.2304 6.5 26C6.5 36.7696 15.2304 45.5 26 45.5C36.7696 45.5 45.5 36.7696 45.5 26C45.5 15.2304 36.7696 6.5 26 6.5ZM9.75 26C9.75 17.0254 17.0254 9.75 26 9.75C34.9746 9.75 42.25 17.0254 42.25 26C42.25 34.9746 34.9746 42.25 26 42.25C17.0254 42.25 9.75 34.9746 9.75 26Z'
        fill='#C8C9CA'
      />
    </StyledSvg>
  );
};

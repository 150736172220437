import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

export const ServiceDetailsDialog = props => {
  return (
    <Dialog
      handleClose={props.handleClose}
      dialogData={props.dialogData}
      title={props.title}
      dialogType={props.dialogType}
    />
  );
};

import React, { useEffect } from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';
import { PhoneMask } from '../../../shared/ui/icons/core/PhoneMask';

const PhonePrimaryStep = ({
  entranceFormClass,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  errors,
  formData,
  filled,
  isFocused,
  isPending,
  disableButton,
  handlePhoneAuthClick,
  handleTriggerCall,
  divider,
  setTimer,
  RESEND_INTERVAL
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Вход по номеру телефона</p>
        <p>Вам поступит звонок, для входа нужно будет ввести последние 4 цифры номера</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={event => {
          handleTriggerCall(event);
          setTimer(RESEND_INTERVAL);
        }}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.phone}
          id='outlined-helperText'
          label='Телефон'
          name='phone'
          helperText={errors.phone ? errors.phone : ''}
          variant='filled'
          type='tel'
          value={formData.phone}
          onChange={handleInputChange}
          disabled={isPending}
          placeholder={'999 999-99-99'}
          onFocus={() => handleFocus('phone')}
          onBlur={() => handleBlur('phone')}
          endAdornmentProps={{
            filled: filled.phone,
            isFocused: isFocused.phone,
            handleEraseValue: handleEraseValue
          }}
          startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
          autoFocus={false}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Получить код по звонку
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
          <div className='authorization__footer__links-wrapper'>
            <img src={divider} alt='divider' />
            <a href='#' onClick={handlePhoneAuthClick}>
              Войти по e-mail и паролю
            </a>
          </div>
          <p className='authorization__footer__policy'>
            Выполняя вход, вы соглашаетесь{' '}
            <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
              с договором публичной оферты
            </a>
            &#160;и&#160;
            <a href=' https://workki.co/upload/iblock/f5f/Personal_data.pdf' target='_blank'>
              правилами обработки данных
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default PhonePrimaryStep;

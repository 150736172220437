import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

export const ServicesDialog = props => {
  return (
    <Dialog
      handleClose={props.handleClose}
      handleSubmit={props.handleSubmit}
      workplaces={props.workplaces}
      serviceProps={props.serviceProps}
      buttonLabel='Отправить заявку'
      title={props.title}
      dialogType={props.dialogType}
    />
  );
};

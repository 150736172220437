import React, { useMemo, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import NotHistory from '../NotHistory';
import ContentLoader from '../ContentLoader';
import CustomScrollbar from '../CustomScrollbar';
import TableDataLoader from '../TableDataLoader';
import TableDataReloader from '../TableDataReloader';
import TicketHistoryList from './TicketHistoryList';
import { getTicketElementId } from './constants';
import useNode from '../../utils/useNode';

const VALUES_TOP_THRESHOLD = 0.9;
const TEXT_ON_EMPTY_LIST = 'Здесь появится ваша история обращений в службу поддержки';

const TicketsHistory = ({
  tickets,
  isLoading,
  onError,
  canLoadMore,
  loadFirstPage,
  loadNextPage,
  showTicketsItem,
  currentItemId
}) => {
  const isListEmpty = useMemo(() => isEmpty(tickets), [tickets]);

  const [tableNode, tableRef] = useNode();
  const [tableBodyNode, tableBodyRef] = useNode();

  const onUpdate = useCallback(
    values => {
      const hasScrolledToBottom = values.top >= VALUES_TOP_THRESHOLD;
      if (hasScrolledToBottom && !isLoading) {
        loadNextPage();
      }
    },
    [isLoading, loadNextPage]
  );

  useEffect(() => {
    if (isLoading || isListEmpty || !canLoadMore) {
      return;
    }

    if (!tableBodyNode || !tableNode) {
      return;
    }

    const componentRect = tableNode.getBoundingClientRect();
    const tableBodyRect = tableBodyNode.getBoundingClientRect();

    if (tableBodyRect.bottom <= componentRect.bottom) {
      loadNextPage();
    }
  }, [canLoadMore, isListEmpty, isLoading, loadNextPage, tableBodyNode, tableNode]);

  // Effect for scrolling to the title of the previously viewed news item
  useEffect(() => {
    const elem = document.getElementById(getTicketElementId(currentItemId));

    if (!elem) {
      return;
    }

    elem.scrollIntoView(false);
  }, [currentItemId]);

  if (isListEmpty && isLoading) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (isListEmpty && !canLoadMore) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='page__section page__section--grow scrollable-table scrollable-table--tickets'>
      <div className='scrollable-table__title'>История запросов</div>
      <CustomScrollbar className='scrollable-table__scrollbar' onUpdate={onUpdate}>
        <div className='scrollable-table__table' ref={tableRef}>
          <div className='scrollable-table__table-head'>
            <div className='scrollable-table__table-head-row'>
              <div className='scrollable-table__cell'>ID</div>
              <div className='scrollable-table__cell'>Дата</div>
              <div className='scrollable-table__cell'>Рабочее место</div>
              <div className='scrollable-table__cell'>Тип</div>
              <div className='scrollable-table__cell'></div>
            </div>
          </div>
          <div className='scrollable-table__table-body' ref={tableBodyRef}>
            <TicketHistoryList list={tickets} showTicketsItem={showTicketsItem} />
          </div>
          {isLoading && <TableDataLoader />}
          {onError && <TableDataReloader reload={loadFirstPage} />}
        </div>
      </CustomScrollbar>
    </section>
  );
};

export default TicketsHistory;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TMCInputsList from './TMCInputsList';
import NotHistory from '../NotHistory';
import ContentLoader from '../ContentLoader';
import ReactTooltip from 'react-tooltip';

const TEXT_ON_EMPTY_LIST = 'Здесь появится ваша история заявок на внос/вынос ТМЦ';
const TEXT_ON_ERROR_LOADING_LIST =
  'Ошибка загрузки истории заявок на внос/вынос ТМЦ. Попробуйте зайти позже или обратитесь к комьюнити менеджеру';

const HistoryInputTMC = ({
  customer,
  loadTMCInputHistory,
  loadTulskayaTMCInputHistory,
  loadParkKulturyTMCInputHistory,
  tmcInputList,
  tmcInputListRequestStatus
}) => {
  useEffect(() => {
    if (customer) {
      loadTMCInputHistory();
      loadParkKulturyTMCInputHistory();
      loadTulskayaTMCInputHistory();
    }
  }, [customer, loadTMCInputHistory]);

  if (tmcInputListRequestStatus.isPending) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (tmcInputListRequestStatus.isFailure) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_ERROR_LOADING_LIST} />
      </section>
    );
  }

  if (tmcInputList.length === 0) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='page__section std-table'>
      <div className='std-table__title'>История заявок</div>
      <div className='std-table__blur-container'>
        <div className='std-table__overflow-table'>
          <table className='std-table__table' cellSpacing='0'>
            <thead>
              <tr className='std-table__table-head'>
                <td className='std-table__cell std-table__cell--head'>Локация</td>
                <td className='std-table__cell std-table__cell--head'>Дата и время</td>
                <td className='std-table__cell std-table__cell--head'>Тип заявки</td>
                <td className='std-table__cell std-table__cell--head'>Статус заявки</td>
                <td className='std-table__cell std-table__cell--head'>Номер авто</td>
                <td className='std-table__cell std-table__cell--head'>Парковочное место</td>
              </tr>
            </thead>
            <tbody>
              <TMCInputsList tmcInputList={tmcInputList} />
            </tbody>
          </table>
          {tmcInputList &&
            tmcInputList.map((TMCItem, key) => {
              const carNumberId = `parking_car_${key}`;
              const TMCItemNumberId = `parking_tmc_${key}`;
              const statusId = `parking_status_${key}`;

              return (
                <>
                  <ReactTooltip
                    id={statusId}
                    type='info'
                    className='parking-form-neogeo__tooltipe'
                    place='bottom'
                    effect='solid'
                    key={statusId}
                    scrollHide={false}
                  >
                    <p>Номер заявки: {TMCItem.request_number}</p>
                    <p>Блок вноса/выноса: {TMCItem.housing}</p>
                    <p>Причина отклонения: {TMCItem.rejection_reason}</p>
                  </ReactTooltip>
                  <ReactTooltip
                    id={carNumberId}
                    className='parking-form-neogeo__tooltipe'
                    type='info'
                    place='bottom'
                    effect='solid'
                    key={carNumberId}
                    scrollHide={false}
                  >
                    <p>ФИО: {TMCItem.name}</p>
                    <p>Марка авто: {TMCItem.car_brand}</p>
                  </ReactTooltip>
                  <ReactTooltip
                    id={TMCItemNumberId}
                    type='info'
                    className='parking-form-neogeo__tooltipe'
                    place='bottom'
                    effect='solid'
                    key={TMCItemNumberId}
                    scrollHide={false}
                  >
                    <p>Парковочная зона: {TMCItem.parking_number}</p>
                  </ReactTooltip>
                </>
              );
            })}
        </div>
      </div>
    </section>
  );
};

HistoryInputTMC.propTypes = {
  customer: PropTypes.object,
  loadTMCInputHistory: PropTypes.func.isRequired,
  loadParkKulturyTMCInputHistory: PropTypes.func.isRequired,
  loadTulskayaTMCInputHistory: PropTypes.func.isRequired,
  tmcInputList: PropTypes.array.isRequired,
  tmcInputListRequestStatus: PropTypes.object.isRequired
};

export default HistoryInputTMC;

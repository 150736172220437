import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  & > path:hover {
    fill: ${props => props.hoverColor || '#8F9295'};
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
`;

export const FileActionIcon = ({ type, hoverColor, handleAction, defaultColor, spacing }) => {
  const renderIconView = () => {
    const content = {
      delete: () => {
        return (
          <SvgWrapper
            style={{ cursor: 'pointer' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
            spacing={spacing}
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M8.25024 6V7.5H4.50024C4.08603 7.5 3.75024 7.83579 3.75024 8.25C3.75024 8.66421 4.08603 9 4.50024 9H5.34368L6.58334 18.2975C6.73238 19.4153 7.6859 20.2501 8.81361 20.2501H15.1871C16.3148 20.2501 17.2683 19.4153 17.4173 18.2975L18.657 9H19.5002C19.9145 9 20.2502 8.66421 20.2502 8.25C20.2502 7.83579 19.9145 7.5 19.5002 7.5H15.7502V6C15.7502 4.75736 14.7429 3.75 13.5002 3.75H10.5002C9.2576 3.75 8.25024 4.75736 8.25024 6ZM10.5002 5.25C10.086 5.25 9.75024 5.58579 9.75024 6V7.5H14.2502V6C14.2502 5.58579 13.9145 5.25 13.5002 5.25H10.5002ZM17.1437 9H6.85696L8.07018 18.0992C8.11987 18.4718 8.4377 18.7501 8.81361 18.7501H15.1871C15.563 18.7501 15.8808 18.4718 15.9305 18.0992L17.1437 9Z'
              fill='#24272A'
            />
          </SvgWrapper>
        );
      },
      crop: () => {
        return (
          <SvgWrapper
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='visible-svg'
            style={{ cursor: 'pointer' }}
            onClick={handleAction}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <path
              d='M6.75 3C6.75 2.58579 6.41421 2.25 6 2.25C5.58579 2.25 5.25 2.58579 5.25 3V5.25H3C2.58579 5.25 2.25 5.58579 2.25 6C2.25 6.41421 2.58579 6.75 3 6.75H5.25V16.5C5.25 17.7426 6.25736 18.75 7.5 18.75H17.25V21C17.25 21.4142 17.5858 21.75 18 21.75C18.4142 21.75 18.75 21.4142 18.75 21V18.75H21C21.4142 18.75 21.75 18.4142 21.75 18C21.75 17.5858 21.4142 17.25 21 17.25H7.5C7.08579 17.25 6.75 16.9142 6.75 16.5V3Z'
              fill='#24272A'
            />
            <path
              d='M8.25 5.25C7.83579 5.25 7.5 5.58579 7.5 6C7.5 6.41421 7.83579 6.75 8.25 6.75H16.5C16.9142 6.75 17.25 7.08579 17.25 7.5V15.75C17.25 16.1642 17.5858 16.5 18 16.5C18.4142 16.5 18.75 16.1642 18.75 15.75V7.5C18.75 6.25736 17.7426 5.25 16.5 5.25H8.25Z'
              fill='#24272A'
            />
          </SvgWrapper>
        );
      },
      rotate: () => {
        return (
          <SvgWrapper
            onClick={handleAction}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            style={{ cursor: 'pointer' }}
            hoverColor={hoverColor}
            fillColor={defaultColor}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.951 8.13107C11.5509 8.23828 11.3135 8.64953 11.4207 9.04963C11.5279 9.44973 11.9391 9.68717 12.3392 9.57996L16.4373 8.48189C16.8374 8.37468 17.0749 7.96343 16.9676 7.56333L15.8696 3.46525C15.7624 3.06515 15.3511 2.82771 14.951 2.93492C14.5509 3.04213 14.3135 3.45338 14.4207 3.85348L14.9801 5.94126C12.459 4.70101 9.32468 5.12958 7.22727 7.22698C4.59123 9.86302 4.59123 14.1369 7.22727 16.7729C9.86331 19.409 14.1372 19.409 16.7732 16.7729C17.0661 16.48 17.0661 16.0052 16.7732 15.7123C16.4803 15.4194 16.0054 15.4194 15.7126 15.7123C13.6623 17.7625 10.3382 17.7625 8.28793 15.7123C6.23768 13.662 6.23768 10.3379 8.28793 8.28764C9.99299 6.58258 12.5791 6.29551 14.5808 7.42642L11.951 8.13107Z'
              fill='#24272A'
            />
          </SvgWrapper>
        );
      }
    };

    return content[type]();
  };

  return renderIconView(type);
};

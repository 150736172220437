import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' clipPath='url(#clip0_4442_162653)' filter='url(#filter0_d_4442_162653)'>
      <path
        d='M826.748 650C752.194 787.011 606.957 880 440 880C196.995 880 0 683.005 0 440C0 196.995 196.995 0 440 0C472.159 0 503.511 3.44997 533.708 10H3060V100H3158.36C3281.71 204.556 3360 360.635 3360 535C3360 709.365 3281.71 865.444 3158.36 970H3060V1060H1900V650H826.748Z'
        fill='white'
      />
    </g>
  );
};

export const Tulskaya_6_Floor = memo(SVGComponents);

import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class CurrencyType extends Model {
  static reducer(action, currencyType) {
    switch (action.type) {
      case actions.requestCurrencyTypes.success.toString():
        action.payload.map(elem => currencyType.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `CurrencyType: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CurrencyType.modelName = 'CurrencyType';

// Declare your related fields.
CurrencyType.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr()
};

export default CurrencyType;

import React, { useEffect, useState } from 'react';

export const InputBoxes = ({ length, handleRequest, error }) => {
  const [values, setValues] = useState(Array(length).fill(''));
  useEffect(() => {
    const allFieldsFilled = values.every(value => {
      return value !== '';
    });
    if (allFieldsFilled) {
      handleRequest(values);
    }
  }, [values]);

  const handleChange = (event, index) => {
    //TODO добавить валидацию на ввод чисел, для тестирования нужно пока так оставить
    const { value } = event.target;
    if (value.length > 1) return;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (value && index < length - 1) {
      //TODO заменить на рефы
      document.getElementById(`input-${index + 1}`).focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !values[index] && index > 0) {
      document.getElementById(`input-${index - 1}`).focus();
    }
    // if ((event.key === 'Enter' && index === length - 1) || index === length - 1) {
    //   // Нажатие Enter на последнем input
    //   setTimeout(handleRequest(values), 1000);
    //   // handleRequest(values);
    // }
  };

  return (
    <div className='authorization__phone-main__field-wrapper'>
      <div>
        {values.map((val, index) => (
          <input
            className='authorization__phone-main__field'
            key={index}
            id={`input-${index}`}
            type='text'
            maxLength='1'
            value={val}
            autoFocus={!!(index === 0)}
            onChange={event => handleChange(event, index)}
            onKeyDown={event => handleKeyDown(event, index)}
          />
        ))}
      </div>
      <span className='authorization__phone-main__error'>{error ? error : ''}</span>
    </div>
  );
};

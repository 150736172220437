import React from 'react';
import { Card, CardContent, Typography, Tooltip, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Question } from '../../icons/core/Question';
import CardShell from '../Profile/CardShell';

const CustomCard = styled(Card)(({ theme, active }) => ({
  borderRadius: '10px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
  padding: '12px',
  height: '82px',
  width: '50%',
  cursor: active ? 'pointer' : ''
}));
const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row'
}));

const IconWrapper = styled('div')(() => ({
  'flexWrap': 'wrap',
  'display': 'flex',
  'minWidth': '16px',
  'minHeight': '16px',
  'marginTop': '2px',
  'alignContent': 'flex-start',
  '&:hover': {
    cursor: 'pointer'
  },
  '& > svg': {
    minWidth: '16px',
    minHeight: '16px'
  }
}));
const BalanceTitle = styled('p')(() => ({
  margin: '0 0 8px 0',
  fontSize: '14px',
  fontWeight: '400',
  color: '#575B60'
}));
const BalanceValue = styled('p')(() => ({
  margin: '0',
  fontSize: '15px',
  fontWeight: '400',
  color: '#24272A'
}));

export const BalanceCard = ({ title, balance, tooltip, handleAction }) => {
  return (
    <CardShell padding='12px 12px 20px 12px' minHeight='auto'>
      <Box onClick={() => (tooltip ? handleAction() : null)} display='flex' alignItems='center'>
        <TitleWrapper>
          <BalanceTitle>{title}</BalanceTitle>
          {tooltip && (
            <IconWrapper>
              <Question spacing={0} />
            </IconWrapper>
          )}
        </TitleWrapper>
      </Box>
      <BalanceValue>{balance} ₽</BalanceValue>
    </CardShell>
  );
};

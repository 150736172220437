import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  margin-right: ${props => props.spacing || '6px'};
`;

export const LegendAttribute = ({ type, hoverColor, handleAction, defaultColor, spacing }) => {
  const renderIconView = () => {
    const content = {
      available: () => {
        return (
          <SvgWrapper
            style={{ cursor: 'pointer' }}
            width='13'
            height='13'
            viewBox='0 0 13 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <rect x='0.5' y='0.333984' width='12' height='12' rx='6' fill='#717479' />
          </SvgWrapper>
        );
      },
      busy: () => {
        return (
          <SvgWrapper
            width='13'
            height='13'
            viewBox='0 0 13 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='visible-svg'
            style={{ cursor: 'pointer' }}
            onClick={handleAction}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <rect x='0.5' y='0.333984' width='12' height='12' rx='6' fill='#D0D1D2' />
          </SvgWrapper>
        );
      },
      picked: () => {
        return (
          <SvgWrapper
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='visible-svg'
            style={{ cursor: 'pointer' }}
            onClick={handleAction}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M4.125 12C4.125 11.3787 4.62868 10.875 5.25 10.875H18.75C19.3713 10.875 19.875 11.3787 19.875 12C19.875 12.6213 19.3713 13.125 18.75 13.125H5.25C4.62868 13.125 4.125 12.6213 4.125 12Z'
              fill='white'
            />
          </SvgWrapper>
        );
      }
    };

    return content[type]();
  };

  return renderIconView(type);
};

import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Print from '../components/Print/Deprecated/Print';
import { Loader } from '../shared/ui/components/Loader';

const PrintPage = ({ isLoading }) => {
  return (
    <PrimaryLayout>
      <main className='main'>{isLoading ? <Loader wrapperType='transparent' /> : <Print />}</main>
    </PrimaryLayout>
  );
};

export default PrintPage;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import cn from 'classnames';
import { isEmpty, keys, isNil, omit } from 'lodash';
import Select from '../Select';
import Loader from 'react-loader-spinner';
import AutosizeTextarea from 'react-autosize-textarea';
import getFormikValidate from '../../utils/getFormikValidate';
import { PRICE_COUNT_TYPE, SERVICE_RUN_TYPE } from '../../constants';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const TEXTAREA_MAX_LENGTH = 200;

const PRICE_COUNT_TYPE_ADDONS = {
  [PRICE_COUNT_TYPE.ONCE]: 'p',
  [PRICE_COUNT_TYPE.BY_MINUTES]: 'р/мин',
  [PRICE_COUNT_TYPE.BY_HOURS]: 'р/час',
  [PRICE_COUNT_TYPE.BY_DAY]: 'р/день',
  [PRICE_COUNT_TYPE.BY_MONTH]: 'р/мес'
};

const validateSchema = {
  service: [
    {
      check: v => isNil(v),
      message: 'Укажите ит-услугу'
    }
  ],
  workplace: [
    {
      check: v => isNil(v),
      message: 'Выберите рабочее место'
    }
  ],
  startAt: [
    {
      check: v => isNil(v) || isEmpty(v),
      message: 'Укажите дату'
    },
    {
      check: v => v.isBefore(dayjs().startOf('day')) || !v.isValid(),
      message: 'Не верно заполнена дата'
    }
  ]
};

const formikConfig = {
  mapPropsToValues: () => ({
    service: null,
    workplace: null,
    startAt: null,
    comment: ''
  }),

  validate: getFormikValidate(validateSchema),
  validateOnChange: false,

  handleSubmit: (values, { props: { createTask } }) => {
    const { service, workplace, startAt, comment } = values;

    const task = {
      service_id: service,
      location: workplace,
      cnt: 1,
      start_at: startAt.format('YYYY-MM-DD HH:mm:ss'),
      comment
    };

    createTask(task);
  },

  displayName: 'ItServiceFormWrapper'
};

const ItServicesFormModal = ({
  workplaces,
  itServices,
  taskCreateStatus,
  taskCreateError,
  resetError,
  isModalWindowOpen,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
  setErrors,
  resetForm
}) => {
  const [formError, setFormError] = useState(false);
  const [formWarn, setFormWarn] = useState(false);
  const [pickedDate, setPickedDate] = useState(dayjs());
  const [pickedPlace, setPickedPlace] = useState(null);
  const [bookingRestriction, setBookingRestriction] = useState({
    startDate: dayjs(),
    expireDate: dayjs().endOf('month')
  });

  useEffect(() => {
    if (pickedDate.isBefore(bookingRestriction.startDate)) {
      setPickedDate(bookingRestriction.startDate);
    }
    setFieldValue('startAt', pickedDate);
  }, [pickedDate, bookingRestriction]);

  useEffect(() => {
    if (pickedPlace) {
      setBookingRestriction(prevRestriction => ({
        ...prevRestriction,
        startDate: dayjs(pickedPlace.bookingStartDate),
        expireDate: dayjs(pickedPlace.bookingExpireDate)
      }));
      setPickedDate(dayjs(pickedPlace.bookingStartDate));
    }
  }, [pickedPlace]);

  const validationError = useMemo(() => {
    if (isEmpty(errors)) {
      return null;
    }

    const messageKey = keys(errors).find(key => !!touched[key]);

    if (isNil(messageKey)) {
      return null;
    }

    return errors[messageKey];
  }, [errors, touched]);

  useEffect(() => {
    if (!!validationError) {
      return setFormError(validationError);
    }

    if (!!taskCreateError && taskCreateStatus.isFailure) {
      return setFormError(taskCreateError);
    }

    setFormError(false);
  }, [validationError, taskCreateError, taskCreateStatus]);

  const isLoading = useMemo(() => taskCreateStatus.isPending, [taskCreateStatus]);

  useEffect(() => {
    if (!isModalWindowOpen) {
      resetForm();
    }
  }, [isModalWindowOpen, resetForm]);

  useEffect(() => {
    if (!!taskCreateError) {
      resetError();
    }
    setPickedPlace(workplaces.find(workplace => workplace.id === values.workplace));
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSelectValueByName = name => ({ value }) => {
    setFieldValue(name, value);
    setErrors(omit(errors, name));
  };

  const selectServiceClickHandler = useCallback(() => {
    if (isNil(values.workplace) && !formError) {
      setFormWarn('Необходимо указать рабочее место');
    }
  }, [formError, values.workplace]);

  useEffect(() => {
    if (!isNil(values.workplace)) {
      setFormWarn(false);
    }
  }, [values.workplace]);

  useEffect(() => {
    if (!!formError) {
      setFormWarn(false);
    }
  }, [formError]);

  const renderButton = () => {
    const btnClass = cn({
      'btn': true,
      'fill-payments-modal__refill-btn': true,
      'btn--solid': true,
      'fill-payments-modal__refill-btn--with-preloader': isLoading
    });
    return (
      <button className={btnClass} type='submit' disabled={isLoading}>
        {isLoading ? <Loader type='ThreeDots' color='white' height='25' width='40' /> : <span>Отправить заявку</span>}
      </button>
    );
  };

  const itServicesForSelect = useMemo(() => {
    return itServices.map(service => {
      const price = service.servicePrices.find(p => p.is_active);
      const priceCountTypeAddon = PRICE_COUNT_TYPE_ADDONS[price.cnt_type_id] || '';
      const label = !isNil(price) ? `${service.name} (${price.price} ${priceCountTypeAddon})` : service.name;
      return { label, value: service.id, run_type_id: service.run_type_id, office_id: service.office_id };
    });
  }, [itServices]);

  const itServicesForSelectFiltered = useMemo(() => {
    let r = [];

    if (isNil(values.workplace)) {
      return r;
    }

    const place = workplaces.find(w => w.id === values.workplace);

    if (isNil(place)) {
      return r;
    }

    r = itServicesForSelect.filter(s => {
      return s.office_id === place.office_id;
    });
    if (isEmpty(r)) {
      r = itServicesForSelect.filter(s => isNil(s.office_id));
    }

    if (!place.is_openspace) {
      return r;
    }

    return r.filter(s => s.run_type_id === SERVICE_RUN_TYPE.IT_OTHER);
  }, [itServicesForSelect, values.workplace, workplaces]);

  useEffect(() => {
    const s = itServicesForSelectFiltered.find(s => s.value === values.service);
    if (isNil(s)) {
      setFieldValue('service', null);
    }
  }, [itServicesForSelectFiltered, setFieldValue, values.service]);

  const workplacesForSelect = useMemo(() => {
    return workplaces.map(wp => ({ value: wp.id, label: `${wp.name} (${wp.officeName})` }));
  }, [workplaces]);

  return (
    <form
      className={cn('fill-payments-modal', { 'has-error': !!formError || !!formWarn })}
      id='fill-payments-modal-form'
      onSubmit={handleSubmit}
      autoComplete='off'
      noValidate
    >
      <div className='fill-payments-modal__body'>
        <div
          className={cn('fill-payments-modal__row includes-select', {
            'includes-select--has-error': errors.workplace && touched.workplace
          })}
        >
          <Select
            value={workplacesForSelect.find(w => w.value === values.workplace) || null}
            options={workplacesForSelect}
            placeholder='Выберите рабочее место'
            onChange={setSelectValueByName('workplace')}
            isDisabled={isLoading}
          />
        </div>
        <div
          className={cn('fill-payments-modal__row includes-select', {
            'includes-select--has-error': errors.service && touched.service
          })}
          onClick={selectServiceClickHandler}
        >
          <Select
            value={itServicesForSelectFiltered.find(s => s.value === values.service) || null}
            options={itServicesForSelectFiltered}
            placeholder='Выберите услугу'
            onChange={setSelectValueByName('service')}
            isDisabled={isLoading || isNil(values.workplace)}
          />
        </div>
        <div className='fill-payments-modal__row'>
          <AutosizeTextarea
            rows={3}
            maxRows={6}
            className={cn('textarea textarea--with-autosize', {
              'has-error': errors.comment && touched.comment
            })}
            type='text'
            name='comment'
            placeholder={`Комментарий... (до ${TEXTAREA_MAX_LENGTH} символов)`}
            maxLength={TEXTAREA_MAX_LENGTH}
            value={values.comment}
            onChange={handleChange}
            disabled={isLoading}
            onBlur={handleBlur}
          />
        </div>
        <div className='fill-payments-modal__row'>
          <div className='datepicker__wrapper datepicker__wrapper--content-center'>
            <span className='datepicker__label'>Дата начала предоставления услуги:</span>
            <DatePicker
              disablePast
              value={pickedDate} // Преобразуем объект dayjs в объект Date
              minDate={bookingRestriction.startDate}
              maxDate={bookingRestriction.expireDate}
              onChange={newValue => setPickedDate(newValue)}
            />
          </div>
        </div>
        <div className='entrance-form__information-msg'>
          После отправки заявки с вашего с лицевого счета будет списана сумма, соответствующая стоимости услуги.
        </div>
        <div className='fill-payments-modal__row'>{renderButton()}</div>
      </div>
      {formError && (
        <div className='fill-payments-modal__notification'>
          <div className='notification notification--error notification--fill-payment-modal'>{formError}</div>
        </div>
      )}
      {formWarn && (
        <div className='fill-payments-modal__notification'>
          <div className='notification notification--error notification--fill-payment-modal'>{formWarn}</div>
        </div>
      )}
    </form>
  );
};

ItServicesFormModal.propTypes = {
  workplaces: PropTypes.array.isRequired,
  itServices: PropTypes.array.isRequired,
  createTask: PropTypes.func.isRequired,
  taskCreateStatus: PropTypes.string.isRequired,
  taskCreateError: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
  isModalWindowOpen: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    service: PropTypes.number,
    workplace: PropTypes.number,
    createAt: PropTypes.object,
    comment: PropTypes.string
  }).isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired
};

export default withFormik(formikConfig)(ItServicesFormModal);

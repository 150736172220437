import { createAction } from '@reduxjs/toolkit';
import { pick, get, includes } from 'lodash';

const defaultRequestSuccessHandler = response => {
  return { payload: get(response, 'data', null) };
};

export const withMetaRequestSuccessHandler = response => {
  return {
    payload: get(response, 'data', null),
    meta: pick(response, ['headers'])
  };
};

export const getRequestStatusActions = requestAction => {
  const type = get(requestAction, 'type', '');

  if (!includes(type, ':REQUEST')) {
    throw new Error('Argument is not request actions object');
  }

  return [requestAction, requestAction.success, requestAction.failure];
};

/**
 * @param {string} action
 * @param {function} requestSuccessHandler
 **/
const createRequestActions = (action, requestSuccessHandler = defaultRequestSuccessHandler) => {
  const requestAction = createAction(`${action}:REQUEST`);

  requestAction.success = createAction(`${action}:SUCCESS`, requestSuccessHandler);
  requestAction.failure = createAction(`${action}:FAILURE`);
  requestAction.reset = createAction(`${action}:RESET`);

  return requestAction;
};
export default createRequestActions;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: ${props => props.width || '136px'};
  min-height: ${props => props.height || '24px'};
`;

export const WorkkiLogo = ({ width, height }) => {
  return (
    <StyledSvg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M99.5518 13.8558H106.375L112.497 23.2568H104.525L99.5518 15.5728V23.2568H92.5786V3.28252L99.5518 0V13.8558Z'
        fill='#383C40'
      />
      <path d='M14.3105 12.9277L18.1485 5.26357L23.7184 16.1073L19.7671 23.9998L14.3105 12.9277Z' fill='#383C40' />
      <path d='M8.12771 5.83821H0.5L9.45016 24L13.4015 16.1063L8.12771 5.83821Z' fill='#383C40' />
      <path d='M52.7457 23.2569V5.95853L59.7189 5.95109V23.2569H52.7457Z' fill='#383C40' />
      <path d='M115.351 23.2574V13.8564H122.325V23.2574H115.351Z' fill='#383C40' />
      <path
        d='M29.133 8.57376C29.133 6.74641 27.6475 5.26394 25.8137 5.26394C23.9811 5.26394 22.4956 6.74641 22.4956 8.57376C22.4956 10.4011 23.9811 11.8823 25.8137 11.8823C27.6475 11.8823 29.133 10.4011 29.133 8.57376Z'
        fill='#383C40'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M39.8987 5.34234C45.0058 5.34234 49.1487 9.47094 49.1487 14.5659C49.1487 19.6597 45.0058 23.7883 39.8987 23.7883C34.7916 23.7883 30.6487 19.6597 30.6487 14.5659C30.6487 9.47094 34.7916 5.34234 39.8987 5.34234ZM43.6414 14.5594C43.6414 16.6307 41.9564 18.3098 39.878 18.3098C37.7995 18.3098 36.1146 16.6307 36.1146 14.5594C36.1146 12.4882 37.7995 10.8091 39.878 10.8091C41.9564 10.8091 43.6414 12.4882 43.6414 14.5594Z'
        fill='#383C40'
      />
      <path
        d='M115.52 8.57364C115.52 10.401 117.006 11.8822 118.838 11.8822C120.672 11.8822 122.157 10.401 122.157 8.57364C122.157 6.74629 120.672 5.26382 118.838 5.26382C117.006 5.26382 115.52 6.74629 115.52 8.57364Z'
        fill='#383C40'
      />
      <path
        d='M103.027 8.57364C103.027 10.401 104.512 11.8822 106.345 11.8822C108.179 11.8822 109.664 10.401 109.664 8.57364C109.664 6.74629 108.179 5.26382 106.345 5.26382C104.512 5.26382 103.027 6.74629 103.027 8.57364Z'
        fill='#383C40'
      />
      <path
        d='M84.111 11.8822C82.2784 11.8822 80.7929 10.401 80.7929 8.57364C80.7929 6.74629 82.2784 5.26382 84.111 5.26382C85.9448 5.26382 87.4303 6.74629 87.4303 8.57364C87.4303 10.401 85.9448 11.8822 84.111 11.8822Z'
        fill='#383C40'
      />
      <path
        d='M84.1409 13.8558H77.3181V0L70.3449 3.28252V23.2568H77.3181V15.5728L82.2908 23.2568H90.2644L84.1409 13.8558Z'
        fill='#383C40'
      />
      <path
        d='M64.3145 5.26394C66.1496 5.26394 67.6338 6.74641 67.6338 8.57376C67.6338 10.4011 66.1496 11.8823 64.3145 11.8823C62.4819 11.8823 60.9977 10.4011 60.9977 8.57376C60.9977 6.74641 62.4819 5.26394 64.3145 5.26394Z'
        fill='#383C40'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M130.812 10.5V9.08091L131.249 9.08073C131.424 9.08073 131.563 9.11511 131.667 9.18373C131.772 9.25234 131.876 9.3805 131.978 9.56805L132.484 10.4998H133.625L133.044 9.43492C132.925 9.21889 132.807 9.05812 132.69 8.9527C132.575 8.8455 132.445 8.7768 132.298 8.74668C132.565 8.67138 132.764 8.55418 132.896 8.39512C133.03 8.23607 133.096 8.03262 133.096 7.78479C133.096 7.42645 132.968 7.16445 132.713 6.99864C132.458 6.8329 132.058 6.75 131.511 6.75H129.875V10.5L130.812 10.5ZM131.878 8.29983C131.782 8.3752 131.621 8.41286 131.397 8.41286H130.812V7.45091L131.397 7.4509C131.621 7.4509 131.782 7.48777 131.878 7.56144C131.976 7.63511 132.025 7.75736 132.025 7.92814C132.025 8.1006 131.976 8.22453 131.878 8.29983Z'
        fill='#383C40'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M131.75 4.875C131.258 4.875 130.77 4.972 130.315 5.16045C129.86 5.34891 129.447 5.62513 129.098 5.97335C128.75 6.32157 128.474 6.73497 128.285 7.18994C128.097 7.64491 128 8.13254 128 8.625C128 9.11746 128.097 9.60509 128.285 10.0601C128.474 10.515 128.75 10.9284 129.098 11.2767C129.447 11.6249 129.86 11.9011 130.315 12.0895C130.77 12.278 131.258 12.375 131.75 12.375C132.242 12.375 132.73 12.278 133.185 12.0895C133.64 11.9011 134.053 11.6249 134.402 11.2767C134.75 10.9284 135.026 10.515 135.215 10.0601C135.403 9.60509 135.5 9.11746 135.5 8.625C135.5 8.13254 135.403 7.64491 135.215 7.18994C135.026 6.73497 134.75 6.32157 134.402 5.97335C134.053 5.62513 133.64 5.34891 133.185 5.16045C132.73 4.972 132.242 4.875 131.75 4.875ZM130.606 5.86391C130.969 5.71372 131.358 5.63642 131.75 5.63642C132.142 5.63642 132.531 5.71372 132.894 5.86391C133.256 6.0141 133.586 6.23424 133.863 6.51176C134.141 6.78927 134.361 7.11873 134.511 7.48132C134.661 7.84391 134.739 8.23253 134.739 8.625C134.739 9.01747 134.661 9.40609 134.511 9.76868C134.361 10.1313 134.141 10.4607 133.863 10.7382C133.586 11.0158 133.256 11.2359 132.894 11.3861C132.531 11.5363 132.142 11.6136 131.75 11.6136C131.358 11.6136 130.969 11.5363 130.606 11.3861C130.244 11.2359 129.914 11.0158 129.637 10.7382C129.359 10.4607 129.139 10.1313 128.989 9.76868C128.839 9.40609 128.761 9.01747 128.761 8.625C128.761 8.23253 128.839 7.84391 128.989 7.48132C129.139 7.11873 129.359 6.78927 129.637 6.51176C129.914 6.23424 130.244 6.0141 130.606 5.86391Z'
        fill='#383C40'
      />
    </StyledSvg>
  );
};

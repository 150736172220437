export const removeZeros = num => {
  const strNum = num.toFixed(20);
  return parseFloat(strNum.replace(/\.?0+$/, '')).toLocaleString('ru');
};
export const formatPhoneNumber = (input, setErrors, setValidationError) => {
  // Очищаем номер от всех нецифровых символов
  const cleaned = ('' + input).replace(/\D/g, '');
  if (cleaned.length < 10) {
    setErrors(prevError => ({
      ...prevError,
      phone: 'Номер не должен содержать менее 10 цифр'
    }));
    if (setValidationError) {
      setValidationError(prevError => ({
        ...prevError,
        phone: true
      }));
    }
    return input;
  }

  // Попытка сопоставить номер с шаблоном
  const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);

  if (match) {
    setErrors(prevError => ({
      ...prevError,
      phone: ''
    }));
    if (setValidationError) {
      setValidationError(prevError => ({
        ...prevError,
        phone: false
      }));
    }
    // Собираем номер в формате "XXX XXX-XX-XX"
    return [match[1], match[2], match[3], match[4]].filter(Boolean).join(' ');
  } else {
    setErrors(prevError => ({
      ...prevError,
      phone: 'Неверный формат номера'
    }));
    if (setValidationError) {
      setValidationError(prevError => ({
        ...prevError,
        phone: true
      }));
    }
  }

  return input;
};
export const validateEmail = email => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

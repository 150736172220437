import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';

const NotFoundErrorPage = () => {
  return (
    <AuthLayout>
      <main className='main'>
        <div className='not-found-error'>
          <div className='not-found-error__code'>500</div>
          <div className='not-found-error__description'>
            Что-то пошло не так, мы уже работаем над устранением проблемы.
          </div>
          <div className='not-found-error__description'>
            Для перехода на главную нажмите{` `}
            <Link className='link' title='Home' to='/'>
              здесь
            </Link>
          </div>
        </div>
      </main>
    </AuthLayout>
  );
};

export default NotFoundErrorPage;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  margin: 0;
  padding: 0;
  width: 12px;
  height: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > path {
    fill: ${props => props.fillColor || '#575B60'};
  }
`;

export const SliderArrow = ({ defaultColor, spacing, rotate, ...props }) => {
  const arrow = (
    <path d='M8.64669 5.14645C8.45143 5.34171 8.45143 5.65829 8.64669 5.85355C8.84195 6.04882 9.15854 6.04882 9.3538 5.85355L11.8538 3.35355C12.0491 3.15829 12.0491 2.84171 11.8538 2.64645L9.3538 0.146447C9.15854 -0.0488156 8.84195 -0.0488156 8.64669 0.146447C8.45143 0.341709 8.45143 0.658292 8.64669 0.853554L10.2931 2.5L0.500244 2.5C0.224102 2.5 0.000244141 2.72386 0.000244141 3C0.000244141 3.27614 0.224102 3.5 0.500244 3.5L10.2931 3.5L8.64669 5.14645Z' />
  );
  return (
    <StyledSvg
      viewBox='0 0 12 6'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      style={{ rotate: rotate ? '180deg' : '' }}
      {...props}
    >
      {arrow}
    </StyledSvg>
  );
};

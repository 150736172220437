import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    width: ${props => props.width || '24px'};
    height: ${props => props.height || '24px'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const UserIcon = ({ defaultColor, spacing, width, height }) => {
  return (
    <StyledSvg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      width={width}
      height={height}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0006 4.3501C7.77561 4.3501 4.35059 7.77512 4.35059 12.0001C4.35059 14.0103 5.12595 15.8395 6.39393 17.2048C6.43273 17.1391 6.47945 17.0649 6.53493 16.9841C6.72854 16.7021 7.02977 16.3385 7.47369 15.9792C8.37289 15.2515 9.80847 14.5811 12.0011 14.5811C14.1937 14.5811 15.629 15.2515 16.5279 15.9793C16.9716 16.3387 17.2726 16.7023 17.4661 16.9843C17.5215 17.0651 17.5682 17.1394 17.607 17.205C18.8751 15.8397 19.6506 14.0105 19.6506 12.0001C19.6506 7.77512 16.2256 4.3501 12.0006 4.3501ZM16.4387 18.2318C15.1871 19.1248 13.6552 19.6501 12.0006 19.6501C10.3457 19.6501 8.8136 19.1246 7.56194 18.2315L7.62208 18.0855L7.62328 18.083C7.62662 18.0758 7.63392 18.0606 7.64561 18.0386C7.66903 17.9943 7.70984 17.9229 7.77153 17.8331C7.89488 17.6534 8.10074 17.4014 8.41736 17.1452C9.03925 16.6419 10.1385 16.0811 12.0011 16.0811C13.8636 16.0811 14.9625 16.6419 15.5839 17.1451C15.9003 17.4013 16.1059 17.6532 16.2291 17.8328C16.2908 17.9226 16.3315 17.994 16.3549 18.0382C16.3666 18.0603 16.3738 18.0755 16.3772 18.0826L16.3784 18.0852L16.4387 18.2318ZM2.85059 12.0001C2.85059 6.94669 6.94718 2.8501 12.0006 2.8501C17.054 2.8501 21.1506 6.94669 21.1506 12.0001C21.1506 17.0535 17.054 21.1501 12.0006 21.1501C6.94718 21.1501 2.85059 17.0535 2.85059 12.0001ZM12.0003 7.97079C10.7348 7.97079 9.70892 8.99667 9.70892 10.2622C9.70892 11.5277 10.7348 12.5535 12.0003 12.5535C13.2658 12.5535 14.2917 11.5277 14.2917 10.2622C14.2917 8.99667 13.2658 7.97079 12.0003 7.97079ZM8.20892 10.2622C8.20892 8.16824 9.90638 6.47079 12.0003 6.47079C14.0942 6.47079 15.7917 8.16824 15.7917 10.2622C15.7917 12.3561 14.0942 14.0535 12.0003 14.0535C9.90638 14.0535 8.20892 12.3561 8.20892 10.2622Z'
        fill='#24272A'
      />
    </StyledSvg>
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, fontFamilyBase, screenRessolutions } from '../../shared/ui/constants/styleConstants';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { Hint } from '../../shared/ui/icons/core/Hint';
import PriceInfoDialog from '../../widgets/DashboardWidgets/PriceInfoDialog';

const Title = styled.p`
  margin: 24px 0 8px 0;
  padding: 0;
  display: none;
  align-self: flex-end;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.workkiDarkSecondary};
  cursor: pointer;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    display: flex;
  }
`;

const UserInfoBox = styled.div`
  margin: 12px 0 0 0;
  padding: 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
  list-style-type: none;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    margin-top: 8px;
  }
`;

const UserBalanceBox = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  list-style-type: none;
  box-sizing: border-box;
  height: 128px;
`;

const UserIdAndPinCodeBox = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  box-sizing: border-box;
  gap: 8px;
`;

const UserGeneralInfoBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 4px;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 4px;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.workkiDarkSecondary};
  }

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: ${fontFamilyBase};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.workkiDarkMain};

    & > span {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${colors.workkiDarkSecondary};
    }
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    flex-direction: row;
  }
`;

const PrintUserInfoSections = ({ customer, handleScrollToHistory }) => {
  const [modalState, setModalState] = useState(false);
  const handleCloseModal = () => setModalState(false);
  return (
    <>
      <Title onClick={handleScrollToHistory}>История печати</Title>
      <UserInfoBox>
        <UserBalanceBox>
          <CardShell padding='10px 12px 12px'>
            <UserGeneralInfoBox style={{ flexDirection: 'column' }}>
              <h4>
                Баланс печати <Hint onClick={() => setModalState(true)} />
              </h4>
              {customer && customer.printer && (
                <p>
                  {customer.printer.balance} ч/б <span>или {Math.floor(customer.printer.balance / 4)} цветных</span>
                </p>
              )}
            </UserGeneralInfoBox>
          </CardShell>
        </UserBalanceBox>
        <UserIdAndPinCodeBox>
          <CardShell padding={'10px 12px 12px'} minHeight={'auto'}>
            <UserGeneralInfoBox>
              <h4>Ваш ID</h4>
              <p>{customer.id}</p>
            </UserGeneralInfoBox>
          </CardShell>
          <CardShell padding={'10px 12px 12px'} minHeight={'auto'}>
            <UserGeneralInfoBox>
              <h4>Пинкод&nbsp;печати</h4>
              {customer.printer && <p>{customer.printer.pin}</p>}
            </UserGeneralInfoBox>
          </CardShell>
        </UserIdAndPinCodeBox>
      </UserInfoBox>
      {modalState && <PriceInfoDialog infoType='print' handleClose={handleCloseModal} />}
    </>
  );
};

export default PrintUserInfoSections;

/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormStandardWrapper from '../../../hocs/FormStandardWrapper';
import { pick, keys, isNull, uniqBy, omitBy } from 'lodash';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { StepButton } from '../../../shared/ui/components/StepButton';
import FormControl from '@mui/material/FormControl';
import { Warning } from '../../../shared/ui/icons/core/Warning';
import { styled } from 'styled-components';
import { colors } from '../../../shared/ui/constants/styleConstants';
import AutosizeTextarea from 'react-autosize-textarea';

const TEXTAREA_MAX_LENGTH = 280;

const initValues = {
  name: '',
  description: '',
  url: ''
};

const validators = {
  name: {
    regex: /^.{2,}$/,
    msg: 'Значение поля "Наименование" должно содержать минимум 2 символа.',
    required: true
  },
  url: {
    regex: /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+|)$/m,
    msg: 'Введен невалидный адрес сайта'
  }
};

const CommonError = styled.div`
  background-color: ${colors.workkiRedLight};
  color: ${colors.workkiRedFill};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin: 8px 0;
  padding: 4px;
  gap: 4px;
  align-items: center;
  font-weight: 400;
  :nth-child(2) {
    margin-top: -2px;
    font-weight: 300;
  }
`;

const renderErrors = errors => (
  <>
    {uniqBy(errors, 'message').map((err, index) => (
      <CommonError key={index}>
        <span>
          <Warning spacing='10px' defaultColor='#DB4344' />
        </span>
        <span>{err.message}</span>
      </CommonError>
    ))}
  </>
);

const ProfileSocialForm = ({
  customer,
  data: { name, description, url },
  handleInput,
  getHandleSubmit,
  initForm,
  hasError,
  errors,
  onPending,
  onSuccess,
  isChanged,
  changeCustomerProfilePublicFields
}) => {
  useEffect(() => {
    const initData = omitBy(pick(customer, keys(initValues)), isNull);
    initForm({ ...initValues, ...initData });
  }, [customer, initForm, onSuccess]);

  const formClasses = cn({
    'profile-social-form': true,
    'has-error': hasError
  });

  const formFooterClasses = cn({
    'profile-social-form__footer': true,
    'profile-social-form__footer--invisible': !isChanged && !hasError && !onPending
  });

  const btnClass = cn({
    'btn': true,
    'btn--solid': true,
    'profile-social-form__submit': true,
    'btn--with-preloader': onPending
  });

  return (
    <form className={formClasses} name='profile-social-form' id='profile-social-form' autoComplete='off' noValidate>
      <div className='profile-social-form__field-list'>
        <CustomTextField
          onChange={handleInput}
          value={name}
          id='name'
          name='name'
          variant='filled'
          label={'Название бизнеса'}
          error={''}
          type='text'
          disabled={onPending}
          required
        />
        <CustomTextField
          onChange={handleInput}
          value={url}
          id='url'
          name='url'
          variant='filled'
          label={'Адрес сайта'}
          error={''}
          type='text'
          disabled={onPending}
        />
        <CustomTextField
          onChange={handleInput}
          value={description}
          id='description'
          name='description'
          variant='filled'
          label={'Описание компании'}
          error={''}
          type='textarea'
          disabled={onPending}
        />
      </div>
      <FormControl fullWidth={true} style={{ padding: '16px 0' }}>
        <StepButton
          handleAction={getHandleSubmit(changeCustomerProfilePublicFields)}
          heightOverride={'56px'}
          filledType='black'
          type='filled'
          title={'Сохранить изменения'}
          disabled={onPending || onSuccess || !isChanged}
        />
      </FormControl>
      {hasError ? renderErrors(errors) : null}
    </form>
  );
};

ProfileSocialForm.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  hasError: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onPending: PropTypes.bool.isRequired,
  onSuccess: PropTypes.bool.isRequired,
  onInit: PropTypes.bool.isRequired,
  isChanged: PropTypes.bool.isRequired,
  handleInput: PropTypes.func.isRequired,
  getHandleSubmit: PropTypes.func.isRequired,
  initForm: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

export default FormStandardWrapper(initValues, validators)(ProfileSocialForm);

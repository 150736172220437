import { pick, isInteger, pickBy, isNil, keys } from 'lodash';
import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getCustomerChildren = () => {
  // TODO remove _disablePagination
  return api.get(
    'customer/child',
    { params: { _disablePagination: 1 } },
    getRequestStatusActions(actions.requestChildren)
  );
};
export const changeCredentialsRequest = (name, files) => {
  console.log('changeCredentialsRequest', name, files);
  const data = new FormData();
  const headers = { 'content-type': 'multipart/form-data' };
  data.append('name', name);
  files.forEach((file, index) => {
    data.append(`files[${index}]`, file);
  });
  return api.post('customer/profile/name', { data, headers }, getRequestStatusActions(actions.requestDepositBanks));
};

export const createCustomerChild = child => {
  const allowedValues = [
    'email',
    'mobile_phone',
    'first_name',
    'last_name',
    'middle_name',
    'description',
    'url',
    'auth_role',
    'photoFile'
  ];
  const headers = { 'content-type': 'application/x-www-form-urlencoded' };
  const allowedData = pick(child, allowedValues);
  const cleanData = pickBy(allowedData, v => !isNil(v));

  const data = new FormData();
  keys(cleanData).forEach(key => data.append(key, cleanData[key]));

  return api.post('customer/child', { data, headers }, getRequestStatusActions(actions.requestChildCreate), false);
};

export const updateCustomerChild = (id, child) => {
  const allowedValues = [
    'email',
    'mobile_phone',
    'first_name',
    'last_name',
    'middle_name',
    'description',
    'url',
    'auth_role'
  ];
  const data = JSON.stringify(pick(child, allowedValues));
  if (!isInteger(id)) {
    throw new Error(`Update customer child parameter error: id is not number`);
  }
  return api.put(`customer/child/${id}`, { data }, getRequestStatusActions(actions.requestChildUpdate), false);
};

export const deleteCustomerChild = id => {
  if (!isInteger(id)) {
    throw new Error(`Delete customer child parameter error: id is not number`);
  }
  return api.delete(`customer/child/${id}`, {}, [
    actions.requestChildDelete,
    () => actions.requestChildDelete.success({ id }),
    actions.requestChildDelete.failure
  ]);
};

export const getCustomerChildRoles = () =>
  api.get(
    'customer/child/role',
    { params: { _disablePagination: 1 } },
    getRequestStatusActions(actions.requestChildRoles)
  );

import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' xmlns='http://www.w3.org/2000/svg' filter='url(#filter0_d_3709_153346)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1220 0H0V620H580V740H1220V0Z' fill='white' />
    </g>
  );
};

export const Komsomolskaya_FOK_Floor = memo(SVGComponents);

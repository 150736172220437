import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#24272A'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
  transform: rotate(${props => props.rotation || '0deg'});
`;

export const DecreaseIcon = ({ defaultColor, spacing, hoverColor, rotation }) => {
  return (
    <StyledSvg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
      rotation={rotation}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3 8C3 7.72386 3.22386 7.5 3.5 7.5H12.5C12.7761 7.5 13 7.72386 13 8C13 8.27614 12.7761 8.5 12.5 8.5H3.5C3.22386 8.5 3 8.27614 3 8Z'
        fill='#C8C9CA'
      />
    </StyledSvg>
  );
};

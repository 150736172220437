import { keys } from 'lodash';

export default validateSchema => values =>
  keys(values).reduce((errors, key) => {
    if (!validateSchema[key]) {
      return errors;
    }

    const { hasError, message } = validateSchema[key].reduce(
      (acc, validator) => {
        if (acc.hasError) {
          return acc;
        }

        const isValid = !validator.check(values[key]);

        if (isValid) {
          return acc;
        }

        return { hasError: true, message: validator.message || null };
      },
      { hasError: false }
    );

    if (hasError) {
      return { ...errors, [key]: message };
    }

    return errors;
  }, {});

import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { isEqual, isNull, last, sortBy, toInteger } from 'lodash';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

import Portal, { createContainer } from '../../hocs/Portal';
import Container from './RoomReservation.container';

import formatAmount from '../../utils/formatAmount';
import { PRICE_COUNT_TYPE } from '../../constants';
import MeetingRoomCard from './MeetingRoomCard';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import { InfoAlert } from '../../shared/ui/components/Profile/InfoAlert';
import CustomSwitch from '../../shared/ui/components/Switch';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { MeetingRoomDialog } from '../../widgets/BookingWidgets/MeetingRooms/MeetingRoomDialog';
import { connect } from 'react-redux';
import { accountSelector } from '../../store/selectors/DefaultSelectors';
import CustomDatePicker from '../../shared/ui/components/CustomDatePicker';
import FormControl from '@mui/material/FormControl';
import { CustomSelectField } from '../../shared/ui/components/Select';
import { TabsComponent } from '../../shared/ui/components/Tabs/Tabs';
import CustomChip from '../../shared/ui/components/Chip';
import { StepLabel } from '../Profile/PrefillProfile/model/PrefillProfileComponent';

moment.tz.setDefault('Europe/Moscow');

const MINUTES_IN_HOUR = 60;

const MODAL_CONTAINER_ID = 'room-reservation-portal';

const SERVICE_TYPES = [
  { serviceTypeId: 1, name: 'Переговорные' },
  { serviceTypeId: 2, name: 'Мультимедиа' },
  { serviceTypeId: 3, name: 'Ивент' }
];

const LoadStatus = {
  INIT: 'init',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'onError'
};

const partOfInitialState = {
  dateTime: {
    from: null,
    to: null
  },
  selectedService: null,
  selectedServiceFreeHours: {
    count: 0,
    isUnlimited: false,
    loadStatus: LoadStatus.INIT
  },
  notEnoughMoney: false
};
const ControllersWrapper = styled.div`
  display: grid;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    grid-template-areas:
      'steplabel'
      'alert'
      'select'
      'datepicker'
      'chips';
    padding: 0 0 24px 0;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    width: 100%;
    grid-template-areas:
      'steplabel steplabel'
      'alert alert'
      'select datepicker'
      'chips chips';
    grid-auto-columns: 1fr 1fr;
    gap: 0 24px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    align-items: center;
    grid-template-areas:
      'steplabel alert'
      'tabs tabs'
      'datepicker datepicker'
      'chips chips';
    grid-auto-columns: 1fr 1fr;
  }
`;
const StepLabelWrapper = styled.div`
  grid-area: steplabel;
  @media (min-width: ${screenRessolutions.laptop}px) {
    margin-top: 10px;
    align-self: flex-start;
  }
`;
const DatePickerWrapper = styled.div`
  grid-area: datepicker;
  @media (min-width: ${screenRessolutions.laptop}px) {
    width: 320px;
  }
`;
const ChipWrapper = styled.div`
  grid-area: chips;
  display: flex;
  @media (min-width: ${screenRessolutions.tablet}px) {
    width: 48%;
    justify-self: flex-end;
    margin-top: -16px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    justify-self: flex-start;
    margin: 0;
  }
`;
const LocationSelectWrapper = styled.div`
  grid-area: select;
  @media (min-width: ${screenRessolutions.laptop}px) {
    display: none;
  }
`;
const TabsBlockWrapper = styled.div`
  grid-area: tabs;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: none;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    display: flex;
    width: 800px;
    margin-bottom: 20px;
  }
`;
const AlertWrapper = styled.div`
  grid-area: alert;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    margin-bottom: 4px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    justify-self: flex-end;
    width: 448px;
  }
`;
const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  & > p {
    margin: 0;
    font-weight: 300;
  }
`;
const StyledSection = styled.section`
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    padding: 14px 16px;
  }
`;

class RoomReservation extends Component {
  constructor(props) {
    super(props);
    moment.tz.setDefault('Europe/Moscow');

    const reservationDate = moment();
    const from = reservationDate.clone().hours(9);
    const to = reservationDate.clone().hours(21);

    this.state = {
      blockDay: false,
      contract: null,
      location: null,
      locationOptions: [],
      locationFreeHours: {
        count: null,
        loadStatus: LoadStatus.INIT
      },
      checked: false,
      chipData: [
        { key: 0, label: 'Сегодня', date: moment(), checked: true },
        { key: 1, label: 'Завтра', date: moment().add(1, 'days'), checked: false },
        { key: 2, label: 'Послезавтра', date: moment().add(2, 'days'), checked: false }
      ],
      timeSlots: [
        { key: 0, label: 'с 12 до 13', date: moment(), checked: false },
        { key: 0, label: 'с 13 до 14', date: moment(), checked: false },
        { key: 0, label: 'с 14 до 15', date: moment(), checked: false },
        { key: 0, label: 'с 15 до 16', date: moment(), checked: false },
        { key: 0, label: 'с 19 до 20', date: moment(), checked: false },
        { key: 0, label: 'с 20 до 21', date: moment(), checked: false }
      ],
      tabValue: '',
      tableView: false,
      successReservationData: null,
      isModalWindowOpen: false,
      modalWindowsOfficeId: 0,
      workingHoursStart: from,
      workingHoursEnd: to,
      reservationDate,
      ...partOfInitialState
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.offices.length > 0 && prevState.location === null) {
      const options = nextProps.offices.reduce((acc, item) => {
        const floorsByLocation = item.officeFloors.filter(floor => floor.active).map(floor => floor.floor_id);
        // const emptyLocation = floorsByLocation.length === 1 && floorsByLocation[0] === null ? item : '';
        acc.push({ value: item.id, label: item.t_name });
        return acc;
        // return acc.filter(location => location.value !== emptyLocation.id);
      }, []);
      const tabOptions = options.reduce((acc, item) => {
        acc.push({ value: item.value, label: item.label });
        return acc;
      }, []);
      const tabLocationValue = tabOptions.length > 0 ? tabOptions[0].value : '';
      return {
        location: nextProps.offices[0],
        tabLocationValue: tabLocationValue,
        locationOptions: options,
        tabOptions: tabOptions
      };
    }
    return null;
  }

  getBlockHour = day => {
    const current = this.state.reservationDate;
    const data = day.filter(x => {
      return x.date.toString() === current.format('DD-MM');
    });

    if (data.length > 0) {
      if (data[0].status === 0) {
        this.setState({ blockDay: true });
      } else {
        this.setState({ blockDay: false });
        this.setLocation(this.state.location);
      }
    } else {
      if (current.isoWeekday() === 6 || current.isoWeekday() === 7) {
        this.setState({ blockDay: true });
      } else {
        this.setState({ blockDay: false });
      }
    }
  };

  getLocationFreeHours = location => {
    const { isGuest, getLocationPaidUnitsCount } = this.props;
    const { locationFreeHours } = this.state;

    if (isGuest) {
      this.setState({
        locationFreeHours: {
          count: null,
          loadStatus: LoadStatus.SUCCESS
        }
      });
      return;
    }

    this.setState({
      locationFreeHours: {
        ...locationFreeHours,
        loadStatus: LoadStatus.PENDING
      }
    });

    return getLocationPaidUnitsCount(location.id)
      .then(response => {
        const { data, status } = response;

        if (status !== 200) {
          throw new Error(`Response error, status: ${status}`);
        }

        if (isNull(data)) {
          throw new Error(`Response is empty`);
        }

        this.setState({
          locationFreeHours: {
            count: toInteger(data / MINUTES_IN_HOUR),
            loadStatus: LoadStatus.SUCCESS
          }
        });
      })
      .catch(() => {
        console.log('Ошибка брони');
        this.setState({
          locationFreeHours: {
            count: null,
            loadStatus: LoadStatus.ERROR
          }
        });
      });
  };

  async componentDidMount() {
    const { contracts, getContracts, getDay } = this.props;
    const day = await getDay();
    if (day) {
      this.getBlockHour(day.data);
    }
    if (!contracts) {
      getContracts();
    }
    createContainer({ id: MODAL_CONTAINER_ID });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { reserveRoomUI } = this.props;

    if (prevProps.reserveRoomUI && prevProps.reserveRoomUI.isFetching && reserveRoomUI.isSuccess) {
      this.setState({
        ...partOfInitialState
      });
      if (reserveRoomUI.isSuccess) {
        this.getLocationFreeHours(this.state.location);
        this.setState({ successReservationData: this.state.selectedService });
        this.setState({ isModalWindowOpen: true });
      }
    }

    if (
      !isEqual(prevState.location, this.state.location) ||
      (this.state.location && this.state.locationFreeHours.loadStatus === LoadStatus.INIT)
    ) {
      this.getLocationFreeHours(this.state.location);
    }

    if (!isEqual(prevState.reservationDate, this.state.reservationDate)) {
      const day = await this.props.getDay();
      if (day) {
        this.getBlockHour(day.data);
      }
    }
  }

  setLocation = (event, newValue) => {
    const { reservationDate } = this.state;
    const location = event.target.value
      ? this.props.offices.find(office => office.id === event.target.value)
      : this.props.offices.find(office => office.id === newValue);
    const { from, to } = this.getWorkingHoursInTheDay(reservationDate, this.state.blockDay, location);

    this.setState({
      location,
      reservationDate,
      tabLocationValue: location.id,
      workingHoursStart: from,
      workingHoursEnd: to,
      ...partOfInitialState
    });
  };

  getWorkingHoursInTheDay = (mDate, blockDay = true, location) => {
    const happyDays = [];
    const happyDay = happyDays.find(d => d.date.isSame(mDate, 'day'));

    if (happyDay) {
      return {
        from: mDate.clone().hours(happyDay.from),
        to: mDate.clone().hours(happyDay.to)
      };
    }
    if ((mDate.isoWeekday() === 6 || mDate.isoWeekday() === 7) && blockDay) {
      return {
        from: mDate.clone().hours(9),
        to: mDate.clone().hours(9)
      };
    }
    return {
      from: mDate.clone().hours(9),
      to: mDate.clone().hours(21)
    };
  };

  setReservationDate = inputDateObj => {
    const { location } = this.state;
    const reservationDate = moment(new Date(inputDateObj).toLocaleDateString('ru'), 'DD.MM.YYYY');
    const { from, to } = this.getWorkingHoursInTheDay(reservationDate, this.state.blockDay, location);

    this.setState({
      location,
      reservationDate,
      workingHoursStart: from,
      workingHoursEnd: to,
      ...partOfInitialState
    });
  };
  setChipData = newData => {
    this.setState({ chipData: newData });
    const currentActiveChip = newData.find(chipValue => chipValue.checked);
    this.setReservationDate(currentActiveChip.date);
  };

  setSelectedReservation = (dateTimeFrom, dateTimeTo, service) => {
    const { cleanErrors, isGuest, getServicePaidUnitsCount } = this.props;

    if (!dateTimeFrom || !dateTimeTo || !service) {
      this.setState({ ...partOfInitialState });
      return;
    }

    if (this.state.blockDay) {
      return;
    }

    cleanErrors();

    if (isGuest) {
      this.setState({
        dateTime: {
          from: moment(dateTimeFrom).seconds(0),
          to: moment(dateTimeTo).seconds(0)
        },
        selectedService: service,
        selectedServiceFreeHours: {
          count: 0,
          isUnlimited: false,
          loadStatus: LoadStatus.SUCCESS
        }
      });
      return;
    }

    this.setState({
      dateTime: {
        from: dateTimeFrom.seconds(0),
        to: dateTimeTo.seconds(0)
      },
      selectedService: service,
      selectedServiceFreeHours: {
        count: 0,
        isUnlimited: false,
        loadStatus: LoadStatus.PENDING
      }
    });

    const from = dateTimeFrom.seconds(0).format('YYYY-MM-DD HH:mm:ss');
    const to = dateTimeTo.seconds(0).format('YYYY-MM-DD HH:mm:ss');

    getServicePaidUnitsCount(service.id, from, to)
      .then(response => {
        const { data, status } = response;

        if (status !== 200) {
          throw new Error(`Response error, status: ${status}`);
        }

        if (isNull(data)) {
          this.setState({
            selectedServiceFreeHours: {
              count: 0,
              isUnlimited: true,
              loadStatus: LoadStatus.SUCCESS
            }
          });
        } else {
          this.setState({
            selectedServiceFreeHours: {
              count: toInteger(data / MINUTES_IN_HOUR),
              isUnlimited: false,
              loadStatus: LoadStatus.SUCCESS
            }
          });
        }
      })
      .catch(() => {
        this.setState({
          selectedServiceFreeHours: {
            count: 0,
            isUnlimited: false,
            loadStatus: LoadStatus.ERROR
          }
        });
      });
  };

  isSelected = () => {
    const { dateTime, selectedService } = this.state;
    return dateTime.from && dateTime.to && selectedService;
  };

  getCurrentPrice = () => {
    const { customer } = this.props;
    const { dateTime, selectedService, selectedServiceFreeHours } = this.state;

    if (selectedServiceFreeHours.isUnlimited) {
      return 0;
    }

    const countHours = dateTime.to.diff(dateTime.from, 'hours') - selectedServiceFreeHours.count;
    const hoursToPay = countHours > 0 ? countHours : 0;
    const priceType = hoursToPay >= 9 ? PRICE_COUNT_TYPE.BY_DAY : PRICE_COUNT_TYPE.BY_HOURS;

    const priceTypes = selectedService.servicePrices
      .filter(price => price.is_active)
      .filter(price => Boolean(price.resident_only) === Boolean(customer.is_resident));
    const priceTypesByHoursCount = priceTypes.filter(price => price.cnt_type_id === priceType);
    const resultPriceType = last(sortBy(priceTypesByHoursCount, pt => Number(pt.price)));

    if (!resultPriceType) {
      console.error(
        'Не найдена цена для ' +
          (!customer.is_resident ? 'не' : '') +
          'резидента с ' +
          (priceType === PRICE_COUNT_TYPE.BY_DAY ? 'тарифом на весь день' : 'почасовым тарифом')
      );
      return null;
    }

    const resultPrice =
      resultPriceType.cnt_type_id === PRICE_COUNT_TYPE.BY_HOURS
        ? Number(resultPriceType.price) * hoursToPay
        : Number(resultPriceType.price);

    return resultPrice;
  };

  getSelectedContract = () => {
    if (this.state.contract) {
      return this.state.contract;
    } else {
      const { id, type } = this.contractsForSelect;
      return {
        contract_id: id,
        type: type
      };
    }
  };

  reserveRoom = async evt => {
    const { dispatchReserveRoom, isGuest, history, reserveRoomUI } = this.props;
    const { dateTime, selectedService, selectedServiceFreeHours, blockDay } = this.state;

    if (blockDay) {
      return;
    }

    if (isGuest) {
      history.push({
        pathname: '/prefill-profile',
        state: {
          message: 'Пожалуйста, заполните профиль для пользования полным функционалом личного кабинета.'
        }
      });
      return;
    }

    if (selectedServiceFreeHours.loadStatus !== LoadStatus.SUCCESS || reserveRoomUI.isFetching) {
      return;
    }

    const contract = this.getSelectedContract();

    dispatchReserveRoom(
      selectedService.id,
      dateTime.from.seconds(0).format('YYYY-MM-DD HH:mm:ss'),
      dateTime.to.seconds(0).format('YYYY-MM-DD HH:mm:ss'),
      contract
    );

    this.setState({
      modalWindowsOfficeId: selectedService.office_id
    });
  };

  renderButtonInner = () => {
    const { reserveRoomUI } = this.props;
    const { selectedServiceFreeHours } = this.state;
    const price = formatAmount(this.getCurrentPrice());
    const isFetching = selectedServiceFreeHours.loadStatus !== LoadStatus.SUCCESS || reserveRoomUI.isFetching;

    return (
      <span
        className={`room-reservation__btn ${isFetching ? 'room-reservation__btn--with-loader' : ''} btn btn--solid`}
      >
        {isFetching && <Loader type='ThreeDots' color='white' width='40' height='30' />}
        <span>{price} &#8381;</span>
      </span>
    );
  };

  renderButton = () => {
    const { notEnoughMoney } = this.state;
    const { getError } = this.props;

    if (getError() === 'service_consume_schedule_time_is_unavailable') {
      return '';
    }

    const price = this.getCurrentPrice();

    if (notEnoughMoney) {
      return (
        <Link className='room-reservation__book-link' to='/fill-payment' title='Забронировать'>
          Недостаточно средств. Пополните счет на сумму {this.renderButtonInner()}
        </Link>
      );
    }

    if (price === null) {
      return <span className='room-reservation__book-link'>Цена не установлена</span>;
    }

    return (
      <button className='room-reservation__book-link' type='button' title='Забронировать' onClick={this.reserveRoom}>
        Забронировать за {this.renderButtonInner()}
      </button>
    );
  };

  get contractsForSelect() {
    const customer = this.props.customer;

    if (!customer) return '';

    return {
      id: customer.contract.id,
      number: customer.contract.id,
      start_at: customer.contract.date,
      type: customer.contract.type,
      label: `№ ${customer.contract.number} от ${moment(customer.contract.date, 'YYYY-MM-DD HH:mm:ss').format(
        'DD.MM.YYYY'
      )}`
    };
  }

  getHours = workingHoursStart => {
    const currentWorkingHour = moment()
      .startOf('hour')
      .add(1, 'hour');
    return workingHoursStart.isBefore(currentWorkingHour) ? currentWorkingHour : workingHoursStart;
  };
  handleSwitchChange = event => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const {
      blockDay,
      location,
      locationFreeHours,
      dateTime,
      reservationDate,
      workingHoursStart,
      workingHoursEnd,
      notEnoughMoney
    } = this.state;
    const { offices, getError } = this.props;
    const dateHoursPickerFrom = reservationDate
      .clone()
      .set('hour', 9)
      .set('minute', 0);
    const dateHoursPickerTo = dateHoursPickerFrom.clone().add(1, 'day');
    const dateHoursWorkingStart = this.getHours(workingHoursStart);

    if (location === null) {
      return (
        <div className='page__loader'>
          <Loader type='ThreeDots' color='#f15a4f' height='25' width='40' />
        </div>
      );
    }

    return (
      <StyledSection>
        {this.state.isModalWindowOpen && (
          <MeetingRoomDialog
            dialogType='meetingRoomSuccess'
            dialogData={this.state.successReservationData}
            handleSubmit={() => {
              this.setState({ isModalWindowOpen: false });
            }}
            handleClose={() => {
              this.setState({ isModalWindowOpen: false });
            }}
          />
        )}
        <ControllersWrapper>
          <StepLabelWrapper>
            <StepLabel reservation={'reservation'}>
              <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
              <p>Переговорные</p>
            </StepLabel>
          </StepLabelWrapper>
          <AlertWrapper>
            {!isNull(locationFreeHours.count) && (
              <InfoAlert title={`В тарифе осталось ${locationFreeHours.count} бесплатных часов`} type={'info'} />
            )}
          </AlertWrapper>
          <DatePickerWrapper>
            <FormControl fullWidth={true}>
              <CustomDatePicker
                customLabel={'День бронирования'}
                pickedDate={dayjs(this.state.reservationDate)}
                setPickedDate={value => this.setReservationDate(value)}
              />
            </FormControl>
          </DatePickerWrapper>
          <ChipWrapper>
            <CustomChip chipData={this.state.chipData} setChipData={newData => this.setChipData(newData)} />
          </ChipWrapper>
          <LocationSelectWrapper>
            <CustomSelectField
              name='location'
              id='custom-select'
              label={'Локация'}
              variant='filled'
              value={location.id}
              onChange={this.setLocation}
              options={this.state.locationOptions}
            />
          </LocationSelectWrapper>
          <TabsBlockWrapper>
            <TabsComponent
              tabsContainerStyle={{ width: '100%', height: '40px' }}
              tabStyle={{ height: '32px', width: '200px' }}
              tabValue={this.state.tabLocationValue}
              handleTabChange={this.setLocation}
              tabs={this.state.tabOptions}
            />
          </TabsBlockWrapper>
          {/*<SwitchWrapper>*/}
          {/*  <p>Расписание в табличном виде</p>*/}
          {/*  <CustomSwitch*/}
          {/*    size={'small'}*/}
          {/*    spacing={'6px'}*/}
          {/*    checked={this.state.checked}*/}
          {/*    onChange={this.handleSwitchChange}*/}
          {/*  />*/}
          {/*</SwitchWrapper>*/}
        </ControllersWrapper>
        <MeetingRoomCard
          location={location}
          dateFrom={dateHoursPickerFrom}
          dateTo={dateHoursPickerTo}
          listServiceTypes={SERVICE_TYPES}
          setSelectedReservation={this.setSelectedReservation}
          workingHoursStart={dateHoursWorkingStart}
          workingHoursEnd={workingHoursEnd}
          blockDay={blockDay}
          account={this.props.account}
          selectedService={this.props.selectedService}
          selectedServiceFreeHours={this.state.selectedServiceFreeHours}
          //чипсы
          chipData={this.state.chipData}
          setChipData={value => this.setState({ chipData: value })}
          //Управление бронью
          pickedDate={dayjs(this.state.reservationDate)}
          setPickedDate={value => this.setReservationDate(value)}
          reserveRoom={this.reserveRoom}
          tableView={this.state.checked}
          dateTime={dateTime}
          getError={this.props.getError}
          isSelected={this.isSelected}
          renderButton={this.renderButton}
          getCurrentPrice={this.getCurrentPrice}
          cardPrice={this.cardPrice}
          locationFreeHours={locationFreeHours.count}
          reserveRoomUI={this.props.reserveRoomUI}
          notEnoughMoney={notEnoughMoney}
        />
      </StyledSection>
    );
  }
}
const mapStateToProps = state => ({
  account: accountSelector(state)
});

const ConnectedRoomReservation = connect(mapStateToProps)(RoomReservation);

export default Container(ConnectedRoomReservation);

import { getCustomerSettings } from '../../services/workki/CustomerActions';
import * as actions from '../actions';

const expectedTypes = new Set([
  actions.requestCustomerProfileChangePublicFieldsSuccess.toString(),
  actions.requestCustomerCurrentSuccess.toString(),
  actions.requestCreateCustomerFileSuccess.toString()
]);

export default store => next => action => {
  if (expectedTypes.has(action.type)) {
    store.dispatch(getCustomerSettings()).catch(e => {});
  }
  return next(action);
};

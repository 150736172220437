import { attr, Model } from 'redux-orm';
import { omit } from 'lodash';
import * as actions from '../actions';

class CustomerChild extends Model {
  static reducer(action, customerChild) {
    switch (action.type) {
      case actions.requestChildren.success.toString():
        action.payload.forEach(customer => customerChild.upsert(customer));
        break;
      case actions.requestChildCreate.success.toString():
        customerChild.upsert(omit(action.payload, ['password']));
        break;
      case actions.requestChildUpdate.success.toString():
        customerChild.withId(action.payload.id).update(action.payload);
        break;
      case actions.requestChildDelete.success.toString():
        customerChild.withId(action.payload.id).update({ is_active: false });
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `CustomerChild: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CustomerChild.modelName = 'CustomerChild';

// Declare your related fields.
CustomerChild.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr()
};

export default CustomerChild;

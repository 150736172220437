import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { CustomSelectField } from '../../../shared/ui/components/Select';
import CustomDatePicker from '../../../shared/ui/components/CustomDatePicker';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { styled } from 'styled-components';
import { colors } from '../../../shared/ui/constants/styleConstants';
import CustomTimePicker from '../../../shared/ui/components/CustomTimePicker';
import { InfoAlert } from '../../../shared/ui/components/Profile/InfoAlert';
import { Warning } from '../../../shared/ui/icons/core/Warning';
import { CheckboxComponent } from '../../../shared/ui/components/Сheckbox';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { CheckboxBlock } from '../../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';

const FieldsGroupLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  margin-top: 30px;

  & > first-of-type {
    margin-top: 40px;
  }
`;
const TimeUnits = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 50%;
  }
  flex-direction: row;
  & > :first-child {
    margin-right: 16px;
  }
`;
const CommonError = styled.div`
  background-color: ${colors.workkiRedLight};
  color: ${colors.workkiRedFill};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin: 8px 0;
  padding: 4px;
  align-items: center;
  font-weight: 400;
  :nth-child(2) {
    margin-top: -2px;
    font-weight: 300;
  }
`;

const TMCWidget = ({ parkingProps, handleClose, scrollPosition }) => {
  const { locations, addTMCInputNeoGeo, addTMCInputParkKultury, loadingError } = parkingProps;
  const CORPUSES = [
    { label: 'A', value: '3695397004000' },
    { label: 'B', value: '3695397005000' },
    { label: 'C', value: '3695397006000' }
  ];
  const NEO_GEO_TYPE_CAR_ENTRY = '3590077188000';
  const PARK_KULTURY_TYPE_CAR_ENTRY = '4590481191001';
  const NEO_GEO_SERVICE_ID = 312;
  const PARK_KULTURY_SERVICE_ID = 723;
  const REQUEST_TYPES = [
    { label: 'На внос имущества', value: '393629549000' },
    { label: 'На вынос имущества', value: '421534163000' }
  ];

  const DOCUMENT_TYPES = [
    { label: 'Паспорт', value: '2658836543000' },
    { label: 'Заграничный паспорт', value: '2658836619000' },
    { label: 'Водительское удостоверение', value: '2658836640000' },
    { label: 'Другой вид документа', value: '2658836660000' }
  ];
  const PARKINGS = [
    { label: 'A', value: '3590266881000' },
    { label: 'B', value: '3590266893000' },
    { label: 'C', value: '3734201288000' }
  ];

  const [carData, setCarData] = useState({
    personalInfo: '',
    documentNumber: '',
    valuableData: '',
    comment: '',
    carNumber: '',
    cardBrand: '',
    location: null,
    building: CORPUSES[0].value,
    requestType: REQUEST_TYPES[0].value,
    documentType: DOCUMENT_TYPES[0].value,
    pickedDate: dayjs(),
    pickedTime: dayjs()
      .set('minute', Math.ceil(dayjs().minute() / 5) * 5)
      .startOf('minute')
  });
  const [filled, setFilled] = useState({
    personalInfo: false,
    documentNumber: false,
    valuableData: false,
    comment: false,
    carNumber: false,
    cardBrand: false
  });
  const [isChecked, setIsChecked] = useState('0');
  const [isFocused, setIsFocused] = useState({
    personalInfo: false,
    documentNumber: false,
    valuableData: false,
    comment: false,
    carNumber: false,
    cardBrand: false
  });

  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    const officeNames = locations && locations.map(office => office.label);
    if (locations && locations.length > 0) {
      const filteredObjects = locations.filter(obj => officeNames.includes(obj.label));
      setFilteredLocations(filteredObjects);
      //Временная фильтрация - пока что заявка TMC на Тульской не работает
      const options = filteredObjects
        .filter(office => office.value.office.id !== 7)
        .map(item => ({ value: item.value.office.id, label: item.label }));
      setSelectOptions(options);
      if (options.length > 0 && carData.location === null) {
        setCarData(prevState => ({ ...prevState, location: options[0].value }));
      }
    }
  }, [locations]);

  const setLocation = event => {
    setCarData(prevState => ({ ...prevState, location: event.target.value }));
  };
  const setBuilding = event => {
    setCarData(prevState => ({ ...prevState, building: event.target.value }));
  };
  const setRequestType = event => {
    setCarData(prevState => ({ ...prevState, requestType: event.target.value }));
  };
  const setDocumentType = event => {
    setCarData(prevState => ({ ...prevState, documentType: event.target.value }));
  };
  const setParking = event => {
    setCarData(prevState => ({ ...prevState, parking: event.target.value }));
  };
  const handleInputChange = (name, inputValue) => {
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: inputValue !== ''
    }));
    setCarData(prevState => {
      return {
        ...prevState,
        [name]: inputValue
      };
    });
  };
  const disableWeekends = date => {
    const day = date.day();
    return day === 0 || day === 6;
  };
  const handleSubmit = () => {
    const dateTime = carData.pickedDate
      .set('hour', carData.pickedTime.hour())
      .set('minute', carData.pickedTime.minute())
      .set('second', 0);
    const parking = {
      service_id: carData.location === 2 ? NEO_GEO_SERVICE_ID : PARK_KULTURY_SERVICE_ID,
      date: dateTime.format('YYYY-MM-DD HH:mm:ss'),
      name: carData.personalInfo,
      valuable_data: carData.valuableData,
      valueable_data: carData.valuableData, //Умышленная опечатка в названии поля - бэкенд иначе не примет для локации NEO-GEO
      documentType: carData.documentType,
      document_number: carData.documentNumber,
      comment: carData.comment,
      car_brand: carData.cardBrand,
      car_number: carData.carNumber,
      lift: isChecked ? '1' : '0',
      corpus: carData.building,
      parkingType: carData.location === 2 ? NEO_GEO_TYPE_CAR_ENTRY : PARK_KULTURY_TYPE_CAR_ENTRY,
      parking: carData.parking,
      requestType: carData.requestType,
      request_type: carData.requestType, //Умышленно сделанно бэкенд на парке культуры принимает поле через snakeCase
      parking_number: ''
    };
    if (carData.location === 2) {
      addTMCInputNeoGeo(parking);
    } else if (carData.location === 4) {
      addTMCInputParkKultury(parking);
    }
  };
  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };
  const handleEraseValue = fieldName => {
    setCarData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };
  useEffect(() => {
    const allFieldsFilledNeo =
      carData.personalInfo &&
      carData.documentNumber &&
      carData.valuableData &&
      carData.carNumber &&
      carData.cardBrand &&
      carData.parking &&
      carData.location &&
      carData.building;
    const allFieldsFilledParkKultury = carData.personalInfo && carData.valuableData && carData.location;

    setIsSubmitDisabled(carData.location === 2 ? !allFieldsFilledNeo : !allFieldsFilledParkKultury);
  }, [carData]);

  return (
    <>
      <InfoAlert
        title={'В случае необходимости оформить пропуск в выходные дни - обратитесь к менеджеру'}
        type={'info'}
      />
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleSubmit}
        autoComplete='off'
        noValidate
      >
        <FormControl fullWidth={true}>
          <FieldsGroupLabel>
            <span>Локация</span>
          </FieldsGroupLabel>
          <CustomSelectField
            name='location'
            id='custom-select'
            label={'Локация'}
            variant='filled'
            isDisabled={selectOptions.length === 0 || selectOptions.length === 1}
            value={carData.location || ''}
            onChange={setLocation}
            options={selectOptions}
          />
          {carData.location === 2 && (
            <CustomSelectField
              name='building'
              id='custom-select'
              label={'Корпус'}
              variant='filled'
              isDisabled={false}
              value={carData.building || ''}
              onChange={setBuilding}
              options={CORPUSES}
            />
          )}
          {carData.location === 2 && (
            <CheckboxBlock>
              <CheckboxComponent
                checked={isChecked.patronymic}
                onChange={() => setIsChecked(prevState => ({ ...prevState, patronymic: !isChecked.patronymic }))}
                name='addressCheckbox'
                checkedIcon={<CheckBoxRoundedIcon />}
              />
              <p>Нужен грузовой лифт</p>
            </CheckboxBlock>
          )}
          <FieldsGroupLabel>
            <span>Прибытие</span>
          </FieldsGroupLabel>
          <TimeUnits>
            <CustomDatePicker
              type='parking'
              customLabel='Дата'
              isDayDisabled={disableWeekends}
              pickedDate={carData.pickedDate}
              setPickedDate={newValue => setCarData(prevState => ({ ...prevState, pickedDate: newValue }))}
            />
            <CustomTimePicker
              scrollPosition={scrollPosition}
              pickedTime={carData.pickedTime}
              setPickedTime={newValue => setCarData(prevState => ({ ...prevState, pickedTime: newValue }))}
            />
          </TimeUnits>
          <FieldsGroupLabel>
            <span>Общие данные</span>
          </FieldsGroupLabel>
          <CustomTextField
            error={''}
            type='text'
            name='personalInfo'
            id='outlined-helperText'
            label='ФИО водителя'
            variant='filled'
            value={carData.personalInfo}
            onFocus={() => handleFocus('personalInfo')}
            onBlur={() => handleBlur('personalInfo')}
            onChange={e => handleInputChange('personalInfo', e.target.value)}
            helperText={''}
            endAdornmentProps={{
              filled: filled.personalInfo,
              isFocused: isFocused.personalInfo,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          <CustomSelectField
            name='building'
            id='custom-select'
            label='Тип заявки'
            variant='filled'
            isDisabled={false}
            value={carData.requestType || ''}
            onChange={setRequestType}
            options={REQUEST_TYPES}
          />
          {carData.location === 2 && (
            <>
              <CustomSelectField
                name='building'
                id='custom-select'
                label='Тип документа'
                variant='filled'
                isDisabled={false}
                value={carData.documentType || ''}
                onChange={setDocumentType}
                options={DOCUMENT_TYPES}
              />
              <CustomTextField
                error={''}
                type='text'
                name='documentNumber'
                id='outlined-helperText'
                label='Серия и номер документа'
                variant='filled'
                value={carData.documentNumber}
                onFocus={() => handleFocus('documentNumber')}
                onBlur={() => handleBlur('documentNumber')}
                onChange={e => handleInputChange('documentNumber', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.documentNumber,
                  isFocused: isFocused.documentNumber,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
            </>
          )}
          <CustomTextField
            error={''}
            type='text'
            name='valuableData'
            id='outlined-helperText'
            label='Данные материальных ценностей'
            variant='filled'
            value={carData.valuableData}
            onFocus={() => handleFocus('valuableData')}
            onBlur={() => handleBlur('valuableData')}
            onChange={e => handleInputChange('valuableData', e.target.value)}
            helperText={''}
            endAdornmentProps={{
              filled: filled.valuableData,
              isFocused: isFocused.valuableData,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          <CustomTextField
            error={''}
            type='text'
            name='comment'
            id='outlined-helperText'
            label='Комментарий'
            variant='filled'
            value={carData.comment}
            onFocus={() => handleFocus('comment')}
            onBlur={() => handleBlur('comment')}
            onChange={e => handleInputChange('comment', e.target.value)}
            helperText={''}
            endAdornmentProps={{
              filled: filled.comment,
              isFocused: isFocused.comment,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          {carData.location === 2 && (
            <>
              <FieldsGroupLabel>
                <span>Данные парковки</span>
              </FieldsGroupLabel>
              <CustomTextField
                error={''}
                type='text'
                name='carNumber'
                id='outlined-helperText'
                label='Номер автомобиля'
                variant='filled'
                value={carData.carNumber}
                onFocus={() => handleFocus('carNumber')}
                onBlur={() => handleBlur('carNumber')}
                onChange={e => handleInputChange('carNumber', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.carNumber,
                  isFocused: isFocused.carNumber,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
              <CustomTextField
                error={''}
                type='text'
                name='cardBrand'
                id='outlined-helperText'
                label='Марка автомобиля'
                variant='filled'
                value={carData.cardBrand}
                onFocus={() => handleFocus('cardBrand')}
                onBlur={() => handleBlur('cardBrand')}
                onChange={e => handleInputChange('cardBrand', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.cardBrand,
                  isFocused: isFocused.cardBrand,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
              <CustomSelectField
                name='location'
                id='custom-select'
                label={'Парковка'}
                variant='filled'
                value={carData.parking || ''}
                onChange={setParking}
                options={PARKINGS}
              />
            </>
          )}
          {loadingError && (
            <CommonError>
              <span>
                <Warning spacing='10px' defaultColor='#DB4344' />
              </span>
              <span>{loadingError}</span>
            </CommonError>
          )}
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title='Отправить заявку'
              handleAction={handleSubmit}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default TMCWidget;

import React from 'react';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import WorkplaceReservation from '../../components/WorkplaceReservation/WorkplaceReservation';

const WorkplacesPage = ({ history }) => {
  return (
    <PrimaryLayout>
      <main className='main'>
        <WorkplaceReservation history={history} />
      </main>
    </PrimaryLayout>
  );
};

export default WorkplacesPage;

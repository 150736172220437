import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const isModalOpen = createReducer(initialState.isModalOpen, {
  [actions.openModal]: () => true,
  [actions.closeModal]: () => false,
  [actions.logout]: () => false
});

export default isModalOpen;

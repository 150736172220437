/* eslint-disable no-shadow */
import { attr, Model, fk } from 'redux-orm';
import * as actions from '../actions';

class Parking extends Model {
  static reducer(action, parking) {
    switch (action.type) {
      case actions.requestParkingList.success.toString():
        action.payload.map(elem => parking.upsert(elem));
        break;
      case actions.requestParkingCreate.success.toString():
        parking.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `Parking: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
Parking.modelName = 'Parking';

// Declare your related fields.
Parking.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  // status_id:
  service_id: fk('Service')
};

export default Parking;

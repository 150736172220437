import React from 'react';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import FillPayment from '../components/FillPayment';
import PageLoader from '../../components/PageLoader';

const FillPaymentPage = props => (
  <PrimaryLayout>
    <main className='main'>
      <h1 className='page__title page__title_payment'>Пополнение счета</h1>
      {props.isLoading ? <PageLoader /> : <FillPayment checkoutDifference={props.location.propsSearch} />}
    </main>
  </PrimaryLayout>
);

export default FillPaymentPage;

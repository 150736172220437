export const LOADING_STATUSES = {
  INIT: 'init',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
  EMPTY: 'empty'
};

export const PUBLIC_PROFILE_MODERATION_STATUS = {
  MODERATION: 1,
  ALLOWED: 2,
  NOT_ALLOWED: 3,
  REMODERATION: 4
};

export const CUSTOMER_FILE_STATUS = {
  VERIFICATION_REQUIRED: 1,
  VERIFIED: 2,
  INCORRECT: 3,
  POOR_QUALITY: 4
};

export const TICKET_CATEGORY_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2
};

import React, { useEffect, useMemo, useState } from 'react';
import { Moment } from '../../../utils/moment';
import { SERVICE_TYPE } from '../../../constants';
import formatAmount from '../../../utils/formatAmount';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { createUDPRequest } from '../../../services/workki/TransactionsActions';
import saveAs from 'file-saver';
import { getPaymentDocument } from '../../../entities/Payments/api/DepositsActions';

const OFFICE_NEEDED_SERVICES = new Set([
  SERVICE_TYPE.WORK_PLACE,
  SERVICE_TYPE.MEETING_ROOM,
  SERVICE_TYPE.MULTIMEDIA_MEETING_ROOM,
  SERVICE_TYPE.EVENT_SPACE
]);

const descriptionByType = {
  [SERVICE_TYPE.WORK_PLACE]: 'Рабочее место',
  [SERVICE_TYPE.MEETING_ROOM]: 'Переговорная',
  [SERVICE_TYPE.MULTIMEDIA_MEETING_ROOM]: 'Переговорная'
};

const getServiceNameWithDesc = service => {
  const desc = descriptionByType[service.type_id];
  if (!desc || service.is_openspace) {
    return service.name;
  }

  return [desc, service.name].filter(v => v).join(' ');
};
const addOffice = (name, office) => (office ? `${name} (${office})` : name);

const TransactionsHistoryRow = ({ transaction }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(transaction.is_need_document);
  const handleChange = event => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    dispatch(
      createUDPRequest({
        id: transaction.additional_id,
        is_need_document: isChecked
      })
    );
  };
  const getDocument = link => {
    const id = link ? link.split('=')[1] : '';
    dispatch(getPaymentDocument(id))
      .then(response => {
        console.log(response);
        if (response.data instanceof Blob) {
          const fileName = 'Платежный документ';
          saveAs(response.data, fileName);
        } else {
          console.error('Ответ не является Blob');
        }
      })
      .catch(error => {
        console.error('Ошибка при скачивании файла:', error);
      });
  };

  const serviceName = useMemo(() => {
    if (!transaction.service) {
      return '';
    }

    if (!transaction.office || !OFFICE_NEEDED_SERVICES.has(transaction.service.type_id)) {
      return getServiceNameWithDesc(transaction.service);
    }

    return addOffice(getServiceNameWithDesc(transaction.service), transaction.office.t_name);
  }, [transaction.office, transaction.service]);

  const reformatServiceName = (name, substring) => {
    let normalizedName = name.toLowerCase();
    let normalizedSubstring = substring.toLowerCase();
    let indexes = [];
    let startIndex = normalizedName.indexOf(normalizedSubstring);
    while (startIndex !== -1) {
      indexes.push(startIndex);
      startIndex = normalizedName.indexOf(normalizedSubstring, startIndex + 1);
    }

    if (indexes.length === 2) {
      let newLine = name.slice(0, indexes[0]) + name.slice(indexes[0] + substring.length);
      return newLine;
    } else {
      return name;
    }
  };

  return (
    <div className='scrollable-table__row'>
      <div className='scrollable-table__cell'>#{transaction.service_request_id}</div>
      <div className='scrollable-table__cell scrollable-table__cell--date'>
        <Moment locale='ru' parse='YYYY-MM-DD HH:mm:ss' format='DD.MM.YYYY HH:mm'>
          {transaction.created_at}
        </Moment>
      </div>
      <div className='scrollable-table__cell scrollable-table__cell--amount'>
        {formatAmount(transaction.amount || 0)} &#8381;
      </div>
      <div className='scrollable-table__cell'>{reformatServiceName(serviceName.split('(')[0], 'Рабочее место')}</div>
      <div className='scrollable-table__cell'>{serviceName.split('(')[1] ? '(' + serviceName.split('(')[1] : '-'}</div>
      <div className='scrollable-table__cell'>
        {' '}
        <Checkbox
          checked={checked}
          onChange={handleChange}
          sx={{
            'color': 'grey',
            '&.Mui-checked': {
              color: '#f15a4f'
            }
          }}
        />
        <span
          onClick={() => (transaction.document_link ? getDocument(transaction.document_link) : '')}
          className='scrollable-table__link-payment'
        >
          {transaction.document_link ? 'Скачать' : '-'}
        </span>
      </div>
      <div className='scrollable-table__cell'>{transaction.contract ? transaction.contract.stringFormat : ''}</div>
    </div>
  );
};

export default TransactionsHistoryRow;

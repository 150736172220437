import React from 'react';
import styled from 'styled-components';
import { colors, fontFamilyBase } from '../../shared/ui/constants/styleConstants';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import CancellationCircle from '../../shared/ui/icons/core/CancellationCircle';
import { Link } from 'react-router-dom';

const CardShellText = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.workkiDarkMain};

  & > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.workkiDarkSecondary};
  }
`;

const CancelBox = styled.p`
  margin: 20px 0 0 0;
  padding: 0;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.workkiDarkSecondary};
  cursor: pointer;

  & > svg {
    path: {
      fill: ${colors.workkiDarkSecondary};
    }
  }
`;

const DashboardActions = ({ handleChangeInfoTypeState }) => {
  return (
    <>
      <CardShell
        style={{ flexDirection: 'row', cursor: 'pointer' }}
        onClick={() => {
          window.location.href = '/passes';
        }}
      >
        <CardShellText>
          Заказать гостевой пропуск
          <span>Пригласить гостя на локацию Workki</span>
        </CardShellText>
        <ArrowBack rotation='180deg' style={{ width: '20px' }} spacing='0' />
      </CardShell>
      <CardShell
        style={{ marginTop: '8px', flexDirection: 'row', cursor: 'pointer' }}
        onClick={() => {
          window.location.href = '/parking';
        }}
      >
        <CardShellText>
          Заказать пропуск на въезд авто
          <span>Приехать в коворкинг на своей машине</span>
        </CardShellText>
        <ArrowBack rotation='180deg' style={{ width: '20px' }} spacing='0' />
      </CardShell>
      {/* <CancelBox onClick={() => handleChangeInfoTypeState('bookingCancel')}>
        <CancellationCircle />
        Отменить бронирование
      </CancelBox> */}
    </>
  );
};

export default DashboardActions;

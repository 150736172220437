import React, { useEffect, useState } from 'react';
import { Date, Description, Details, Transaction, Type } from '../../entities/Payments/model/Payments';
import { StatusBar, StatusBlock } from './ServiceDetailsWidget';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Loader } from '../../shared/ui/components/Loader';
import { LinkArrow } from '../../shared/ui/icons/core/LinkArrow';
import { ServiceDetailsDialog } from './ServiceDetailsDialog';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;
export const Container = styled.div`
  color: #24272a;
  background-color: white;
  font-family: Commissioner, sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
`;
export const StatusWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    flex-direction: row;
    align-items: center;
    & > div {
      margin-right: 12px;
    }
  }
`;

const HistoryList = ({
  customer,
  guestList,
  parkingList,
  tmcList,
  guestsRequestStatus,
  parkingRequestStatus,
  loadGuestData,
  loadParkingData,
  loadTmcData
}) => {
  const { loadTMCInputHistory, loadTulskayaTMCInputHistory, loadParkKulturyTMCInputHistory } = loadTmcData;
  const [open, setOpen] = useState({
    parkingInfo: false,
    guestsInfo: false,
    tmcInfo: false
  });
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  useEffect(() => {
    if (customer) {
      loadTMCInputHistory();
      loadTulskayaTMCInputHistory();
      loadParkKulturyTMCInputHistory();
      loadGuestData();
      loadParkingData();
    }
  }, [customer, loadGuestData, loadParkingData]);

  if (
    parkingRequestStatus.isPending ||
    guestsRequestStatus.isInit ||
    parkingRequestStatus.isInit ||
    guestsRequestStatus.isPending
  ) {
    return (
      <section className='page__section std-table'>
        <Loader wrapperType='transparent' />
      </section>
    );
  }

  if (parkingRequestStatus.isFailure || guestsRequestStatus.isFailure) {
    return (
      <Container>
        <Transaction>
          <Details>
            <Description>Ошибка загрузки истории заказов пропусков</Description>
          </Details>
        </Transaction>
      </Container>
    );
  }

  const renderStatusTitle = loadingStatus => {
    const content = {
      Создан: () => (
        <StatusBar type={'default'}>
          <p>Создана</p>
        </StatusBar>
      ),
      Принято: () => (
        <StatusBar type={'accepted'}>
          <p>Принята</p>
        </StatusBar>
      ),
      Отклонено: () => (
        <StatusBar type={'rejected'}>
          <p>Отклонена</p>
        </StatusBar>
      ),
      Закрыто: () => (
        <StatusBar type={'default'}>
          <p>Закрыта</p>
        </StatusBar>
      )
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };
  const renderHistory = () => {
    if (guestList || parkingList || tmcList) {
      return (
        <Container>
          {guestList.length > 0 &&
            guestList.map(item => (
              <Transaction
                onClick={() => {
                  setSelectedServiceId(item.id);
                  setOpen(prevState => ({ ...prevState, guestsInfo: true }));
                }}
                key={item.id || item.service_request_id}
              >
                <Details>
                  <Type>
                    Гостевой пропуск{' '}
                    <LinkArrow type={'small'} defaultColor='#999' spacing={'12px'} width={'12px'} height={'12px'} />
                  </Type>
                  <Description>{item.location.t_name}</Description>
                </Details>
                <Details direction='end'>
                  <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
                </Details>
              </Transaction>
            ))}
          {tmcList.length > 0 &&
            tmcList.map(item => (
              <Transaction
                onClick={() => {
                  setSelectedServiceId(item.id);
                  setOpen(prevState => ({ ...prevState, tmcInfo: true }));
                }}
                key={item.id || item.service_request_id}
              >
                <Details>
                  <Type>
                    <StatusWrapper>
                      <div>
                        Пропуск на вынос ТМЦ&nbsp;
                        <LinkArrow type={'small'} defaultColor='#999' width={'12px'} height={'12px'} />
                      </div>
                      {item.request_status && renderStatusTitle(item.request_status)}
                    </StatusWrapper>
                  </Type>
                  <Description>{item.location.t_name}</Description>
                </Details>
                <Details direction='end'>
                  <Date>{dayjs(item.date).format('HH:MM')}</Date>
                  <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
                </Details>
              </Transaction>
            ))}
          {parkingList.length > 0 &&
            parkingList.map(item => (
              <Transaction
                onClick={() => {
                  setSelectedServiceId(item.id);
                  setOpen(prevState => ({ ...prevState, parkingInfo: true }));
                }}
                key={item.id || item.service_request_id}
              >
                <Details>
                  <Type>
                    <StatusWrapper>
                      <div>
                        Пропуск на въезд авто&nbsp;
                        <LinkArrow type={'small'} defaultColor='#999' width={'12px'} height={'12px'} />
                      </div>
                      {item.request_status && renderStatusTitle(item.request_status)}
                    </StatusWrapper>
                  </Type>
                  <Description>{item.location.t_name}</Description>
                </Details>
                <Details direction='end'>
                  <Date>{dayjs(item.date).format('HH:MM')}</Date>
                  <Date>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
                </Details>
              </Transaction>
            ))}
        </Container>
      );
    }

    return (
      <Container>
        <Transaction>
          <Details>
            <Description>Здесь появится история заявок на пропуск</Description>
          </Details>
        </Transaction>
      </Container>
    );
  };

  return (
    <Wrapper>
      {renderHistory()}
      <>
        {open.parkingInfo && (
          <ServiceDetailsDialog
            title='Пропуск на въезд авто'
            dialogData={parkingList.filter(parkingRequest => parkingRequest.id === selectedServiceId)}
            dialogType='serviceDetails'
            handleClose={() => {
              setOpen(prevState => ({ ...prevState, parkingInfo: false }));
            }}
          />
        )}
      </>
      <>
        {open.tmcInfo && (
          <ServiceDetailsDialog
            title='Пропуск на внос/вынос ТМЦ'
            dialogData={tmcList.filter(tmcRequest => tmcRequest.id === selectedServiceId)}
            dialogType='serviceDetails'
            handleClose={() => {
              setOpen(prevState => ({ ...prevState, tmcInfo: false }));
            }}
          />
        )}
      </>
      <>
        {open.guestsInfo && (
          <ServiceDetailsDialog
            title='Гостевой пропуск'
            dialogData={guestList.filter(guestRequest => guestRequest.id === selectedServiceId)}
            dialogType='serviceDetails'
            handleClose={() => {
              setOpen(prevState => ({ ...prevState, guestsInfo: false }));
            }}
          />
        )}
      </>
    </Wrapper>
  );
};

export default HistoryList;

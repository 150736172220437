import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styleConstants';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || colors.workkiDarkMiddle};
    :hover {
      ${props => props.hoverColor || colors.workkiDarkMiddle};
    }
  }
  cursor: pointer;
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
`;

const VerticalEllipsis = ({ defaultColor, hoverColor, bigArrow, onClick, minWidth, ...props }) => {
  return (
    <StyledSvg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      hoverColor={hoverColor}
      onClick={onClick}
      minWidth={minWidth}
      {...props}
    >
      <path d='M12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5Z' />
      <path d='M13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12Z' />
      <path d='M13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18C10.5 17.1716 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1716 13.5 18Z' />
    </StyledSvg>
  );
};

export default VerticalEllipsis;

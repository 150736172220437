import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

const PriceInfoDialog = ({ infoType, handleClose, price, ...props }) => {
  const infoTypes = {
    print: {
      title: 'Баланс печати',
      paragraph: [
        { text: `Это количество доступных для печати страниц.`, price: null },
        {
          text: `Чёрно-белая печать стоит дешевле цветной, поэтому за цветную печать мы списываем с\u00A0баланса 4 чёрно-белые страницы.`,
          price: null
        }
      ]
    },
    booking: {
      title: 'Стоимость бронирования',
      paragraph: [
        { text: `Указана за оплаченный период.`, price: null },
        { text: `Автопродление на следующий месяц будет стоить`, price: price }
      ]
    },
    prolongation: {
      title: 'Стоимость бронирования',
      paragraph: [
        { text: `В\u00A0конце каждого месяца будет проходить автоматический платёж.`, price: null },
        { text: `Автопродление на следующий месяц будет стоить\u00A0`, price: price }
      ]
    }
  };

  const getTitle = () => {
    const titleType = Object.keys(infoTypes).find(item => item === infoType);
    return infoTypes[titleType].title;
  };

  const getParagraph = () => {
    const paragraphs = Object.keys(infoTypes).find(item => item === infoType);
    return infoTypes[paragraphs].paragraph;
  };
  return (
    <Dialog
      title={getTitle()}
      dialogData={getParagraph()}
      dialogType={'generalInfo'}
      handleSubmit={handleClose}
      handleClose={handleClose}
      price={price}
      buttonLabel='Понятно, спасибо'
      {...props}
    />
  );
};

export default PriceInfoDialog;

import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';
import { getUDPDocuments } from '../../store/actions';

export const getTransactions = page =>
  api.get(
    `account/balance/consumption`,
    { params: { page: page || 1, sort: '-id' } },
    getRequestStatusActions(actions.requestTransactions)
  );
export const createUDPRequest = transactionData => {
  return api.post(
    'account/balance/consumption/update',
    { data: { ...transactionData } },
    getRequestStatusActions(actions.createUDPRequest)
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { screenRessolutions } from '../shared/ui/constants/styleConstants';
import CardShell from '../shared/ui/components/Profile/CardShell';
import { SliderArrow } from '../shared/ui/icons/core/SliderArror';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useResize } from '../shared/utils/hooks/useResize';
import ServicesHistory from '../entities/Services/model/ServicesHistory';
import { LabelWrapper } from '../components/WorkplaceReservation/WorkplaceReservation';
import { ArrowBack } from '../shared/ui/icons/core/ArrowBack';
import { PrefillForm } from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { StepLabel } from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';

const SectionsBox = styled.div`
  margin: 8px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
`;

const SectionText = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 268px;
  box-sizing: border-box;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #24272a;
    width: 100%;
    max-width: 268px;
    overflow-wrap: anywhere;
  }

  & > p {
    margin: 2px 0 0 0;
    padding: 0;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: Commissioner, sans-serif;
    color: #575b60;
    width: 100%;
    max-width: 268px;
    overflow-wrap: anywhere;
  }
`;

const SectionImg = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f4f4f4;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
`;

const PassesPage = () => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [outerModalOpen, setOuterModalOpen] = useState({
    guests: false,
    parking: false,
    tmc: false
  });

  const history = useHistory();

  const arrowSize = {
    width: '15px',
    height: '7.5px'
  };

  const cardPaddings = '12px 12px 14px 16px';

  const checkWidth = () => {
    const isScreenLaptop = window.innerWidth >= screenRessolutions.laptop;
    if (isLaptop !== isScreenLaptop) setIsLaptop(isScreenLaptop);
  };

  useResize(checkWidth, 0);
  useEffect(() => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, []);

  return (
    <PrimaryLayout>
      <main className='main'>
        <PrefillForm>
          <LabelWrapper>
            <StepLabel reservation={'reservation'} position='center'>
              {isTouchDevice && (
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
              )}
              <p>Заявки на пропуск</p>
            </StepLabel>
          </LabelWrapper>
          <SectionsBox>
            <CardShell
              onClick={() => setOuterModalOpen(prevState => ({ ...prevState, guests: true }))}
              style={{ padding: cardPaddings, marginBottom: '8px' }}
              minHeight={'fit-content'}
            >
              <Section style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SectionText>
                  <h4>Гостевой пропуск</h4>
                  <p>Пригласить гостя на локацию Workki</p>
                </SectionText>
                <SectionImg>
                  <SliderArrow style={{ ...arrowSize }} />
                </SectionImg>
              </Section>
            </CardShell>
            <CardShell
              onClick={() => setOuterModalOpen(prevState => ({ ...prevState, parking: true }))}
              style={{ padding: cardPaddings, marginBottom: '8px' }}
              minHeight={'fit-content'}
            >
              <Section style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SectionText>
                  <h4>Пропуск на въезд авто</h4>
                  <p>Приехать в коворкинг на своей машине</p>
                </SectionText>
                <SectionImg>
                  <SliderArrow style={{ ...arrowSize }} />
                </SectionImg>
              </Section>
            </CardShell>
            <CardShell
              onClick={() => setOuterModalOpen(prevState => ({ ...prevState, tmc: true }))}
              style={{ padding: cardPaddings, marginBottom: '8px' }}
              minHeight={'fit-content'}
            >
              <Section style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SectionText>
                  <h4>Пропуск на внос/вынос ТМЦ</h4>
                  <p>Перенести мебель или габаритную технику</p>
                </SectionText>
                <SectionImg>
                  <SliderArrow style={{ ...arrowSize }} />
                </SectionImg>
              </Section>
            </CardShell>
          </SectionsBox>
          <ServicesHistory
            setOuterModalOpen={(value, type) => setOuterModalOpen(prevState => ({ ...prevState, [type]: false }))}
            outerModalOpen={outerModalOpen}
          />
        </PrefillForm>
      </main>
    </PrimaryLayout>
  );
};

export default PassesPage;

import { Model } from 'redux-orm';
import * as actions from '../actions';

class PaymentSystem extends Model {
  static reducer(action, paymentSystem) {
    switch (action.type) {
      case actions.requestPaymentSystemSuccess.toString():
        action.payload.map(elem => paymentSystem.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `PaymentSystem: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
PaymentSystem.modelName = 'PaymentSystem';

// Declare your related fields.
PaymentSystem.fields = {
  //  id: attr(), // non-relational field for any value; optional but highly recommended
  // t_name: attr(),
};

export default PaymentSystem;

import { fk, attr, Model } from 'redux-orm';
import * as actions from '../actions';

// service status_id
// const ACTIVE = 1;
// const UNAVAILABLE_FOR_DIRECT = 2;
// const DISABLED = 3;
// const OVERDUE = 4;

class Service extends Model {
  static reducer(action, service) {
    switch (action.type) {
      case actions.requestServicesSuccess.toString():
        action.payload.forEach(elem => {
          elem.units = elem.units.map(unit => unit.id);
          elem.isFree = false;
          service.upsert(elem);
        });
        // DepositStatus.create(data);
        break;
      case actions.requestServicesIsActive.success.toString():
        service
          .all()
          .toModelArray()
          .forEach(s => {
            s = { ...s.ref };
            const prevIsFree = s.isFree;
            s.isFree = !!action.payload.find(elem => elem.id === s.id);
            if (s.isFree !== prevIsFree) {
              service.upsert(s);
            }
          });
        break;
      case actions.requestServiceLocationIsAvailable.success.toString():
        // eslint-disable-next-line no-case-declarations
        const freeIds = action.payload.rooms.map(r => r.id);
        service
          .all()
          .filter(s => s.type_id === 5 && s.office_id === action.payload.id)
          .toModelArray()
          .forEach(s => {
            s = { ...s.ref };
            const prevIsFree = s.isFree;
            s.isFree = freeIds.includes(s.id);
            if (s.isFree !== prevIsFree) {
              service.upsert(s);
            }
          });
        break;
      case actions.requestServiceRequestCreate.success.toString():
        service
          .all()
          .toModelArray()
          .forEach(s => {
            s = { ...s.ref };
            if (s.id === action.payload.service_id) {
              s.isFree = false;
              service.upsert(s);
            }
          });
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Service: ${this.name}`;
  }
}
Service.modelName = 'Service';

Service.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr(),
  units: fk('Service', 'services'),
  office_id: fk('Office', 'services')
};

export default Service;

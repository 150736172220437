import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  & > path {
    fill: ${props => props.fillColor || 'white'};
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '0'};
`;

export const ScalingIcon = ({ type, hoverColor, handleAction, defaultColor, spacing }) => {
  const renderIconView = () => {
    const content = {
      increase: () => {
        return (
          <SvgWrapper
            style={{ cursor: 'pointer' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10.875 18.75C10.875 19.3713 11.3787 19.875 12 19.875C12.6213 19.875 13.125 19.3713 13.125 18.75V13.125H18.75C19.3713 13.125 19.875 12.6213 19.875 12C19.875 11.3787 19.3713 10.875 18.75 10.875H13.125V5.25C13.125 4.62868 12.6213 4.125 12 4.125C11.3787 4.125 10.875 4.62868 10.875 5.25V10.875H5.25C4.62868 10.875 4.125 11.3787 4.125 12C4.125 12.6213 4.62868 13.125 5.25 13.125H10.875V18.75Z'
                fill='white'
              />
            </svg>
          </SvgWrapper>
        );
      },
      decrease: () => {
        return (
          <SvgWrapper
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='visible-svg'
            style={{ cursor: 'pointer' }}
            onClick={handleAction}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M4.125 12C4.125 11.3787 4.62868 10.875 5.25 10.875H18.75C19.3713 10.875 19.875 11.3787 19.875 12C19.875 12.6213 19.3713 13.125 18.75 13.125H5.25C4.62868 13.125 4.125 12.6213 4.125 12Z'
              fill='white'
            />
          </SvgWrapper>
        );
      }
    };

    return content[type]();
  };

  return renderIconView(type);
};

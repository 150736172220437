import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class TicketCategory extends Model {
  static reducer(action, TicketCategory) {
    switch (action.type) {
      case actions.requestTicketCategories.success.toString():
        action.payload.forEach(t => {
          TicketCategory.upsert(t);
        });
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Service: ${this.name}`;
  }
}

TicketCategory.modelName = 'TicketCategory';

TicketCategory.fields = {
  id: attr() // non-relational field for any value; optional but highly recommended
};

export default TicketCategory;

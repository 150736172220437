import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'}!important;
`;

export const PhoneMask = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='46'
      height='22'
      viewBox='0 0 46 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <rect y='4' width='20' height='14' rx='2' fill='black' fill-opacity='0.15' />
      <g clip-path='url(#clip0_2974_428)'>
        <rect x='1' y='5' width='18' height='4' fill='white' />
        <rect x='1' y='9' width='18' height='4' fill='#0039A6' />
        <rect x='1' y='13' width='18' height='4' fill='#DB4344' />
      </g>
      <path
        d='M28.104 16.048C28.104 15.5467 28.104 15.064 28.104 14.6C28.104 14.1307 28.104 13.6907 28.104 13.28V11.152C28.104 10.7413 28.104 10.304 28.104 9.84C28.104 9.37067 28.104 8.88533 28.104 8.384H29.312C29.312 8.88533 29.312 9.37067 29.312 9.84C29.312 10.304 29.312 10.7413 29.312 11.152V13.28C29.312 13.6907 29.312 14.1307 29.312 14.6C29.312 15.064 29.312 15.5467 29.312 16.048H28.104ZM24.992 12.808V11.632C25.4933 11.632 25.9787 11.632 26.448 11.632C26.9173 11.632 27.3547 11.632 27.76 11.632H29.656C30.0667 11.632 30.504 11.632 30.968 11.632C31.4373 11.632 31.9227 11.632 32.424 11.632V12.808C31.9227 12.808 31.4373 12.808 30.968 12.808C30.504 12.808 30.0667 12.808 29.656 12.808H27.76C27.3547 12.808 26.9173 12.808 26.448 12.808C25.9787 12.808 25.4933 12.808 24.992 12.808ZM35.002 17C35.3327 16.2747 35.6633 15.552 35.994 14.832C36.33 14.1067 36.658 13.392 36.978 12.688L38.506 9.36C38.698 8.95467 38.8873 8.54933 39.074 8.144C39.2607 7.73867 39.4447 7.33067 39.626 6.92L39.898 7.224H37.65C36.9887 7.224 36.3247 7.224 35.658 7.224C34.9913 7.224 34.322 7.224 33.65 7.224V6.032H41.066V6.968C40.7673 7.624 40.474 8.26933 40.186 8.904C39.898 9.53867 39.602 10.192 39.298 10.864L38.282 13.096C37.994 13.736 37.7007 14.384 37.402 15.04C37.1033 15.6907 36.8073 16.344 36.514 17H35.002Z'
        fill='#24272A'
      />
      <defs>
        <clipPath id='clip0_2974_428'>
          <rect x='1' y='5' width='18' height='12' rx='1' fill='white' />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};

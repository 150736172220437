import { WorkplaceType, AreaType } from '../types';

export const KOMSOMOLSKAYA_FLOOR_AREAS = [
  {
    name: 'С101',
    uuid: 'area-C101',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M370 57H420V210H390V215H360V210H320V110H315V80H320V70H370V57Z',
    workplaces: [
      { uuid: 'wp-101-1', name: 'wp-101-1', position: { x: 320, y: 70, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-101-2', name: 'wp-101-2', position: { x: 320, y: 110, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-101-3', name: 'wp-101-3', position: { x: 380, y: 70, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-101-4', name: 'wp-101-4', position: { x: 380, y: 110, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-101-5', name: 'wp-101-5', position: { x: 380, y: 150, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С102',
    uuid: 'area-C102',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M490 97V110H460V97H440V263H490V265H520V263H525V97H490Z',
    workplaces: [
      { uuid: 'wp-102-1', name: 'wp-102-1', position: { x: 440, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-102-2', name: 'wp-102-2', position: { x: 440, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-102-3', name: 'wp-102-3', position: { x: 440, y: 200, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-102-4', name: 'wp-102-4', position: { x: 485, y: 110, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-102-5', name: 'wp-102-5', position: { x: 485, y: 150, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С103',
    uuid: 'area-C103',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M498.5 327L521.057 378.893C534.551 409.927 565.168 430.001 599.008 430.001H620V410H633V327H585V325H555V327L498.5 327Z',
    workplaces: [
      { uuid: 'wp-103-1', name: 'wp-103-1', position: { x: 515, y: 339, angle: 66 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-103-2', name: 'wp-103-2', position: { x: 542, y: 375, angle: 37 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-103-3', name: 'wp-103-3', position: { x: 590, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-103-4', name: 'wp-103-4', position: { x: 590, y: 370, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С104',
    uuid: 'area-C104',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M535 97H570V110H600V97H620V130H625V263H620V265H590V263H535V97Z',
    workplaces: [
      { uuid: 'wp-104-1', name: 'wp-104-1', position: { x: 535, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-104-2', name: 'wp-104-2', position: { x: 535, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-104-3', name: 'wp-104-3', position: { x: 535, y: 200, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-104-4', name: 'wp-104-4', position: { x: 585, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-104-5', name: 'wp-104-5', position: { x: 585, y: 180, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С105',
    uuid: 'area-C105',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M637 410V327H705V325H735V327H743V433H680V430H640V410H637Z',
    workplaces: [
      { uuid: 'wp-105-1', name: 'wp-105-1', position: { x: 640, y: 350, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-105-2', name: 'wp-105-2', position: { x: 640, y: 390, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-105-3', name: 'wp-105-3', position: { x: 700, y: 350, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-105-4', name: 'wp-105-4', position: { x: 700, y: 390, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С106',
    uuid: 'area-C106',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M640 130V97H660V110H690V97H725V263H670V265H640V263H635V130H640Z',
    workplaces: [
      { uuid: 'wp-106-1', name: 'wp-106-1', position: { x: 635, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-106-2', name: 'wp-106-2', position: { x: 635, y: 180, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-106-3', name: 'wp-106-3', position: { x: 685, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-106-4', name: 'wp-106-4', position: { x: 685, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-106-5', name: 'wp-106-5', position: { x: 685, y: 200, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С107',
    uuid: 'area-C107',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M747 433V327H755V325H785V327H853V430H850V433H747Z',
    workplaces: [
      { uuid: 'wp-107-1', name: 'wp-107-1', position: { x: 750, y: 350, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-107-2', name: 'wp-107-2', position: { x: 750, y: 390, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-107-3', name: 'wp-107-3', position: { x: 810, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-107-4', name: 'wp-107-4', position: { x: 810, y: 370, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С108',
    uuid: 'area-C108',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M735 263V97H770V110H800V97H820V130H825V263H820V265H790V263H735Z',
    workplaces: [
      { uuid: 'wp-108-1', name: 'wp-108-1', position: { x: 735, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-108-2', name: 'wp-108-2', position: { x: 735, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-108-3', name: 'wp-108-3', position: { x: 735, y: 200, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-108-4', name: 'wp-108-4', position: { x: 785, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-108-5', name: 'wp-108-5', position: { x: 785, y: 180, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С109',
    uuid: 'area-C109',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath:
      'M972.272 327L949.291 393.069C941.606 415.164 920.785 429.979 897.394 430H857V327H895V325H925V327H972.272Z',
    workplaces: [
      { uuid: 'wp-109-1', name: 'wp-109-1', position: { x: 860, y: 350, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-109-2', name: 'wp-109-2', position: { x: 860, y: 390, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-109-3', name: 'wp-109-3', position: { x: 910, y: 360, angle: -70 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С110',
    uuid: 'area-C110',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M840 130V97H860V110H890V97H925V263H870V265H840V263H835V130H840Z',
    workplaces: [
      { uuid: 'wp-110-1', name: 'wp-110-1', position: { x: 835, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-2', name: 'wp-110-2', position: { x: 835, y: 180, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-3', name: 'wp-110-3', position: { x: 885, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-4', name: 'wp-110-4', position: { x: 885, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-5', name: 'wp-110-5', position: { x: 885, y: 200, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С111',
    uuid: 'area-C111',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M935 260V97H970V110H1000V97H1020V260H970V265H940V260H935Z',
    workplaces: [
      { uuid: 'wp-110-1', name: 'wp-110-1', position: { x: 935, y: 110, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-2', name: 'wp-110-2', position: { x: 935, y: 150, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-3', name: 'wp-110-3', position: { x: 985, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-4', name: 'wp-110-4', position: { x: 985, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-110-5', name: 'wp-110-5', position: { x: 985, y: 200, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С112',
    uuid: 'area-C112',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1290 410V110H1340V97H1370V110H1410V410H1370V465H1340V410H1290Z',
    workplaces: [
      { uuid: 'wp-112-1', name: 'wp-112-1', position: { x: 1290, y: 340, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-2', name: 'wp-112-2', position: { x: 1290, y: 300, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-3', name: 'wp-112-3', position: { x: 1290, y: 260, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-4', name: 'wp-112-4', position: { x: 1290, y: 220, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-5', name: 'wp-112-5', position: { x: 1290, y: 180, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-6', name: 'wp-112-6', position: { x: 1290, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-7', name: 'wp-112-7', position: { x: 1370, y: 340, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-8', name: 'wp-112-8', position: { x: 1370, y: 300, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-9', name: 'wp-112-9', position: { x: 1370, y: 260, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-10', name: 'wp-112-10', position: { x: 1370, y: 220, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-11', name: 'wp-112-11', position: { x: 1370, y: 180, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-112-12', name: 'wp-112-12', position: { x: 1370, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С113',
    uuid: 'area-C113',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M740 510H720V583H773V570H775V540H773V507H740V510Z',
    workplaces: [
      { uuid: 'wp-113-1', name: 'wp-113-1', position: { x: 720, y: 510, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С114',
    uuid: 'area-C114',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M720 683V587H773V610H775V640H773V683H720Z',
    workplaces: [
      { uuid: 'wp-114-1', name: 'wp-114-1', position: { x: 720, y: 590, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-114-2', name: 'wp-114-2', position: { x: 720, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С115',
    uuid: 'area-C115',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M640 760V690L720 689.896V687H773V720H775V750H773V763H750V760H640Z',
    workplaces: [
      { uuid: 'wp-115-1', name: 'wp-115-1', position: { x: 640, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-115-2', name: 'wp-115-2', position: { x: 680, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-115-3', name: 'wp-115-3', position: { x: 720, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С116',
    uuid: 'area-C116',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1427 910V777H1430V775H1460V777H1520V943H1440V910H1427Z',
    workplaces: [
      { uuid: 'wp-116-1', name: 'wp-116-1', position: { x: 1430, y: 870, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-116-2', name: 'wp-116-2', position: { x: 1430, y: 830, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-116-3', name: 'wp-116-3', position: { x: 1480, y: 820, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-116-4', name: 'wp-116-4', position: { x: 1480, y: 860, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-116-5', name: 'wp-116-5', position: { x: 1480, y: 900, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С117',
    uuid: 'area-C117',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1327 963V777H1390V775H1420V777H1423V910H1420V963H1327Z',
    workplaces: [
      { uuid: 'wp-117-1', name: 'wp-117-1', position: { x: 1330, y: 920, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-2', name: 'wp-117-2', position: { x: 1330, y: 880, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-3', name: 'wp-117-3', position: { x: 1330, y: 840, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-4', name: 'wp-117-4', position: { x: 1330, y: 800, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-5', name: 'wp-117-5', position: { x: 1380, y: 840, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-6', name: 'wp-117-6', position: { x: 1380, y: 880, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-117-7', name: 'wp-117-7', position: { x: 1380, y: 920, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С118',
    uuid: 'area-C118',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1323 730V710H1280V707H1217V800H1220V940H1240V963H1323V760H1325V730H1323Z',
    workplaces: [
      { uuid: 'wp-118-1', name: 'wp-118-1', position: { x: 1220, y: 900, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-2', name: 'wp-118-2', position: { x: 1220, y: 860, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-3', name: 'wp-118-3', position: { x: 1220, y: 820, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-4', name: 'wp-118-4', position: { x: 1220, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-5', name: 'wp-118-5', position: { x: 1220, y: 740, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-6', name: 'wp-118-6', position: { x: 1280, y: 800, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-7', name: 'wp-118-7', position: { x: 1280, y: 840, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-8', name: 'wp-118-8', position: { x: 1280, y: 880, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-118-9', name: 'wp-118-9', position: { x: 1280, y: 920, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С119',
    uuid: 'area-C119',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M937 1003V837H940V835H970V837H980V840H1040V940H1030V960H1020V1003H937Z',
    workplaces: [
      { uuid: 'wp-119-1', name: 'wp-119-1', position: { x: 940, y: 960, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-119-2', name: 'wp-119-2', position: { x: 940, y: 920, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-119-3', name: 'wp-119-3', position: { x: 940, y: 880, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-119-4', name: 'wp-119-4', position: { x: 1000, y: 840, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-119-5', name: 'wp-119-5', position: { x: 1000, y: 880, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С120',
    uuid: 'area-C120',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M837 990V837H840V835H870V837H933V1003H840V990H837Z',
    workplaces: [
      { uuid: 'wp-120-1', name: 'wp-120-1', position: { x: 840, y: 940, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-120-2', name: 'wp-120-2', position: { x: 840, y: 900, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-120-4', name: 'wp-120-4', position: { x: 890, y: 880, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-120-3', name: 'wp-120-3', position: { x: 890, y: 920, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-120-5', name: 'wp-120-5', position: { x: 890, y: 960, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С121',
    uuid: 'area-C121',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M740 840V837H790V835H820V837H833V990H820V1023H737V840H740Z',
    workplaces: [
      { uuid: 'wp-121-1', name: 'wp-121-1', position: { x: 740, y: 970, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-121-2', name: 'wp-121-2', position: { x: 740, y: 930, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-121-4', name: 'wp-121-4', position: { x: 740, y: 890, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-121-3', name: 'wp-121-3', position: { x: 740, y: 850, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-121-5', name: 'wp-121-5', position: { x: 790, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-121-6', name: 'wp-121-6', position: { x: 790, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С122',
    uuid: 'area-C122',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M637 840H640V835H670V840H733V1023H640V1010H637V840Z',
    workplaces: [
      { uuid: 'wp-122-1', name: 'wp-122-1', position: { x: 640, y: 970, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-122-2', name: 'wp-122-2', position: { x: 640, y: 930, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-122-4', name: 'wp-122-4', position: { x: 640, y: 890, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-122-3', name: 'wp-122-3', position: { x: 690, y: 850, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-122-5', name: 'wp-122-5', position: { x: 690, y: 890, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-122-6', name: 'wp-122-6', position: { x: 690, y: 930, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С123',
    uuid: 'area-C123',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M537 1043V840H590V835H620V840H633V1010H620V1043H537Z',
    workplaces: [
      { uuid: 'wp-123-1', name: 'wp-123-1', position: { x: 540, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-2', name: 'wp-123-2', position: { x: 540, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-4', name: 'wp-123-4', position: { x: 540, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-3', name: 'wp-123-3', position: { x: 540, y: 870, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-5', name: 'wp-123-5', position: { x: 590, y: 890, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-6', name: 'wp-123-6', position: { x: 590, y: 930, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-123-7', name: 'wp-123-7', position: { x: 590, y: 970, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С124',
    uuid: 'area-C124',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M440 1043V840H490V835H520V840H533V1043H440Z',
    workplaces: [
      { uuid: 'wp-124-1', name: 'wp-124-1', position: { x: 440, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-2', name: 'wp-124-2', position: { x: 440, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-4', name: 'wp-124-4', position: { x: 440, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-3', name: 'wp-124-3', position: { x: 440, y: 870, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-5', name: 'wp-124-5', position: { x: 490, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-6', name: 'wp-124-6', position: { x: 490, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-124-7', name: 'wp-124-7', position: { x: 490, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С125',
    uuid: 'area-C125',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M217 850H290V840H320V850H420V1043H390V1030H217V850Z',
    workplaces: [
      { uuid: 'wp-125-1', name: 'wp-125-1', position: { x: 220, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-2', name: 'wp-125-2', position: { x: 220, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-3', name: 'wp-125-3', position: { x: 220, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-4', name: 'wp-125-4', position: { x: 220, y: 870, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-5', name: 'wp-125-5', position: { x: 280, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-6', name: 'wp-125-6', position: { x: 280, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-7', name: 'wp-125-7', position: { x: 280, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-8', name: 'wp-125-8', position: { x: 320, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-9', name: 'wp-125-9', position: { x: 320, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-10', name: 'wp-125-10', position: { x: 320, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-11', name: 'wp-125-11', position: { x: 380, y: 870, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-12', name: 'wp-125-12', position: { x: 380, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-13', name: 'wp-125-13', position: { x: 380, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-125-14', name: 'wp-125-14', position: { x: 380, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С126',
    uuid: 'area-C126',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M147 830V737H283V770H285V800H283V830H147Z',
    workplaces: [
      { uuid: 'wp-126-1', name: 'wp-126-1', position: { x: 150, y: 790, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-126-2', name: 'wp-126-2', position: { x: 190, y: 790, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-126-3', name: 'wp-126-3', position: { x: 190, y: 740, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-126-4', name: 'wp-126-4', position: { x: 230, y: 740, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С127',
    uuid: 'area-C127',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M350 670V640H420V763H347V710H345V680H347V670H350Z',
    workplaces: [
      { uuid: 'wp-127-1', name: 'wp-127-1', position: { x: 360, y: 720, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-127-2', name: 'wp-127-2', position: { x: 380, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-127-3', name: 'wp-127-3', position: { x: 360, y: 640, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С128',
    uuid: 'area-C128',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M170 650V640H283V670H285V700H283V733H147V650H170Z',
    workplaces: [
      { uuid: 'wp-128-1', name: 'wp-128-1', position: { x: 190, y: 690, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-128-2', name: 'wp-128-2', position: { x: 230, y: 690, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-128-3', name: 'wp-128-3', position: { x: 170, y: 640, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-128-4', name: 'wp-128-4', position: { x: 210, y: 640, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С129',
    uuid: 'area-C129',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M90 440V430H283V560H285V590H283V620H77V440H90Z',
    workplaces: [
      { uuid: 'wp-129-1', name: 'wp-129-1', position: { x: 80, y: 580, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-2', name: 'wp-129-2', position: { x: 120, y: 580, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-3', name: 'wp-129-3', position: { x: 160, y: 580, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-4', name: 'wp-129-4', position: { x: 200, y: 580, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-5', name: 'wp-129-5', position: { x: 80, y: 520, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-6', name: 'wp-129-6', position: { x: 120, y: 520, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-7', name: 'wp-129-7', position: { x: 160, y: 520, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-8', name: 'wp-129-8', position: { x: 80, y: 480, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-9', name: 'wp-129-9', position: { x: 120, y: 480, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-10', name: 'wp-129-10', position: { x: 160, y: 480, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-11', name: 'wp-129-11', position: { x: 90, y: 430, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-12', name: 'wp-129-12', position: { x: 130, y: 430, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-13', name: 'wp-129-13', position: { x: 170, y: 430, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-129-14', name: 'wp-129-14', position: { x: 210, y: 430, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'С130',
    uuid: 'area-C130',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M20 57H70V70H130V360H133V380H135V410H133V420H7V360H20V57Z',
    workplaces: [
      { uuid: 'wp-1', name: 'wp-1', position: { x: 20, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-2', name: 'wp-1', position: { x: 20, y: 130, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-3', name: 'wp-1', position: { x: 20, y: 180, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-4', name: 'wp-1', position: { x: 20, y: 230, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-5', name: 'wp-1', position: { x: 20, y: 280, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-6', name: 'wp-1', position: { x: 90, y: 70, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-7', name: 'wp-1', position: { x: 90, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-8', name: 'wp-1', position: { x: 90, y: 170, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-9', name: 'wp-1', position: { x: 90, y: 220, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-10', name: 'wp-1', position: { x: 90, y: 270, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-11', name: 'wp-1', position: { x: 90, y: 320, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'KOM-OP-1',
    uuid: 'area-kom-op-sp-0',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath:
      'M1970 230H1850V500H1810V470H1630V500L1670 540H1740V690L1845 795L1890 750L1891.5 751.5L1921.5 721.5L1920 720L2015 625L2000 610L2020 590V230H2010V220H1970V230Z',
    workplaces: [
      { name: 'К101', uuid: 'op-1-1', type: WorkplaceType.DEFAULT, position: { x: 1640, y: 470, angle: 180 } },
      { name: 'К102', uuid: 'op-1-2', type: WorkplaceType.DEFAULT, position: { x: 1680, y: 470, angle: 180 } },
      { name: 'К103', uuid: 'op-1-3', type: WorkplaceType.DEFAULT, position: { x: 1720, y: 470, angle: 180 } },
      { name: 'К104', uuid: 'op-1-4', type: WorkplaceType.DEFAULT, position: { x: 1760, y: 470, angle: 180 } },
      //
      {
        name: 'К105',
        uuid: 'op-2-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1930, y: 440, angle: -90 }
      },
      {
        name: 'К106',
        uuid: 'op-2-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1930, y: 400, angle: -90 }
      },
      {
        name: 'К107',
        uuid: 'op-2-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1930, y: 360, angle: -90 }
      },
      {
        name: 'К108',
        uuid: 'op-2-4',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1930, y: 320, angle: -90 }
      },
      {
        name: 'К109',
        uuid: 'op-2-5',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1930, y: 280, angle: -90 }
      },
      { name: 'К110', uuid: 'op-2-6', type: WorkplaceType.DEFAULT, position: { x: 1970, y: 440, angle: 90 } },
      { name: 'К111', uuid: 'op-2-7', type: WorkplaceType.DEFAULT, position: { x: 1970, y: 400, angle: 90 } },
      { name: 'К112', uuid: 'op-2-8', type: WorkplaceType.DEFAULT, position: { x: 1970, y: 360, angle: 90 } },
      { name: 'К113', uuid: 'op-2-9', type: WorkplaceType.DEFAULT, position: { x: 1970, y: 320, angle: 90 } },
      { name: 'К114', uuid: 'op-2-10', type: WorkplaceType.DEFAULT, position: { x: 1970, y: 280, angle: 90 } },
      //
      { name: 'К115', uuid: 'op-3-1', type: WorkplaceType.DEFAULT, position: { x: 1920, y: 550, angle: 90 } },
      { name: 'К116', uuid: 'op-3-2', type: WorkplaceType.DEFAULT, position: { x: 1920, y: 590, angle: 90 } },
      { name: 'К117', uuid: 'op-3-3', type: WorkplaceType.DEFAULT, position: { x: 1920, y: 630, angle: 90 } },
      {
        name: 'К118',
        uuid: 'op-3-4',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1880, y: 550, angle: -90 }
      },
      {
        name: 'К119',
        uuid: 'op-3-5',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1880, y: 590, angle: -90 }
      },
      {
        name: 'К120',
        uuid: 'op-3-6',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1880, y: 630, angle: -90 }
      },
      { name: 'К121', uuid: 'op-3-7', type: WorkplaceType.DEFAULT, position: { x: 1830, y: 550, angle: 90 } },
      { name: 'К122', uuid: 'op-3-8', type: WorkplaceType.DEFAULT, position: { x: 1830, y: 590, angle: 90 } },
      { name: 'К123', uuid: 'op-3-9', type: WorkplaceType.DEFAULT, position: { x: 1830, y: 630, angle: 90 } },
      {
        name: 'К124',
        uuid: 'op-3-10',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1790, y: 550, angle: -90 }
      },
      {
        name: 'К125',
        uuid: 'op-3-11',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1790, y: 590, angle: -90 }
      },
      {
        name: 'К126',
        uuid: 'op-3-12',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1790, y: 630, angle: -90 }
      },
      { name: 'К127', uuid: 'op-3-13', type: WorkplaceType.DEFAULT, position: { x: 1740, y: 550, angle: 90 } },
      { name: 'К128', uuid: 'op-3-14', type: WorkplaceType.DEFAULT, position: { x: 1740, y: 590, angle: 90 } },
      { name: 'К129', uuid: 'op-3-15', type: WorkplaceType.DEFAULT, position: { x: 1740, y: 630, angle: 90 } },
      //
      {
        name: 'К130',
        uuid: 'op-4-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1850, y: 400, angle: 90 }
      },
      { name: 'К131', uuid: 'op-4-1', type: WorkplaceType.DEFAULT, position: { x: 1850, y: 360, angle: 90 } },
      { name: 'К132', uuid: 'op-4-1', type: WorkplaceType.DEFAULT, position: { x: 1850, y: 320, angle: 90 } },
      { name: 'К133', uuid: 'op-4-1', type: WorkplaceType.DEFAULT, position: { x: 1850, y: 280, angle: 90 } }
    ]
  }
];

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector } from '../../store/selectors/DefaultSelectors';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import { useResize } from '../../shared/utils/hooks/useResize';
import StepBack from '../../shared/ui/components/StepBack';
import { StepButton } from '../../shared/ui/components/StepButton';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SupportContactsAndManagers from './SupportContactsAndManagers';
import SupportInformationLinks from './SupportInformationLinks';
import { ticketsSelector } from '../../store/selectors/TicketsSelectors';
import SupportHistoryList from './SupportHistoryList';
import * as actions from '../../store/actions';
import { getTickets } from '../../services/workki/TicketsActions';
import { PrefillForm } from '../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { ButtonWrapper, MainWrapper } from '../../entities/Payments/model/Payments';
import { UserIdBox } from '../../entities/Dashboard/DashboardUserInfoSections';
import { SupportRequestDialog } from '../../widgets/Services/Support/SupportRequestDialog';

const TitleBox = styled.div`
  margin: -6px 0 8px 0;
  padding: 0;
  display: grid;
  width: 100%;
  grid-template-areas:
    'stepBack description'
    'button button';
  justify-content: space-between;
  gap: 12px;

  @media screen and (min-width: ${screenRessolutions.tablet}px) {
    margin: 2px 0 8px 0;
  }

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    margin: 10px 0 24px 0;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin: 16px 0 40px 0;
    display: flex;
  }
`;

const TitleDescription = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-end;
  width: 100%;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #575b60;
  grid-area: description;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    display: none;
  }
`;

const TopButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-area: button;
  justify-items: flex-end;
  flex-direction: row-reverse;
`;

const Support = ({ offices }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState({ support: false, success: false });
  const history = useHistory();
  const defaultScreenState = {
    laptop: window.innerWidth >= screenRessolutions.laptop,
    desktop: window.innerWidth >= screenRessolutions.desktop
  };
  const [screenWidthState, setScreenWidthState] = useState({ ...defaultScreenState });
  const setScreenState = () => {
    const isLaptopScreen = window.innerWidth >= screenRessolutions.laptop;
    const isDesktopScreen = window.innerWidth >= screenRessolutions.desktop;

    if (isLaptopScreen !== screenWidthState.laptop || isDesktopScreen !== screenWidthState.desktop) {
      setScreenWidthState({
        laptop: isLaptopScreen,
        desktop: isDesktopScreen
      });
    }
  };

  useResize(setScreenState, 0);

  const customer = useSelector(customerSelector);

  const tickets = useSelector(ticketsSelector);

  const loadHistory = useCallback(() => {
    dispatch(actions.resetTickets());
    customer && dispatch(getTickets());
  }, [dispatch, customer]);

  useEffect(() => loadHistory(), [loadHistory]);

  return (
    <MainWrapper>
      <PrefillForm>
        <TitleBox>
          <StepBack
            title={'Поддержка'}
            style={{ gridArea: 'stepBack', maxWidth: screenWidthState.desktop ? '239px' : '' }}
            onClick={() => history.goBack()}
            titleStyle={{ padding: 0 }}
          />
          {!screenWidthState.desktop && <TitleDescription>История обращений</TitleDescription>}
          {screenWidthState.laptop && (
            <TopButtonWrapper>
              <StepButton
                handleAction={() => (window.location.href = '/tickets')}
                type='filled'
                title='Обратиться в поддержку'
                filledType='red'
                spacing='0'
              />
            </TopButtonWrapper>
          )}
        </TitleBox>
        <CardShell padding={'14px 16px'} type='support'>
          <UserIdBox>
            <p>ID аккаунта:</p>
            <p>{customer.id}</p>
          </UserIdBox>
        </CardShell>
        <SupportContactsAndManagers screenWidthState={screenWidthState} offices={offices} />
        <SupportInformationLinks screenWidthState={screenWidthState} />
        {tickets.length > 0 && <SupportHistoryList tickets={tickets} />}
      </PrefillForm>
      {openDialog.support && <SupportRequestDialog dialogType='support' title='Обращение в службу поддержки' />}
    </MainWrapper>
  );
};

export default Support;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const HoursPickerTableHead = ({ timeMarker, date, type }) => {
  const isValidHour = timeMarker.dateTime.hour() >= 9 && timeMarker.dateTime.hour() <= 21;
  const isValidDate = timeMarker.dateTime.date() === date;
  const thClassName = cn({
    'hours-picker__head-cell': type !== 'mobile' && true,
    'hours-picker__head-cell-mobile': type === 'mobile' && true,
    'hours-picker__head-cell--current': timeMarker.isSelected,
    'hours-picker__head-cell--current-extreme': timeMarker.isBold
  });

  return (
    <th className={thClassName}>{isValidHour && isValidDate && <span>{timeMarker.dateTime.format('HH')}:00</span>}</th>
  );
};

HoursPickerTableHead.propTypes = {
  timeMarker: PropTypes.object.isRequired
};

export default HoursPickerTableHead;

import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';

const ChangePasswordStep = ({
  entranceFormClass,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  handleClickShowPassword,
  errors,
  formData,
  filled,
  isFocused,
  showPassword,
  isPending,
  disableButton,
  handleResetPassword
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Изменение пароля</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={() => handleResetPassword()}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.password}
          id='outlined-helperText'
          label='Пароль'
          name='password'
          helperText={errors.password ? errors.password : ''}
          variant='filled'
          type={showPassword.password ? 'text' : 'password'}
          value={formData.password}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('password')}
          onBlur={() => handleBlur('password')}
          endAdornmentProps={{
            filled: filled.password,
            isFocused: isFocused.password,
            handleEraseValue: handleEraseValue,
            showPassword: showPassword.password,
            handleClickShowPassword: () => handleClickShowPassword('password')
          }}
          autoFocus={false}
        />
        <CustomTextField
          error={errors.matchPassword}
          id='outlined-helperText'
          label='Повторите пароль'
          name='matchPassword'
          helperText={errors.matchPassword ? errors.matchPassword : ''}
          variant='filled'
          type={showPassword.matchPassword ? 'text' : 'password'}
          value={formData.matchPassword}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('matchPassword')}
          onBlur={() => handleBlur('matchPassword')}
          endAdornmentProps={{
            filled: filled.matchPassword,
            isFocused: isFocused.matchPassword,
            handleEraseValue: handleEraseValue,
            showPassword: showPassword.matchPassword,
            handleClickShowPassword: () => handleClickShowPassword('matchPassword')
          }}
          autoFocus={false}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Изменить
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordStep;

import React from 'react';
import Loader from 'react-loader-spinner';

const ContentLoader = () => (
  <div className='content-loader'>
    <Loader type='ThreeDots' color='#f15a4f' height='25' width='40' />
  </div>
);

export default ContentLoader;

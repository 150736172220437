import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' xmlns='http://www.w3.org/2000/svg' filter='url(#filter0_d_3709_153346)'>
      <path
        fill='white'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1610 0H1630V450H1830V210H2040V610L2020 630L2080 690L1910 860V1000H1770L1725 955L1690 990L1615 915L1580 950H1440V1020H1420V970H1240V1040H1220V990H1040V1060H1020V1010H840V1090H820V1030H640V1110H620V1050H440V1110H420V1050H190V1030H210V850H120V830H140V650H50V630H70V440H0V50H440V90H1610V0Z'
      />
    </g>
  );
};

export const Komsomolskaya_Floor = memo(SVGComponents);

import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

export const CommonServiceSuccessDialog = props => {
  return (
    <Dialog
      title='Заявка отправлена'
      handleClose={props.handleClose}
      buttonLabel='Cпасибо'
      dialogType={props.dialogType}
    />
  );
};

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import cn from 'classnames';
import { isEmpty, keys, isNil, flatMap, isArray, intersection, difference, union, includes } from 'lodash';
import Select from '../Select';
import Loader from 'react-loader-spinner';
import AutosizeTextarea from 'react-autosize-textarea';
import getFormikValidate from '../../utils/getFormikValidate';
import { TICKET_CATEGORY_STATUS } from '../../constants';
import {
  FORM_ID,
  FIELD_NAMES,
  TEXTAREA_MAX_LENGTH,
  VALIDATION_SCHEMA,
  SUPER_CATEGORIES,
  LABELS,
  DEFAULT_SUPER_CATEGORY_ID,
  FILE_CONSTRAINS
} from './constants';

const prepareSuperCategories = remoteCategories => {
  if (!isArray(remoteCategories)) {
    return [];
  }

  const activeRemoteCategoriesIds = remoteCategories
    .filter(c => c.status_id === TICKET_CATEGORY_STATUS.ACTIVE)
    .map(c => c.id);

  const localCategoriesIds = flatMap(SUPER_CATEGORIES, sc => sc.categories);

  const activeRemoteCategoriesIdsWithoutSuperCategory = difference(activeRemoteCategoriesIds, localCategoriesIds);
  const defaultSuperCategory = SUPER_CATEGORIES.find(sc => sc.id === DEFAULT_SUPER_CATEGORY_ID);
  const defaultLocalCategoryIds = defaultSuperCategory ? defaultSuperCategory.categories : [];
  const activeDefaultLocalCategoryIds = intersection(defaultLocalCategoryIds, activeRemoteCategoriesIds);

  const defaultCategories = union(activeRemoteCategoriesIdsWithoutSuperCategory, activeDefaultLocalCategoryIds);

  const superCategoriesWithActiveItems = SUPER_CATEGORIES.map(sc => ({
    ...sc,
    categories:
      sc.id === DEFAULT_SUPER_CATEGORY_ID ? defaultCategories : intersection(sc.categories, activeRemoteCategoriesIds)
  }));

  return superCategoriesWithActiveItems.filter(sc => !isEmpty(sc.categories));
};

const getCategoriesForSuperCategory = (superCategoryId, superCategories, categories) => {
  const superCategory = superCategories.find(sc => sc.id === superCategoryId);

  if (!superCategory) {
    return [];
  }

  return superCategory.categories.reduce((acc, id) => {
    const category = categories.find(c => c.id === id);
    if (!category) {
      return acc;
    }
    return [...acc, { value: id, label: category.name }];
  }, []);
};

const formData = values => {
  const properties = {
    [FIELD_NAMES.CATEGORY]: values[FIELD_NAMES.CATEGORY].value,
    [FIELD_NAMES.WORKPLACE]: values[FIELD_NAMES.WORKPLACE].value,
    [FIELD_NAMES.DESCRIPTION]: values[FIELD_NAMES.DESCRIPTION],
    [FIELD_NAMES.FILE]: values[FIELD_NAMES.FILE]
  };

  return properties;
};

const TicketForm = ({
  ticketCreate,
  ticketCreateStatus,
  ticketCreateError,
  workplaces,
  ticketCategories,
  resetError,
  isModalWindowOpen,
  defaultServiceId,
  defaultTicketType
}) => {
  const formik = useFormik({
    initialValues: {
      [FIELD_NAMES.WORKPLACE]: {},
      [FIELD_NAMES.SUPER_CATEGORY]: {},
      [FIELD_NAMES.CATEGORY]: {},
      [FIELD_NAMES.DESCRIPTION]: '',
      [FIELD_NAMES.FILE]: null
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: getFormikValidate(VALIDATION_SCHEMA),
    onSubmit: values => {
      const data = formData(values);
      ticketCreate(data);
    }
  });

  // ERRORS AND VALIDATIONS
  const [formError, setFormError] = useState(false);

  const validationError = useMemo(() => {
    if (isEmpty(formik.errors)) {
      return null;
    }

    const messageKey = keys(formik.errors).find(key => !!formik.touched[key]);

    if (isNil(messageKey)) {
      return null;
    }

    return formik.errors[messageKey];
  }, [formik.errors, formik.touched]);

  useEffect(() => {
    if (!!validationError) {
      return setFormError(validationError);
    }

    if (!!ticketCreateError && ticketCreateStatus.isFailure) {
      return setFormError(ticketCreateError);
    }

    setFormError(false);
  }, [validationError, ticketCreateError, ticketCreateStatus]);

  const isLoading = useMemo(() => ticketCreateStatus.isPending, [ticketCreateStatus]);

  useEffect(() => {
    if (!isModalWindowOpen) {
      formik.resetForm();
    }
  }, [formik, isModalWindowOpen]);

  useEffect(() => {
    if (!!ticketCreateError) {
      resetError();
    }
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  // WORKPLACES
  const workplacesForSelect = useMemo(() => {
    return workplaces.map(wp => ({ value: wp.id, label: `${wp.name} (${wp.officeName})` }));
  }, [workplaces]);

  useEffect(() => {
    let selectedWorkplace = null;
    if (workplacesForSelect.length === 1) {
      selectedWorkplace = workplacesForSelect[0];
    }
    if (defaultServiceId && defaultTicketType) {
      selectedWorkplace = workplacesForSelect.find(el => el.value === +defaultServiceId) || selectedWorkplace;
      if (defaultTicketType === 'cancel_booking') {
        const descriptionBody = 'Отмена бронирования переговорной.';
        const workplaceLabel = selectedWorkplace ? selectedWorkplace.label + '.' : '';
        const description = `${descriptionBody} ${workplaceLabel}`;
        formik.setFieldValue(FIELD_NAMES.DESCRIPTION, description, false);
        formik.setFieldValue(
          FIELD_NAMES.SUPER_CATEGORY,
          superCategoriesForSelect.find(sc => sc.value === 6) || null,
          false
        );
      }
    }
    if (selectedWorkplace) {
      formik.setFieldValue(FIELD_NAMES.WORKPLACE, selectedWorkplace, false);
    }
  }, [formik.setFieldValue, workplacesForSelect, defaultServiceId, defaultTicketType]);

  // SUPER CATEGORIES
  const superCategories = useMemo(() => prepareSuperCategories(ticketCategories), [ticketCategories]);
  const superCategoriesForSelect = useMemo(() => superCategories.map(sc => ({ value: sc.id, label: sc.name })), [
    superCategories
  ]);
  const selectedSuperCategoryId = useMemo(
    () => formik.values[FIELD_NAMES.SUPER_CATEGORY] && formik.values[FIELD_NAMES.SUPER_CATEGORY].value,
    [formik.values]
  );

  // CATEGORIES
  const categoriesForSelect = useMemo(() => {
    if (!selectedSuperCategoryId) {
      return [];
    }

    return getCategoriesForSuperCategory(selectedSuperCategoryId, superCategories, ticketCategories);
  }, [selectedSuperCategoryId, superCategories, ticketCategories]);

  useEffect(() => {
    const selectedSuperCategory = superCategories[selectedSuperCategoryId];
    if (!selectedSuperCategory || !selectedSuperCategory.categories) {
      return;
    }

    if (!includes(selectedSuperCategory.categories, formik.values[FIELD_NAMES.CATEGORY].value)) {
      formik.setFieldValue(FIELD_NAMES.CATEGORY, {}, false);
    }
  }, [formik.setFieldValue, categoriesForSelect]);

  useEffect(() => {
    if (categoriesForSelect.length === 1) {
      formik.setFieldValue(FIELD_NAMES.CATEGORY, categoriesForSelect[0], false);
    }
  }, [formik.setFieldValue, categoriesForSelect]);

  // FILE
  const fileRef = useRef();

  const uploadFile = useCallback(
    evt => {
      evt.preventDefault();

      if (!fileRef.current || isLoading) {
        return;
      }

      fileRef.current.click();
    },
    [isLoading]
  );

  const onChangeFile = useCallback(
    evt => {
      evt.preventDefault();
      const file = fileRef.current.files[0] || null;

      if (isLoading) {
        return;
      }

      formik.setFieldError(FIELD_NAMES.FILE);
      formik.setFieldValue(FIELD_NAMES.FILE, file, false);
    },
    [formik, isLoading]
  );

  const renderButton = () => {
    const btnClass = cn({
      'btn': true,
      'fill-payments-modal__refill-btn': true,
      'btn--solid': true,
      'fill-payments-modal__refill-btn--with-preloader': isLoading
    });
    return (
      <button className={btnClass} type='submit' disabled={isLoading}>
        {isLoading ? <Loader type='ThreeDots' color='white' height='25' width='40' /> : <span>Отправить</span>}
      </button>
    );
  };

  return (
    <form
      className={cn('fill-payments-modal', { 'has-error': !!formError })}
      id={FORM_ID}
      onSubmit={formik.handleSubmit}
      autoComplete='off'
      noValidate
    >
      <div className='fill-payments-modal__body'>
        <div
          className={cn('fill-payments-modal__row includes-select', 'big-select', {
            'includes-select--has-error': formik.errors[FIELD_NAMES.WORKPLACE] && formik.touched[FIELD_NAMES.WORKPLACE]
          })}
        >
          <Select
            value={workplacesForSelect.find(w => w.value === formik.values[FIELD_NAMES.WORKPLACE].value) || null}
            options={workplacesForSelect}
            placeholder={LABELS[FIELD_NAMES.WORKPLACE]}
            onChange={option => {
              formik.setFieldValue(FIELD_NAMES.WORKPLACE, option, true);
            }}
            isDisabled={isLoading}
          />
        </div>
        <div
          className={cn('fill-payments-modal__row includes-select', {
            'includes-select--has-error':
              formik.errors[FIELD_NAMES.SUPER_CATEGORY] && formik.touched[FIELD_NAMES.SUPER_CATEGORY]
          })}
        >
          <Select
            value={
              superCategoriesForSelect.find(sc => sc.value === formik.values[FIELD_NAMES.SUPER_CATEGORY].value) || null
            }
            options={superCategoriesForSelect}
            placeholder={LABELS[FIELD_NAMES.SUPER_CATEGORY]}
            onChange={option => {
              formik.setFieldValue(FIELD_NAMES.SUPER_CATEGORY, option, true);
            }}
            isDisabled={isLoading}
          />
        </div>
        <div
          className={cn('fill-payments-modal__row includes-select', {
            'includes-select--has-error': formik.errors[FIELD_NAMES.CATEGORY] && formik.touched[FIELD_NAMES.CATEGORY]
          })}
        >
          <Select
            value={categoriesForSelect.find(sc => sc.value === formik.values[FIELD_NAMES.CATEGORY].value) || null}
            options={categoriesForSelect}
            placeholder={LABELS[FIELD_NAMES.CATEGORY]}
            onChange={option => {
              formik.setFieldValue(FIELD_NAMES.CATEGORY, option, true);
            }}
            isDisabled={isLoading}
          />
        </div>
        <div className='fill-payments-modal__row'>
          <AutosizeTextarea
            rows={3}
            maxRows={6}
            className={cn('textarea textarea--with-autosize', {
              'has-error': formik.errors[FIELD_NAMES.DESCRIPTION] && formik.touched[FIELD_NAMES.DESCRIPTION]
            })}
            type='text'
            name={FIELD_NAMES.DESCRIPTION}
            placeholder={LABELS[FIELD_NAMES.DESCRIPTION]}
            maxLength={TEXTAREA_MAX_LENGTH}
            value={formik.values[FIELD_NAMES.DESCRIPTION]}
            onChange={formik.handleChange}
            disabled={isLoading}
            onBlur={e => {
              e.preventDefault();
              formik.setFieldError(FIELD_NAMES.DESCRIPTION);
            }}
          />
        </div>
        <div className='fill-payments-modal__row'>
          <div
            className={cn('fill-payments-modal__file-input input', {
              'has-error': formik.errors[FIELD_NAMES.FILE] && formik.touched[FIELD_NAMES.FILE]
            })}
          >
            <input
              ref={fileRef}
              id='fileInput'
              type='file'
              onChange={onChangeFile}
              accept={FILE_CONSTRAINS.MIME_TYPES.join(', ')}
              disabled={isLoading}
            />
            <span
              className={cn('fill-payments-modal__file-input-text', {
                'fill-payments-modal__file-input-text--empty': !formik.values[FIELD_NAMES.FILE]
              })}
              onClick={uploadFile}
            >
              {formik.values[FIELD_NAMES.FILE] && formik.values[FIELD_NAMES.FILE].name
                ? formik.values[FIELD_NAMES.FILE].name
                : LABELS[FIELD_NAMES.FILE]}
            </span>
            <button
              className='fill-payments-modal__file-input-button btn btn--solid btn--sm'
              type='button'
              onClick={uploadFile}
              disabled={isLoading}
            >
              Загрузить
            </button>
          </div>
        </div>
        <div className='fill-payments-modal__row'>{renderButton()}</div>
      </div>
      {formError && (
        <div className='fill-payments-modal__notification'>
          <div className='notification notification--error notification--fill-payment-modal'>{formError}</div>
        </div>
      )}
    </form>
  );
};

TicketForm.propTypes = {
  workplaces: PropTypes.array.isRequired,
  ticketCategories: PropTypes.array.isRequired,
  ticketCreate: PropTypes.func.isRequired,
  ticketCreateStatus: PropTypes.object.isRequired,
  ticketCreateError: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
  isModalWindowOpen: PropTypes.bool.isRequired
};

export default TicketForm;

import { createSelector } from 'redux-orm';
import orm from '../models/orm';
import { isNil } from 'lodash';

const dbStateSelector = state => state.entities;

export const transactionsSelector = createSelector(
  orm,
  dbStateSelector,
  session =>
    session.Transaction.all()
      .toModelArray()
      .sort((a, b) => Number(b.service_request_id || 0) - Number(a.service_request_id || 0))
      .map(t => {
        const service = !isNil(t.service_id) ? t.service_id.ref : null;
        const office = !isNil(t.service_id) && !isNil(t.service_id.office_id) ? t.service_id.office_id.ref : null;
        return { ...t.ref, service, office };
      })
);

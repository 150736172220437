import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { success, error } from 'react-toastify-redux';

import * as actions from '../../../store/actions';
import PageLoader from '../../PageLoader';
import {
  printerPackages,
  serviceRequestWithWorkplacesSelector,
  servicesSelector
} from '../../../store/selectors/ServicesSelector';
import { dispatchBuyNow } from '../../../services/workki/ServicesActions';
import ErrorWithShowedMessage from '../../../errors/ErrorWithShowedMessage';
import { getServiceSchedule } from '../../../store/selectors/ServicesSelector';
import { getCustomerContracts } from '../../../services/workki/CustomerActions';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import { customerSelector, officeSelector } from '../../../store/selectors/DefaultSelectors';
import CardShell from '../../../shared/ui/components/Profile/CardShell';
import { StepButton } from '../../../shared/ui/components/StepButton';
import styled from 'styled-components';
import { colors, fontFamilyBase, screenRessolutions } from '../../../shared/ui/constants/styleConstants';
import Counter from '../../../shared/ui/components/Counter';

const mapStateToProps = state => ({
  auth: state.auth,
  all: printerPackages(state),
  serviceRequestCreateRequestStatus: requestStatusSelector(actions.requestServiceRequestCreate)(state),
  contract: customerSelector(state).contract,
  offices: officeSelector(state),
  meetingRooms: getServiceSchedule(state)
});

const mapDispatchToProps = dispatch => ({
  test: () => {
    dispatch(success('Success message'));
  },
  getContracts: () => dispatch(getCustomerContracts()),
  buyPackage: (service_id, cnt, price_id, callback, contract) => {
    dispatch(dispatchBuyNow(service_id, null, cnt, price_id, contract))
      .then(() => {
        dispatch(success('Успешная покупка печати', { hideProgressBar: true }));
      })
      .catch(err => {
        if (err instanceof ErrorWithShowedMessage) {
          throw err;
        }

        let message = 'Ошибка покупки печати!';

        if (err !== null && err.response && err.response.data) {
          message = Array.isArray(err.response.data) ? err.response.data[0].message : err.response.data.message;
        }

        dispatch(error(message, { hideProgressBar: true }));
      })
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(() => {});
  }
});
const Title = styled.h2`
  margin: 32px 0 0 0;
  padding: 0;
  width: 100%;
  font-family: ${fontFamilyBase};
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${colors.workkiDarkMain};
  text-align: start;

  @media screen and (min-width: ${screenRessolutions.desktopPlus}px) {
    margin-top: 56px;
  }
`;

const PackagesBox = styled.ul`
  margin: 16px 0 16px 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 8px;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const CardTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-family: ${fontFamilyBase};
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  color: ${colors.workkiDarkMain};
`;
const LocationTitle = styled.p`
  margin: 4px 0 12px 0;
  ${colors.workkiDarkSecondary};
`;
const ButtonWrapper = styled.div`
  margin: 16px 0 4px 0;
  & > button {
    width: 100%;
  }
`;

const CardPackagesCounterBox = styled.div`
  margin: 20px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  width: 100%;
  box-sizing: border-box;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.workkiDarkSecondary};
`;

const PrintPackages = ({
  all,
  buyPackage,
  reloadBalance,
  getContracts,
  contract,
  offices,
  meetingRooms,
  currentLocation
}) => {
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: moment().subtract(14, 'days'),
      selectorType: 'standard'
    })
  );

  const allServices = useSelector(servicesSelector);
  const printPackages = useSelector(printerPackages);
  const [packagesCount, setPackagesCount] = useState(null);
  const definePagesCount = tariffName => {
    const pagesNumber = tariffName.match(/\d+/);
    return pagesNumber[0];
  };

  const printServicesFiltered = useMemo(() => {
    if (activeWorkplaces.length > 0 || meetingRooms.length > 0) {
      let service_ids = [...meetingRooms, ...activeWorkplaces].map(s => s.service_id);
      let office_ids = allServices.filter(s => service_ids.includes(s.id)).map(s => s.office_id);

      return all.filter(s => office_ids.includes(s.office_id));
    }

    return all.filter(s => !s.office_id);
  }, [activeWorkplaces, all, allServices]);
  useEffect(() => {
    const packages = printPackages.reduce((acc, printPackage) => {
      acc.push({ id: printPackage.id, count: 1 });
      return acc;
    }, []);
    setPackagesCount(packages);
  }, [printPackages, currentLocation]);

  useEffect(() => {
    if (!contract) {
      getContracts();
    }
  }, [contract, getContracts]);
  const handleIncrement = id => {
    setPackagesCount(prevPackages =>
      prevPackages.map(item => (item.id === id ? { ...item, count: item.count + 1 } : item))
    );
  };

  const handleDecrement = id => {
    setPackagesCount(prevPackages =>
      prevPackages.map(item => (item.id === id && item.count > 1 ? { ...item, count: item.count - 1 } : item))
    );
  };

  if (!contract) {
    return <PageLoader />;
  }
  const defineCount = item => {
    return packagesCount.find(count => count.id === item.id).count;
  };

  return (
    <>
      <PackagesBox>
        {packagesCount &&
          packagesCount.length > 0 &&
          printServicesFiltered
            .filter(service => service.office_id === currentLocation)
            .map(item => (
              <div>
                <CardShell type='print' style={{ justifyContent: 'flex-start' }}>
                  <CardTitle>{item.name}</CardTitle>
                  <LocationTitle>{offices.find(office => office.id === item.office_id).t_name}</LocationTitle>
                  <CardPackagesCounterBox>
                    Количество <br />
                    пакетов печати
                    <Counter
                      count={defineCount(item)}
                      type='print'
                      span={definePagesCount(item.name) * defineCount(item) + ' страниц'}
                      handleIncrement={() => handleIncrement(item.id)}
                      handleDecrement={() => handleDecrement(item.id)}
                    />
                  </CardPackagesCounterBox>
                  <ButtonWrapper>
                    <StepButton
                      spacing='0'
                      heightOverride='32px'
                      size='thin'
                      fontSizeOverride='14px'
                      type='outlined'
                      title={`Оплатить ${Number(item.servicePrices[0].price) * defineCount(item)} ₽`}
                      handleAction={() =>
                        buyPackage(item.id, defineCount(item), item.servicePrices[0].id, reloadBalance, contract)
                      }
                    />
                  </ButtonWrapper>
                </CardShell>
              </div>
            ))}
      </PackagesBox>
    </>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintPackages);

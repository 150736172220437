import React from 'react';
import { Dialog } from '../../../shared/ui/components/Dialog';

export const ChangeCredentialsDialog = props => {
  return (
    <Dialog
      handleClose={props.handleClose}
      dialogData={props.dialogData}
      handleSubmit={props.handleSumbit}
      dialogType={props.dialogType}
      {...props}
    />
  );
};

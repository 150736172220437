// const { REACT_APP_DOMAIN } = process.env;
const REACT_APP_DOMAIN = 'https://demo-api.workki.co';

const API_VERSION = '1.0';

//'https://apiworkki.dev.aic.ru/1.0/'
export const API_URL = `${REACT_APP_DOMAIN}/${API_VERSION}/`;
export const BackgroundConfig = {
  imageSource: 'local', // возможные значения: 'local' или 'server'
  serverImageUrl: REACT_APP_DOMAIN // URL бэкенда
};

import { useEffect, useState, useMemo } from 'react';
import { noop, defaults } from 'lodash';

const getScript = src => document.querySelector(`script[src="${src}"]`);

const isExisting = src => {
  const existingScript = getScript(src);
  return !!existingScript;
};

const STATUSES = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

const defaultOptions = {
  src: '',
  onLoad: noop,
  shouldRemoveScriptOnUnmount: false
};

const useScript = options => {
  const { src, onLoad, shouldRemoveScriptOnUnmount } = defaults(options, defaultOptions);

  const [status, setStatus] = useState(STATUSES.PENDING);

  useEffect(() => {
    if (isExisting(src)) {
      setStatus(STATUSES.SUCCESS);
      onLoad();

      return () => {
        if (shouldRemoveScriptOnUnmount) {
          const script = getScript(src);
          document.body.removeChild(script);
        }
      };
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const handleLoad = () => {
      setStatus(STATUSES.SUCCESS);
      onLoad();
    };

    const handleError = err => {
      setStatus(STATUSES.FAILURE);
      console.error(err);
    };

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    document.body.appendChild(script);
    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);

      if (shouldRemoveScriptOnUnmount) {
        document.body.removeChild(script);
      }
    };
  }, [onLoad, shouldRemoveScriptOnUnmount, src]);

  const isReady = useMemo(() => status === STATUSES.SUCCESS, [status]);
  const onError = useMemo(() => status === STATUSES.FAILURE, [status]);
  return [isReady, onError];
};

export default useScript;

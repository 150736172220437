import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const location = createReducer(initialState.location, {
  [actions.requestServiceLocationIsAvailable.success]: (state, action) => {
    const { locationsWithLoadedDataIds: locationIds } = state;
    const { id } = action.payload;
    if (id && !locationIds.includes(id)) {
      return { ...state, locationsWithLoadedDataIds: [...locationIds, id] };
    }
    return state;
  },
  [actions.logout]: () => initialState.location
});

export default location;

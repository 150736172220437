import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TMCInputHistory from './TMCInputHistory';
import TMCInputPanel from './TMCInputPanel';
import { officeWithParkingSelector, parkingList, customerSelector } from '../../store/selectors/DefaultSelectors';
import {
  createNeoGeoParking,
  createTulskayaTMC,
  getParkingList,
  getTulskayaTMC,
  getParkKulturyTMC,
  createParkKulturyTMC
} from '../../services/workki/ServicesActions';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import * as actions from '../../store/actions';
import useModalState from '../../utils/useModalState';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';

const mapStateToProps = state => ({
  customer: customerSelector(state),
  locations: officeWithParkingSelector(state),
  tmcInputList: parkingList(state, { parkingType: false }),
  tmcInputListRequestStatus: requestStatusSelector(actions.requestParkingList)(state),
  tmcInputCreateRequestStatus: requestStatusSelector(actions.requestParkingCreate)(state),
  getError: () => {
    const { parkingCreate: error } = state.errors;
    if (!error || error instanceof ErrorWithShowedMessage) {
      return '';
    }
    return (
      (error.response && error.response.data && Array.isArray(error.response.data) && error.response.data[0].message) ||
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
    );
  }
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  resetError: () => dispatch(actions.cleanErrors()),
  loadTMCInputHistory: () => dispatch(getParkingList()),
  loadTulskayaTMCInputHistory: () => dispatch(getTulskayaTMC()),
  loadParkKulturyTMCInputHistory: () => dispatch(getParkKulturyTMC()),
  addTMCInputNeoGeo: parking => dispatch(createNeoGeoParking(parking)), // 312 ид сервиса парковок
  addTMCInputParkKultury: tmcData => dispatch(createParkKulturyTMC(tmcData)),
  addTMCInputTulskaya: tmcData => dispatch(createTulskayaTMC(tmcData))
});

const InputTMC = props => {
  const [isModalWindowOpen, setOpenModal, closeModal] = useModalState(false);

  const closeModalWindow = useCallback(() => {
    props.dispatch(actions.requestParkingCreate.reset());
    closeModal();
  }, [closeModal]); // eslint-disable-line

  useEffect(() => {
    if (props.tmcInputCreateRequestStatus.isSuccess) {
      closeModalWindow();
    }
  }, [props.tmcInputCreateRequestStatus]); // eslint-disable-line

  return (
    <Fragment>
      <TMCInputPanel
        locations={props.locations}
        setOpenModal={setOpenModal}
        closeModalWindow={closeModalWindow}
        isModalWindowOpen={isModalWindowOpen}
        getError={props.getError}
        resetError={props.resetError}
        addTMCInputParkKultury={props.addTMCInputParkKultury}
        addTMCInputTulskaya={props.addTMCInputTulskaya}
        addTMCInputNeoGeo={props.addTMCInputNeoGeo}
        tmcInputListRequestStatus={props.tmcInputListRequestStatus}
        tmcInputCreateRequestStatus={props.tmcInputCreateRequestStatus}
      />
      <TMCInputHistory
        customer={props.customer}
        loadTMCInputHistory={props.loadTMCInputHistory}
        loadParkKulturyTMCInputHistory={props.loadParkKulturyTMCInputHistory}
        loadTulskayaTMCInputHistory={props.loadTulskayaTMCInputHistory}
        tmcInputList={props.tmcInputList}
        tmcInputListRequestStatus={props.tmcInputListRequestStatus}
      />
    </Fragment>
  );
};

InputTMC.propTypes = {
  customer: PropTypes.object,
  locations: PropTypes.array.isRequired,
  tmcInputList: PropTypes.array.isRequired,
  tmcInputListRequestStatus: PropTypes.object.isRequired,
  tmcInputCreateRequestStatus: PropTypes.object.isRequired,
  getError: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  loadTMCInputHistory: PropTypes.func.isRequired,
  loadParkKulturyTMCInputHistory: PropTypes.func.isRequired,
  loadTulskayaTMCInputHistory: PropTypes.func.isRequired,
  addTMCInputNeoGeo: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputTMC);

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getCustomerPublicFilePathByFileId from '../../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';
import { customerSelector, customerAvatarFileSelector } from '../../store/selectors/DefaultSelectors';
import { roles } from '../../roles';
import { UserIcon } from '../../shared/ui/icons/core/UserIcon';
import styled from 'styled-components';
import { NavigationElement } from '../../shared/ui/components/Navigation/NavigationElement';
import { menuItems } from '../../shared/utils/menuItems/menuItems';

const UserInfoWrapper = styled.div`
  font-size: 16px;
  & > a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
  }
  margin-bottom: 36px;
`;
const UserDescription = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    color: #24272a;
    margin: 0;
  }
`;
const CopyrightMessage = styled.div`
  font-size: 12px;
  color: #8f9295;
  font-weight: 500;
`;
const getDisplayedName = customer =>
  customer.is_company
    ? customer.individual.name_short
    : `${customer.individual.last_name} ${customer.individual.first_name} ${customer.individual.middle_name}`;

const SubmenuWidget = () => {
  const customer = useSelector(customerSelector);
  const avatarFile = useSelector(customerAvatarFileSelector);
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(localStorage.getItem('currentRoute') || '');
  useEffect(() => {
    if (location.pathname === '/profile') {
      localStorage.setItem('currentRoute', '/profile');
      setCurrentRoute('/profile');
    } else {
      setCurrentRoute(localStorage.getItem('currentRoute'));
    }
  }, [location.pathname]);

  if (!customer) {
    return null;
  }

  const isGuest = customer.role === roles.guest;
  const displayedName = isGuest ? 'Профиль не заполнен' : getDisplayedName(customer);

  const img = avatarFile ? getCustomerPublicFilePathByFileId(avatarFile.id) : null;

  return (
    <>
      <UserInfoWrapper>
        <NavigationElement
          role={'independent'}
          name={'Profile'}
          route={isGuest ? '/prefill-profile' : '/profile'}
          text={'Профиль'}
          currentRoute={currentRoute}
          isMobile={false}
        />
      </UserInfoWrapper>
      <CopyrightMessage>
        © 2024, Workki
        <br />
        Все права защищены
      </CopyrightMessage>
    </>
  );
};

export default SubmenuWidget;

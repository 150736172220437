import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { createBackgroundService } from './BackgroundService';

const BackgroundContext = createContext();

const backgroundService = createBackgroundService();

export const BackgroundProvider = ({ children }) => {
  const [background, setBackground] = useState(backgroundService.getBackground());

  useEffect(() => {
    const interval = setInterval(() => {
      const backgrounds = Object.keys(backgroundService.localImages);
      const currentIndex = backgrounds.indexOf(localStorage.getItem('background'));
      const nextIndex = (currentIndex + 1) % backgrounds.length;
      const newBackground = backgrounds[nextIndex];

      backgroundService.changeBackground(newBackground);
      setBackground(backgroundService.getBackground());
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  const changeBackground = useCallback(newBackground => {
    backgroundService.changeBackground(newBackground);
    setBackground(backgroundService.getBackground());
  }, []);

  const contextValue = useMemo(() => ({ background, changeBackground }), [background, changeBackground]);

  return <BackgroundContext.Provider value={contextValue}>{children}</BackgroundContext.Provider>;
};

export const useBackground = () => {
  return useContext(BackgroundContext);
};

import { fk, attr, Model } from 'redux-orm';
import * as actions from '../actions';

class ServiceSchedule extends Model {
  static reducer(action, serviceSchedule) {
    switch (action.type) {
      case actions.requestServicesScheduleRequests.success.toString():
        action.payload.map(elem => serviceSchedule.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `ServiceSchedule: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
ServiceSchedule.modelName = 'ServiceSchedule';

// Declare your related fields.
ServiceSchedule.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  // name: attr(),
  service_id: fk('Service', 'reservations')
};

export default ServiceSchedule;

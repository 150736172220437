import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/system';

const CustomList = styled(List)(({ view }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: view === 'horizontal' ? 'row' : 'column'
}));

const StyledListItem = styled(ListItem)({
  color: '#000',
  paddingTop: 0,
  paddingLeft: 0,
  width: 'initial'
});

const ListLabel = styled('p')({
  fontSize: '15px',
  fontWeight: '500',
  marginBottom: '12px'
});

const StyledListItemText = styled(ListItemText)({
  fontSize: '14px'
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '8px',
  color: 'red',
  fontSize: '8px'
});

const StyledCircleIcon = styled(CircleIcon)({
  fontSize: '8px'
});

const defaultItems = [
  'доступ 24/7',
  'печать 40 ч/б или 10 цв. в месяц на одно рабочее место',
  '6 ч. в переговорных в месяц, далее 1500 ₽/ч',
  'доступ для 3 гостей до 2 часов в день',
  'предоставление юр. адреса (по тарифам)',
  'чай, кофе и вода',
  'админ и IT поддержка'
];

const generateListItems = (type, propItems, tariffData) => {
  const listItems = [];

  const addCommonItems = () => {
    listItems.push('чай, кофе и вода');
    listItems.push('админ и IT поддержка');
  };

  if (type === 'workplace' && propItems.tariff) {
    const { name } = propItems.tariff;

    if (name.includes('Кабинет')) {
      listItems.push('Доступ 24/7');
      if (tariffData) {
        if (tariffData.freeHoursMeetingRoom) {
          listItems.push(`${tariffData.freeHoursMeetingRoom} ч. в переговорных в месяц, далее 1 500 ₽/ч`);
        }
        if (tariffData.guestsCount) {
          listItems.push(`доступ для ${tariffData.guestsCount} гостей до 2 часов в день`);
        }
        if (tariffData.printerPages) {
          listItems.push(`печать ${tariffData.printerPages} листов в месяц на одно рабочее место`);
        }
      }
      listItems.push('предоставление юр. адреса (по тарифам)');
    } else if (name.includes('Коворкинг')) {
      listItems.push('доступ по будням 09:00 – 21:00');
      if (tariffData) {
        if (tariffData.freeHoursMeetingRoom) {
          listItems.push(`${tariffData.freeHoursMeetingRoom} ч. в переговорных в месяц, далее 1 500 ₽/ч`);
        }
        if (tariffData.guestsCount) {
          listItems.push(`доступ для ${tariffData.guestsCount} гостей до 2 часов в день`);
        }
        if (tariffData.printerPages) {
          listItems.push(`печать ${tariffData.printerPages} листов в месяц на одно рабочее место`);
        }
      }
    } else if (name.includes('Клуб')) {
      listItems.push('доступ по будням 09:00 – 21:00');
      listItems.push('печать (по тарифам)');
      listItems.push('1 000 ₽/ч в переговорных');
    }

    addCommonItems();
  }

  return listItems.length ? listItems : propItems || defaultItems;
};

const CustomStyledList = ({ label, propItems, tariffData, view, type }) => {
  const [workplaceListItems, setWorkplaceListItems] = useState([]);

  useEffect(() => {
    setWorkplaceListItems(generateListItems(type, propItems, tariffData));
  }, [propItems, tariffData, type]);

  return (
    <>
      {label && <ListLabel>{label}</ListLabel>}
      <CustomList view={view || 'vertical'}>
        {workplaceListItems.map((text, index) => (
          <StyledListItem key={index}>
            <StyledListItemIcon>
              <StyledCircleIcon />
            </StyledListItemIcon>
            <StyledListItemText primary={text} />
          </StyledListItem>
        ))}
      </CustomList>
    </>
  );
};

export default CustomStyledList;

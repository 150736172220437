import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { resetTransactions } from '../../../store/actions';
import SimpleBalancePanel from './SimpleBalancePanel';
import TransactionsHistory from './TransactionsHistory';
import { accountSelector } from '../../../store/selectors/DefaultSelectors';
import { getTransactions } from '../../../services/workki/TransactionsActions';
import { transactionsSelector } from '../../../store/selectors/TransactionsSelector';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import * as actions from '../../../store/actions';

const Transactions = () => {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const transactions = useSelector(transactionsSelector);

  const currentPage = useSelector(state => state.pagination.transactions.currentPage);
  const pageCount = useSelector(state => state.pagination.transactions.pageCount);

  const canLoadMore = useMemo(() => !isNil(currentPage) && !isNil(currentPage) && currentPage < pageCount, [
    currentPage,
    pageCount
  ]);

  const loadStatus = useSelector(requestStatusSelector(actions.requestTransactions));

  const loadFirstPage = useCallback(() => {
    dispatch(resetTransactions());
    dispatch(getTransactions(1));
  }, [dispatch]);

  const loadNextPage = useCallback(() => canLoadMore && dispatch(getTransactions(currentPage + 1)), [
    canLoadMore,
    currentPage,
    dispatch
  ]);

  useEffect(() => loadFirstPage(), [loadFirstPage]);

  return (
    <>
      <SimpleBalancePanel account={account} />
      <TransactionsHistory
        transactions={transactions}
        isLoading={loadStatus.isPending}
        onError={loadStatus.isFailure}
        canLoadMore={canLoadMore}
        loadFirstPage={loadFirstPage}
        loadNextPage={loadNextPage}
      />
    </>
  );
};

export default Transactions;

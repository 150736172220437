import React, { memo } from 'react';
import { noop } from 'lodash';

const TableDataReloader = ({ reload }) => {
  const handler = e => {
    e.preventDefault();
    e.stopPropagation();
    reload();
  };

  return (
    <div className='table-data-loader'>
      <span>Произошла ошибка загрузки данных, нажмите чтобы </span>
      <button onClick={handler} className='profile-logins__add-login-btn btn btn--solid btn--sm' type='button'>
        Пререзагрузить
      </button>
    </div>
  );
};

TableDataReloader.defaultProps = {
  reload: noop
};

export default memo(TableDataReloader);

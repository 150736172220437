import { pick, pickBy, isNil, keys, get, isNull } from 'lodash';
import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getTickets = incomingParams => {
  const allowedParams = ['page'];
  const defaultParams = { sort: '-id', page: 1, _disableCache: 1 };
  const params = {
    ...defaultParams,
    ...pick(incomingParams, allowedParams)
  };
  return api.get('ticket', { params }, getRequestStatusActions(actions.requestTickets));
};

export const getTicket = ticketId => api.get(`ticket/${ticketId}`, {}, getRequestStatusActions(actions.requestTicket));

export const getTicketInfo = ticketId =>
  api.get(`ticket/info/${ticketId}`, {}, [
    actions.requestTicketInfo,
    res => actions.requestTicketInfo.success(res, ticketId),
    actions.requestTicketInfo.failure
  ]);

export const createTicket = ticket => {
  const allowedValues = ['category_id', 'description', 'service_id', 'file'];
  const allowedData = pick(ticket, allowedValues);
  const cleanData = pickBy(allowedData, v => !isNil(v));

  const data = new FormData();
  keys(cleanData).forEach(key => data.append(key, cleanData[key]));

  return api.post(
    'ticket',
    { data, headers: { 'content-type': 'application/x-www-form-urlencoded' } },
    getRequestStatusActions(actions.requestTicketCreate)
  );
};

export const getTicketComments = ticketId =>
  api.get(`ticket/comment/${ticketId}`, { params: { _disablePagination: 1 } }, [
    actions.requestTicketComments,
    res => actions.requestTicketComments.success(res, ticketId),
    actions.requestTicketComments.failure
  ]);

export const checkHasTicketNewComment = ticketId =>
  api.get(
    `ticket/check-new-comment/${ticketId}`,
    { params: { _disableCache: 1 } },
    [
      actions.requestCheckHasTicketNewComment,
      res => actions.requestCheckHasTicketNewComment.success(res, ticketId),
      actions.requestCheckHasTicketNewComment.failure
    ],
    false
  );

export const createTicketComment = comment => {
  const ticketId = get(comment, 'ticket_id', null);

  if (isNull(ticketId)) {
  }

  const allowedValues = ['ticket_id', 'text'];
  const allowedData = pick(comment, allowedValues);
  const cleanData = pickBy(allowedData, v => !isNil(v));

  const data = new FormData();
  keys(cleanData).forEach(key => data.append(key, cleanData[key]));

  return api.post(
    `ticket/comment/${ticketId}`,
    { data, headers: { 'content-type': 'application/x-www-form-urlencoded' } },
    getRequestStatusActions(actions.requestTicketCommentCreate)
  );
};

export const getTicketBinaryFile = ticketId => {
  return api.get(`ticket/file/${ticketId}/show`, {
    responseType: 'blob',
    timeout: 60000
  });
};

export const getTicketCategories = () =>
  api.get('ticket/category', {}, getRequestStatusActions(actions.requestTicketCategories));

import moment from 'moment-timezone';

const TOKEN_NAME = '_token';

moment.tz.setDefault('Europe/Moscow');

export const setToken = token => {
  localStorage.setItem(TOKEN_NAME, JSON.stringify(token));
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem(TOKEN_NAME));
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
};

const isExpiredToken = token => {
  const date_token = moment(token.date_create).add(token.expires_in, 'seconds');
  return date_token.diff(moment(), 'seconds') < 5000;
};

export const hasActualToken = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  return !isExpiredToken(token);
};

import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { getTicketElementId } from './constants';

const renderPlace = ticket => {
  if (!ticket.place) {
    return '';
  }

  if (!ticket.office) {
    return ticket.place.name;
  }

  return `${ticket.place.name} (${ticket.office.t_name})`;
};

const TicketsHistoryRow = ({ ticket, showTicketsItem }) => {
  return (
    <div id={getTicketElementId(ticket.id)} className='scrollable-table__row'>
      <div className='scrollable-table__cell'>#{ticket.id}</div>
      <div className='scrollable-table__cell scrollable-table__cell--date'>
        <Moment locale='ru' parse='YYYY-MM-DD HH:mm:ss' format='DD.MM.YYYY HH:mm'>
          {ticket.created_at}
        </Moment>
      </div>
      <div className='scrollable-table__cell scrollable-table__cell--limited' title={renderPlace(ticket)}>
        {renderPlace(ticket)}
      </div>
      <div className='scrollable-table__cell'>{ticket.category && ticket.category.name}</div>
      <div className='scrollable-table__cell'>
        <span onClick={showTicketsItem(ticket.id)} className='scrollable-table__control'>
          Подробно
        </span>
      </div>
    </div>
  );
};

TicketsHistoryRow.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketsHistoryRow;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { customerSelector } from '../store/selectors/DefaultSelectors';
import StepBack from '../shared/ui/components/StepBack';
import ProfilePhoto from '../shared/ui/components/ProfilePhoto';
import styled from 'styled-components';
import ProfileCard from '../shared/ui/components/Profile/ProfileCard';
import { logoutUser, sendCode } from '../entities/Authentification/api/AuthActions';
import { ChangeUserDataDialog } from '../widgets/AuthorizationWidgets/ChangeUserDataDialog';
import requestStatusSelector from '../store/selectors/requestStatusSelector';
import * as actions from '../store/actions';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { screenRessolutions } from '../shared/ui/constants/screenResolutions.constants';
import PageLoader from '../components/PageLoader';
import PrefillProfilePage from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import ProfileAvatarModal from '../components/Profile/ProfileSocial/ProfileAvatarModal';
import ProfileAvatar from '../components/Profile/ProfileSocial/ProfileAvatar';
import { LogoPlaceholder } from '../shared/ui/icons/core/LogoPlaceholder';
import Portal, { createContainer } from '../hocs/Portal';
import { DialogContent, DialogWrapper, Title, TopBlock } from '../shared/ui/components/Dialog';
import { IconComponent } from '../shared/ui/components/Field/IconComponent';
import { CustomTextField } from '../shared/ui/components/Field/CustomTextField';
import { Hints } from '../shared/ui/components/Profile/Hints';
import { StepButton } from '../shared/ui/components/StepButton';
import {
  changeCustomerProfilePhoto,
  changeCustomerProfileName,
  getCustomerCurrent,
  deleteCustomerProfilePhoto,
  deleteCustomerProfile
} from '../services/workki/CustomerActions';
import { FileUploadPreview } from '../shared/ui/components/Field/FileUploadField';
import * as types from '../services/workki/TypesActions';
import getCustomerPublicFilePathByFileId from '../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';

const UserDataWidthWrapper = styled.div`
  margin: 0;
  padding: 20px 0;
  width: 344px;
  box-sizing: content-box;

  @media screen and (max-width: ${screenRessolutions.tablet}px) {
    max-width: 328px;
  }
`;

const UserDataChangeBox = styled.div`
  margin: 20px 0 32px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const UserDataChangeTitle = styled.h3`
  margin: 0;
  padding: 0;
  width: 100%;
  text-direction: start;
  font-family: Commissioner, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #24272a;
`;

const UserDataChangeList = styled.ul`
  margin: 0;
  padding: 0;
  list-stile-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    gap: 8px;
  }
`;

const UserDataChangeItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    justify-content: flex-start;
  }
`;

const UserDataChangeItemLabel = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Commissioner, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #24272a;

  & > span {
    font-size: 12px;
    line-height: 16px;
    color: #8f9295;
  }
`;

const StyledSvg = styled.svg`
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
`;

const RemoveProfileBox = styled.div`
  margin: 20px 0 0 0;
  padding: 16px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid #e9eaea;
`;

const RemoveProfileTitleBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: Commissioner, sans-serif;
  gap: 4px;
  cursor: pointer;

  & > h3 {
    margin: 2px 0;
    paddind: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #8f9295;
  }
`;

const RemoveProfileSvg = styled.svg`
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
`;

const RemoveProfileWarningText = styled.p`
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8f9295;
`;

const AvatarContainer = styled.div`
  padding: 10px 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RESEND_INTERVAL = 60;

const ProfileSettings = ({ defaultProfileState, setProfilePageState, isPending, isLoading, ...props }) => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector);
  const makeLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);
  const [isModalWindowOpened, setIsModalWindowOpened] = useState(false);
  const [isPhotoEditorOpened, setIsPhotoEditorOpened] = useState(false);
  const [cardFile, setCardFile] = useState([]);
  const [nameChangeFiles, setNameChangeFiles] = useState([]);
  const [avatarFile, setAvatarFile] = useState([]);
  const [currentEditingPhoto, setCurrentEditingPhoto] = useState(null);
  // const [photoEditorCallback, setPhotoEditorCallback] = useState(null);
  const photoEditorCallback = useRef(null);
  const DIALOG_CONTAINER_ID = 'profile-edit-container';
  const PHOTO_EDIT_CONTAINER_ID = 'photo-edit-container';
  const getUserPhone = () => {
    let userPhone = customer.mobile_phone.split('');
    userPhone.unshift('+');
    userPhone.splice(2, 0, ' ');
    userPhone.splice(6, 0, ' ');
    userPhone.splice(10, 0, '-');
    userPhone.splice(13, 0, '-');
    return userPhone.join('');
  };

  const modalType = {
    NAME: 'name',
    NAME_SUCCESS: 'name_success',
    PHONE: 'phone',
    EMAIL: 'email',
    PHOTO_FOR_PASS: 'photoForPass',
    PASSWORD: 'password',
    MATCH_PASSWORD: 'matchPassword',
    AVATAR_EDITOR: 'avatarEditor',
    DELETE_PROFILE: 'deleteProfile',
    DELETE_PROFILE_SUCCESS: 'deleteProfileSuccess'
  };

  const loadedPhotoType = {
    AVATAR: 'avatar',
    CARD: 'card'
  };

  const defaultFormData = {
    [modalType.NAME]: '',
    [modalType.PHONE]: '',
    [modalType.EMAIL]: '',
    [modalType.PHOTO_FOR_PASS]: '',
    [modalType.PASSWORD]: '',
    [modalType.MATCH_PASSWORD]: ''
  };

  const [formData, setFormData] = useState({ ...defaultFormData });
  // console.log('formData', formData);
  const passCallback = photo => {
    console.log('setPhotoEditorCallback photo', photo);
    console.log('passCallback', photo);
    if (photo) {
      setCurrentPhotos([photo]);
      setCardFile([photo]);
    }
  };
  // console.log('customer', customer);
  let cards = [
    {
      title: 'Фото на пропуск',
      caption: '',
      handleFollowLink: () => {
        setModalContent(modalType.PHOTO_FOR_PASS);
        photoEditorCallback.current = passCallback;
        setIsModalWindowOpened(true);
      }
    },
    {
      title: 'Обновить пароль',
      // caption: 'Менялся 2 месяца назад',
      handleFollowLink: () => {
        if (customer.email) {
          setModalContent(modalType.PASSWORD);
          setIsModalWindowOpened(true);
        } else {
          alert('Ошбика при получении данных о вашем e-mail');
        }
      }
    }
  ];

  const defaultModal = {
    nameChange: false,
    phoneChange: false,
    emailChange: false,
    photoForPassChange: false,
    passwordChange: false,
    deleteProfileChange: false
  };

  const [modalState, setModalState] = useState({ ...defaultModal });

  const handleChangeState = fieldName => {
    console.log({
      ...defaultModal,
      [fieldName]: true
    });
    setModalState({
      ...defaultModal,
      [fieldName]: true
    });
  };

  const getModal = () => {
    const modal = Object.keys(modalState).find(item => modalState[item]);
    return modal;
  };

  const handleCloseModal = () => setModalState({ ...defaultModal });

  const [errors, setErrors] = useState({
    email: '',
    commonError: '',
    password: '',
    matchPassword: '',
    firstName: '',
    phone: ''
  });

  const [modalContent, setModalContent] = useState(null);

  const [isChangeData, setIsChangeData] = useState({
    open: false,
    type: '',
    requestSent: false
  });

  const [loadingStatus, setLoadingStatus] = useState('unset');

  const [validationError, setValidationError] = useState({
    name: false,
    phone: false,
    email: false,
    photoForPass: false,
    password: false,
    matchPassword: false
  });

  const [disableButton, setIsDisableButton] = useState(false);

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    // const inputValue = value.replace(/\s+/g, '');
    console.log('{ name, value } = evt.target;', name, value);
    handleValidation(name, value);
    updateFormData(name, value);
  };

  const formatPhoneNumber = input => {
    // Очищаем номер от всех нецифровых символов
    const cleaned = ('' + input).replace(/\D/g, '');
    if (cleaned.length < 10) {
      setErrors(prevError => ({
        ...prevError,
        phone: 'Номер не должен содержать менее 10 цифр'
      }));
      setValidationError(prevError => ({
        ...prevError,
        phone: true
      }));
      return input;
    }
  };

  const updateFormData = (name, inputValue) => {
    console.log('updateFormData', name, inputValue);
    setFormData(prevState => {
      if (name === 'phone') {
        const cleaned = inputValue.replace(/\D/g, '');
        const limitedInput = cleaned.substring(0, 10);
        return {
          ...prevState,
          [name]: formatPhoneNumber(limitedInput)
        };
      } else {
        return {
          ...prevState,
          [name]: inputValue
        };
      }
    });
  };

  const handleEmailValidation = inputValue => {
    const isValidEmail = validateEmail(inputValue);

    if (!isValidEmail) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Неверный формат email'
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: ''
      }));
      setValidationError(prevError => ({
        ...prevError,
        email: false
      }));
    }
  };

  const validateEmail = email => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const validatePassword = password => {
    // Регулярное выражение для проверки пароля
    // (?=.*[a-zA-Z]) - наличие хотя бы одной латинской буквы (обязательно)
    // (?=.*\d) - наличие хотя бы одной цифры (обязательно)
    // [a-zA-Z\d!@#$%^&*]+ - только латинские буквы, цифры и указанные спецсимволы
    // {8,20} - длина пароля от 8 до 20 символов
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,20}$/;

    const isValid = passwordRegex.test(password);
    return isValid;
  };

  const handlePasswordValidation = inputValue => {
    if (!validatePassword(inputValue)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Неверный формат пароля'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        password: false
      }));
    }
  };

  const handleMatchPasswordValidation = inputValue => {
    if (inputValue !== formData.password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: 'Пароли не совпадают'
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: true
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        matchPassword: ''
      }));
      setValidationError(prevErrors => ({
        ...prevErrors,
        matchPassword: false
      }));
    }
  };

  const handleValidation = (name, inputValue) => {
    switch (name) {
      case 'email':
        const testEmail = validateEmail(inputValue);
        console.log(!testEmail, 'статус кнопки');
        setIsDisableButton(!testEmail);
        break;
      case 'password':
        handlePasswordValidation(inputValue);
        break;
      case 'matchPassword':
        handleMatchPasswordValidation(inputValue);
        break;
    }
  };

  const [isFocused, setIsFocused] = useState({
    name: false,
    phone: false,
    email: false,
    photoForPass: false,
    password: false,
    matchPassword: false
  });

  const handleFocus = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: true } : true
    }));
  };

  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: fieldName === 'email' ? { ...prevFocused[fieldName], [type]: false } : false
    }));
  };

  const handleEraseValue = (fieldName, type) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  const { name, phone, email, photoForPass, password, matchPassword } = formData;

  const [changeStep, setChangeStep] = useState({
    type: 'personalData',
    // type: 'changePassword',
    data: {}
  });

  const [timer, setTimer] = useState(RESEND_INTERVAL);

  const [filled, setFilled] = useState({
    email: false,
    password: false,
    matchPassword: false
  });

  const isServerError = statusCode => {
    return statusCode ? statusCode.toString().startsWith('5') : false;
  };

  const handleTriggerCall = evt => {
    evt.preventDefault();
    const phoneWithCountryCode = '7' + phone;
    setLoadingStatus('loading');
    dispatch(sendCode({ phoneWithCountryCode }))
      .then(response => {
        console.log(response.data.data, 'данные по коду клиента');
        setLoadingStatus('unset');
        setChangeStep(prevStep => ({
          ...prevStep,
          type: 'phoneAuth',
          destination: 'flash-call',
          data: response.data.data
        }));
        if (isChangeData.open) {
          setIsChangeData(prevState => ({
            ...prevState,
            open: false,
            type: ''
          }));
        }
        setTimer(RESEND_INTERVAL);
        return response;
      })
      .catch(error => {
        const requestErrors = error.response ? error.response.data : null;
        const status = error.status;
        if (requestErrors && !isServerError(status) && Array.isArray(requestErrors)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: requestErrors.find(error => error.field === 'mobile_phone')
              ? requestErrors.find(error => error.field === 'mobile_phone').message
              : '',
            commonError: requestErrors.find(error => error.field === 'reCaptcha')
              ? requestErrors.find(error => error.field === 'reCaptcha').message
              : ''
          }));
        } else if (isServerError(status)) {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Ошибка сервера'
          }));
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
            commonError: 'Неизвестная ошибка'
          }));
        }
        setLoadingStatus('unset');
      });
  };

  const handleRegisterSubmit = () => {
    console.log('Отправка формы');
    console.log(formData);
  };

  // useEffect(() => {
  //   if (formData.phone.length !== 10) {
  //     setIsDisableButton(true);
  //   } else {
  //     setIsDisableButton(false);
  //   }
  // }, [formData]);
  const history = useHistory();

  const getLoadedFile = () => {
    if (fileInputRef.current) {
      return fileInputRef.current.files[0];
    }
    return null;
  };

  const triggerClickToUploadFile = () => {
    fileInputRef.current.click();
  };

  const restoreChangeName = () => {
    setIsModalWindowOpened(false);
    setModalContent(null);
    setNameChangeFiles([]);
    setFormData(prevState => {
      return {
        ...prevState,
        [modalType.NAME]: ''
      };
    });
  };

  const fileInputRef = useRef(null);

  const [currentPhotos, setCurrentPhotos] = useState(cardFile);

  const disableSendName = !formData[modalType.NAME].length || !nameChangeFiles.length;

  const renderDialogContent = type => {
    switch (type) {
      case modalType.NAME: {
        return (
          <DialogWrapper
            onClick={() => {
              restoreChangeName();
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Изменить имя</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      restoreChangeName();
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                  />
                </TopBlock>
                <CustomTextField
                  // error={errors.inn.legalEntity}
                  type='text'
                  name={modalType.NAME}
                  id='outlined-helperText'
                  label='Новое имя'
                  variant='filled'
                  value={formData[modalType.NAME]}
                  // onFocus={() => handleFocus('name', 'legalEntity')}
                  // onBlur={() => handleBlur('name', 'legalEntity')}
                  onChange={handleInputChange}
                  // helperText={errors.inn.legalEntity ? errors.inn.legalEntity : ''}
                  // endAdornmentProps={{
                  //   filled: filled.inn.legalEntity,
                  //   isFocused: isFocused.inn.legalEntity,
                  //   handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                  // }}
                  autoFocus={true}
                />
                <FileUploadPreview
                  key={'change-name-photos'}
                  fieldType='changeName'
                  files={nameChangeFiles}
                  disablePhotoEditing
                  setFiles={files => {
                    console.log('files', files);
                    setNameChangeFiles(files);
                  }}
                />
                {/*<Hints*/}
                {/*  open={open}*/}
                {/*  formData={formData}*/}
                {/*  anchorEl={anchorEl}*/}
                {/*  hintsData={hintsData}*/}
                {/*  setInnValue={inn => setInnValue(inn)}*/}
                {/*  inputWidth={inputWidth}*/}
                {/*  type={'inn'}*/}
                {/*/>*/}
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Отправить'
                  handleAction={() => {
                    dispatch(changeCustomerProfileName(customer.id, name, nameChangeFiles)).then(() => {
                      setModalContent(modalType.NAME_SUCCESS);
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                      });
                    });
                  }}
                  disabled={disableSendName}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.NAME_SUCCESS: {
        return (
          <DialogWrapper
            onClick={() => {
              restoreChangeName();
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div style={{ alignSelf: 'center', margin: '12px 0 0' }}>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M35.9475 17.4142C36.7286 16.6332 36.7286 15.3668 35.9475 14.5858C35.1665 13.8047 33.9002 13.8047 33.1191 14.5858L19.0667 29.1716L14.4809 24.5858C13.6998 23.8047 12.4335 23.8047 11.6525 24.5858C10.8714 25.3668 10.8714 26.6332 11.6525 27.4142L17.6525 33.4142C18.4335 34.1953 19.6998 34.1953 20.4809 33.4142L35.9475 17.4142Z'
                      fill='#02A93B'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z'
                      fill='#02A93B'
                    />
                  </svg>
                </div>
                <div style={{ alignSelf: 'center', margin: '-20px 0' }}>
                  <Title>Заявка отправлена</Title>
                </div>
                <p style={{ alignSelf: 'center', textAlign: 'center', padding: '0 20px', margin: '8px' }}>
                  Заявка на изменение данных отправлена.
                  <br />
                  Сведения об изменении статуса заявки будут приходить на вашу электронную почту.
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Спасибо'
                  handleAction={() => {
                    restoreChangeName();
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.EMAIL: {
        return <></>;
      }
      case modalType.DELETE_PROFILE: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer alightItems={'center'}>
                <TopBlock direction={'space-between'}>
                  <Title>Удалить профиль?</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>Ваши персональные данные будут удалены из системы, а личный кабинет Workki будет отключен.</p>
                <StepButton
                  type='outlined'
                  title='Да, удалить'
                  handleAction={() => {
                    dispatch(deleteCustomerProfile(customer.id)).then(() => {
                      dispatch(getCustomerCurrent()).then(() => {
                        setModalContent(modalType.DELETE_PROFILE_SUCCESS);
                        dispatch(types.getAccount());
                      });
                    });
                  }}
                />
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Нет, не удалять'
                  handleAction={() => {
                    setIsModalWindowOpened(false);
                    setModalContent(null);
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.DELETE_PROFILE_SUCCESS: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <div style={{ alignSelf: 'center', margin: '12px 0 0' }}>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M35.9475 17.4142C36.7286 16.6332 36.7286 15.3668 35.9475 14.5858C35.1665 13.8047 33.9002 13.8047 33.1191 14.5858L19.0667 29.1716L14.4809 24.5858C13.6998 23.8047 12.4335 23.8047 11.6525 24.5858C10.8714 25.3668 10.8714 26.6332 11.6525 27.4142L17.6525 33.4142C18.4335 34.1953 19.6998 34.1953 20.4809 33.4142L35.9475 17.4142Z'
                      fill='#02A93B'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24Z'
                      fill='#02A93B'
                    />
                  </svg>
                </div>
                <div style={{ alignSelf: 'center', margin: '-20px 0' }}>
                  <Title>Запрос отправлен</Title>
                </div>
                <p style={{ alignSelf: 'center', textAlign: 'center', padding: '0 20px', margin: '8px' }}>
                  Запрос на удаление персональных данных отправлен. На вашу электронную почту придет письмо со статусом
                  заявки.
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Спасибо'
                  handleAction={() => {
                    setIsModalWindowOpened(false);
                    setModalContent(null);
                  }}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.PHONE: {
        return <></>;
      }
      case modalType.PASSWORD: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'}>
                  <Title>Сброс пароля</Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>
                  Письмо о сбросе пароля придёт на почту <strong>{customer.email}</strong>
                </p>
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Отпарвить пиьмо на e-mail'
                  handleAction={() => {
                    // dispatch(changeCustomerProfileName(customer.id, name, ['qwe']));
                  }}
                  // disabled={isSubmitDisabled}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.PHOTO_FOR_PASS: {
        return (
          <DialogWrapper
            onClick={() => {
              setIsModalWindowOpened(false);
              setModalContent(null);
            }}
          >
            <DialogContent onClick={evt => evt.stopPropagation()}>
              <ContentContainer>
                <TopBlock direction={'space-between'} sticky>
                  <Title
                    onClick={() => {
                      console.log(photoEditorCallback);
                    }}
                  >
                    Фото на пропуск
                  </Title>
                  <IconComponent
                    type='close'
                    handleAction={() => {
                      setIsModalWindowOpened(false);
                      setModalContent(null);
                    }}
                    defaultColor={'#24272A'}
                    hoverColor={'#383c40'}
                    spacing='0'
                  />
                </TopBlock>
                <p>
                  Требуется на некоторых локациях.
                  <br />
                  При необходимости менеджер Workki свяжется с вами и попросит его загрузить.
                </p>
                <FileUploadPreview
                  fieldType='employee'
                  files={cardFile}
                  setFiles={files => setCardFile(files)}
                  deletePhotoHandler={() => {
                    dispatch(deleteCustomerProfilePhoto(customer.id, loadedPhotoType.CARD)).then(() => {
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                        setAvatarFile(null);
                      });
                    });
                  }}
                  // setFiles={files => {}}
                  changeFileHandler={photo => {
                    setIsPhotoEditorOpened(true);
                    setCurrentPhotos(photo);
                    setCurrentEditingPhoto(photo);
                    console.log('setPhotoEditorCallback(passCallback)', passCallback);
                    photoEditorCallback.current = passCallback;
                    // setPhotoEditorCallback(file => {
                    //   setCardFile(file);
                    // });
                    // setPhotoEditorCallback(() => {
                    //
                    // })
                    // setModalContent(modalType.AVATAR_EDITOR);
                    // setIsModalWindowOpened(true);
                  }}
                  isSingleFile
                />
                <StepButton
                  filledType='black'
                  type='filled'
                  title='Сохранить'
                  handleAction={() => {
                    dispatch(changeCustomerProfilePhoto(customer.id, cardFile, loadedPhotoType.CARD)).then(() => {
                      dispatch(getCustomerCurrent()).then(() => {
                        dispatch(types.getAccount());
                        setAvatarFile(null);
                      });
                    });
                  }}
                  disabled={!currentPhotos.length}
                />
              </ContentContainer>
            </DialogContent>
          </DialogWrapper>
        );
      }
      case modalType.MATCH_PASSWORD: {
        return <></>;
      }
      case modalType.AVATAR_EDITOR: {
        return (
          <ProfileAvatarModal
            image={getLoadedFile()}
            modalWindowTitle='Добавление изображения'
            sendCustomerFile={file => {
              dispatch(changeCustomerProfilePhoto(customer.id, file, loadedPhotoType.AVATAR)).then(() => {
                dispatch(getCustomerCurrent()).then(() => {
                  dispatch(types.getAccount());
                  setAvatarFile(null);
                });
              });
              // /customer/profile/photo
            }}
            isModalWindowOpen={isModalWindowOpened}
            closeModalWindow={() => {
              setIsModalWindowOpened(false);
            }}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const avatarCallback = blob => {
    // console.log('blob', blob, 'photoEditorCallback', photoEditorCallback);
    // console.log('setPhotoEditorCallback photo', blob);
    // if (blob) {
    //   setCurrentPhotos([blob]);
    //   setCardFile([blob]);
    // }
    console.log('avatarCallback', blob);
    console.log('photoEditorCallback', photoEditorCallback.current);
    !!photoEditorCallback.current && photoEditorCallback.current(blob);
  };

  useEffect(() => {
    createContainer({ id: DIALOG_CONTAINER_ID });
    createContainer({ id: PHOTO_EDIT_CONTAINER_ID });
  }, []);

  useEffect(() => {
    console.log('cardFiles', cardFile);
  }, [cardFile]);

  return (
    <PrimaryLayout>
      <main className='main'>
        {isLoading ? (
          <PageLoader />
        ) : (
          // <PrefillProfilePage style={{ alignItems: 'center' }}>
          <>
            <UserDataWidthWrapper>
              <StepBack
                onClick={() => history.push('/profile')}
                title={'Настройки профиля'}
                style={{ marginBottom: '8px' }}
              />
              {/*<ProfilePhoto*/}
              {/*  onChange={() => console.log('Изменить фото')}*/}
              {/*  onPhotoClick={() => console.log('Клик по фото')}*/}
              {/*  onRemove={() => console.log('Удалить фото')}*/}
              {/*  onEmptyPhotoClick={() => console.log('Клик по пустому фото')}*/}
              {/*>*/}
              {/*  123*/}
              {/*</ProfilePhoto>*/}
              <AvatarContainer>
                {customer.avatar ? (
                  <ProfileAvatar
                    src={getCustomerPublicFilePathByFileId(82215)}
                    // src={customer.avatar}
                    sendCustomerFile={() => alert('Загрузка...')}
                    deleteCallback={() => {
                      dispatch(deleteCustomerProfilePhoto(customer.id, loadedPhotoType.AVATAR)).then(() => {
                        dispatch(getCustomerCurrent()).then(() => {
                          dispatch(types.getAccount());
                          restoreChangeName();
                        });
                      });
                    }}
                    openEditorCallback={triggerClickToUploadFile}
                  />
                ) : (
                  <LogoPlaceholder onClick={triggerClickToUploadFile} captionText={'Добавить фото'} />
                )}
              </AvatarContainer>
              <UserDataChangeBox>
                <UserDataChangeTitle>Персональные данные</UserDataChangeTitle>
                <UserDataChangeList>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>Имя:</span>
                      {customer.name}
                    </UserDataChangeItemLabel>
                    <StyledSvg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={() => {
                        setModalContent(modalType.NAME);
                        setIsModalWindowOpened(true);
                      }}
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'
                      />
                    </StyledSvg>
                  </UserDataChangeItem>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>Телефон:</span>
                      {getUserPhone('phone')}
                    </UserDataChangeItemLabel>
                    {/*пока заккоментировано тк нашлась ошибка в userFlow при котором можно сменить реквизиты без подтверждения старыми*/}
                    <div></div>
                    {/*<StyledSvg*/}
                    {/*  width='20'*/}
                    {/*  height='20'*/}
                    {/*  viewBox='0 0 20 20'*/}
                    {/*  fill='none'*/}
                    {/*  xmlns='http://www.w3.org/2000/svg'*/}
                    {/*  onClick={() => handleChangeState('phoneChange')}*/}
                    {/*>*/}
                    {/*  <path*/}
                    {/*    fill-rule='evenodd'*/}
                    {/*    clip-rule='evenodd'*/}
                    {/*    d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'*/}
                    {/*  />*/}
                    {/*</StyledSvg>*/}
                  </UserDataChangeItem>
                  <UserDataChangeItem>
                    <UserDataChangeItemLabel>
                      <span>E-mail:</span>
                      {customer.email}
                    </UserDataChangeItemLabel>
                    {/*пока заккоментировано тк нашлась ошибка в userFlow при котором можно сменить реквизиты без подтверждения старыми*/}
                    <div></div>
                    {/*<StyledSvgStyledSvg*/}
                    {/*  width='20'*/}
                    {/*  height='20'*/}
                    {/*  viewBox='0 0 20 20'*/}
                    {/*  fill='none'*/}
                    {/*  xmlns='http://www.w3.org/2000/svg'*/}
                    {/*  onClick={() => handleChangeState('emailChange')}*/}
                    {/*>*/}
                    {/*  <path*/}
                    {/*    fill-rule='evenodd'*/}
                    {/*    clip-rule='evenodd'*/}
                    {/*    d='M14.4508 3.56675C13.7186 2.83452 12.5314 2.83452 11.7992 3.56675L4.55807 10.8079C4.55051 10.8154 4.54315 10.8232 4.53601 10.8311C4.46263 10.9123 4.41433 11.008 4.39112 11.1085L3.14367 16.0982C3.09043 16.3112 3.15283 16.5365 3.30807 16.6918C3.46331 16.847 3.68861 16.9094 3.9016 16.8561L8.8899 15.6091C9.00059 15.5838 9.10573 15.528 9.19194 15.4418L9.19521 15.4385L16.4331 8.20064C17.1653 7.4684 17.1653 6.28122 16.4331 5.54899L14.4508 3.56675ZM8.74999 14.116L15.5492 7.31675C15.7933 7.07268 15.7933 6.67695 15.5492 6.43287L13.567 4.45064C13.3229 4.20656 12.9271 4.20656 12.6831 4.45064L5.88387 11.2498L8.74999 14.116ZM5.33861 12.4723L4.60899 15.3908L7.52748 14.6612L5.33861 12.4723Z'*/}
                    {/*  />*/}
                    {/*</StyledSvg>*/}
                  </UserDataChangeItem>
                </UserDataChangeList>
              </UserDataChangeBox>
              <ProfileCard
                cards={cards}
                logoutShown={true}
                handleLogout={makeLogout}
                handleFollowLink={() => {
                  setProfilePageState({ ...defaultProfileState, profileSettings: true });
                }}
              />
              <RemoveProfileBox>
                <RemoveProfileTitleBox
                  onClick={() => {
                    setIsModalWindowOpened(true);
                    setModalContent(modalType.DELETE_PROFILE);
                  }}
                >
                  <h3>Удаление профиля</h3>
                  <RemoveProfileSvg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M5.59088 3.34088C5.92038 3.01137 6.45462 3.01137 6.78412 3.34088L12.4091 8.96588C12.7386 9.29538 12.7386 9.82962 12.4091 10.1591L6.78412 15.7841C6.45462 16.1136 5.92038 16.1136 5.59088 15.7841C5.26137 15.4546 5.26137 14.9204 5.59088 14.5909L10.6193 9.5625L5.59088 4.53412C5.26137 4.20462 5.26137 3.67038 5.59088 3.34088Z'
                    />
                  </RemoveProfileSvg>
                </RemoveProfileTitleBox>
                <RemoveProfileWarningText>После удаления профиля восстановление невозможно</RemoveProfileWarningText>
              </RemoveProfileBox>
            </UserDataWidthWrapper>
            {!!getModal() && (
              <ChangeUserDataDialog
                dialogType={getModal()}
                disabledButton={disableButton}
                handleClose={() => {
                  setFormData({ ...defaultFormData });
                  handleCloseModal();
                  setIsDisableButton(false);
                }}
                errors={errors}
                formData={formData}
                handleInputChange={handleInputChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleEraseValue={handleEraseValue}
                handleSubmit={isChangeData.type === 'phoneConfirmation' ? handleTriggerCall : handleRegisterSubmit}
                isPending={isPending}
                filled={filled}
                isFocused={isFocused}
                requestSent={isChangeData.requestSent}
              />
            )}
            <input
              ref={fileInputRef}
              onInput={() => {
                if (fileInputRef.current.files[0]) {
                  setIsModalWindowOpened(true);
                  setModalContent(modalType.AVATAR_EDITOR);
                }
              }}
              className='file-upload__input is-empty'
              type='file'
              accept='image/jpeg, image/png'
            />
          </>
          // </PrefillProfilePage>
        )}
      </main>
      {isPhotoEditorOpened && (
        <Portal id={PHOTO_EDIT_CONTAINER_ID}>
          <div style={{ zIndex: 21, position: 'relative' }}>
            <ProfileAvatarModal
              image={currentEditingPhoto}
              modalWindowTitle='Добавление изображения'
              sendCustomerFile={blob => {
                setAvatarFile(blob);
              }}
              isModalWindowOpen={isModalWindowOpened}
              closeModalWindow={() => {
                setIsPhotoEditorOpened(false);
              }}
              saveCallback={avatarCallback}
            />
          </div>
        </Portal>
      )}
      {isModalWindowOpened && <Portal id={DIALOG_CONTAINER_ID}>{renderDialogContent(modalContent)}</Portal>}
    </PrimaryLayout>
  );
};

const mapStateToProps = state => {
  const requestAuthStatus = requestStatusSelector(actions.requestLogin)(state);
  return {
    errorMessage: state.errors.auth,
    isPending: requestAuthStatus.isPending
    // isAuthenticated: state.isAuthenticated
  };
};

export default connect(mapStateToProps)(ProfileSettings);

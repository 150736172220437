import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { pick, find } from 'lodash';
import ItServicesForm from './ItServicesForm';
import ModalWindowWrapper from '../../hocs/ModalWindowWrapper';
import { serviceRequestWithWorkplacesSelector, itServicesSelector } from '../../store/selectors/ServicesSelector';
import { officeSelector } from '../../store/selectors/DefaultSelectors';
import { createItServiceTask, getItServiceTaskList } from '../../services/workki/ServicesActions';
import * as actions from '../../store/actions';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';

const getWorkplaces = (activeWorkplaces, offices) => {
  return activeWorkplaces
    .map(workplace => {
      if (!workplace.reservation_place) {
        return null;
      }
      const place = pick(workplace.reservation_place, ['id', 'name', 'is_openspace']);
      const office = find(offices, o => o.id === workplace.reservation_place.office_id);

      return {
        ...place,
        officeName: office.t_name,
        office_id: office.id,
        bookingStartDate: workplace.start_at,
        bookingExpireDate: workplace.expire_at
      };
    })
    .filter(v => !!v);
};

const ItServicesModal = ({ closeModalWindow, isModalWindowOpen }) => {
  const dispatch = useDispatch();

  // CLOSE MODAL AFTER CREATE TASK
  const taskCreateStatus = useSelector(requestStatusSelector(actions.requestItServiceTaskCreate));
  useEffect(() => {
    if (taskCreateStatus.isSuccess) {
      dispatch(actions.requestItServiceTaskCreate.reset());
      closeModalWindow();
    }
  }, [closeModalWindow, taskCreateStatus]);

  // UPDATE TASK LIST AFTER CREATE TASK
  const taskGetListStatus = useSelector(requestStatusSelector(actions.requestItServiceTaskList));
  useEffect(() => {
    if (taskCreateStatus.isSuccess && !taskGetListStatus.isPending) {
      dispatch(getItServiceTaskList());
    }
  }, [taskCreateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // MODAL DATA
  const offices = useSelector(officeSelector);
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: moment().subtract(14, 'days'),
      selectorType: 'standard'
    })
  );
  const workplaces = useMemo(() => getWorkplaces(activeWorkplaces, offices), [activeWorkplaces, offices]);

  const itServices = useSelector(itServicesSelector);
  // SEND MODAL FORM
  const createTask = data => dispatch(createItServiceTask(data));
  const resetError = () => dispatch(actions.cleanErrors());

  const error = useSelector(state => state.errors.itServiceTaskCreate);
  const taskCreateError = useMemo(() => {
    if (!error || error instanceof ErrorWithShowedMessage) {
      return '';
    }
    return (
      (error.response && error.response.data && Array.isArray(error.response.data) && error.response.data[0].message) ||
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
    );
  }, [error]);

  return (
    <ItServicesForm
      workplaces={workplaces}
      itServices={itServices}
      createTask={createTask}
      taskCreateStatus={taskCreateStatus}
      taskCreateError={taskCreateError}
      resetError={resetError}
      isModalWindowOpen={isModalWindowOpen}
      closeModalWindow={closeModalWindow}
    />
  );
};

export default ModalWindowWrapper(ItServicesModal);

import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class PublicProfiles extends Model {
  static reducer(action, publicProfile) {
    switch (action.type) {
      case actions.requestPublicProfileList.success.toString():
        const actualProfilesIds = new Set(action.payload.map(profile => profile.id));
        const idsToDelete = publicProfile
          .all()
          .toModelArray()
          .filter(p => !actualProfilesIds.has(p.id))
          .map(p => p.id);
        idsToDelete.forEach(id => publicProfile.withId(id).delete());
        action.payload.forEach(item => publicProfile.upsert(item));
        break;
      default:
    }
    return undefined;
  }
}

PublicProfiles.modelName = 'PublicProfiles';

PublicProfiles.fields = {
  id: attr()
};

export default PublicProfiles;

export const screenRessolutions = {
  smallMobile: 320,
  mobile: 360,
  tablet: 768,
  laptop: 1366,
  desktop: 1920,
  desktopPlus: 2560
};
export const fontFamilyBase = 'Commissioner, sans-serif';
export const colors = {
  workkiDarkMain: '#24272a',
  workkiDarkFill: '#383c40',
  workkiDarkSecondary: '#575b60',
  workkiDarkMiddle: '#8f9295',
  workkiLightLine: '#c8c9ca',
  workkiLightLight2: '#e9eaea',
  workkiLightLight1: '#f4f4f4',
  workkiLightLight05: '#fafafa',
  workkiLightWhite: '#fff',
  workkiRedMain: '#ba393a',
  workkiRedFill: '#db4344',
  workkiRedLine: '#f1b4b4',
  workkiLightRed: '#fbecec',
  workkiRedLight: '#fdf6f6',
  workkiGreenSuccess: '#02A93B',
  workkiGreenMain: '#008d64',
  workkiGreenFill: '#00a676',
  workkiGreenLine: '#99dbc8',
  workkiGreenLight: '#f2fbf8',
  workkiBlueMain: '#005f7a',
  workkiBlueFill: '#00708f',
  workkiBlueLine: '#00708f',
  workkiBlueLight: '#f2f8f9'
};

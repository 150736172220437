import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const AlertIcon = ({ defaultColor, spacing, ...props }) => {
  return (
    <StyledSvg
      width='20'
      height='25'
      viewBox='0 0 20 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      {...props}
    >
      <path
        d='M10 12.0834C10.3452 12.0834 10.625 12.3632 10.625 12.7084V16.4584C10.625 16.8036 10.3452 17.0834 10 17.0834C9.65482 17.0834 9.375 16.8036 9.375 16.4584V12.7084C9.375 12.3632 9.65482 12.0834 10 12.0834Z'
        fill='#BA393A'
      />
      <path
        d='M10 10.8334C10.3452 10.8334 10.625 10.5536 10.625 10.2084C10.625 9.8632 10.3452 9.58337 10 9.58337C9.65482 9.58337 9.375 9.8632 9.375 10.2084C9.375 10.5536 9.65482 10.8334 10 10.8334Z'
        fill='#BA393A'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.5 13.3334C2.5 9.19124 5.85786 5.83337 10 5.83337C14.1421 5.83337 17.5 9.19124 17.5 13.3334C17.5 17.4755 14.1421 20.8334 10 20.8334C5.85786 20.8334 2.5 17.4755 2.5 13.3334ZM10 7.08337C6.54822 7.08337 3.75 9.88159 3.75 13.3334C3.75 16.7852 6.54822 19.5834 10 19.5834C13.4518 19.5834 16.25 16.7852 16.25 13.3334C16.25 9.88159 13.4518 7.08337 10 7.08337Z'
        fill='#BA393A'
      />
    </StyledSvg>
  );
};

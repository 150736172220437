import React from 'react';
import { Dialog } from '../../../shared/ui/components/Dialog';

export const PrintFileDialog = props => {
  return (
    <Dialog
      dialogData={props.dialogData}
      title={props.title}
      handleSubmit={props.handleSubmit}
      handleClose={props.handleClose}
      buttonLabel='Отправить'
      dialogType={props.dialogType}
    />
  );
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { resetTickets } from '../../store/actions';
import TicketsHistory from './TicketsHistory';
import TicketDetails from './TicketDetails';
import TicketModal from './TicketModal';
import useModalState from '../../utils/useModalState';
import { getActiveServiceRequests } from '../../services/workki/ServicesActions';
import { getTickets } from '../../services/workki/TicketsActions';
import { ticketsSelector } from '../../store/selectors/TicketsSelectors';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import queryString from 'query-string';

moment.tz.setDefault('Europe/Moscow');

const MODE = {
  ITEM: 'item',
  LIST: 'list'
};

const Ticket = props => {
  const dispatch = useDispatch();

  // LIST
  const tickets = useSelector(ticketsSelector);
  const loadStatus = useSelector(requestStatusSelector(actions.requestTickets));

  const currentPage = useSelector(state => state.pagination.tickets.currentPage);
  const pageCount = useSelector(state => state.pagination.tickets.pageCount);

  const [defaultServiceId, setDefaultServiceId] = useState('');
  const [defaultTicketType, setDefaultTicketType] = useState('');

  useEffect(() => {
    const { serviceId, type } = queryString.parse(window.location.search);
    setDefaultServiceId(serviceId || '');
    setDefaultTicketType(type || '');
    if (serviceId && type) {
      openModalWindow();
    }
  }, []);

  const canLoadMore = useMemo(() => !isNil(currentPage) && !isNil(currentPage) && currentPage < pageCount, [
    currentPage,
    pageCount
  ]);

  useEffect(() => {
    props.customer && dispatch(getActiveServiceRequests());
  }, [dispatch, props.customer]);

  const loadFirstPage = useCallback(() => {
    dispatch(resetTickets());
    props.customer && dispatch(getTickets({ page: 1 }));
  }, [dispatch, props.customer]);

  const loadNextPage = useCallback(() => canLoadMore && dispatch(getTickets({ page: currentPage + 1 })), [
    canLoadMore,
    currentPage,
    dispatch
  ]);

  useEffect(() => loadFirstPage(), [loadFirstPage]);

  // VIEW (MODE)
  const [{ mode, currentItemId }, setState] = useState({
    mode: MODE.LIST,
    currentItemId: null
  });

  const showTicketsItem = useCallback(id => () => setState({ mode: MODE.ITEM, currentItemId: id }), []);
  const showTicketsList = useCallback(() => setState({ mode: MODE.LIST, currentItemId }), [currentItemId]);

  const currentItem = useMemo(() => tickets.find(t => t.id === currentItemId), [currentItemId, tickets]);
  useEffect(() => {
    if (mode === MODE.ITEM && !currentItem) {
      showTicketsList();
    }
  }, [currentItem, mode, showTicketsList]);

  // MODAL
  const [isModalWindowOpen, openModalWindow, closeModalWindow] = useModalState(false);

  return (
    <>
      <section className='page__section balance-panel balance-panel_guest balance-panel_ticket'>
        <div className='balance-panel__inner'>
          {mode === MODE.LIST ? (
            <>
              <div className='balance-panel__state' />
              <div className='balance-panel__action'>
                <button
                  type='button'
                  className='balance-panel__link btn btn--solid balance-panel__link_add-guest'
                  onClick={openModalWindow}
                >
                  <span className='balance-panel__link-text'>Создать запрос</span>
                </button>
              </div>
            </>
          ) : (
            <div className='ticket__main-container'>
              <div className='ticket-title'>
                <span className='ticket-title__id'>Запрос #{currentItemId}</span>
                <span className='ticket-title__time'>
                  {currentItem.created_at &&
                    `(${moment(currentItem.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')})`}
                </span>
              </div>
              <div className='balance-panel__action'>
                <button
                  type='button'
                  className='balance-panel__link btn btn--solid balance-panel__link_add-guest'
                  onClick={showTicketsList}
                >
                  <span className='balance-panel__link-text'>Вернуться к списку</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      {mode === MODE.LIST ? (
        <>
          <TicketsHistory
            tickets={tickets}
            showTicketsItem={showTicketsItem}
            isLoading={loadStatus.isPending}
            onError={loadStatus.isFailure}
            canLoadMore={canLoadMore}
            loadFirstPage={loadFirstPage}
            loadNextPage={loadNextPage}
            currentItemId={currentItemId}
          />
          <TicketModal
            isModalWindowOpen={isModalWindowOpen}
            closeModalWindow={closeModalWindow}
            additionalClasses={['modal-window--fill-payments', 'modal-window--fill-payments_guest']}
            modalWindowTitle='Обращение в службу поддержки'
            defaultServiceId={defaultServiceId}
            defaultTicketType={defaultTicketType}
          />
        </>
      ) : (
        <TicketDetails ticket={currentItem} showTicketsList={showTicketsList} />
      )}
    </>
  );
};

export default Ticket;

import { Model } from 'redux-orm';
import * as actions from '../actions';

class CustomerFile extends Model {
  static reducer(action, customerFile) {
    switch (action.type) {
      case actions.requestCustomerProfileFilesSuccess.toString():
        action.payload.map(elem => customerFile.upsert(elem));
        break;
      case actions.requestServiceSendFileToPrinterSuccess.toString():
        customerFile.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `CustomerFile: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CustomerFile.modelName = 'CustomerFile';

export default CustomerFile;

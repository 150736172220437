import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styleConstants';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || colors.workkiDarkSecondary};
    :hover {
      ${props => props.hoverColor || colors.workkiDarkSecondary};
    }
  }
  cursor: pointer;
`;

const CancellationCircle = ({ defaultColor, hoverColor, onClick, ...props }) => {
  return (
    <StyledSvg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      hoverColor={hoverColor}
      onClick={onClick}
      {...props}
    >
      <path d='M7.11351 7.61351C7.33318 7.39384 7.68934 7.39384 7.90901 7.61351L9.5 9.2045L11.091 7.61351C11.3107 7.39384 11.6668 7.39384 11.8865 7.61351C12.1062 7.83318 12.1062 8.18934 11.8865 8.40901L10.2955 10L11.8865 11.591C12.1062 11.8107 12.1062 12.1668 11.8865 12.3865C11.6668 12.6062 11.3107 12.6062 11.091 12.3865L9.5 10.7955L7.90901 12.3865C7.68934 12.6062 7.33318 12.6062 7.11351 12.3865C6.89384 12.1668 6.89384 11.8107 7.11351 11.591L8.7045 10L7.11351 8.40901C6.89384 8.18934 6.89384 7.83318 7.11351 7.61351Z' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.72703 14.773C2.09099 12.1369 2.09099 7.86307 4.72703 5.22703C7.36307 2.59099 11.6369 2.59099 14.273 5.22703C16.909 7.86307 16.909 12.1369 14.273 14.773C11.6369 17.409 7.36307 17.409 4.72703 14.773ZM5.52252 6.02252C3.32583 8.21922 3.32583 11.7808 5.52252 13.9775C7.71922 16.1742 11.2808 16.1742 13.4775 13.9775C15.6742 11.7808 15.6742 8.21922 13.4775 6.02252C11.2808 3.82583 7.71922 3.82582 5.52252 6.02252Z'
      />
    </StyledSvg>
  );
};

export default CancellationCircle;

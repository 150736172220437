import * as actions from '../actions';
import { getTicketComments } from '../../services/workki/TicketsActions';
import { isEmpty, isNumber } from 'lodash';

const TIMEOUT = 3000;

export default store => next => action => {
  if (
    actions.requestTicketCommentCreate.success.toString() === action.type &&
    action.payload &&
    !isEmpty(action.payload.ticketId) &&
    isNumber(Number(action.payload.ticketId))
  ) {
    setTimeout(() => {
      store.dispatch(getTicketComments(Number(action.payload.ticketId)));
    }, TIMEOUT);
  }
  return next(action);
};

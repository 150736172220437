import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import PageLoader from '../components/PageLoader';
import Transactions from '../deprecated/components/Transactions';

const TransactionsPage = props => (
  <PrimaryLayout>
    <main className='main'>
      <h1 className='page__title page__title_payment'>Списания средств</h1>
      {props.isLoading ? <PageLoader /> : <Transactions />}
    </main>
  </PrimaryLayout>
);

export default TransactionsPage;

import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../ui/constants/styleConstants';

const StyledSvg = styled.svg`
  margin-right: ${props => props.spacing || '16px'};
  & > path {
    fill: ${props => props.fillColor || colors.workkiDarkMiddle};
    :hover {
      ${props => props.hoverColor || colors.workkiDarkMiddle};
    }
  }
  cursor: pointer;
`;

export const CopyIcon = ({ defaultColor, spacing, hoverColor }) => {
  return (
    <StyledSvg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.50012 3.5C6.50012 2.67157 7.17169 2 8.00012 2H9.58591C9.98373 2 10.3653 2.15804 10.6466 2.43934L13.0608 4.85355C13.3421 5.13486 13.5001 5.51639 13.5001 5.91421V9.5C13.5001 10.3284 12.8285 11 12.0001 11H8.00012C7.17169 11 6.50012 10.3284 6.50012 9.5V3.5ZM8.00012 3H9.00012C9.27626 3 9.50012 3.22386 9.50012 3.5V4.5C9.50012 5.32843 10.1717 6 11.0001 6H12.0001C12.2763 6 12.5001 6.22386 12.5001 6.5V9.5C12.5001 9.77614 12.2763 10 12.0001 10H8.00012C7.72398 10 7.50012 9.77614 7.50012 9.5V3.5C7.50012 3.22386 7.72398 3 8.00012 3ZM11.0001 5H11.793L10.5001 3.70711V4.5C10.5001 4.77614 10.724 5 11.0001 5Z'
        fill='#575B60'
      />
      <path
        d='M5.00012 5C4.17169 5 3.50012 5.67157 3.50012 6.5V12.5C3.50012 13.3284 4.17169 14 5.00012 14H9.00012C9.82855 14 10.5001 13.3284 10.5001 12.5V12C10.5001 11.7239 10.2763 11.5 10.0001 11.5C9.72398 11.5 9.50012 11.7239 9.50012 12V12.5C9.50012 12.7761 9.27626 13 9.00012 13H5.00012C4.72398 13 4.50012 12.7761 4.50012 12.5V6.5C4.50012 6.22386 4.72398 6 5.00012 6H5.50012C5.77626 6 6.00012 5.77614 6.00012 5.5C6.00012 5.22386 5.77626 5 5.50012 5H5.00012Z'
        fill='#575B60'
      />
    </StyledSvg>
  );
};

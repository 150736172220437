import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  dispatchBuyNow,
  getServiceLocationIsAvailable,
  getWorkplaceAvailablePrice,
  getUnfixedWorkplaceTypes
} from '../../../services/workki/ServicesActions';
import { roles } from '../../../roles';
import ErrorWithShowedMessage from '../../../errors/ErrorWithShowedMessage';
import { getActualCustomerCurrent, getCustomerContracts } from '../../../services/workki/CustomerActions';
import { feeServicesSelector } from '../../../store/selectors/ServicesSelector';
import * as actions from '../../../store/actions';
import {
  accountSelector,
  customerContractsSelector,
  customerSelector
} from '../../../store/selectors/DefaultSelectors';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';

const mapStateToProps = (state, props) => {
  const customer = customerSelector(state);
  const serviceRequestCreateStatus = requestStatusSelector(actions.requestServiceRequestCreate)(state);
  return {
    accountUI: state.ui.account,
    account: accountSelector(state),
    customer,
    isLimitsError: false,
    contracts: customerContractsSelector(state),
    isGuest: customer && customer.role === roles.guest,
    feeServices: feeServicesSelector(state, props),
    loadingByNow: serviceRequestCreateStatus.isPending,
    isSuccess: serviceRequestCreateStatus.isSuccess,
    hasError: serviceRequestCreateStatus.isFailure,
    getError: () => {
      const { serviceRequestCreate: error } = state.errors;
      if (!error || error instanceof ErrorWithShowedMessage) {
        return '';
      }
      return (
        (error.response &&
          error.response.data &&
          Array.isArray(error.response.data) &&
          error.response.data[0].message) ||
        'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
      );
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkplaceAvailablePrice: (...args) => dispatch(getWorkplaceAvailablePrice(...args)),
    getUnfixedWorkplaceTypes: officeId => dispatch(getUnfixedWorkplaceTypes(officeId)),
    getContracts: () => dispatch(getCustomerContracts()),
    buyNow: (service_id, active_from, cnt, price_id, contract, isSubscribed, officeId, isNewCustomer) =>
      dispatch(dispatchBuyNow(service_id, active_from, cnt, price_id, contract, isSubscribed)).then(response => {
        dispatch(getServiceLocationIsAvailable(officeId));
        if (isNewCustomer) {
          dispatch(getActualCustomerCurrent());
        }
        return response;
      })
  };
};

const withBookingWidget = Component => {
  const WrappedComponent = props => {
    const [buyNowResponse, setBuyNowResponse] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState('');

    const handleBuyNow = (...args) => {
      setLoadingStatus('loading');
      props
        .buyNow(...args)
        .then(response => {
          setBuyNowResponse(response);
          setLoadingStatus('success');
        })
        .catch(() => {
          setLoadingStatus('error');
          //Доработать обработку сообщения ошибки при превышенных лимитах брони
          // this.setState({ isLimitsError: true });
        });
    };

    return (
      <Component {...props} loadingStatus={loadingStatus} buyNowResponse={buyNowResponse} handleBuyNow={handleBuyNow} />
    );
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};

export default withBookingWidget;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { accountSelector, customerSelector } from '../../store/selectors/DefaultSelectors';
import { getServiceSchedule, serviceRequestWithWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import * as actions from '../../store/actions';
import {
  changeSubscription,
  dispatchBuyNow,
  getActiveServiceRequests,
  getEmployeesServices,
  getSchedule,
  getServiceIsActive
} from '../../services/workki/ServicesActions';
import { getAccount } from '../../services/workki/TypesActions';
import { getBalancePrinter } from '../../services/workki/CustomerActions';
import { error, success } from 'react-toastify-redux';
import { currencySelector } from '../../store/selectors/DepositsSelector';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import moment from 'moment-timezone';
import DashboardUserInfoSections from './DashboardUserInfoSections';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { useResize } from '../../shared/utils/hooks/useResize';
import { WorkkiLogo } from '../../shared/ui/icons/core/workkiLogo';
import DashboardBookingList from './DashboardBookingList';
import { PrefillForm } from '../Profile/PrefillProfile/model/PrefillProfileComponent';

const mapStateToProps = (state, props) => {
  return {
    currency: currencySelector(state),
    office: state.ui.office,
    accountUI: state.ui.account,
    account: accountSelector(state),
    customer: customerSelector(state),
    subscriptions: serviceRequestWithWorkplacesSelector(state, props),
    meetingRooms: getServiceSchedule(state),
    serviceRequestCreateRequestStatus: requestStatusSelector(actions.requestServiceRequestCreate)(state),
    servicesRequestsLoadingStatus: requestStatusSelector(actions.requestServicesRequests)(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReservationRequest: () => dispatch(getActiveServiceRequests()),
    getBalance: () => dispatch(getAccount()),
    getBalancePrinter: () => dispatch(getBalancePrinter()),
    changeSubscription: (requestId, isChosenSubscribe) => {
      dispatch(changeSubscription(requestId, isChosenSubscribe));
    },
    getServiceIsActive: (active_from, active_to, service_id = null) => {
      dispatch(getServiceIsActive(active_from, active_to, service_id));
    },
    dispatchBuyNow: async (service_id, active_from, cnt, price_id, isSubscribed = null, note = null) => {
      dispatch(dispatchBuyNow(service_id, active_from, cnt, price_id, isSubscribed, note))
        .then(() => {
          dispatch(success('Продление аренды завершено успешно', { hideProgressBar: true }));
        })
        .catch(err => {
          // информация о сетевых ошибках и ошибках сервера выводится ранее
          if (err instanceof ErrorWithShowedMessage) {
            throw err;
          }

          let message = 'Ошибка продления аренды';
          if (
            err &&
            err.response &&
            err.response.data &&
            Array.isArray(err.response.data) &&
            err.response.data.length &&
            err.response.data[0].code
          ) {
            switch (err.response.data[0].code) {
              case 1:
                message = 'На Вашем счете недостаточно стредств';
                break;
              case 2:
                message = 'Кабинет занят другим резидентом';
                break;
              default:
            }
          }
          dispatch(error(message), { hideProgressBar: true });
          throw err;
        })
        .then(() => {
          dispatch(getAccount());
        })
        .catch(() => {});
    },
    getMeetingRoom: () => {
      dispatch(
        getSchedule(
          moment()
            .subtract(2, 'days')
            .format('YYYY-MM-DD')
        )
      );
    },
    getEmployeesServices: () => dispatch(getEmployeesServices())
  };
};

const Dashboard = ({
  customer,
  getServiceIsActive,
  subscriptions,
  getBalance,
  getReservationRequest,
  getBalancePrinter,
  getMeetingRoom,
  getEmployeesServices,
  accountUI,
  account,
  meetingRooms,
  dispatchBuyNow,
  // продление или отмена автопродления
  changeSubscription,

  serviceRequestCreateRequestStatus,
  servicesRequestsLoadingStatus,
  services,
  ...props
}) => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const handleChangeScreenState = () => {
    const isScreenLaptop = window.innerWidth >= screenRessolutions.laptop;
    if (isScreenLaptop !== isLaptop) setIsLaptop(isScreenLaptop);
  };
  useResize(handleChangeScreenState, 0);

  const preloadDashboardData = () => {
    getBalance();
    getReservationRequest();
    getMeetingRoom();
  };

  useEffect(() => {
    if (customer) {
      preloadDashboardData();
    }
  }, [customer]);

  useEffect(() => {
    if (!subscriptions) return;
    if (subscriptions.length > 0) {
      const activeFrom = moment()
        .add(4, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const activeTo = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss');
      getServiceIsActive(activeFrom, activeTo);
    }
  }, [subscriptions, getServiceIsActive]);

  return (
    <PrefillForm overflow='unset' alignItems='center'>
      {!isLaptop && <WorkkiLogo width={'136px'} height={'24px'} />}
      <DashboardUserInfoSections account={account} customer={customer} />
      {(subscriptions.length > 0 || meetingRooms.length > 0) && (
        <DashboardBookingList
          bookingPlacesList={subscriptions.filter(subscription => subscription.reservation_place.type_id !== 1)}
          bookingMeetingRoomsList={meetingRooms}
          changeSubscription={changeSubscription}
        />
      )}
    </PrefillForm>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

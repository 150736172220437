import React, { useRef, useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { TextField, InputAdornment } from '@mui/material';
import { colors } from '../constants/styleConstants';
import { TimeIcon } from '../icons/core/TimeIcon';
import Popper from '@mui/material/Popper';
import { GlobalStyles } from '@mui/material';

const GlobalOverrideStyles = () => (
  <GlobalStyles
    styles={{
      '.MuiMultiSectionDigitalClockSection-item': {
        '&:hover': {
          backgroundColor: `${colors.workkiRedLight} !important`,
          borderRadius: '4px'
        }
      },
      '.MuiMultiSectionDigitalClockSection-item.Mui-selected': {
        'backgroundColor': `${colors.workkiRedLine} !important`,
        'color': `${colors.workkiDarkMain} !important`,
        'borderRadius': '4px',
        '&:hover': {
          backgroundColor: `${colors.workkiRedLine} !important`
        }
      },
      '.MuiPickersLayout-actionBar': {
        display: 'none !important'
      }
    }}
  />
);

// Стилизация компонента TimePicker
const StyledTimePicker = styled(DesktopTimePicker)(({ withLabel }) => ({
  '& .MuiInputBase-root': {
    'backgroundColor': colors.workkiLightLight1,
    'borderRadius': 8,
    '&.Mui-focused': {
      backgroundColor: `${colors.workkiLightLight05} !important`
    },
    '&:hover': {
      backgroundColor: `${colors.workkiLightLight05} !important`
    },
    '&.MuiFilledInput-root': {
      'paddingRight': 0,
      'paddingLeft': '4px',
      'cursor': 'pointer',
      '&::before, &::after': {
        border: 'none'
      }
    }
  },
  '& .MuiIconButton-root': {
    padding: 0,
    margin: '1px 16px 0 0'
  },
  '& .MuiInputLabel-root': {
    'paddingLeft': 0,
    'color': colors.workkiDarkMiddle,
    '&.Mui-focused, &.Mui-error': {
      color: `${colors.workkiDarkMiddle} !important`
    }
  }
}));

const CustomPopper = styled(Popper)(({ yPosition, xPosition }) => ({
  'zIndex': 1300,
  'transition': 'none !important',
  'position': 'absolute',
  'inset': yPosition && xPosition ? `${yPosition}px auto auto ${xPosition}px !important` : '0px auto auto 0px',
  'transform': 'translateY(8px)',
  '& .MuiPaper-root': {
    transition: 'none !important'
  }
}));

const CustomTimePicker = ({ pickedTime, setPickedTime, customLabel, scrollPosition }) => {
  const textFieldRef = useRef(null); // ref для TextField
  const [anchorEl, setAnchorEl] = useState(null);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [isPopperOpen, setIsPopperOpen] = useState(false); // Состояние для управления видимостью поппера

  // Функция для обновления позиции TextField
  const updatePosition = useCallback(() => {
    if (textFieldRef.current) {
      const rect = textFieldRef.current.getBoundingClientRect();
      setYPosition(Math.round(rect.bottom)); // Учитываем текущий скролл
      setXPosition(Math.round(rect.left)); // Учитываем текущий скролл
    }
  }, []);

  useEffect(() => {
    setAnchorEl(textFieldRef.current);
    updatePosition();
  }, [updatePosition]);

  useEffect(() => {
    // Закрытие поппера при изменении scrollPosition и пересчет позиции
    if (isPopperOpen) {
      setIsPopperOpen(false);
      updatePosition();
      setIsPopperOpen(true);
    }
  }, [updatePosition, isPopperOpen, scrollPosition]);

  useEffect(() => {
    // Обработчик для обновления позиции при скролле и изменении размеров окна
    const handleUpdate = () => {
      updatePosition();
    };

    window.addEventListener('scroll', handleUpdate);
    window.addEventListener('resize', handleUpdate);

    return () => {
      window.removeEventListener('scroll', handleUpdate);
      window.removeEventListener('resize', handleUpdate);
    };
  }, [updatePosition]);

  return (
    <>
      <GlobalOverrideStyles />
      <StyledTimePicker
        value={pickedTime}
        onChange={setPickedTime}
        label={customLabel || 'Время'}
        minutesStep={5}
        ampm={false}
        slots={{
          openPickerIcon: props => <TimeIcon spacing={'0'} />,
          popper: props =>
            isPopperOpen && (
              <CustomPopper
                {...props}
                yPosition={yPosition}
                xPosition={xPosition}
                anchorEl={anchorEl} // Привязка к TextField
              />
            ),
          textField: props => (
            <TextField
              {...props}
              ref={textFieldRef}
              label='Время'
              variant='filled'
              InputProps={{
                ...props.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <span style={{ color: 'black' }}></span>
                  </InputAdornment>
                )
              }}
              onClick={() => setIsPopperOpen(true)} // Открываем поппер при клике
            />
          )
        }}
      />
    </>
  );
};

export default CustomTimePicker;

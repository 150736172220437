import { WorkplaceType, AreaType } from '../types';

export const KOMSOMOLSKAYA_FOK_FLOOR_1_AREAS = [
  {
    uuid: 'area-110',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '110',
    svgPath: 'M20 20H300V200H260V205H230V200H60V110H20V20Z',
    workplaces: [
      { uuid: 'work-110-1', name: 'work-110', position: { x: 70, y: 40, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-1.1', name: 'work-110', position: { x: 20, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-1.2', name: 'work-110', position: { x: 20, y: 70, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-2', name: 'work-110', position: { x: 160, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-3', name: 'work-110', position: { x: 160, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-4', name: 'work-110', position: { x: 160, y: 100, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-5', name: 'work-110', position: { x: 200, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-6', name: 'work-110', position: { x: 200, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-7', name: 'work-110', position: { x: 200, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-8', name: 'work-110', position: { x: 260, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-9', name: 'work-110', position: { x: 260, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-10', name: 'work-110', position: { x: 260, y: 100, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-110-11', name: 'work-110', position: { x: 260, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-111',
    type: AreaType.CABINET,
    position: { x: 310, y: 20 },
    size: { width: 90, height: 185 },
    name: '111',
    svgPath: 'M310 20H400V200H370V205H340V200H310V20Z',
    workplaces: [
      { uuid: 'work-111-1', name: 'work-111-1', position: { x: 310, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-2', name: 'work-111-1', position: { x: 310, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-3', name: 'work-111-1', position: { x: 310, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-4', name: 'work-111-1', position: { x: 310, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-5', name: 'work-111-1', position: { x: 360, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-6', name: 'work-111-1', position: { x: 360, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-7', name: 'work-111-1', position: { x: 360, y: 100, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-111-8', name: 'work-111-1', position: { x: 360, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-112',
    type: AreaType.CABINET,
    position: { x: 410, y: 20 },
    size: { width: 90, height: 185 },
    name: '112',
    svgPath: 'M410 20H500V200H470V205H440V200H410V20Z',
    workplaces: [
      { uuid: 'work-112-1', name: 'work-112-1', position: { x: 410, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-2', name: 'work-112-1', position: { x: 410, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-3', name: 'work-112-1', position: { x: 410, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-4', name: 'work-112-1', position: { x: 410, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-5', name: 'work-112-1', position: { x: 460, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-6', name: 'work-112-1', position: { x: 460, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-7', name: 'work-112-1', position: { x: 460, y: 100, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-112-8', name: 'work-112-1', position: { x: 460, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-113',
    type: AreaType.CABINET,
    position: { x: 510, y: 20 },
    size: { width: 90, height: 185 },
    name: '113',
    svgPath: 'M510 20H560V130H600V200H570V205H540V200H510V20Z',
    workplaces: [
      { uuid: 'work-113-1', name: 'work-113-1', position: { x: 510, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-113-2', name: 'work-113-1', position: { x: 510, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-113-3', name: 'work-113-1', position: { x: 510, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-113-4', name: 'work-113-1', position: { x: 510, y: 140, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-113-8', name: 'work-113-1', position: { x: 560, y: 140, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-109',
    type: AreaType.CABINET,
    position: { x: 20, y: 220 },
    size: { width: 195, height: 90 },
    name: '109',
    svgPath: 'M20 220H215V250H170V300H160V310H20V220Z',
    workplaces: [
      { uuid: 'work-109-1', name: 'work-109-1', position: { x: 20, y: 220, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-109-2', name: 'work-109-1', position: { x: 60, y: 220, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-109-3', name: 'work-109-1', position: { x: 20, y: 270, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-109-4', name: 'work-109-1', position: { x: 60, y: 270, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-109-5', name: 'work-109-1', position: { x: 100, y: 270, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-105',
    type: AreaType.CABINET,
    position: { x: 20, y: 320 },
    size: { width: 195, height: 90 },
    name: '105',
    svgPath: 'M20 320H160V370H200V380H215V410H20V320Z',
    workplaces: [
      { uuid: 'work-105-1', name: 'work-105-1', position: { x: 20, y: 320, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-105-2', name: 'work-105-1', position: { x: 80, y: 320, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-105-3', name: 'work-105-1', position: { x: 120, y: 320, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-105-4', name: 'work-105-1', position: { x: 20, y: 370, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-105-5', name: 'work-105-1', position: { x: 60, y: 370, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-114',
    type: AreaType.CABINET,
    position: { x: 320, y: 265 },
    size: { width: 80, height: 135 },
    name: '114',
    svgPath: 'M320 400V267H330V265H360V267H400V400H320Z',
    workplaces: [
      { uuid: 'work-114-1', name: 'work-114-1', position: { x: 320, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-114-2', name: 'work-114-2', position: { x: 360, y: 310, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-114-3', name: 'work-114-3', position: { x: 360, y: 350, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  //Позже добавим преговорки
  // {
  //   uuid: 'area-M101',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 410, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: { title: 'M11', position: { x: 150, y: 150 } },
  //   svgPath: 'M420 267V265H450V267H500V400H410V267H420Z',
  //   workplaces: [{ uuid: 'work-m11-1', position: { x: 420, y: 280, angle: 0 }, type: WorkplaceType.MEETING }],
  // },
  // {
  //   uuid: 'area-M102',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 510, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: { title: 'M12', position: { x: 150, y: 150 } },
  //   svgPath: 'M520 267V265H550V267H570V270H600V400H510V267H520Z',
  //   workplaces: [{ uuid: 'work-m12-1', position: { x: 520, y: 280, angle: 0 }, type: WorkplaceType.MEETING }],
  // },
  {
    uuid: 'area-103',
    type: AreaType.CABINET,
    position: { x: 290, y: 525 },
    size: { width: 100, height: 75 },
    name: '103',
    svgPath: 'M290 600V530H300V525H330V530H390V600H290Z',
    workplaces: [
      { uuid: 'work-103-1', name: 'work-103-1', position: { x: 310, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-103-2', name: 'work-103-1', position: { x: 350, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-102',
    type: AreaType.CABINET,
    position: { x: 400, y: 525 },
    size: { width: 90, height: 75 },
    name: '102',
    svgPath: 'M400 600V530H410V525H440V530H490V600H400Z',
    workplaces: [
      { uuid: 'work-102-1', name: 'work-102-1', position: { x: 410, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-102-2', name: 'work-102-1', position: { x: 450, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-101',
    type: AreaType.CABINET,
    position: { x: 500, y: 525 },
    size: { width: 100, height: 75 },
    name: '101',
    svgPath: 'M500 600V530H560V525H590V530H600V600H500Z',
    workplaces: [
      { uuid: 'work-101-1', name: 'work-102-1', position: { x: 510, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-101-2', name: 'work-102-1', position: { x: 550, y: 560, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'OP-1',
    uuid: 'area-kom-op-sp',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    size: { width: 480, height: 380 },
    svgPath: 'M820 200H720V20H1200V400H820V200Z',
    workplaces: [
      //
      { uuid: 'op-7-6', name: 'К101', position: { x: 1080, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-7-5', name: 'К102', position: { x: 1080, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-7-4', name: 'К103', position: { x: 1080, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      {
        name: 'К104',
        uuid: 'op-7-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 250, angle: -90 }
      },
      {
        name: 'К105',
        uuid: 'op-7-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 290, angle: -90 }
      },
      {
        name: 'К106',
        uuid: 'op-7-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 330, angle: -90 }
      },
      //
      { uuid: 'op-6-6', name: 'К107', position: { x: 980, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-6-5', name: 'К108', position: { x: 980, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-6-4', name: 'К109', position: { x: 980, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      {
        name: 'К110',
        uuid: 'op-6-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 940, y: 250, angle: -90 }
      },
      {
        name: 'К111',
        uuid: 'op-6-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 940, y: 290, angle: -90 }
      },
      {
        name: 'К112',
        uuid: 'op-6-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 940, y: 330, angle: -90 }
      },
      //
      { uuid: 'op-5-6', name: 'К113', position: { x: 880, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-5-5', name: 'К114', position: { x: 880, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-5-4', name: 'К115', position: { x: 880, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      {
        name: 'К116',
        uuid: 'op-5-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 250, angle: -90 }
      },
      {
        name: 'К117',
        uuid: 'op-5-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 290, angle: -90 }
      },
      {
        name: 'К118',
        uuid: 'op-5-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 330, angle: -90 }
      },
      {
        name: 'К119',
        uuid: 'op-1-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 760, y: 100, angle: -90 }
      },
      {
        name: 'К120',
        uuid: 'op-1-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 760, y: 60, angle: -90 }
      },
      {
        name: 'К121',
        uuid: 'op-1-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 760, y: 20, angle: -90 }
      },
      { uuid: 'op-1-4', name: 'К122', position: { x: 800, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-1-5', name: 'К123', position: { x: 800, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-1-6', name: 'К124', position: { x: 800, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      //
      {
        name: 'К125',
        uuid: 'op-2-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 860, y: 100, angle: -90 }
      },
      {
        name: 'К126',
        uuid: 'op-2-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 860, y: 60, angle: -90 }
      },
      {
        name: 'К127',
        uuid: 'op-2-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 860, y: 20, angle: -90 }
      },
      { uuid: 'op-2-4', name: 'К128', position: { x: 900, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-2-5', name: 'К129', position: { x: 900, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-2-6', name: 'К130', position: { x: 900, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      //
      {
        name: 'К131',
        uuid: 'op-3-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 960, y: 100, angle: -90 }
      },
      {
        name: 'К132',
        uuid: 'op-3-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 960, y: 60, angle: -90 }
      },
      {
        name: 'К133',
        uuid: 'op-3-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 960, y: 20, angle: -90 }
      },
      { uuid: 'op-3-4', name: 'К134', position: { x: 1000, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-3-5', name: 'К135', position: { x: 1000, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-3-6', name: 'К136', position: { x: 1000, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT },
      //
      {
        name: 'К137',
        uuid: 'op-4-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1060, y: 100, angle: -90 }
      },
      {
        name: 'К138',
        uuid: 'op-4-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1060, y: 60, angle: -90 }
      },
      {
        name: 'К139',
        uuid: 'op-4-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1060, y: 20, angle: -90 }
      },
      { uuid: 'op-4-4', name: 'К140', position: { x: 1100, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-4-5', name: 'К141', position: { x: 1100, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-4-6', name: 'К142', position: { x: 1100, y: 100, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  }
];
export const KOMSOMOLSKAYA_FOK_FLOOR_2_AREAS = [
  {
    uuid: 'area-201',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '201',
    svgPath: 'M600 720V620H760V640H770V670H760V700H740V720H600Z',
    workplaces: [
      { uuid: 'work-201-1', name: 'work-201-1', position: { x: 610, y: 620, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-201-2', name: 'work-201-2', position: { x: 650, y: 620, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-201-3', name: 'work-201-3', position: { x: 690, y: 620, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-201-4', name: 'work-201-4', position: { x: 610, y: 680, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-201-5', name: 'work-201-5', position: { x: 650, y: 680, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-201-6', name: 'work-201-6', position: { x: 690, y: 680, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-202',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '202',
    svgPath: 'M520 453V320H610V400H600V420H610V450H600V455H570V453H520Z',
    workplaces: [
      { uuid: 'work-202-1', name: 'work-202-1', position: { x: 520, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-202-2', name: 'work-202-2', position: { x: 520, y: 370, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-202-3', name: 'work-202-3', position: { x: 520, y: 410, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-202-4', name: 'work-202-4', position: { x: 560, y: 320, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-202-5', name: 'work-202-5', position: { x: 570, y: 360, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-203',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '203',
    svgPath: 'M520 600V507H545V505H575V507H600V510H620V600H520Z',
    workplaces: [
      { uuid: 'work-203-1', name: 'work-203-1', position: { x: 520, y: 520, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-203-2', name: 'work-203-2', position: { x: 520, y: 560, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-203-3', name: 'work-203-3', position: { x: 580, y: 520, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-203-4', name: 'work-203-4', position: { x: 580, y: 560, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-204',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '204',
    svgPath: 'M410 600V507H445V505H475V507H510V600H410Z',
    workplaces: [
      { uuid: 'work-204-1', name: 'work-204-1', position: { x: 410, y: 520, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-204-2', name: 'work-204-2', position: { x: 410, y: 560, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-204-3', name: 'work-204-3', position: { x: 470, y: 520, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-204-4', name: 'work-204-4', position: { x: 470, y: 560, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-205',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '205',
    svgPath: 'M350 453V370H410V400H400V420H410V455H380V453H350Z',
    workplaces: [
      { uuid: 'work-205-1', name: 'work-205-1', position: { x: 350, y: 370, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-205-2', name: 'work-205-2', position: { x: 350, y: 410, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-206',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '206',
    svgPath: 'M300 600V507H335V505H365V507H400V600H300Z',
    workplaces: [
      { uuid: 'work-206-1', name: 'work-206-1', position: { x: 300, y: 520, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-206-2', name: 'work-206-2', position: { x: 300, y: 560, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-206-3', name: 'work-206-3', position: { x: 360, y: 520, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-206-4', name: 'work-206-4', position: { x: 360, y: 560, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-207',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '207',
    svgPath: 'M340 370H277V453H280V455H310V453H340V370Z',
    workplaces: [
      { uuid: 'work-207-1', name: 'work-207-1', position: { x: 280, y: 370, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-207-2', name: 'work-207-2', position: { x: 300, y: 410, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-208',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '208',
    svgPath: 'M20 410H200V440H215V470H200V500H20V410Z',
    workplaces: [
      { uuid: 'work-208-1', name: 'work-208-1', position: { x: 20, y: 410, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-2', name: 'work-208-2', position: { x: 60, y: 410, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-3', name: 'work-208-3', position: { x: 100, y: 410, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-4', name: 'work-208-4', position: { x: 20, y: 460, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-5', name: 'work-208-5', position: { x: 60, y: 460, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-6', name: 'work-208-6', position: { x: 100, y: 460, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-208-7', name: 'work-208-7', position: { x: 140, y: 460, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-209',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '209',
    svgPath: 'M20 400V330H160V370H215V400H20Z',
    workplaces: [
      { uuid: 'work-209-1', name: 'work-209-1', position: { x: 20, y: 330, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-209-2', name: 'work-209-2', position: { x: 60, y: 330, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-209-3', name: 'work-209-3', position: { x: 100, y: 330, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-210',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '210',
    svgPath: 'M20 220H215V250H170V300H160V320H20V220Z',
    workplaces: [
      { uuid: 'work-210-1', name: 'work-210-1', position: { x: 20, y: 220, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-210-2', name: 'work-210-2', position: { x: 60, y: 220, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-210-3', name: 'work-210-3', position: { x: 20, y: 280, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-210-4', name: 'work-210-4', position: { x: 60, y: 280, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-210-5', name: 'work-210-5', position: { x: 100, y: 280, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-211',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '211',
    svgPath: 'M20 130H160V150H165V180H160V200H20V130Z',
    workplaces: [
      { uuid: 'work-211-1', name: 'work-211-1', position: { x: 20, y: 130, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-211-2', name: 'work-211-2', position: { x: 60, y: 130, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-211-3', name: 'work-211-3', position: { x: 100, y: 130, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-212',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '212',
    svgPath: 'M20 120V20H200V125H170V120H20Z',
    workplaces: [
      { uuid: 'work-212-1', name: 'work-212-1', position: { x: 20, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-2', name: 'work-212-2', position: { x: 80, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-3', name: 'work-212-3', position: { x: 120, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-4', name: 'work-212-4', position: { x: 160, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-5', name: 'work-212-5', position: { x: 20, y: 80, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-6', name: 'work-212-6', position: { x: 60, y: 80, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-212-7', name: 'work-212-7', position: { x: 100, y: 80, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-213',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '213',
    svgPath: 'M210 123V20H300V123H270V125H240V123H210Z',
    workplaces: [
      { uuid: 'work-213-1', name: 'work-213-1', position: { x: 210, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-213-2', name: 'work-213-2', position: { x: 210, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-213-3', name: 'work-213-3', position: { x: 260, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-213-4', name: 'work-213-4', position: { x: 260, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-214',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '214',
    svgPath: 'M310 123V20H400V123H370V125H340V123H310Z',
    workplaces: [
      { uuid: 'work-214-1', name: 'work-214-1', position: { x: 310, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-214-2', name: 'work-214-2', position: { x: 310, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-214-3', name: 'work-214-3', position: { x: 360, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-214-4', name: 'work-214-4', position: { x: 360, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-215',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '215',
    svgPath: 'M277 167H360V165H390V167H410V190H390V220H410V270H277V167Z',
    workplaces: [
      { uuid: 'work-215-1', name: 'work-215-1', position: { x: 280, y: 170, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-215-2', name: 'work-215-2', position: { x: 280, y: 230, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-215-3', name: 'work-215-3', position: { x: 320, y: 230, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-215-4', name: 'work-215-4', position: { x: 360, y: 230, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-216',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '216',
    svgPath: 'M410 123V20H500V123H470V125H440V123H410Z',
    workplaces: [
      { uuid: 'work-216-1', name: 'work-216-1', position: { x: 410, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-216-2', name: 'work-216-2', position: { x: 410, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-216-3', name: 'work-216-3', position: { x: 460, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-216-4', name: 'work-216-4', position: { x: 460, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-217',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '217',
    svgPath: 'M420 310V167H450V165H480V167H510V310H420Z',
    workplaces: [
      { uuid: 'work-217-1', name: 'work-217-1', position: { x: 420, y: 190, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-217-2', name: 'work-217-2', position: { x: 420, y: 230, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-217-3', name: 'work-217-3', position: { x: 420, y: 270, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-217-4', name: 'work-217-4', position: { x: 470, y: 190, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-217-5', name: 'work-217-5', position: { x: 470, y: 230, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-217-6', name: 'work-217-6', position: { x: 470, y: 270, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-218',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '218',
    svgPath: 'M510 123V20H600V60H570V125H540V123H510Z',
    workplaces: [
      { uuid: 'work-218-1', name: 'work-218-1', position: { x: 510, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-218-2', name: 'work-218-2', position: { x: 510, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-219',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '219',
    svgPath: 'M520 310V167H550V165H580V167H610V310H520Z',
    workplaces: [
      { uuid: 'work-219-1', name: 'work-219-1', position: { x: 520, y: 190, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-219-2', name: 'work-219-2', position: { x: 520, y: 230, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-219-3', name: 'work-219-3', position: { x: 520, y: 270, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-219-4', name: 'work-219-4', position: { x: 570, y: 190, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-219-5', name: 'work-219-5', position: { x: 570, y: 230, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-219-6', name: 'work-219-6', position: { x: 570, y: 270, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-220',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '220',
    svgPath: 'M610 120V20H705V123H670V125H640V123H620V120H610Z',
    workplaces: [
      { uuid: 'work-220-1', name: 'work-220-1', position: { x: 610, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-220-2', name: 'work-220-2', position: { x: 610, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-220-3', name: 'work-220-3', position: { x: 665, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-220-4', name: 'work-220-4', position: { x: 665, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-221',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '221',
    svgPath: 'M715 123V20H810V120H775V125H745V123H715Z',
    workplaces: [
      { uuid: 'work-221-1', name: 'work-221-1', position: { x: 715, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-221-2', name: 'work-221-2', position: { x: 715, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-221-3', name: 'work-221-3', position: { x: 770, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-221-4', name: 'work-221-4', position: { x: 770, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-222',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '222',
    svgPath: 'M820 120V20H915V123H880V125H850V123H830V120H820Z',
    workplaces: [
      { uuid: 'work-222-1', name: 'work-222-1', position: { x: 820, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-222-2', name: 'work-222-2', position: { x: 820, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-222-3', name: 'work-222-3', position: { x: 875, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-222-4', name: 'work-222-4', position: { x: 875, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-223',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '223',
    svgPath: 'M925 123V20H1020V123H985V125H955V123H925Z',
    workplaces: [
      { uuid: 'work-223-1', name: 'work-223-1', position: { x: 925, y: 20, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-223-2', name: 'work-223-2', position: { x: 925, y: 60, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-223-3', name: 'work-223-3', position: { x: 980, y: 20, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-223-4', name: 'work-223-4', position: { x: 980, y: 60, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-224',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '224',
    svgPath: 'M1030 125V20H1200V120H1110V123H1060V125H1030Z',
    workplaces: [
      { uuid: 'work-224-1', name: 'work-224-1', position: { x: 1040, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-224-2', name: 'work-224-2', position: { x: 1080, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-224-3', name: 'work-224-3', position: { x: 1120, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-224-4', name: 'work-224-4', position: { x: 1160, y: 20, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-224-5', name: 'work-224-5', position: { x: 1120, y: 80, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-224-6', name: 'work-224-6', position: { x: 1160, y: 80, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-225',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '225',
    svgPath: 'M1117 160V130H1200V220H1117V190H1115V160H1117Z',
    workplaces: [
      {
        uuid: 'work-225-1',
        name: 'work-225-1',
        position: { x: 1160, y: 130, angle: 180 },
        type: WorkplaceType.DEFAULT
      },
      { uuid: 'work-225-3', name: 'work-225-3', position: { x: 1160, y: 180, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-226',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '226',
    svgPath: 'M1117 260V230H1200V320H1117V290H1115V260H1117Z',
    workplaces: [
      {
        uuid: 'work-226-1',
        name: 'work-226-1',
        position: { x: 1160, y: 230, angle: 180 },
        type: WorkplaceType.DEFAULT
      },
      { uuid: 'work-226-3', name: 'work-226-3', position: { x: 1160, y: 280, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-227',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '227',
    svgPath: 'M1117 360V330H1200V420H1117V390H1115V360H1117Z',
    workplaces: [
      {
        uuid: 'work-227-1',
        name: 'work-227-1',
        position: { x: 1160, y: 330, angle: 180 },
        type: WorkplaceType.DEFAULT
      },
      { uuid: 'work-227-3', name: 'work-227-3', position: { x: 1160, y: 380, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  //Позже добавим преговорки
  // {
  //   uuid: 'area-М221',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 410, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: "М221",
  //   svgPath: 'M277 280H410V360H277V335H275V305H277V280Z',
  //   workplaces: [],
  // },
  // {
  //   uuid: 'area-M204',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 510, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: 'M204',
  //   svgPath: 'M420 453V320H510V455H480V453H420Z',
  //   workplaces: [],
  // },
  {
    uuid: 'area-kom-op-sp-2',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    size: { width: 480, height: 380 },
    name: 'OP-2',
    svgPath: 'M820 420V200H1000V420H820Z',
    workplaces: [
      {
        name: 'К201',
        uuid: 'op-3-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 850, y: 370, angle: 0 }
      },
      {
        name: 'К202',
        uuid: 'op-3-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 370, angle: 0 }
      },
      {
        name: 'К203',
        uuid: 'op-3-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 370, angle: 0 }
      },
      //
      { uuid: 'op-2-5', name: 'К204', position: { x: 830, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-2-6', name: 'К205', position: { x: 870, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-2-7', name: 'К206', position: { x: 910, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-2-8', name: 'К207', position: { x: 950, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      //
      {
        name: 'К208',
        uuid: 'op-2-4',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 950, y: 270, angle: 0 }
      },
      {
        name: 'К209',
        uuid: 'op-2-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 910, y: 270, angle: 0 }
      },
      {
        name: 'К210',
        uuid: 'op-2-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 870, y: 270, angle: 0 }
      },
      {
        name: 'К211',
        uuid: 'op-2-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 830, y: 270, angle: 0 }
      },
      //
      { uuid: 'op-1-1', name: 'К212', position: { x: 870, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-1-2', name: 'К213', position: { x: 910, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'op-1-3', name: 'К214', position: { x: 950, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  }
];

export const KOMSOMOLSKAYA_FOK_FLOOR_0_AREAS = [
  {
    uuid: 'fok-0-catering',
    type: AreaType.CATERING,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'fok-0-catering',
    svgPath: 'M400 210H350V350H345V380H350V410H400V380H420V410H600V350H610V300H600V210H420V220H400V210Z',
    workplaces: []
  },
  {
    uuid: 'fok-0-conference_room',
    type: AreaType.CATERING,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'fok-0-conference_room',
    svgPath:
      'M1060 10V20H720V440H730V445H780V440H870V470H865V500H870V580H1140V610H1190V600H1200V550H1210V520H1200V410H1110V200H1115V170H1110V10H1060Z',
    workplaces: []
  }
];

import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styleConstants';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || colors.workkiDarkSecondary};
    :hover {
      ${props => props.hoverColor || colors.workkiDarkSecondary};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
  transform: rotate(${props => props.rotation || '0deg'});
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '')};
`;
export const ArrowBack = ({
  defaultColor,
  spacing,
  hoverColor,
  rotation,
  bigArrow,
  minWidth,
  width,
  height,
  backgroundColor,
  borderRadius,
  ...props
}) => {
  return (
    <StyledSvg
      width={width ? width : '28'}
      height={height ? height : '35'}
      viewBox='0 0 28 35'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
      rotation={rotation}
      minWidth={minWidth}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      {...props}
    >
      {bigArrow ? (
        <path d='M4.00024 16C4.00024 15.7304 4.10693 15.4857 4.28038 15.3059C4.28459 15.3015 4.28884 15.2972 4.29314 15.2929L9.29314 10.2929C9.68366 9.90237 10.3168 9.90237 10.7074 10.2929C11.0979 10.6834 11.0979 11.3166 10.7074 11.7071L7.41446 15L27.0002 15C27.5525 15 28.0002 15.4477 28.0002 16C28.0002 16.5523 27.5525 17 27.0002 17L7.41446 17L10.7074 20.2929C11.0979 20.6834 11.0979 21.3166 10.7074 21.7071C10.3168 22.0976 9.68366 22.0976 9.29314 21.7071L4.29413 16.7081C4.1066 16.5206 4.00024 16.2652 4.00024 16Z' />
      ) : (
        <path d='M3.50024 18.3333C3.50024 18.0974 3.59359 17.8833 3.74536 17.7259C3.74905 17.7221 3.75277 17.7183 3.75653 17.7145L8.13153 13.3395C8.47323 12.9978 9.02725 12.9978 9.36896 13.3395C9.71067 13.6812 9.71067 14.2353 9.36896 14.577L6.48768 17.4583L23.6252 17.4583C24.1085 17.4583 24.5002 17.85 24.5002 18.3333C24.5002 18.8165 24.1085 19.2083 23.6252 19.2083L6.48768 19.2083L9.36896 22.0895C9.71067 22.4312 9.71067 22.9853 9.36896 23.327C9.02725 23.6687 8.47323 23.6687 8.13153 23.327L3.7574 18.9528C3.5933 18.7887 3.50024 18.5653 3.50024 18.3333Z' />
      )}
    </StyledSvg>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions';

const useModalState = () => {
  const isModalWindowOpen = useSelector(state => state.isModalOpen);
  const dispatch = useDispatch();

  const closeModalWindow = () => dispatch(actions.closeModal());
  const openModalWindow = () => dispatch(actions.openModal());

  return [isModalWindowOpen, openModalWindow, closeModalWindow];
};

export default useModalState;

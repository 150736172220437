import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

const colors = {
  workkiDarkMain: '#24272a',
  workkiDarkFill: '#383c40',
  workkiDarkSecondary: '#575b60',
  workkiDarkMiddle: '#8f9295',
  workkiLightLine: '#c8c9ca',
  workkiLightLight2: '#e9eaea',
  workkiLightLight1: '#f4f4f4',
  workkiLightLight05: '#fafafa',
  workkiLightWhite: '#fff',
  workkiRedMain: '#ba393a',
  workkiRedFill: '#db4344',
  workkiRedLine: '#f1b4b4',
  workkiRedLight: '#fdf6f6',
  workkiGreenMain: '#008d64',
  workkiGreenFill: '#00a676',
  workkiGreenLine: '#99dbc8',
  workkiGreenLight: '#f2fbf8',
  workkiBlueMain: '#005f7a',
  workkiBlueFill: '#00708f',
  workkiBlueLine: '#00708f',
  workkiBlueLight: '#f2f8f9'
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  'width': '16px',
  'height': '16px',
  'borderRadius': '4px',
  'marginRight': '6px',
  'border': 'none',
  'boxShadow': '0 1px 3px 0 #00000026 inset',
  'color': 'transparent',
  'backgroundColor': colors.workkiLightLight1,
  '& > svg': {
    width: '22px',
    height: '22px',
    borderRadius: '4px'
  },
  '&:hover': {
    backgroundColor: colors.workkiLightLight2
  },
  '&.Mui-checked, &.MuiCheckbox-indeterminate': {
    color: colors.workkiDarkFill,
    borderRadius: '4px'
  },
  '&.Mui-checked:hover': {
    color: colors.workkiDarkMain,
    borderRadius: '4px'
  }
}));

export const CheckboxComponent = ({ checked, onChange, name }) => {
  return <StyledCheckbox checked={checked} onChange={onChange} name={name} checkedIcon={<CheckBoxRoundedIcon />} />;
};

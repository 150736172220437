import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class ServiceFileType extends Model {
  static reducer(action, serviceFileType) {
    switch (action.type) {
      case actions.requestServiceFileTypeSuccess.toString():
        action.payload.map(elem => serviceFileType.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `ServiceFileType: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
ServiceFileType.modelName = 'ServiceFileType';

// Declare your related fields.
ServiceFileType.fields = {
  id: attr() // non-relational field for any value; optional but highly recommended
  //  name: attr(),
};

export default ServiceFileType;

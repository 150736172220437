import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

const BookingItemActionsDialog = ({
  getGuestPass,
  getParkingPass,
  getCancelBooking,
  handleClose,
  infoType,
  handleChangeInfoTypeState,
  ...props
}) => {
  const getTitle = () => {
    if (infoType === 'dashboardActions') return 'Дополнительные действия';
    if (infoType === 'bookingCancel') return 'Отмена бронирования';
  };

  const getDialogData = () => {
    if (infoType === 'bookingCancel') {
      return ['Отменить активное бронирование можно только через менеджера Workki.', 'Отправить заявку на отмену?'];
    }
  };

  const getRequestSentDescription = () => {
    if (infoType === 'confirmed') {
      return [
        'Заявка\u00A0на\u00A0отмену бронирования отправлена менеджеру Workki.',
        'После согласования средства поступят на\u00A0баланс личного кабинета.'
      ];
    }
  };

  const getRequestSentMessage = () => (infoType === 'confirmed' ? 'Заявка отправлена' : '');

  return (
    <Dialog
      title={getTitle()}
      dialogType={infoType}
      handleClose={handleClose}
      handleChangeInfoTypeState={handleChangeInfoTypeState}
      dialogData={getDialogData()}
      requestSentMessage={getRequestSentMessage()}
      requestSentDescription={getRequestSentDescription()}
      {...props}
    />
  );
};

export default BookingItemActionsDialog;

import React, { Component } from 'react';
import moment from 'moment';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import { getSchedule } from '../../services/workki/ServicesActions';
import RoomReservation from '../../entities/RoomReservation';

class RoomReservationPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const dateFrom = moment()
      .subtract(2, 'days')
      .format('YYYY-MM-DD');
    dispatch(getSchedule(dateFrom));
  }

  render() {
    const { history } = this.props;
    return (
      <PrimaryLayout>
        <main>
          <RoomReservation history={history} />
        </main>
      </PrimaryLayout>
    );
  }
}

export default RoomReservationPage;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import produce from 'immer';
import isEqual from 'react-fast-compare';
import { roomReservationSelector } from '../../store/selectors/ServicesSelector';
import MeetingRoomInfo from './MeetingRoomInfo';
let block = false;

const mapStateToProps = (state, props) => {
  block = props.blockDay;
  return {
    auth: state.auth,
    rooms: roomReservationSelector(state, props)
  };
};

const book = 'book';
const busy = 'busy';
const selected = 'current';
const paid = 'paid';

class MeetingRoomsCardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTimeObjects: { allIds: [], byId: [] },
      rooms: { allIds: [], byId: {} },
      overflowOff: false,
      block: false,
      pickedRoom: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };

    // Обнуление минут и секунд у переданного времени
    const resetTime = time => {
      return time.clone().set({ minute: 0, second: 0, millisecond: 0 });
    };

    const getStatusMarker = (service, timeMarkerFrom, timeMarkerTo) => {
      // Обнуляем минуты и секунды у границ временного отрезка
      const fromTime = resetTime(timeMarkerFrom.dateTime);
      const toTime = resetTime(timeMarkerTo.dateTime);

      const reservation = service.reservations.find(
        reservation =>
          moment(reservation.start_at)
            .startOf('hour')
            .isSameOrBefore(fromTime) &&
          moment(reservation.finish_at)
            .endOf('hour')
            .isSameOrAfter(toTime)
      );

      if (reservation) {
        if (reservation.is_yours) {
          return paid;
        }
        return book;
      }
      if (block) {
        return busy;
      }
      if (toTime.isSameOrBefore(props.workingHoursStart) || toTime.isAfter(props.workingHoursEnd)) {
        return busy;
      }
      return null;
    };

    const initialMarkers = (dateTimeMarkers, service, key) => {
      const markers = { byId: {}, allIds: [] };
      dateTimeMarkers.allIds.forEach((item, i, arr) => {
        if (arr.length - 1 === i) {
          return;
        }
        const marker = {
          id: `${key}_${service.id}_${i}`,
          status: getStatusMarker(service, dateTimeMarkers.byId[item], dateTimeMarkers.byId[arr[i + 1]]),
          time_from: item,
          time_to: arr[i + 1],
          service_id: service.id
        };
        markers.allIds.push(marker.id);
        markers.byId[marker.id] = marker;
      });
      return markers;
    };

    const initialState = properties => {
      const initState = {};
      const countHours = properties.dateTo.diff(properties.dateFrom, 'hours');
      initState.dateFrom = moment(properties.dateFrom);
      initState.dateTo = moment(properties.dateTo);
      initState.workingHoursStart = properties.workingHoursStart;
      initState.workingHoursEnd = properties.workingHoursEnd;
      initState.dateTimeObjects = { byId: {}, allIds: [] };
      initState.rooms = { byId: {}, allIds: [] };
      initState.uniqueKey = properties.uniqueKey;
      initState.servicesProps = properties.rooms;
      initState.block = properties.blockDay;

      for (let count = 0; countHours >= count; count++) {
        const dateTime = resetTime(properties.dateFrom.clone().add(count, 'hours'));
        const timeMarker = {
          isSelected: false,
          isBold: false,
          dateTime,
          id: `${properties.uniqueKey}_${count}`
        };
        initState.dateTimeObjects.allIds.push(timeMarker.id);
        initState.dateTimeObjects.byId[timeMarker.id] = timeMarker;
      }

      properties.rooms.forEach(item => {
        initState.rooms.allIds.push(item.id);
        initState.rooms.byId[item.id] = {
          service: item,
          markers: initialMarkers(initState.dateTimeObjects, item, properties.uniqueKey)
        };
      });

      return initState;
    };

    if (
      !props.dateFrom.isSame(state.dateFrom) ||
      !props.dateTo.isSame(state.dateTo) ||
      !props.workingHoursEnd.isSame(state.workingHoursEnd) ||
      !props.workingHoursStart.isSame(state.workingHoursStart) ||
      props.uniqueKey !== state.uniqueKey ||
      !isEqual(state.servicesProps, props.rooms)
    ) {
      return initialState(props);
    }

    if (state.block !== props.blockDay) {
      return initialState(props);
    }

    return newState;
  }

  changeMarker = (marker, changeOject) => {
    const changeMarker = { ...marker, ...changeOject };
    this.setState(
      produce(newState => {
        newState.rooms.byId[marker.service_id].markers.byId[marker.id] = changeMarker;
      })
    );
  };

  resetSelectedAll = (andSubscriptionReset = false) => {
    this.setState(
      produce(newState => {
        newState.rooms.allIds.forEach(room_id => {
          newState.rooms.byId[room_id].markers.allIds.forEach(marker_id => {
            const marker = newState.rooms.byId[room_id].markers.byId[marker_id];
            if (marker.status === selected) {
              marker.status = null;
            }
          });
        });
      })
    );
    this.resetAllTimeMarkers();
    if (andSubscriptionReset) {
      this.subscriberReset();
    }
  };

  setSelectedReservation = (service_id = null) => {
    const { setSelectedReservation } = this.props;
    const { dateTimeObjects, rooms } = this.state;
    if (!service_id) {
      this.setState({ pickedRoom: null });
      setSelectedReservation(null, null, null);
      return;
    }
    const idsMarekrsNeedSelect = this.findListTimeMarkersIdsNeedSelecteByServiceId(service_id);
    if (idsMarekrsNeedSelect.length === 0) {
      return;
    }
    this.resetAllTimeMarkers();
    const first_id = idsMarekrsNeedSelect.shift();
    const last_id = idsMarekrsNeedSelect.pop();
    this.changeTimeMarker(first_id, { isSelected: true, isBold: true });
    this.changeTimeMarker(last_id, { isSelected: true, isBold: true });
    idsMarekrsNeedSelect.forEach(id => this.changeTimeMarker(id, { isSelected: true }));
    this.setState({ pickedRoom: rooms.byId[service_id].service });
    setSelectedReservation(
      dateTimeObjects.byId[first_id].dateTime,
      dateTimeObjects.byId[last_id].dateTime,
      rooms.byId[service_id].service
    );
  };

  resetAllTimeMarkers = () => {
    this.setState(
      produce(newState => {
        newState.dateTimeObjects.allIds.forEach(marker_id => {
          const marker = newState.dateTimeObjects.byId[marker_id];
          newState.dateTimeObjects.byId[marker_id] = {
            ...marker,
            ...{ isBold: false, isSelected: false }
          };
        });
      })
    );
  };

  changeTimeMarker(markerId, changeObject) {
    this.setState(
      produce(newState => {
        const dateTimeMarker = newState.dateTimeObjects.byId[markerId];
        newState.dateTimeObjects.byId[markerId] = { ...dateTimeMarker, ...changeObject };
      })
    );
  }

  findListTimeMarkersIdsNeedSelecteByServiceId(serviceId) {
    const idsMarekrsNeedSelect = [];
    const { rooms } = this.state;
    rooms.byId[serviceId].markers.allIds.forEach(marker_id => {
      const marker = rooms.byId[serviceId].markers.byId[marker_id];
      if (marker.status === selected) {
        idsMarekrsNeedSelect[marker.time_from] = marker.time_from;
        idsMarekrsNeedSelect[marker.time_to] = marker.time_to;
      }
    });
    return Object.keys(idsMarekrsNeedSelect);
  }

  subscriberReset() {
    const { outerResetCalendar, uniqueKey } = this.props;
    if (typeof outerResetCalendar === 'function') {
      outerResetCalendar(uniqueKey);
    }
  }

  setOverflowStatus = () => {
    this.setState({
      overflowOff: !this.state.overflowOff
    });
  };

  render() {
    const { rooms, dateTimeObjects } = this.state;
    const { rooms: roomsProps, name, handleSlideChange, location } = this.props;
    if (roomsProps.length === 0) {
      return null;
    }
    return (
      <div>
        {rooms.allIds.map(id => (
          <MeetingRoomInfo
            key={id}
            pickedRoom={this.state.pickedRoom}
            roomId={rooms.byId[id].service.id}
            roomName={rooms.byId[id].service.name}
            description={rooms.byId[id].service.description}
            markers={rooms.byId[id].markers}
            changeMarker={this.changeMarker}
            resetSelectedAll={this.resetSelectedAll}
            setSelectedReservation={this.setSelectedReservation}
            setOverflowStatus={this.setOverflowStatus}
            location={location}
            account={this.props.account}
            //чипсы
            chipData={this.props.chipData}
            setChipData={this.props.setChipData}
            setPickedDate={this.props.setPickedDate}
            pickedDate={this.props.pickedDate}
            dateTime={this.props.dateTime}
            rooms={rooms}
            dateTimeObjects={dateTimeObjects}
            //Пропсы для слайдера
            reserveRoom={this.props.reserveRoom}
            locationFreeHours={this.props.locationFreeHours}
            selectedService={this.props.selectedService}
            handleSlideChange={handleSlideChange}
            isSelected={this.props.isSelected}
            cardPrice={this.props.cardPrice}
            getError={this.props.getError}
            dateFrom={this.state.dateFrom}
            selectedServiceFreeHours={this.props.selectedServiceFreeHours}
            getCurrentPrice={this.props.getCurrentPrice}
            reserveRoomUI={this.props.reserveRoomUI}
            openDialog={this.props.openDialog}
            setOpenDialog={this.props.setOpenDialog}
          />
        ))}
      </div>
    );
  }
}

MeetingRoomsCardContent.propTypes = {
  name: PropTypes.string.isRequired,
  uniqueKey: PropTypes.string.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  setSelectedReservation: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  blockDay: PropTypes.bool.isRequired
};

const HoursPickerTableContainer = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(MeetingRoomsCardContent);

export default HoursPickerTableContainer;

import React from 'react';
import { Chip, ListItem, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, screenRessolutions } from '../../ui/constants/styleConstants';

export const StyledChip = styled(Chip)(({ isChecked }) => ({
  'backgroundColor': isChecked ? colors.workkiDarkFill : 'white',
  'color': isChecked ? 'white' : colors.workkiDarkMain,
  'border': `1px ${colors.workkiLightLine} solid`,
  'padding': '4px 10px 6px 10px',
  'height': '32px',
  'borderRadius': '16px',
  'fontSize': '14px',
  'fontWeight': '400',
  'margin': '8px 8px 0 0',
  '& > span': {
    padding: 0
  },
  '&:hover': {
    backgroundColor: !isChecked ? `${colors.workkiLightLine} !important` : `${colors.workkiDarkMain} !important`
  }
}));
export const ChipList = styled(`div`)(({ type }) => ({
  display: 'flex',
  padding: '0',
  flexDirection: 'row',
  flexWrap: type === 'time' ? 'wrap' : 'no-wrap',
  [`@media (min-width: ${screenRessolutions.smallMobile})`]: {
    width: type === 'time' ? '100%' : '235px'
  },
  [`@media (min-width: ${screenRessolutions.laptop})`]: {
    width: type === 'time' ? '674px' : '235px'
  },
  [`@media (min-width: ${screenRessolutions.desktop})`]: {
    width: type === 'time' ? '828px' : '235px'
  }
}));

const CustomChip = ({ inputRef, required, disabled, checked, ref, chipData, setChipData, ...props }) => {
  const handleClickChip = key => {
    const updatedChipData = chipData.map(chip =>
      chip.key === key ? { ...chip, checked: true } : { ...chip, checked: false }
    );
    setChipData(updatedChipData);
  };

  return (
    <ChipList type={'default'}>
      {chipData.map(data => (
        <ListItem key={data.key} disableGutters sx={{ padding: 0 }}>
          <StyledChip
            variant='outlined'
            label={data.label}
            isChecked={data.checked}
            onClick={() => handleClickChip(data.key)}
          />
        </ListItem>
      ))}
    </ChipList>
  );
};

export default CustomChip;

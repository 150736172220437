import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const Warning = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path d='M12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V9C11.25 8.58579 11.5858 8.25 12 8.25Z' />
      <path d='M12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75C12.75 15.3358 12.4142 15 12 15C11.5858 15 11.25 15.3358 11.25 15.75C11.25 16.1642 11.5858 16.5 12 16.5Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0518 4.49773C10.918 2.99856 13.082 2.99856 13.9482 4.49773L21.0992 16.8744C21.9659 18.3744 20.8834 20.25 19.151 20.25H4.84906C3.11669 20.25 2.0342 18.3744 2.90087 16.8744L10.0518 4.49773ZM12.6494 5.24814C12.3607 4.74842 11.6394 4.74842 11.3506 5.24814L4.19966 17.6248C3.91077 18.1248 4.2716 18.75 4.84906 18.75H19.151C19.7284 18.75 20.0893 18.1248 19.8004 17.6248L12.6494 5.24814Z'
      />
    </StyledSvg>
  );
};

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PrimaryLayout from '../layouts/PrimaryLayout';
import 'svgxuse';
import { getCustomerCurrent } from '../services/workki/CustomerActions';
import { Loader } from '../shared/ui/components/Loader';
import NamePage from './NamePage';

const ProfilePage = ({ Customer, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerCurrent());
  }, [dispatch]);

  if (!Customer[0]) {
    return null;
  }

  return (
    <PrimaryLayout>
      <main className='main'>{isLoading ? <Loader wrapperType='transparent' /> : <NamePage />}</main>
    </PrimaryLayout>
  );
};

export default ProfilePage;

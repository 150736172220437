import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import BalancePanel from './BalancePanel';
import HistoryPayments from './HistoryPayments';
import {
  getDeposits,
  getDepositBanks,
  createDeposit as createDepositAction
} from '../../../entities/Payments/api/DepositsActions';
import { depositsSelector } from '../../../store/selectors/DepositsSelector';
import { accountSelector, customerSelector } from '../../../store/selectors/DefaultSelectors';
import { resetDeposits } from '../../../store/actions';
import * as actions from '../../../store/actions';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';

const FillPayment = ({ history, checkoutDifference }) => {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const deposits = useSelector(depositsSelector);
  const customer = useSelector(customerSelector);

  const currentPage = useSelector(state => state.pagination.deposits.currentPage);
  const pageCount = useSelector(state => state.pagination.deposits.pageCount);

  const canLoadMore = useMemo(() => !isNil(currentPage) && !isNil(currentPage) && currentPage < pageCount, [
    currentPage,
    pageCount
  ]);

  const requestDepositsStatus = useSelector(requestStatusSelector(actions.requestDeposits));

  const loadFirstPage = useCallback(() => {
    dispatch(resetDeposits());
    dispatch(getDepositBanks());
    dispatch(getDeposits(1));
  }, [dispatch]);

  const loadNextPage = useCallback(() => canLoadMore && dispatch(getDeposits(currentPage + 1)), [
    dispatch,
    canLoadMore,
    currentPage
  ]);

  useEffect(() => loadFirstPage(), [loadFirstPage]);

  const createDeposit = useCallback(
    payment => {
      dispatch(createDepositAction(payment));
    },
    [dispatch]
  );

  return (
    <>
      <BalancePanel
        createDeposit={createDeposit}
        account={account}
        customer={customer}
        history={history}
        checkoutDifference={checkoutDifference}
      />
      <HistoryPayments
        account={account}
        payments={deposits}
        isLoading={requestDepositsStatus.isPending}
        onError={requestDepositsStatus.isFailure}
        canLoadMore={canLoadMore}
        loadNextPage={loadNextPage}
        loadFirstPage={loadFirstPage}
        customer={customer}
      />
    </>
  );
};

export default FillPayment;

import styled from 'styled-components';
import { screenRessolutions } from '../../../shared/ui/constants/styleConstants';

export const ProfileSectionTopBlock = styled.div`
  display: flex;
  margin-top: 16px;
  font-weight: 500;
  padding: 14px 0;
  width: 100%;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    font-size: 22px;
    margin: 0;
    h1 {
      margin: 0 !important;
      font-weight: 500;
    }
  }
  @media (min-width: ${screenRessolutions.mobile}px) {
    font-size: 22px;
    justify-content: space-between;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    font-size: 32px;
    width: 100%;
    h1 {
      margin: 0;
      font-weight: 500;
    }
    cursor: pointer;
  }
`;

export const ProfileSectionTitleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Section = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
`;

export const SectionText = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 268px;
  box-sizing: border-box;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #24272a;
    width: 100%;
    max-width: 268px;
    overflow-wrap: anywhere;
  }

  & > p {
    margin: 2px 0 0 0;
    padding: 0;
    display: flex;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    line-height: 16px;
    font-family: Commissioner, sans-serif;
    width: 240px;
    max-width: 268px;
    overflow-wrap: anywhere;
  }
`;

export const SectionImg = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f4f4f4;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
`;

export const cardPaddings = '12px 12px 14px 16px';

export const arrowSize = {
  width: '15px',
  height: '7.5px'
};

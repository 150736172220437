import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class Day extends Model {
  static reducer(action, Day) {
    switch (action.type) {
      case actions.requestDaySuccess.toString():
        action.payload.map(elem => Day.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `Day: ${this.date}`;
  }
  // Declare any static or instance methods you need.
}
Day.modelName = 'Day';

// Declare your related fields.
Day.fields = {
  // date: attr(), // non-relational field for any value; optional but highly recommended
  // status: attr() // non-relational field for any value; optional but highly recommended
  // status_id:
};

export default Day;

import { attr, Model, fk } from 'redux-orm';
import * as actions from '../actions';

class Ticket extends Model {
  static reducer(action, Ticket) {
    switch (action.type) {
      case actions.requestTickets.reset.toString():
        Ticket.all().delete();
        break;
      case actions.requestTickets.success.toString():
        action.payload.forEach(t => {
          Ticket.upsert(t);
        });
        break;
      case actions.requestTicket.success.toString():
        Ticket.upsert(action.payload);
        break;
      case actions.requestTicketInfo.success.toString():
        Ticket.withId(action.payload.id).update(action.payload);
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Service: ${this.name}`;
  }
}

Ticket.modelName = 'Ticket';

Ticket.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  category_id: fk('TicketCategory', 'ticket_to_ticket_category'),
  service_id: fk('Service', 'ticket_to_ticket_place')
};

export default Ticket;

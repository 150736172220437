import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors, fontFamilyBase } from '../constants/styleConstants';
import { DecreaseIcon } from '../icons/core/DecreaseIcon';
import { IncreaseIcon } from '../icons/core/IncreaseIcon';

const CounterBlock = styled.div`
  background-color: ${colors.workkiLightLight1};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 172px;
  height: fit-content;
  & > p {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
  }
`;
const CounterControl = styled.div`
  justify-content: flex-start;
  padding: 10px 14px;
`;
const CounterLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > p {
    margin: 0;
    padding: 0;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.workkiDarkMain};
  }

  & > span {
    margin: 0;
    padding: 0;
    font-family: ${fontFamilyBase};
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.workkiDarkMiddle};
  }
`;
const Counter = ({ type, span, count, handleDecrement, handleIncrement }) => {
  const renderPostfix = () => {
    const content = {
      month: () => {
        if (count % 100 >= 11 && count % 100 <= 14) {
          return 'месяцев';
        }
        if (count % 10 === 1) {
          return 'месяц';
        }
        if (count % 10 >= 2 && count % 10 <= 4) {
          return 'месяца';
        }
        return 'месяцев';
      },
      day: () => {
        if (count % 100 >= 11 && count % 100 <= 14) {
          return 'дней';
        }
        if (count % 10 === 1) {
          return 'день';
        }
        if (count % 10 >= 2 && count % 10 <= 4) {
          return 'дня';
        }
        return 'дней';
      },
      print: () => {
        return 'шт';
      }
    };

    return type ? content[type]() : 'шт.';
  };

  return type !== 'test_day' || !type ? (
    <CounterBlock>
      <CounterControl onClick={handleDecrement}>
        <DecreaseIcon spacing={'0'} />
      </CounterControl>
      {count && (
        <CounterLabel>
          <p>
            {count} {renderPostfix()}
          </p>
          <span>{span}</span>
        </CounterLabel>
      )}
      <CounterControl onClick={handleIncrement}>
        <IncreaseIcon spacing={'0'} />
      </CounterControl>
    </CounterBlock>
  ) : null;
};

export default Counter;

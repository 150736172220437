import React, { useState } from 'react';
import styled from 'styled-components';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { screenRessolutions } from '../shared/ui/constants/styleConstants';
import CardShell from '../shared/ui/components/Profile/CardShell';
import { SliderArrow } from '../shared/ui/icons/core/SliderArror';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useResize } from '../shared/utils/hooks/useResize';
import { PrefillForm } from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';

const Title = styled.h2`
  margin: -1px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Commissioner, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #24272a;

  @media (min-width: ${screenRessolutions.tablet}px) {
    margin: 2px 0 0 0;
  }
`;

const SectionsBox = styled.div`
  margin: 8px 0 0 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Sections = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'topLeft topRight'
    'footer footer';
  justify-content: center;
  box-sizing: border-box;
  gap: 8px 8px;
  width: 100%;
  max-width: 340px;
  list-style-type: none;
`;

const Section = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 16px;
`;

const SectionText = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 268px;
  box-sizing: border-box;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #24272a;
    width: 100%;
    max-width: 268px;
    overflow-wrap: anywhere;
  }

  & > p {
    margin: 2px 0 0 0;
    padding: 0;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: Commissioner, sans-serif;
    color: #575b60;
    width: 100%;
    max-width: 268px;
    overflow-wrap: anywhere;
  }
`;

const SectionImg = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f4f4f4;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
`;

const ServicesPage = () => {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);

  const history = useHistory();

  if (isLaptop) history.push('/passes');

  const arrowSize = {
    width: '15px',
    height: '7.5px'
  };

  const cardPaddings = '12px 12px 14px 16px';

  const checkWidth = () => {
    const isScreenLaptop = window.innerWidth >= screenRessolutions.laptop;
    if (isLaptop !== isScreenLaptop) setIsLaptop(isScreenLaptop);
  };

  useResize(checkWidth, 0);

  return (
    <PrimaryLayout>
      <main className='main'>
        <PrefillForm>
          <Title>Услуги</Title>
          <SectionsBox>
            <Sections>
              <CardShell onClick={() => history.push('/passes')} style={{ padding: cardPaddings, gridArea: 'topLeft' }}>
                <Section>
                  <SectionText>
                    <h4>Заявки на пропуск</h4>
                    <p>Пропуска, внос/вынос ТМЦ, въезд на авто</p>
                  </SectionText>
                  <SectionImg onClick={() => {}}>
                    <SliderArrow style={{ ...arrowSize }} />
                  </SectionImg>
                </Section>
              </CardShell>
              <CardShell onClick={() => history.push('/print')} style={{ padding: cardPaddings, gridArea: 'topRight' }}>
                <Section>
                  <SectionText>
                    <h4>Печать</h4>
                    <p>Печать документов на&nbsp;локациях Workki</p>
                  </SectionText>
                  <SectionImg onClick={() => history.push('/print')}>
                    <SliderArrow style={{ ...arrowSize }} />
                  </SectionImg>
                </Section>
              </CardShell>
              <CardShell onClick={() => history.push('/it')} style={{ padding: cardPaddings, gridArea: 'footer' }}>
                <Section style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <SectionText>
                    <h4>IT-услуги</h4>
                    <p>Проведение интернета и другие</p>
                  </SectionText>
                  <SectionImg onClick={() => history.push('/it')}>
                    <SliderArrow style={{ ...arrowSize }} />
                  </SectionImg>
                </Section>
              </CardShell>
            </Sections>
          </SectionsBox>
        </PrefillForm>
      </main>
    </PrimaryLayout>
  );
};

export default ServicesPage;

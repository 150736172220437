import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  margin-right: ${props => props.spacing || '16px'};
`;

export const MailIcon = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.50015 3.5C2.67173 3.5 2.00015 4.17157 2.00015 5V11C2.00015 11.8284 2.67173 12.5 3.50015 12.5H12.5002C13.3286 12.5 14.0002 11.8284 14.0002 11V5C14.0002 4.17157 13.3286 3.5 12.5002 3.5H3.50015ZM3.00015 10.7929L5.1466 8.64645C5.34186 8.45118 5.65844 8.45118 5.85371 8.64645C6.04897 8.84171 6.04897 9.15829 5.85371 9.35355L3.70726 11.5H12.293L10.1466 9.35355C9.95134 9.15829 9.95134 8.84171 10.1466 8.64645C10.3419 8.45118 10.6584 8.45118 10.8537 8.64645L13.0002 10.7929V5.20711L9.06081 9.14645C8.47503 9.73223 7.52528 9.73223 6.93949 9.14645L3.00015 5.20711V10.7929ZM12.293 4.5H3.70726L7.6466 8.43934C7.84186 8.6346 8.15844 8.6346 8.35371 8.43934L12.293 4.5Z'
        fill='#383C40'
      />
    </StyledSvg>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Moscow');

const TicketsList = ({ list }) =>
  list &&
  list.map(ticket => (
    <tr key={ticket.id} className='std-table__row'>
      <td className='std-table__cell'>
        <span className='std-table__limited-cell-content'>
          {ticket.place.name} ({ticket.office.t_name})
        </span>
      </td>
      <td className='std-table__cell'>
        <span className='std-table__limited-cell-content'>{ticket.service.name}</span>
      </td>
      <td className='std-table__cell std-table__cell--date'>
        {ticket.created_at && moment(ticket.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}
      </td>
      <td className='std-table__cell std-table__cell--date'>
        {ticket.start_at && moment(ticket.start_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}
      </td>
    </tr>
  ));

TicketsList.propTypes = {
  list: PropTypes.array.isRequired
};

export default TicketsList;

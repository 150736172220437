import { useRef, useEffect, useCallback, useMemo } from 'react';

/**
 * useEventListener - хук, позволяющий навешивать обработчики событий на элементы
 * @param {string} type Тип события, например: 'keydown'
 * @param {Function} listener Функция, которую мы хотим вызвать по выбранному событию
 * @param {RefObject|EventTarget} element Ref элемента или DOM элемент (по умолчанию window)
 * @param {Object} options Опции для addEventListener
 * @returns {void}
 */
export const useEventListener = ({ type, listener, element = window, options }) => {
  const savedListener = useRef(null);
  const getRefElement = element => {
    if (element && 'current' in element) {
      return element.current;
    }

    return element;
  };

  useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  const handleEventListener = useCallback(event => {
    if (savedListener.current) {
      savedListener.current(event);
    }
  }, []);

  useEffect(() => {
    const target = getRefElement(element) || window;
    target.addEventListener(type, handleEventListener, options);
    return () => target.removeEventListener(type, handleEventListener, options);
  }, [type, element, options, handleEventListener]);
};

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import PrintPackages from './PrintPackages';
import PrintHistory from '../PrintHistory';
import { getBalancePrinter } from '../../../services/workki/CustomerActions';
import { customerSelector, officeSelector } from '../../../store/selectors/DefaultSelectors';
import { getActiveServiceRequests } from '../../../services/workki/ServicesActions';
import { ButtonWrapper, GroupTitle, MainWrapper } from '../../../entities/Payments/model/Payments';
import { PrefillForm } from '../../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { ArrowBack } from '../../../shared/ui/icons/core/ArrowBack';
import { StepButton } from '../../../shared/ui/components/StepButton';
import PrintUserInfoSections from '../PrintUserInfoSections';
import { useResize } from '../../../shared/utils/hooks/useResize';
import styled from 'styled-components';
import { colors, screenRessolutions } from '../../../shared/ui/constants/styleConstants';
import { CustomSelectField } from '../../../shared/ui/components/Select';
import * as actions from '../../../store/actions';
import { PrintFileDialog } from '../../../widgets/Services/Print/PrintFileDialog';
import { PrintSuccessDialog } from '../../../widgets/Services/Print/PrintSuccessDialog';
const TitleBox = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    align-items: center;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin-top: 8px;
  }
`;

const TitleDescription = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-end;
  font-family: Commissioner, sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 14px;
  color: ${colors.workkiDarkSecondary};

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    display: none;
  }
`;
const SelectWrapper = styled.div`
  margin-top: 16px;
`;
const Print = () => {
  const [balance, setBalance] = useState(0);
  const customer = useSelector(customerSelector);
  const dispatch = useDispatch();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [officesOptions, setOfficesOptions] = useState([]);
  const [openDialog, setOpenDialog] = useState({
    success: false,
    printFile: false
  });
  const historyRef = useRef();
  const offices = useSelector(officeSelector);
  const handleScrollToHistory = () => {
    if (historyRef.current) {
      historyRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
  const handleResize = () => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else setIsTouchDevice(false);
  };
  useResize(handleResize, 0);

  const reloadBalancePages = async () => {
    try {
      const response = await dispatch(getBalancePrinter());
      if (response.data && isArray(response.data)) {
        setBalance(response.data[0].balance);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (customer) {
      reloadBalancePages();
      dispatch(getActiveServiceRequests());
    }
  }, [customer, dispatch]);
  const handleSelectChange = event => {
    dispatch(actions.requestServiceRequestCreate.reset());
    const selectedLocation = offices.find(location => location.id === event.target.value);
    setCurrentLocation(selectedLocation.id);
  };
  useEffect(() => {
    if (offices) {
      const options = offices.reduce((acc, item) => {
        const floorsByLocation = item.officeFloors.filter(floor => floor.active).map(floor => floor.floor_id);
        const emptyLocation = floorsByLocation.length === 1 && floorsByLocation[0] === null ? item : '';
        if (item.active === 1) {
          acc.push({ value: item.id, label: item.t_name });
        }
        return acc.filter(location => location.value !== emptyLocation.id);
      }, []);
      setOfficesOptions(options);
      if (options && options.length > 0 && currentLocation === '') {
        setCurrentLocation(options[0].value);
      }
    }
  }, [offices]);

  return (
    <MainWrapper>
      <PrefillForm overflow='unset'>
        <GroupTitle>
          <span>
            {isTouchDevice && (
              <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
            )}
            Печать
          </span>
          {!isTouchDevice && (
            <StepButton
              handleAction={() => setOpenDialog(prevState => ({ ...prevState, printFile: true }))}
              type={'filled'}
              filledType={'red'}
              title={'Отправить на печать'}
              spacing={'0'}
            />
          )}
          {isTouchDevice && <TitleDescription onClick={handleScrollToHistory}>История печати</TitleDescription>}
        </GroupTitle>
        {!isTouchDevice && (
          <TitleBox>
            <TitleDescription onClick={handleScrollToHistory}>История печати</TitleDescription>
          </TitleBox>
        )}
        <PrintUserInfoSections customer={customer} handleScrollToHistory={handleScrollToHistory} />
        <SelectWrapper>
          <CustomSelectField
            name='location'
            id='custom-select'
            label={'Локация'}
            variant='filled'
            value={currentLocation}
            onChange={handleSelectChange}
            options={officesOptions}
          />
        </SelectWrapper>
        <PrintPackages currentLocation={currentLocation} />
        <PrintHistory historyRef={historyRef} customer={customer} />
      </PrefillForm>
      {isTouchDevice && (
        <ButtonWrapper>
          <StepButton
            handleAction={() => setOpenDialog(prevState => ({ ...prevState, printFile: true }))}
            type={'filled'}
            filledType={'red'}
            title={'Отправить файл на печать'}
            spacing={'0'}
          />
        </ButtonWrapper>
      )}
      {openDialog.printFile && (
        <PrintFileDialog
          dialogType='printFile'
          title='Отправить файл на печать'
          dialogData={customer}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, printFile: false }));
          }}
          handleSubmit={() => {
            reloadBalancePages();
            setOpenDialog(prevState => ({ ...prevState, printFile: false, success: true }));
          }}
        />
      )}
      {openDialog.success && (
        <PrintSuccessDialog
          dialogType='printSuccess'
          title='Файл отправлен на печать'
          dialogData={customer}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, success: false }));
          }}
        />
      )}
    </MainWrapper>
  );
};

export default Print;

import React from 'react';
import Loader from 'react-loader-spinner';

const PageLoader = () => (
  <div className='page__loader'>
    <Loader type='ThreeDots' color='#f15a4f' height='25' width='40' />
  </div>
);

export default PageLoader;

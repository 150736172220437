import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#24272A'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
`;

export const AddGuest = ({ defaultColor, spacing, hoverColor }) => {
  return (
    <StyledSvg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
    >
      <path
        d='M8.15625 15.0625C8.15625 15.5285 8.53401 15.9062 9 15.9062C9.46599 15.9062 9.84375 15.5285 9.84375 15.0625V10.8438H14.0625C14.5285 10.8438 14.9062 10.466 14.9062 10C14.9062 9.53401 14.5285 9.15625 14.0625 9.15625H9.84375V4.9375C9.84375 4.47151 9.46599 4.09375 9 4.09375C8.53401 4.09375 8.15625 4.47151 8.15625 4.9375V9.15625H3.9375C3.47151 9.15625 3.09375 9.53401 3.09375 10C3.09375 10.466 3.47151 10.8438 3.9375 10.8438H8.15625V15.0625Z'
        fill='#575B60'
      />
    </StyledSvg>
  );
};

import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const MeetingRoomsInfoCell = ({
  marker,
  type,
  isMouseDown,
  changeMarker,
  resetSelectedAll,
  setSelectedReservation,
  changeIsMouseDown,
  isCorrectedSelected,
  setOverflowStatus,
  markerList
}) => {
  const isFree = useCallback(marker => marker.status === null || marker.status === 'current', []);
  function preventDefaultTouchMove(event) {
    event.preventDefault();
  }
  const handleMouseDown = useCallback(
    (marker, evt) => {
      const timePickerInModalWidget = document.getElementById('time-picker-in-modal-widget');
      const currentOpenedDialogModal = document.getElementById('current-opened-dialog-modal');
      if (timePickerInModalWidget) {
        timePickerInModalWidget.addEventListener('touchmove', preventDefaultTouchMove, { passive: false });
      }
      if (currentOpenedDialogModal) {
        currentOpenedDialogModal.style.overflow = 'hidden';
      }
      changeIsMouseDown(true);
      resetSelectedAll(true);
      if (isFree(marker)) {
        changeMarker(marker, { status: 'current' });
      }
    },
    [setOverflowStatus, changeIsMouseDown, resetSelectedAll, isFree, changeMarker]
  );

  const handleMouseUp = useCallback(
    (marker, evt) => {
      changeIsMouseDown(false);
      const timePickerInModalWidget = document.getElementById('time-picker-in-modal-widget');
      const currentOpenedDialogModal = document.getElementById('current-opened-dialog-modal');
      if (timePickerInModalWidget) {
        timePickerInModalWidget.removeEventListener('touchmove', preventDefaultTouchMove, { passive: false });
      }
      if (currentOpenedDialogModal) {
        currentOpenedDialogModal.style.overflow = 'auto';
      }
      if (!isCorrectedSelected()) {
        resetSelectedAll();
        setSelectedReservation();
        return;
      }
      setSelectedReservation(marker.service_id);
    },
    [setOverflowStatus, changeIsMouseDown, resetSelectedAll, setSelectedReservation, isCorrectedSelected]
  );

  const handleMouseEnter = useCallback(
    (marker, evt) => {
      if (!isMouseDown || marker.status === 'current') {
        return;
      }
      if (!isFree(marker)) {
        changeIsMouseDown(false);
        resetSelectedAll();
        return;
      }
      changeMarker(marker, { status: 'current' });
    },
    [isMouseDown, isFree, changeIsMouseDown, resetSelectedAll, changeMarker]
  );

  const handleTouchMove = useCallback(
    (marker, evt) => {
      const newMarker = markerList[document.elementFromPoint(evt.touches[0].clientX, evt.touches[0].clientY).id];
      if (newMarker) {
        if (!isMouseDown || newMarker.status === 'current') {
          return;
        }
        if (!isFree(newMarker)) {
          changeIsMouseDown(false);
          resetSelectedAll();
          return;
        }
        changeMarker(newMarker, { status: 'current' });
      }
    },
    [isMouseDown, isFree, changeIsMouseDown, resetSelectedAll, changeMarker, markerList]
  );

  const tdClassName = cn({
    'hours-picker__cell': type !== 'mobile',
    'hours-picker__mobile-cell': type === 'mobile',
    [`is-${marker.status}`]: true,
    'card-view-cell': true
  });

  return (
    <td
      id={marker.id}
      className={tdClassName}
      onMouseDown={evt => handleMouseDown(marker, evt)}
      onMouseUp={evt => handleMouseUp(marker, evt)}
      onMouseOver={evt => handleMouseEnter(marker, evt)}
      onTouchStart={evt => handleMouseDown(marker, evt)}
      onTouchEnd={evt => handleMouseUp(marker, evt)}
      onTouchMove={evt => handleTouchMove(marker, evt)}
    />
  );
};

MeetingRoomsInfoCell.propTypes = {
  isMouseDown: PropTypes.bool.isRequired,
  marker: PropTypes.object.isRequired,
  changeMarker: PropTypes.func.isRequired,
  resetSelectedAll: PropTypes.func.isRequired,
  setSelectedReservation: PropTypes.func.isRequired,
  changeIsMouseDown: PropTypes.func.isRequired,
  isCorrectedSelected: PropTypes.func.isRequired,
  setOverflowStatus: PropTypes.func.isRequired,
  markerList: PropTypes.array.isRequired
};

export default MeetingRoomsInfoCell;

import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styleConstants';

const StatusBox = styled.div`
  margin: 0;
  padding: 0 8px 2px 8px;
  height: 18px;
  display: flex;
  align-items: center;
  font-family: Commissioner, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  border-radius: 4px;
  background-color: ${({ backgroundcolor }) => backgroundcolor || ''};
  width: min-content;
`;

const DashboardStatus = ({ statusId, startDate, ...props }) => {
  const getBoxColor = () => {
    if (statusId === 1) return colors.workkiGreenSuccess;
    if (statusId === 2) return '#E6F1F4';
    if (statusId === 3) return '#8F9295';
    if (statusId === 4) return '#E9EAEA';
  };

  const getText = () => {
    if (statusId === 1) return 'активно';
    if (statusId === 2) return startDate ? `c ${startDate}` : 'неактивно';
    if (statusId === 3) return 'отменено';
    if (statusId === 4) return 'завершено';
  };

  return (
    <StatusBox backgroundcolor={getBoxColor()} {...props}>
      {getText()}
    </StatusBox>
  );
};

export default DashboardStatus;

import { attr, Model, fk } from 'redux-orm';
import * as actions from '../actions';

class ItServiceTask extends Model {
  static reducer(action, ItServiceTask) {
    switch (action.type) {
      case actions.requestItServiceTaskList.success.toString():
        action.payload.forEach(task => {
          ItServiceTask.upsert(task);
        });
        break;
      case actions.requestItServiceTaskCreate.success.toString():
        action.payload.forEach(task => {
          ItServiceTask.upsert(task);
        });
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Service: ${this.name}`;
  }
}

ItServiceTask.modelName = 'ItServiceTask';

ItServiceTask.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  service_id: fk('Service', 'it_task_services_to_services'),
  location: fk('Service', 'it_task_places_to_services')
};

export default ItServiceTask;

import React from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

const wrapAuthor = content => <div className='ticket-details__author'>{content}:</div>;
const handleAuthor = author => {
  if (author.name === 'Автоматическое создание запросов') {
    return wrapAuthor('Вы');
  }

  return wrapAuthor(!isEmpty(author.email) ? `${author.name} (${author.email})` : author.name);
};

const handleTime = time => (
  <div className='ticket-details__time'>{moment(time, 'DD.MM.YYYY hh:mm:ss').format('DD.MM.YYYY HH:mm')}</div>
);

const renderAuthor = comment => {
  if (!isEmpty(comment.updateAuthor) && !isEmpty(comment.updateAuthor.name)) {
    return handleAuthor(comment.updateAuthor);
  }

  if (!isEmpty(comment.author) && !isEmpty(comment.author.name)) {
    return handleAuthor(comment.author);
  }

  return null;
};

const renderDateTime = comment => {
  if (!isEmpty(comment.updated)) {
    return handleTime(comment.updated);
  }

  if (!isEmpty(comment.created)) {
    return handleTime(comment.created);
  }

  return null;
};

const CommentItem = ({ comment }) => {
  return (
    <div className='ticket-details__comments-item'>
      <div className='ticket-details__comments-info'>
        {renderAuthor(comment)}
        {renderDateTime(comment)}
      </div>
      <div className='ticket-details__comments-text'>{comment.text}</div>
    </div>
  );
};

export default CommentItem;

import React from 'react';
import styled from 'styled-components';
import { LinkArrow } from '../../icons/core/LinkArrow';
import { LogoutIcon } from '../../icons/core/LogoutIcon';

export const ProfileCardList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  :hover,
  :active {
    background-color: #fafafa;
    cursor: pointer;
  }
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
`;

export const ProfileCardWrapper = styled.div`
  & > div > p {
    margin: 0;
  }
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > svg {
    align-self: center;
    justify-self: center;
    & > path {
      width: 7px;
      height: 12px;
    }
  }
  border-bottom: #f4f4f4 1px solid;
`;

export const Card = styled.div`
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const ProfileCardTitle = styled.p`
  font-weight: 400;
  align-items: center;
`;

export const ProfileCardCaption = styled.p`
  font-weight: 300;
  color: #575b60;
  font-size: 12px;
`;

export const ProfileCard = ({ cards, logoutShown, handleLogout, handleFollowLink }) => {
  return cards ? (
    <ProfileCardList>
      {cards.map((card, index) => (
        <ProfileCardWrapper key={index} onClick={() => card.handleFollowLink()}>
          <Card>
            <ProfileCardTitle>{card.title}</ProfileCardTitle>
            <ProfileCardCaption>{card.caption}</ProfileCardCaption>
          </Card>
          <LinkArrow defaultColor='#8F9295' width={'16px'} height={'16px'} />
        </ProfileCardWrapper>
      ))}
      {logoutShown && (
        <ProfileCardWrapper onClick={handleLogout}>
          <Card style={{ flexDirection: 'row' }}>
            <LogoutIcon defaultColor={'#000000'} spacing={'4px'} onClick={handleLogout} />
            <ProfileCardTitle>Выйти</ProfileCardTitle>
          </Card>
        </ProfileCardWrapper>
      )}
    </ProfileCardList>
  ) : null;
};

export default ProfileCard;

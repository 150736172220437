import React from 'react';
import { useBackground } from './BackgroundProvider';

const withBackgroundContext = (Component, selectContext) => {
  const MemoizedComponent = React.memo(Component);
  return props => {
    const context = useBackground();
    const selectedContext = selectContext(context);
    return <MemoizedComponent {...props} {...selectedContext} />;
  };
};

export default withBackgroundContext;

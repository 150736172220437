import { createSelector } from 'redux-orm';
import moment from 'moment';
import { isNil } from 'lodash';
import orm from '../models/orm';
import { SERVICE_TYPE, FILE_TYPE_ID } from '../../constants';
import dayjs from 'dayjs';

const dbStateSelector = state => state.entities;

const location = (state, props) => props.location;
const roomReservationTypeId = (state, props) => props.roomReservationTypeId;
const dateCurrentSubscriptionView = (state, props) => props.dateCurrentSubscriptionView;
const selectorType = (state, props) => props.selectorType;
const fileTypeId = (state, props) => props.fileTypeId;

export const feeServicesSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Service.all()
      .filter(s => s.type_id === 6 && s.status_id === 1)
      .toRefArray();
  }
);

export const roomReservationSelector = createSelector(
  orm,
  dbStateSelector,
  location,
  roomReservationTypeId,
  (session, loc, roomReservationType) => {
    // roomReservationTypeId = 1;  //тип сервиса переговорная комната
    return session.Service.all()
      .filter(service => service.type_id === roomReservationType && service.status_id === 1)
      .filter(service => service.office_id === loc.id)
      .toModelArray()
      .map(service => {
        return Object.assign({}, service.ref, {
          reservations: service.reservations.toRefArray()
        });
      });
  }
);

export const allWorkplacesSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    // 5 - тип сервиса рабочее место
    return session.Service.all()
      .filter(service => service.type_id === 5)
      .toModelArray()
      .map(service => {
        return Object.assign({}, service.ref, {
          tariffInfo: service.tariff,
          //Не работает, но лучше не трогать
          tariff: service.units.ref,
          tariff_files: service.units.files.toRefArray()
        });
      });
  }
);

export const serviceRequestWithWorkplacesSelector = createSelector(
  orm,
  dbStateSelector,
  dateCurrentSubscriptionView,
  selectorType,
  (session, dateCurrentSubscription, selectorType) => {
    // Ensure dateCurrentSubscription is properly formatted as dayjs object
    const formattedDate = dayjs(dateCurrentSubscription);

    // 5 тип сервиса переговорная комната
    return session.ServiceRequest.all()
      .toModelArray()
      .filter(service => {
        return (
          (service.status_id === 3 || service.status_id === 5) &&
          service.service_id !== null &&
          (selectorType === 'standard'
            ? service.service_id.type_id === 5
            : service.service_id.type_id === 5 || service.service_id.type_id === 1 || service.service_id.type_id === 4)
        );
      })
      .filter(service => {
        const currentDate = dayjs(service.expire_at, 'YYYY-MM-DD HH:mm:ss');
        return currentDate.diff(formattedDate, 'day') >= 0; // Check if currentDate is same or after formattedDate
      })
      .sort((a, b) => {
        const expireA = dayjs(a.expire_at, 'YYYY-MM-DD HH:mm:ss');
        const expireB = dayjs(b.expire_at, 'YYYY-MM-DD HH:mm:ss');
        return expireA.diff(expireB, 'second') < 0 ? 1 : -1; // Sort by expiration date in descending order
      })
      .reduce((result, item) => {
        const expire = dayjs(item.expire_at, 'YYYY-MM-DD HH:mm:ss');
        const currentDate = dayjs();
        if (currentDate.diff(expire, 'second') < 0) {
          result.push(item);
          return result;
        }

        const element = result.find(service => service.service_id.id === item.service_id.id);
        if (!element) {
          result.push(item);
        }
        return result;
      }, [])
      .map(serviceRequest => {
        let reservation_place = null;
        let tariff = null;
        let isFree = null;
        if (serviceRequest.service_id !== null) {
          reservation_place = serviceRequest.service_id.ref;
          tariff = serviceRequest.service_id.units !== null ? serviceRequest.service_id.units.ref : null;
          isFree = serviceRequest.service_id.ref.isFree;
        }
        return Object.assign({}, serviceRequest.ref, {
          reservation_place,
          tariff,
          isFree
        });
      });
  }
);
export const getServiceSchedule = createSelector(
  orm,
  dbStateSelector,
  session => {
    const offices = session.Office.all()
      .toModelArray()
      .map(service => {
        return Object.assign({}, service.ref);
      });
    const negotiationRoomServices = session.ServiceSchedule.all()
      .toModelArray()
      .filter(el => el.is_yours)
      .filter(el => !el.finish_at || moment().isSameOrBefore(moment(el.finish_at, 'YYYY-MM-DD HH:mm:ss')))
      .map(serviceSchedule => {
        return Object.assign({}, serviceSchedule.ref);
      });
    const roomServiceIds = negotiationRoomServices.map(roomService => roomService.service_id);
    const services = session.Service.all()
      .toModelArray()
      .filter(service => roomServiceIds.includes(service.id))
      .map(service => {
        return Object.assign({}, service.ref);
      });
    return negotiationRoomServices.map(roomService => {
      const service = services.find(service => service.id === roomService.service_id);

      return {
        ...roomService,
        service,
        office: offices.find(office => office.id === service.office_id)
      };
    });
  }
);

export const printerPackages = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.Service.all()
      .filter(service => service.type_id === SERVICE_TYPE.PRINT)
      .toModelArray()
      .map(service => {
        return Object.assign({}, service.ref);
      });
  }
);

export const printerFiles = createSelector(
  orm,
  dbStateSelector,
  session => {
    const test = session.CustomerFile.all()
      .filter(file => file.type_id === FILE_TYPE_ID.OTHER)
      .toModelArray()
      .map(file => {
        return Object.assign({}, file.ref);
      });
    return test;
  }
);

export const fileList = createSelector(
  orm,
  dbStateSelector,
  fileTypeId,
  session => {
    return session.CustomerFile.all()
      .toModelArray()
      .filter(file => file.type_id === FILE_TYPE_ID.PASSPORT || file.type_id === FILE_TYPE_ID.PHOTO)
      .map(file => {
        return Object.assign({}, file.ref);
      });
  }
);

export const itServicesSelector = createSelector(
  orm,
  dbStateSelector,
  session =>
    session.Service.all()
      .filter(s => s.type_id === SERVICE_TYPE.IT)
      .toRefArray()
);

export const itServicesTasksSelector = createSelector(
  orm,
  dbStateSelector,
  session => {
    return session.ItServiceTask.all()
      .toModelArray()
      .sort((a, b) => {
        const timeA = moment(a.created_at, 'YYYY-MM-DD HH:mm:ss');
        const timeB = moment(b.created_at, 'YYYY-MM-DD HH:mm:ss');
        return timeA.isBefore(timeB) ? 1 : -1;
      })
      .map(item => {
        const service = !isNil(item.service_id) ? item.service_id.ref : null;
        const place = !isNil(item.location) ? item.location.ref : null;
        const office = !isNil(place) && !isNil(place.office_id) ? item.location.office_id.ref : null;
        return { ...item.ref, service, place, office };
      });
  }
);

export const guestLimitsSelector = createSelector(
  orm,
  dbStateSelector,
  session =>
    session.GuestLimit.all()
      .toModelArray()
      .map(el => ({
        officeId: el.id,
        limit: el.limit
      }))
);

export const servicesSelector = createSelector(
  orm,
  dbStateSelector,
  session =>
    session.Service.all()
      .toModelArray()
      .map(s => ({ ...s.ref, office: s.office_id ? s.office_id.ref : null }))
);

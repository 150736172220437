/* eslint-disable no-shadow */
import { Model, fk } from 'redux-orm';
import * as actions from '../actions';

class GuestLimit extends Model {
  static reducer(action, guestLimit) {
    switch (action.type) {
      case actions.requestGuestLimit.success.toString():
        const officeIds = Object.keys(action.payload.guestLimits);
        officeIds.forEach(officeId => {
          guestLimit.upsert({
            id: officeId,
            office_id: officeId,
            limit: action.payload.guestLimits[officeId]
          });
        });
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `GuestLimit: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
GuestLimit.modelName = 'GuestLimit';

// Declare your related fields.
GuestLimit.fields = {
  // id: attr(), // non-relational field for any value; optional but highly recommended
  // status_id:
  office_id: fk('Office', 'guest_limits_to_office')
};

export default GuestLimit;

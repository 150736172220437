import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { PhoneMask } from '../../../shared/ui/icons/core/PhoneMask';
import { formatPhoneNumber, validateEmail } from '../../../shared/utils/serviceUtils/validationUtils';
import { FileUploadPreview } from '../../../shared/ui/components/Field/FileUploadField';
import { FormGroupTitle } from '../../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { useDispatch } from 'react-redux';
import { createCustomerChild, updateCustomerChild } from '../../../services/workki/ChildActions';
import { InfoAlert } from '../../../shared/ui/components/Profile/InfoAlert';

const EmployeeWidget = ({ handleClose, type, dialogData, handleSubmit }) => {
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    description: ''
  });
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    description: ''
  });
  const [filled, setFilled] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
    phone: false,
    email: false,
    description: false
  });
  const [isFocused, setIsFocused] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
    phone: false,
    email: false,
    description: false
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = (name, inputValue) => {
    const cleaned = inputValue.replace(/\D/g, '');
    const limitedInput = cleaned.substring(0, 10);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));

    if (name === 'email') {
      validateEmail(inputValue)
        ? setErrors(prevState => ({ ...prevState, email: '' }))
        : setErrors(prevState => ({ ...prevState, email: 'Неверный формат E-mail' }));
    }
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: inputValue !== ''
    }));
    setEmployeeData(prevState => {
      return {
        ...prevState,
        [name]: name === 'phone' ? formatPhoneNumber(limitedInput, setErrors) : inputValue
      };
    });
  };

  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };
  const handleEraseValue = fieldName => {
    setEmployeeData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };
  useEffect(() => {
    if (type === 'editEmployee') {
      const { employees, employeeId } = dialogData;
      const pickedEmployee = employees.find(employee => employee.id === employeeId);
      setEmployeeData(employeeData => ({
        ...employeeData,
        firstName: pickedEmployee.first_name,
        lastName: pickedEmployee.last_name,
        middleName: pickedEmployee.middle_name,
        email: pickedEmployee.email,
        description: pickedEmployee.description,
        phone: pickedEmployee.mobile_phone
      }));
    }
  }, []);
  useEffect(() => {
    const allFieldsFilled =
      employeeData.firstName &&
      employeeData.middleName &&
      employeeData.lastName &&
      employeeData.description &&
      employeeData.email;

    const hasErrors = Object.values(errors).some(error => error !== '');

    setIsSubmitDisabled(!allFieldsFilled || hasErrors);
  }, [employeeData, errors]);
  const dispatch = useDispatch();

  const handleCreate = useCallback(
    async event => {
      event.preventDefault();

      const { firstName, middleName, lastName, phone, description, email } = employeeData;

      const childData = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        email: email,
        mobile_phone: phone,
        description,
        photoFile: files[0]
      };

      try {
        await dispatch(createCustomerChild(childData));
        handleClose();
      } catch (error) {
        const serverErrors =
          error.response && error.response.data && Array.isArray(error.response.data) ? error.response.data : [];
        const newErrors = {};

        serverErrors.forEach(err => {
          if (err.field === 'email') {
            newErrors.email = err.message;
          } else if (err.field === 'mobile_phone') {
            newErrors.phone = err.message;
          } else if (err.field === 'first_name') {
            newErrors.firstName = err.message;
          } else if (err.field === 'last_name') {
            newErrors.lastName = err.message;
          } else if (err.field === 'middle_name') {
            newErrors.middleName = err.message;
          } else if (err.field === 'description') {
            newErrors.description = err.message;
          }
        });

        setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
        console.error('Error creating customer child:', error);
      }
    },
    [employeeData, files, dispatch, handleClose]
  );
  const handleEdit = useCallback(
    async event => {
      event.preventDefault();

      const { firstName, middleName, lastName, phone, description, email } = employeeData;

      const updatedData = {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        email: email,
        mobile_phone: phone,
        description
      };

      const { employeeId } = dialogData;

      try {
        await dispatch(updateCustomerChild(employeeId, updatedData));

        handleClose();
      } catch (error) {
        //Дублирование можно будет вынести
        const serverErrors =
          error.response && error.response.data && Array.isArray(error.response.data) ? error.response.data : [];
        const newErrors = {};

        serverErrors.forEach(err => {
          if (err.field === 'email') {
            newErrors.email = err.message;
          } else if (err.field === 'mobile_phone') {
            newErrors.phone = err.message;
          } else if (err.field === 'first_name') {
            newErrors.firstName = err.message;
          } else if (err.field === 'last_name') {
            newErrors.lastName = err.message;
          } else if (err.field === 'middle_name') {
            newErrors.middleName = err.message;
          } else if (err.field === 'description') {
            newErrors.description = err.message;
          }
        });

        setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
        console.error('Error updating customer child:', error);
      }
    },
    [employeeData, dispatch, dialogData, handleClose]
  );

  return (
    <>
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={type === 'addEmployee' ? handleCreate : handleEdit}
        autoComplete='off'
        noValidate
      >
        <FormControl fullWidth={true}>
          <>
            <CustomTextField
              error={''}
              type='text'
              name='firstName'
              id='outlined-helperText'
              label='Имя'
              variant='filled'
              disabled={type === 'editEmployee'}
              value={employeeData.firstName}
              onFocus={() => handleFocus('firstName')}
              onBlur={() => handleBlur('firstName')}
              onChange={e => handleInputChange('firstName', e.target.value)}
              helperText={errors.firstName ? errors.firstName : ''}
              endAdornmentProps={{
                filled: filled.firstName,
                isFocused: isFocused.firstName,
                handleEraseValue: name => handleEraseValue(name)
              }}
              autoFocus={false}
            />
            <CustomTextField
              error={''}
              type='text'
              disabled={type === 'editEmployee'}
              name='lastName'
              id='outlined-helperText'
              label='Фамилия'
              variant='filled'
              value={employeeData.lastName}
              onFocus={() => handleFocus('lastName')}
              onBlur={() => handleBlur('lastName')}
              onChange={e => handleInputChange('lastName', e.target.value)}
              helperText={errors.lastName ? errors.lastName : ''}
              endAdornmentProps={{
                filled: filled.lastName,
                isFocused: isFocused.lastName,
                handleEraseValue: name => handleEraseValue(name)
              }}
              autoFocus={false}
            />
            <CustomTextField
              error={''}
              type='text'
              name='middleName'
              disabled={type === 'editEmployee'}
              id='outlined-helperText'
              label='Отчество'
              variant='filled'
              value={employeeData.middleName}
              onFocus={() => handleFocus('middleName')}
              onBlur={() => handleBlur('middleName')}
              onChange={e => handleInputChange('middleName', e.target.value)}
              helperText={errors.middleName ? errors.middleName : ''}
              endAdornmentProps={{
                filled: filled.middleName,
                isFocused: isFocused.middleName,
                handleEraseValue: name => handleEraseValue(name)
              }}
              autoFocus={false}
            />
            {/*<InfoAlert*/}
            {/*  type={'info'}*/}
            {/*  title={'ФИО сотрудника может быть изменено только менеджером Workki'}*/}
            {/*  link={'Оставить заявку'}*/}
            {/*  handleAction={() => handleSubmit()}*/}
            {/*/>*/}
            <CustomTextField
              error={''}
              type='text'
              name='email'
              id='outlined-helperText'
              label='E-mail'
              variant='filled'
              value={employeeData.email}
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              onChange={e => handleInputChange('email', e.target.value)}
              helperText={errors.email ? errors.email : ''}
              endAdornmentProps={{
                filled: filled.email,
                isFocused: isFocused.email,
                handleEraseValue: name => handleEraseValue(name)
              }}
              autoFocus={false}
            />
            <CustomTextField
              error={''}
              id='outlined-helperText'
              label='Телефон'
              name='phone'
              helperText={errors.phone ? errors.phone : ''}
              variant='filled'
              type='tel'
              value={employeeData.phone}
              placeholder={'999 999-99-99'}
              onFocus={() => handleFocus('phone')}
              onBlur={() => handleBlur('phone')}
              onChange={e => handleInputChange('phone', e.target.value)}
              endAdornmentProps={{
                filled: filled.phone,
                isFocused: isFocused.phone,
                handleEraseValue: name => handleEraseValue(name)
              }}
              startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
              autoFocus={false}
            />
            <CustomTextField
              error={''}
              type='text'
              name='description'
              id='outlined-helperText'
              label='Описание'
              variant='filled'
              value={employeeData.description}
              onFocus={() => handleFocus('description')}
              onBlur={() => handleBlur('description')}
              onChange={e => handleInputChange('description', e.target.value)}
              helperText={errors.description ? errors.description : ''}
              endAdornmentProps={{
                filled: filled.description,
                isFocused: isFocused.description,
                handleEraseValue: name => handleEraseValue(name)
              }}
              autoFocus={false}
            />
            {type === 'addEmployee' && (
              <>
                <FormGroupTitle>
                  <p>Фото на пропуск (необязательно)</p>
                </FormGroupTitle>
                <FileUploadPreview fieldType='employee' files={files} setFiles={value => setFiles(value)} />
              </>
            )}
          </>
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title={type === 'addEmployee' ? 'Добавить сотрудника' : 'Сохранить изменения'}
              handleAction={type === 'addEmployee' ? handleCreate : handleEdit}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default EmployeeWidget;

import * as React from 'react';
import { AreaType } from '../../types';
import { FloorCabinet } from './FloorCabinet';
import { FloorOpenSpace } from './FloorOpenSpace';
import { LazyFloorFloor } from '../LazyFloorFloor';
export const FloorAreasConstructor = ({ floorAreas, openPriceCard, place, size, onTouchStart, filtersActive }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox={`0 0 ${size.width} ${size.height}`}
      className='workplace-floor-area-inner'
      xmlns='http://www.w3.org/2000/svg'
    >
      <LazyFloorFloor place={place} />
      {floorAreas.map(area => {
        switch (area.type) {
          case AreaType.OPEN_SPACE:
            return (
              <FloorOpenSpace
                key={area.uuid}
                floorArea={area}
                openPriceCard={openPriceCard}
                onTouchStart={onTouchStart}
              />
            );
          case AreaType.CABINET:
            return (
              <FloorCabinet
                filtersActive={filtersActive}
                key={area.uuid}
                floorArea={area}
                openPriceCard={openPriceCard}
                onTouchStart={onTouchStart}
              />
            );
          default:
            return null;
        }
      })}
    </svg>
  );
};
export default FloorAreasConstructor;

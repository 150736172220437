import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ServicesDialog } from '../../widgets/Services/ServicesDialog';
import { GuestPassSuccessDialog } from '../../widgets/Services/GuestPass/GuestPassSuccessDialog';
import { CommonServiceSuccessDialog } from '../../widgets/Services/CommonServiceSuccessDialog';
const ParkingPanel = props => {
  const [openDialog, setOpenDialog] = useState({ parking: false, success: false });
  useEffect(() => {
    if (props.outerModalOpen) {
      setOpenDialog(prevState => ({ ...prevState, parking: true }));
    }
  }, [props.outerModalOpen]);

  useEffect(() => {
    if (props.parkingCreateRequestStatus.isSuccess) {
      props.setOuterModalOpen(false, 'parking');
      setOpenDialog(prevState => ({ ...prevState, parking: false, success: true }));
    }
  }, [props.parkingCreateRequestStatus]);

  const loadingError = useMemo(() => {
    if (!props.parkingCreateRequestStatus.isFailure) {
      return null;
    }

    const loadingError = props.getError();

    return loadingError !== '' ? loadingError : null;
  }, [props.getError, props.parkingCreateRequestStatus]);

  return (
    <>
      {openDialog.parking && (
        <ServicesDialog
          title='Пропуск на въезд авто'
          serviceProps={{ ...props, loadingError }}
          workplaces={props.locations}
          dialogType='parking'
          handleSubmit={() => {
            setOpenDialog(prevState => ({ ...prevState, parking: false }));
            props.setOuterModalOpen(false, 'parking');
          }}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, parking: false }));
            props.setOuterModalOpen(false, 'parking');
          }}
        />
      )}
      {openDialog.success && (
        <CommonServiceSuccessDialog
          dialogType='commomServiceSuccess'
          title='Заявка отправлена'
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, success: false }));
          }}
        />
      )}
    </>
  );
};

ParkingPanel.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  parkingListRequestStatus: PropTypes.object.isRequired
};

export default ParkingPanel;

import initialState from '../initialState';
import * as actions from '../actions';
import { LOADING_STATUSES } from '../../constants';

const requestStatuses = (state = initialState.requestStatuses, action) => {
  const { type } = action;

  if (type === actions.logout.toString()) {
    return initialState.requestStatuses;
  }

  const matches = /(.*):(REQUEST|RESET|SUCCESS|FAILURE|EMPTY)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]:
      requestState === 'REQUEST'
        ? LOADING_STATUSES.PENDING
        : requestState === 'RESET'
        ? LOADING_STATUSES.INIT
        : LOADING_STATUSES[requestState]
  };
};

export default requestStatuses;

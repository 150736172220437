import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

export const ForgetPasswordDialog = props => {
  const fields = [
    //TODO по сути тут можно заменить идентичные поля на единообразные и в компоненте ниже
    {
      view: 'field',
      type: 'email',
      name: 'email',
      label: 'E-mail',
      valueKey: 'forgetPassword',
      errorKey: 'forgetPassword',
      context: 'forgetPassword',
      autoFocus: true,
      complexValueKey: true
    }
  ];

  return (
    <Dialog
      title='Забыли пароль?'
      fields={fields}
      buttonLabel='Восстановить'
      requestSentMessage='Запрос отправлен'
      requestSentDescription='Запрос на смену пароля отправлен. Для подтверждения запроса перейдите в вашу почту.'
      {...props}
    />
  );
};

import React, { useEffect, useState, useRef } from 'react';
import Portal, { createContainer } from '../../../../hocs/Portal';
import heic2any from 'heic2any';
import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { Loader } from '../Loader';
import { AddFile } from '../../icons/core/AddFile';
import Cropper from 'react-cropper';
import { colors } from '../../constants/styleConstants';
import 'cropperjs/dist/cropper.css';
import { FileActionIcon } from '../../icons/core/FileAction';

const FilePreviewContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '8px',
  marginBottom: '10px',
  width: 'fit-content',
  textAlign: 'center'
});
const PreviewImageWrapper = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'alignItems': 'center',
  '& > img': {
    width: '200px',
    height: '200px'
  }
});
const PreviewActionsBlock = styled('div')({
  'display': 'flex',
  'flexDirection': 'row',
  'justifyContent': 'center',
  'alignItems': 'center',
  '& > div': {
    marginRight: '8px'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  'marginTop': '16px',
  'marginBottom': '16px'
});

const PreviewIconBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});
const PreviewIconCaption = styled('p')({
  fontSize: '12px',
  color: `${colors.workkiDarkMain}`,
  fontWeight: '300',
  margin: '0',
  textAlign: 'center'
});

const StatusOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`;

const StatusContainer = styled.div`
  margin: 0 40px;
`;

const FilePreviewIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5px'
});

const FileInput = styled('input')({
  display: 'none'
});

const FileInputCaption = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'alignItems': 'center',
  'fontSize': '12px',
  'fontWeight': '300',
  '& > p': {
    'margin': 0,
    'fontSize': '12px',
    'fontWeight': '300',
    '&:first-child': {
      color: '#24272A'
    },
    '&:nth-child(2)': {
      color: '#8F9295'
    }
  }
});

const FilePreviewList = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'center',
  marginTop: '10px'
});

const DropZone = styled(Box)(({ isDragActive }) => ({
  'border': `2px dashed ${colors.workkiLightLine}`,
  '&:hover': {
    border: `2px dashed ${colors.workkiRedLine}`
  },
  'borderRadius': '8px',
  'padding': '20px',
  'textAlign': 'center',
  'backgroundColor': isDragActive ? '#f0f0f0' : '#fff',
  'cursor': 'pointer',
  'transition': 'background-color 0.3s ease',
  'margin': '16px 0'
}));

const ModalContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const FileUploadPreview = ({
  setFiles,
  files,
  fieldType,
  changeFileHandler,
  isSingleFile,
  disablePhotoEditing,
  deletePhotoHandler
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('unset');
  const [statusShown, setStatusShown] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const cropperRef = useRef(null);

  const MODAL_CONTAINER_ID = 'dropzone-overlay';

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID });
    setMounted(true);
  }, []);

  const checkHEICSupport = file => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = URL.createObjectURL(file);
    });
  };
  console.log('files upd', files);
  const convertHEICToJPEG = async file => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg'
      });
      return new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });
    } catch (error) {
      console.error('Ошибка при конвертации HEIC в JPEG:', error);
      return null;
    }
  };

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);
    const processedFiles = [];

    for (const file of selectedFiles) {
      const isSupported = await checkHEICSupport(file);

      if (!isSupported && file.type === 'image/heic') {
        const convertedFile = await convertHEICToJPEG(file);
        if (convertedFile) {
          processedFiles.push(convertedFile);
        }
      } else {
        processedFiles.push(file);
      }
    }
    if (isSingleFile) {
      setFiles([processedFiles[0]]);
    } else {
      console.log('input card', [...files, ...processedFiles]);
      setFiles([...files, ...processedFiles]);
    }
  };

  const handleFileRemove = index => {
    deletePhotoHandler();
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleDragOver = event => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragActive(false);
    const selectedFiles = Array.from(event.dataTransfer.files);
    if (isSingleFile) {
      setFiles([selectedFiles[0]]);
    } else {
      setFiles([...files, ...selectedFiles]);
    }
  };

  const renderStatusContent = () => {
    const content = {
      unset: () => {
        return;
      },
      loading: () => {
        return <Loader />;
      },
      success: () => {
        return <div></div>;
      },
      error: () => {
        return;
      }
    };

    return content[loadingStatus]();
  };

  const renderFileContent = type => {
    const content = {
      employee: () => {
        return (
          <>
            <p>Загрузить фото на пропуск</p>
            <p>JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      },
      default: () => {
        return (
          <>
            <p>Загрузить фото/скан паспорта</p>
            <p>JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      },
      print: () => {
        return (
          <>
            <p>Прикрепить файл</p>
            <p>PDF, WORD, EXCEL, TEXT, JPG до 15 Мбайт</p>
          </>
        );
      },
      changeCredentials: () => {
        return (
          <>
            <p>Прикрепить файлы</p>
            <p>PDF, DOC, JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      },
      changeName: () => {
        return (
          <>
            <p>Прикрепить файлы</p>
            <p>PDF, DOC, JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      }
    };

    return type ? content[type]() : content.default;
  };

  const handleRotateLeft = index => {
    const file = files[index];
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.height;
      canvas.height = img.width;
      ctx.rotate((-90 * Math.PI) / 180);
      ctx.drawImage(img, -img.width, 0);
      canvas.toBlob(blob => {
        const newFile = new File([blob], file.name, { type: file.type });
        setFiles(prevFiles => prevFiles.map((f, i) => (i === index ? newFile : f)));
      }, file.type);
    };
  };

  const handleCrop = index => {
    // if (changeFileHandler) {
    // return;
    // }
    const file = files[index];
    changeFileHandler(file);
    setSelectedFile(file);
    // setModalOpen(true);
  };

  const handleCropSave = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(blob => {
        const newFile = new File([blob], selectedFile.name, { type: selectedFile.type });
        setFiles(prevFiles => prevFiles.map(file => (file === selectedFile ? newFile : file)));
        setSelectedFile(null);
        setModalOpen(false);
      });
    }
  };

  const renderControls = (index, file) => {
    const deleteControl = (
      <PreviewIconBlock>
        <FileActionIcon
          defaultColor={'#24272A'}
          type={'delete'}
          handleAction={() => handleFileRemove(index)}
          spacing={'0px'}
        />
        <PreviewIconCaption>Удалить</PreviewIconCaption>
      </PreviewIconBlock>
    );
    const rotateAndCropControls = (
      <>
        <PreviewIconBlock>
          <FileActionIcon
            defaultColor={'#24272A'}
            type={'rotate'}
            handleAction={() => {
              handleRotateLeft(index);
            }}
          />
          <PreviewIconCaption>Повернуть</PreviewIconCaption>
        </PreviewIconBlock>
        <PreviewIconBlock>
          <FileActionIcon defaultColor={'#24272A'} type={'crop'} handleAction={() => handleCrop(index)} />
          <PreviewIconCaption>Обрезать</PreviewIconCaption>
        </PreviewIconBlock>
      </>
    );
    const changeControl = (
      <PreviewIconBlock>
        <div
          style={{
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => changeFileHandler(file)}
        >
          <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.875 3.125C7.49429 3.125 6.375 4.24429 6.375 5.625C6.375 7.00571 7.49429 8.125 8.875 8.125C10.2557 8.125 11.375 7.00571 11.375 5.625C11.375 4.24429 10.2557 3.125 8.875 3.125ZM7.625 5.625C7.625 4.93464 8.18464 4.375 8.875 4.375C9.56536 4.375 10.125 4.93464 10.125 5.625C10.125 6.31536 9.56536 6.875 8.875 6.875C8.18464 6.875 7.625 6.31536 7.625 5.625Z'
              fill='#24272A'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0.125 2.5C0.125 1.46447 0.964466 0.625 2 0.625H12C13.0355 0.625 13.875 1.46447 13.875 2.5V12.5C13.875 13.5355 13.0355 14.375 12 14.375H2C0.964467 14.375 0.125 13.5355 0.125 12.5V2.5ZM1.375 12.5V10.2588L3.43306 8.20079C3.67714 7.95671 4.07286 7.95671 4.31694 8.20079L9.24115 13.125H2C1.65482 13.125 1.375 12.8452 1.375 12.5ZM12 13.125H11.0089L5.20083 7.31691C4.46859 6.58467 3.28141 6.58467 2.54918 7.31691L1.375 8.49108V2.5C1.375 2.15482 1.65482 1.875 2 1.875H12C12.3452 1.875 12.625 2.15482 12.625 2.5V12.5C12.625 12.8452 12.3452 13.125 12 13.125Z'
              fill='#24272A'
            />
          </svg>
        </div>
        <PreviewIconCaption>Изменить</PreviewIconCaption>
      </PreviewIconBlock>
    );

    if (disablePhotoEditing) {
      return deleteControl;
    } else if (changeFileHandler) {
      return (
        <>
          {changeControl}
          {deleteControl}
        </>
      );
    }
    return (
      <>
        {rotateAndCropControls}
        {deleteControl}
      </>
    );
  };

  return (
    <>
      <DropZone
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        isDragActive={isDragActive}
      >
        {(files.length === 0 || fieldType === 'changeCredentials') && (
          <label htmlFor='file-upload'>
            <FileInput id='file-upload' type='file' multiple={!isSingleFile} onChange={handleFileChange} />
            <AddFile defaultColor={'#C8C9CA'} hoverColor={'#DB4344'} spacing={'0'} />
            <FileInputCaption>{renderFileContent(fieldType)}</FileInputCaption>
          </label>
        )}
        {fieldType !== 'changeCredentials' && (
          <FilePreviewList>
            {files.map((file, index) => {
              return (
                <FilePreviewContainer key={index}>
                  <PreviewImageWrapper>
                    <img
                      src={(() => {
                        return URL.createObjectURL(file);
                      })()}
                      alt={file.name}
                      width='100'
                    />
                  </PreviewImageWrapper>
                  <PreviewActionsBlock>{renderControls(index, file)}</PreviewActionsBlock>
                </FilePreviewContainer>
              );
            })}
          </FilePreviewList>
        )}
      </DropZone>
      {isMounted && (
        <Portal id={MODAL_CONTAINER_ID}>
          {statusShown && (
            <StatusOverlay>
              <StatusContainer style={{ display: 'flex' }}>{renderStatusContent()}</StatusContainer>
            </StatusOverlay>
          )}
        </Portal>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer>
          {selectedFile && (
            <Cropper
              src={URL.createObjectURL(selectedFile)}
              style={{ height: 400, width: '100%' }}
              initialAspectRatio={1}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
            />
          )}
          <Button onClick={handleCropSave}>Сохранить</Button>
        </ModalContainer>
      </Modal>
    </>
  );
};

import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const settings = createReducer(initialState.settings, {
  [actions.requestCustomerSettings.success]: (state, { payload: { is_public, publicity_status_id } }) => ({
    ...state,
    isPublicCustomerProfile: is_public,
    publicProfileModerationStatus: publicity_status_id
  }),
  [actions.requestChangeProfileIsPublic.success]: (state, { payload: { is_public } }) => ({
    ...state,
    isPublicCustomerProfile: is_public
  }),
  [actions.logout]: () => initialState.settings
});

export default settings;

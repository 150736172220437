import React from 'react';
import styled from 'styled-components';

const StatusBox = styled.div`
  margin: 0;
  padding: 0 8px 2px 8px;
  height: 18px;
  display: flex;
  align-items: center;
  font-family: Commissioner, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  border-radius: 4px;
  background-color: ${({ backgroundcolor }) => backgroundcolor || ''};
  width: min-content;
`;

const SupportAppealStatus = ({ statusId, ...props }) => {
  const getBoxColor = () => {
    if (statusId === 1 || statusId === 4) return '#8F9295';
    if (statusId === 2) return '#00708F';
    if (statusId === 3) return '#02A93B';
    if (statusId === 5) return '#BA393A';
  };

  const getText = () => {
    if (statusId === 1 || statusId === 4) return 'открыто';
    if (statusId === 2) return 'в работе';
    if (statusId === 3) return 'решено';
    if (statusId === 5) return 'отклонён';
  };

  return (
    <StatusBox backgroundcolor={getBoxColor()} {...props}>
      {getText()}
    </StatusBox>
  );
};

export default SupportAppealStatus;

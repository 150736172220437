import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class Customer extends Model {
  static reducer(action, customer) {
    switch (action.type) {
      case actions.requestCustomerCurrentSuccess.toString():
        customer.upsert(action.payload);
        break;
      case actions.requestActualCustomerCurrentSuccess.toString():
        customer.withId(action.payload.id).update(action.payload);
        break;
      case actions.requestCustomerProfileChangePublicFieldsSuccess.toString():
        customer.withId(action.payload.id).update(action.payload);
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Customer: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
Customer.modelName = 'Customer';

// Declare your related fields.
Customer.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr()
};

export default Customer;

import React from 'react';
import { Placeslist } from '../types';
export const FloorWrapper = ({ children, scaleFactor, activeLocation }) => {
  return (
    <div className='workplace-floor-wrapper'>
      <div
        style={
          Placeslist === Placeslist.PARK_KULTURY_4 ||
          Placeslist === Placeslist.PARK_KULTURY_5 ||
          Placeslist === Placeslist.PARK_KULTURY_6
            ? { scale: `${scaleFactor}`, rotate: '180deg' }
            : { scale: `${scaleFactor}` }
        }
        className='workplace-floor-wrapper-inner'
      >
        {children}
      </div>
    </div>
  );
};

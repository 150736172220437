import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentForm from './CommentForm';
import ModalWindowWrapper from '../../../hocs/ModalWindowWrapper';
import { createTicketComment } from '../../../services/workki/TicketsActions';
import ErrorWithShowedMessage from '../../../errors/ErrorWithShowedMessage';
import * as actions from '../../../store/actions';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';

const CommentModal = ({ closeModalWindow, isModalWindowOpen, ticket }) => {
  const dispatch = useDispatch();

  // CLOSE MODAL AFTER CREATE COMMENT
  const ticketCommentCreateStatus = useSelector(requestStatusSelector(actions.requestTicketCommentCreate));
  useEffect(() => {
    if (ticketCommentCreateStatus.isSuccess) {
      dispatch(actions.requestTicketCommentCreate.reset());
      closeModalWindow();
    }
  }, [closeModalWindow, dispatch, ticketCommentCreateStatus, ticket]);

  // SEND MODAL FORM
  const ticketCommentCreate = data => dispatch(createTicketComment(data));
  const resetError = () => dispatch(actions.cleanErrors());

  const error = useSelector(state => state.errors.createTicketComment);
  const ticketCommentCreateError = useMemo(() => {
    if (!error || error instanceof ErrorWithShowedMessage) {
      return '';
    }
    return (
      (error.response && error.response.data && Array.isArray(error.response.data) && error.response.data[0].message) ||
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
    );
  }, [error]);

  return (
    <CommentForm
      ticket={ticket}
      ticketCommentCreate={ticketCommentCreate}
      ticketCommentCreateStatus={ticketCommentCreateStatus}
      ticketCommentCreateError={ticketCommentCreateError}
      resetError={resetError}
      isModalWindowOpen={isModalWindowOpen}
      closeModalWindow={closeModalWindow}
    />
  );
};

export default ModalWindowWrapper(CommentModal);

import React from 'react';
import AuthLayout from '../layouts/AuthLayout';
import AuthLogin from '../entities/Authentification/model';
import withBackgroundContext from '../shared/utils/BackgroundProvider/withBackgroundContext';
import styled from 'styled-components';

const MainStyled = styled.main`
  @media (min-width: 320px) {
    background: none;
    width: 100vw;
    height: 100vh;
  }
  @media (min-width: 768px) {
    background-image: url(${props => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    transition: background-image 0.6s ease-out;
  }
`;

const Main = ({ background }) => {
  return (
    <MainStyled background={background} className='main-auth'>
      <MemoizedAuthLogin />
    </MainStyled>
  );
};

const MemoizedAuthLogin = React.memo(AuthLogin);

const MainWithBackground = withBackgroundContext(Main, context => ({
  background: context.background
}));

const AuthorizationPage = () => {
  return (
    <AuthLayout>
      <MainWithBackground />
    </AuthLayout>
  );
};

export default AuthorizationPage;

import { createReducer, combineReducers } from '@reduxjs/toolkit';
import * as actions from '../actions';
import { get } from 'lodash';
import { PAGINATION_HEADERS } from '../../constants';

const initialState = {
  pageCount: null,
  currentPage: null
};

const handlePaginatedSuccessAction = (state, { meta }) => {
  const currentPage = Number(get(meta, `headers.${PAGINATION_HEADERS.CURRENT_PAGE}`, 1));
  const pageCount = Number(get(meta, `headers.${PAGINATION_HEADERS.PAGE_COUNT}`, 1));

  return { pageCount, currentPage };
};

const pagination = combineReducers({
  transactions: createReducer(initialState, {
    [actions.resetTransactions]: () => initialState,
    [actions.requestTransactions.success]: handlePaginatedSuccessAction
  }),
  deposits: createReducer(initialState, {
    [actions.resetDeposits]: () => initialState,
    [actions.requestDeposits.success]: handlePaginatedSuccessAction
  }),
  tickets: createReducer(initialState, {
    [actions.resetTickets]: () => initialState,
    [actions.requestTickets.success]: handlePaginatedSuccessAction
  })
});

export default pagination;

import { WorkplaceType, AreaType } from '../types';

export const TULSKAYA_FLOOR_6_AREAS = [
  {
    uuid: 'area-640',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '640',
    svgPath: 'M3156.89 104C3220.38 158.098 3271.76 225.973 3306.42 303H3047V280H3045V250H3047V240H3060V104H3156.89Z',
    workplaces: [
      { uuid: 'work-640-1', name: '640', position: { x: 3110, y: 160, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-2', name: '640', position: { x: 3110, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-3', name: '640', position: { x: 3150, y: 160, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-4', name: '640', position: { x: 3150, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-5', name: '640', position: { x: 3100, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-6', name: '640', position: { x: 3140, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-7', name: '640', position: { x: 3180, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-640-8', name: '640', position: { x: 3220, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-639',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '639',
    svgPath:
      'M3308.2 307H3047V380H3045V410H3047V420H3150V433H3230V430H3250V433H3346.83C3338.83 389.017 3325.74 346.807 3308.2 307Z',
    workplaces: [
      { uuid: 'work-639-1', name: '639', position: { x: 3100, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-2', name: '639', position: { x: 3140, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-3', name: '639', position: { x: 3180, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-4', name: '639', position: { x: 3220, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-5', name: '639', position: { x: 3160, y: 390, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-6', name: '639', position: { x: 3200, y: 390, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-7', name: '639', position: { x: 3240, y: 390, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-8', name: '639', position: { x: 3280, y: 390, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-638',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '638',
    svgPath:
      'M3347.55 437H3250V450H3230V437H3150V460H3047V470H3045V500H3047V563H3355.32C3355.77 553.724 3356 544.388 3356 535C3356 501.578 3353.1 468.832 3347.55 437Z',
    workplaces: [
      { uuid: 'work-638-1', name: '638', position: { x: 3160, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-2', name: '638', position: { x: 3200, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-3', name: '638', position: { x: 3240, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-4', name: '638', position: { x: 3280, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-5', name: '638', position: { x: 3090, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-6', name: '638', position: { x: 3130, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-7', name: '638', position: { x: 3170, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-8', name: '638', position: { x: 3210, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-9', name: '638', position: { x: 3250, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-10', name: '638', position: { x: 3290, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-637',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '637',
    svgPath:
      'M3355.11 567H3047V580H3045V610H3047V620H3050V640H3047V683H3336.45C3346.53 645.718 3352.89 606.911 3355.11 567Z',
    workplaces: [
      { uuid: 'work-637-1', name: '637', position: { x: 3100, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-2', name: '637', position: { x: 3140, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-3', name: '637', position: { x: 3180, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-4', name: '637', position: { x: 3250, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-5', name: '637', position: { x: 3290, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-6', name: '637', position: { x: 3060, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-7', name: '637', position: { x: 3100, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-8', name: '637', position: { x: 3140, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-9', name: '637', position: { x: 3180, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-10', name: '637', position: { x: 3270, y: 640, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-636',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '636',
    svgPath: 'M3335.36 687H3047V740H3045V770H3047V780H3150V803H3288.66C3308.29 766.548 3324.04 727.697 3335.36 687Z',
    workplaces: [
      { uuid: 'work-636-1', name: '636', position: { x: 3080, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-2', name: '636', position: { x: 3120, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-3', name: '636', position: { x: 3160, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-4', name: '636', position: { x: 3200, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-5', name: '636', position: { x: 3240, y: 690, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-6', name: '636', position: { x: 3160, y: 760, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-7', name: '636', position: { x: 3200, y: 760, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-635',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '635',
    svgPath: 'M3286.48 807C3253.2 867.614 3209.15 921.473 3156.89 966H3060V840H3045V810H3150V807H3286.48Z',
    workplaces: [
      { uuid: 'work-635-1', name: '635', position: { x: 3110, y: 830, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-2', name: '635', position: { x: 3110, y: 870, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-3', name: '635', position: { x: 3150, y: 830, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-4', name: '635', position: { x: 3150, y: 870, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-634',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '634',
    svgPath: 'M2940 1050V847H2980V845H3010V890H3020V960H3056V1056H2917V1050H2940Z',
    workplaces: [
      { uuid: 'work-634-1', name: '634', position: { x: 2940, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-2', name: '634', position: { x: 2940, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-3', name: '634', position: { x: 2940, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-4', name: '634', position: { x: 3010, y: 990, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-633',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '633',
    svgPath: 'M2650 860V847H2680V845H2710V847H2790V860H2810V1050H2828V1056H2642V1050H2650V1030H2640V860H2650Z',
    workplaces: [
      { uuid: 'work-633-1', name: '633', position: { x: 2770, y: 910, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-2', name: '633', position: { x: 2770, y: 950, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-3', name: '633', position: { x: 2770, y: 990, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-4', name: '633', position: { x: 2700, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-5', name: '633', position: { x: 2700, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-6', name: '633', position: { x: 2700, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-7', name: '633', position: { x: 2660, y: 910, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-8', name: '633', position: { x: 2660, y: 950, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-9', name: '633', position: { x: 2660, y: 990, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-628',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '628',
    svgPath: 'M1767 646V487H1810V485H1840V487H1870V490H1880V630H1890V640H1893V646H1767Z',
    workplaces: [
      { uuid: 'work-628-1', name: '628', position: { x: 1840, y: 500, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-2', name: '628', position: { x: 1840, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-3', name: '628', position: { x: 1840, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-4', name: '628', position: { x: 1770, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-5', name: '628', position: { x: 1770, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-6', name: '628', position: { x: 1770, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-627',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '257',
    svgPath: 'M1707 620V487H1730V485H1760V487H1763V646H1702V640H1710V620H1707Z',
    workplaces: [
      { uuid: 'work-627-1', name: '627', position: { x: 1710, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-627-2', name: '627', position: { x: 1710, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-626',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '626',
    svgPath: 'M1577 646V487H1640V485H1670V487H1703V620H1690V640H1698V646H1577Z',
    workplaces: [
      { uuid: 'work-626-1', name: '626', position: { x: 1660, y: 500, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-2', name: '626', position: { x: 1660, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-3', name: '626', position: { x: 1660, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-4', name: '626', position: { x: 1580, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-5', name: '626', position: { x: 1580, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-6', name: '626', position: { x: 1580, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-625',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '625',
    svgPath: 'M1440 620V490H1470V487H1510V485H1540V487H1573V646H1442V640H1450V620H1440Z',
    workplaces: [
      { uuid: 'work-625-1', name: '625', position: { x: 1530, y: 500, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-625-2', name: '625', position: { x: 1530, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-625-3', name: '625', position: { x: 1530, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-625-5', name: '625', position: { x: 1440, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-625-6', name: '625', position: { x: 1440, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-624',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '624',
    svgPath: 'M1307 646V447H1370V445H1400V447H1410V630H1430V640H1438V646H1307Z',
    workplaces: [
      { uuid: 'work-624-1', name: '624', position: { x: 1370, y: 500, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-2', name: '624', position: { x: 1370, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-3', name: '624', position: { x: 1370, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-4', name: '624', position: { x: 1310, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-5', name: '624', position: { x: 1310, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-6', name: '624', position: { x: 1310, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-623',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '623',
    svgPath: 'M1237 620V450H1250V447H1260V445H1290V447H1303V646H1237V640H1250V620H1237Z',
    workplaces: [
      { uuid: 'work-623-1', name: '623', position: { x: 1240, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-623-2', name: '623', position: { x: 1240, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-623-3', name: '623', position: { x: 1240, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-622',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '622',
    svgPath: 'M1042 640V646H1233V640H1210V620H1233V450H1210V447H1177V487H1110V485H1080V487H1070V630H1050V640H1042Z',
    workplaces: [
      { uuid: 'work-622-1', name: '622', position: { x: 1190, y: 500, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-2', name: '622', position: { x: 1190, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-3', name: '622', position: { x: 1190, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-4', name: '622', position: { x: 1130, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-5', name: '622', position: { x: 1130, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-6', name: '622', position: { x: 1090, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-622-7', name: '622', position: { x: 1090, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-621',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '621',
    svgPath: 'M852 640V646H1038V640H1030V620H1040V487H1030V485H1000V487H900V490H870V640H852Z',
    workplaces: [
      { uuid: 'work-621-1', name: '621', position: { x: 940, y: 540, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-2', name: '621', position: { x: 940, y: 580, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-3', name: '621', position: { x: 980, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-4', name: '621', position: { x: 980, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-5', name: '621', position: { x: 870, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-6', name: '621', position: { x: 870, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-7', name: '621', position: { x: 870, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-613',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '613',
    svgPath:
      'M755.192 741.249C782.311 712.883 805.639 680.862 824.362 646H848V640H830V630H825L808.5 622L816.5 605.5L820.5 607.5L829 587.5L659.5 522.5H657.5L646.5 540.5L645.5 540L628.5 565.5L629 566.5L607.5 600L755.192 741.249Z',
    workplaces: [
      { uuid: 'work-613-1', name: '613', position: { x: 689.07, y: 540.33, angle: 201 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-2', name: '613', position: { x: 726.41, y: 554.67, angle: 201 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-3', name: '613', position: { x: 763.76, y: 569, angle: 201 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-4', name: '613', position: { x: 642.2, y: 602.44, angle: 44 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-5', name: '613', position: { x: 670.97, y: 630.22, angle: 44 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-6', name: '613', position: { x: 699.74, y: 658.01, angle: 44 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-612',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '612',
    svgPath:
      'M754.194 742.288C726.096 771.486 693.967 796.777 658.702 817.264L655 811L661.5 807L561.5 626.5L572 621V619.5L599 604L599.5 605L607 601L754.194 742.288Z',
    workplaces: [
      { uuid: 'work-612-1', name: '612', position: { x: 593.16, y: 662.27, angle: 61 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-612-2', name: '612', position: { x: 612.56, y: 697.25, angle: 61 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-612-3', name: '612', position: { x: 631.95, y: 732.23, angle: 61 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-611',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '611',
    svgPath:
      'M656.274 818.664C622.231 838.15 585.308 853.177 546.302 862.949L544.5 856L552.5 854L548.5 836.5L540 838.5L496.5 664.5L508 658V656.5L535 641L535.5 642L546 636L646 816L652.5 812.5L656.274 818.664Z',
    workplaces: [
      { uuid: 'work-611-1', name: '611', position: { x: 542, y: 690, angle: 241 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-611-2', name: '611', position: { x: 562, y: 725, angle: 241 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-611-3', name: '611', position: { x: 581, y: 760, angle: 241 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-610',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '610',
    svgPath:
      'M544.089 863.498C510.734 871.668 475.873 876 440 876C409.352 876 379.442 872.838 350.579 866.822L352 860L359.5 861.5L363.5 844L356.5 842L394 666H430V664H460V666H495L538 839L530.5 841L535 859L542.5 857L544.089 863.498Z',
    workplaces: [
      { uuid: 'work-610-1', name: '610', position: { x: 467, y: 712.94, angle: 255 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-2', name: '610', position: { x: 477, y: 751.76, angle: 255 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-3', name: '610', position: { x: 487, y: 790.57, angle: 255 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-4', name: '610', position: { x: 376.6, y: 715.15, angle: 100 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-5', name: '610', position: { x: 368.28, y: 754.28, angle: 100 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-6', name: '610', position: { x: 359.96, y: 793.41, angle: 100 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-609',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '609',
    svgPath:
      'M348.682 866.422C307.998 857.752 269.415 843.407 233.8 824.255L238.5 816.5L245 820L342 639L353.5 645L354.5 644L381.5 658.5L381 659.5L392 665.5L354 842L346 840L342 858L350 860L348.682 866.422Z',
    workplaces: [
      { uuid: 'work-609-1', name: '609', position: { x: 304, y: 697, angle: 119 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-609-2', name: '609', position: { x: 285, y: 732, angle: 119 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-609-3', name: '609', position: { x: 266.5, y: 767, angle: 118 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-608',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '608',
    svgPath:
      'M232.145 823.36C195.24 803.308 161.555 778.083 132.062 748.658L137 744.5L143 750L156 737L150.5 731.5L278 605L287.5 610L288.5 609L315.5 623.5L315 624.5L325 630L229 811.5L236 816L232.145 823.36Z',
    workplaces: [
      { uuid: 'work-608-1', name: '608', position: { x: 250, y: 669, angle: 299 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-608-2', name: '608', position: { x: 231, y: 704, angle: 299 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-608-3', name: '608', position: { x: 212, y: 739, angle: 299 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-607',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '607',
    svgPath:
      'M130.454 747.046C86.6309 702.869 52.1997 649.361 30.4033 589.767L223 520L244.5 553.5L246 553L262.5 578.5L261.5 579.5L276.5 602.5L149 730L143 724L130 737L135 742.5L130.454 747.046Z',
    workplaces: [
      { uuid: 'work-607-1', name: '607', position: { x: 195, y: 610, angle: 315 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-2', name: '607', position: { x: 165, y: 640, angle: 315 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-3', name: '607', position: { x: 135, y: 670, angle: 315 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-4', name: '607', position: { x: 145, y: 540, angle: 160 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-5', name: '607', position: { x: 108, y: 553, angle: 160 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-6', name: '607', position: { x: 70, y: 567, angle: 160 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-606',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '606',
    svgPath:
      'M29.7282 587.909C15.8568 549.438 7.24235 508.456 4.75195 465.829L11.9995 465.5L12.4995 473.5L218.5 463L219 476L220.5 476.5L222 507L221 507.5L221.5 517.5L29.7282 587.909Z',
    workplaces: [
      { uuid: 'work-606-1', name: '606', position: { x: 122, y: 468, angle: 178 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-606-2', name: '606', position: { x: 82, y: 470, angle: 178 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-606-3', name: '606', position: { x: 42, y: 472, angle: 178 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-605',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '605',
    svgPath:
      'M4.61263 463.313C4.20592 455.593 4 447.82 4 440C4 406.116 7.86527 373.134 15.1789 341.473L215 388L216 389L214.5 389.5L215 401L216.5 401.5L218.5 432L217 432.5L217.5 444L11.5 455.5V463L4.61263 463.313Z',
    workplaces: [
      { uuid: 'work-605-1', name: '605', position: { x: 117, y: 408, angle: 357 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-605-2', name: '605', position: { x: 77, y: 410, angle: 357 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-605-3', name: '605', position: { x: 37, y: 412, angle: 357 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-604',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '604',
    svgPath:
      'M96.6927 171.201L257.5 297L241 333L242 334L229 362L228 361L216.5 385.5L15.7783 338.913C30.5216 276.809 58.5489 219.85 96.6927 171.201Z',
    workplaces: [
      { uuid: 'work-604-1', name: '604', position: { x: 130, y: 326, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-2', name: '604', position: { x: 90, y: 316, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-3', name: '604', position: { x: 50, y: 306, angle: 13 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-4', name: '604', position: { x: 99, y: 193, angle: 216 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-5', name: '604', position: { x: 130, y: 217, angle: 216 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-6', name: '604', position: { x: 161, y: 242, angle: 216 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-603',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '603',
    svgPath:
      'M98.2656 169.208C124.134 136.605 154.57 107.791 188.605 83.7305L193 89.9998L186 94.9998L304.5 264L293.5 271.5L294 273L269 290.5L267.5 290L259.5 295.5L98.2656 169.208Z',
    workplaces: [
      { uuid: 'work-603-1', name: '603', position: { x: 172, y: 122, angle: 235 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-603-2', name: '603', position: { x: 195, y: 155, angle: 235 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-603-3', name: '603', position: { x: 218, y: 187, angle: 235 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-602',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '602',
    svgPath:
      'M191.013 82.041C225.608 57.9323 263.862 38.7106 304.776 25.376L367.5 219.5L357 227L358 228L332.5 246L332 244.5L320 252.5L202 84.0001L195.5 88.5001L191.013 82.041Z',
    workplaces: [
      { uuid: 'work-602-1', name: '602', position: { x: 222, y: 87, angle: 56 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-602-2', name: '602', position: { x: 244, y: 120, angle: 56 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-602-3', name: '602', position: { x: 267, y: 153, angle: 56 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-653',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '653',
    svgPath: 'M867 20V14H983V20H980V30H970V120H950V143H940V145H910V143H870V20H867Z',
    workplaces: [
      { uuid: 'work-653-1', name: '653', position: { x: 870, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-653-2', name: '653', position: { x: 870, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-653-3', name: '653', position: { x: 930, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-652',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '652',
    svgPath: 'M987 20V14H1103V143H1070V145H1040V143H1000V30H990V20H987Z',
    workplaces: [
      { uuid: 'work-652-1', name: '652', position: { x: 1000, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-652-2', name: '652', position: { x: 1000, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-652-3', name: '652', position: { x: 1060, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-652-3', name: '652', position: { x: 1060, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-651',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '651',
    svgPath: 'M1107 143V14H1228V20H1210V40H1223V143H1170V145H1140V143H1107Z',
    workplaces: [
      { uuid: 'work-651-1', name: '651', position: { x: 1110, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-651-2', name: '651', position: { x: 1110, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-651-3', name: '651', position: { x: 1180, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-651-3', name: '651', position: { x: 1180, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-650',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '650',
    svgPath: 'M1232 20V14H1303V223H1250V210H1225V180H1227V40H1250V20H1232Z',
    workplaces: [
      { uuid: 'work-650-1', name: '650', position: { x: 1260, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-650-2', name: '650', position: { x: 1260, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-650-3', name: '650', position: { x: 1260, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-650-4', name: '650', position: { x: 1260, y: 160, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-648',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '648',
    svgPath: 'M1307 223V14H1438V20H1430V180H1435V210H1430V223H1307Z',
    workplaces: [
      { uuid: 'work-648-1', name: '648', position: { x: 1310, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-2', name: '648', position: { x: 1310, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-3', name: '648', position: { x: 1310, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-4', name: '648', position: { x: 1310, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-1', name: '648', position: { x: 1390, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-2', name: '648', position: { x: 1390, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-648-3', name: '648', position: { x: 1390, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-647',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '647',
    svgPath: 'M1767 20V14H1908V20H1890V30H1870V173H1850V175H1820V173H1800V170H1770V20H1767Z',
    workplaces: [
      { uuid: 'work-647-1', name: '647', position: { x: 1770, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-647-2', name: '647', position: { x: 1770, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-647-3', name: '647', position: { x: 1830, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-647-4', name: '647', position: { x: 1830, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-647-5', name: '647', position: { x: 1830, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-646',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '646',
    svgPath: 'M1900 180H1895V210H1930V213H2090V210H2093V40H2090V20H2098V14H1912V20H1930V40H1900V180Z',
    workplaces: [
      { uuid: 'work-646-1', name: '646', position: { x: 1940, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-2', name: '646', position: { x: 1940, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-3', name: '646', position: { x: 1940, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-4', name: '646', position: { x: 1980, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-5', name: '646', position: { x: 1980, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-6', name: '646', position: { x: 1980, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-7', name: '646', position: { x: 2050, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-8', name: '646', position: { x: 2050, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-646-9', name: '646', position: { x: 2050, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-644',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '644',
    svgPath: 'M2102 20V14H2213V20H2200V233H2140V235H2110V233H2100V230H2110V210H2097V40H2110V20H2102Z',
    workplaces: [
      { uuid: 'work-644-1', name: '644', position: { x: 2100, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-2', name: '644', position: { x: 2100, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-3', name: '644', position: { x: 2100, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-4', name: '644', position: { x: 2160, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-5', name: '644', position: { x: 2160, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-6', name: '644', position: { x: 2160, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-644-7', name: '644', position: { x: 2160, y: 160, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-643',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '643',
    svgPath: 'M2217 20V14H2333V233H2260V235H2230V20H2217Z',
    workplaces: [
      { uuid: 'work-643-1', name: '643', position: { x: 2230, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-2', name: '643', position: { x: 2230, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-3', name: '643', position: { x: 2230, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-4', name: '643', position: { x: 2290, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-5', name: '643', position: { x: 2290, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-6', name: '643', position: { x: 2290, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-643-7', name: '643', position: { x: 2290, y: 160, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-642',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '642',
    svgPath: 'M2337 233V14H2458V20H2450V40H2453V210H2450V230H2458V233H2380V235H2350V233H2337Z',
    workplaces: [
      { uuid: 'work-642-1', name: '642', position: { x: 2340, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-2', name: '642', position: { x: 2340, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-3', name: '642', position: { x: 2340, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-4', name: '642', position: { x: 2410, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-5', name: '642', position: { x: 2410, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-6', name: '642', position: { x: 2410, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-642-7', name: '642', position: { x: 2410, y: 160, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-641',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '641',
    svgPath: 'M2462 20V14H2573V20H2560V233H2500V235H2470V233H2462V230H2470V210H2457V40H2470V20H2462Z',
    workplaces: [
      { uuid: 'work-641-1', name: '641', position: { x: 2460, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-2', name: '641', position: { x: 2460, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-3', name: '641', position: { x: 2460, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-4', name: '641', position: { x: 2520, y: 40, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-5', name: '641', position: { x: 2520, y: 80, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-6', name: '641', position: { x: 2520, y: 120, angle: 270 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-641-7', name: '641', position: { x: 2520, y: 160, angle: 270 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'T-6-OP-1',
    uuid: 'area-t-6-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    //todo вставить
    svgPath: '',
    workplaces: [
      { name: 'К603', uuid: 't6-op-1-1', type: WorkplaceType.DEFAULT, position: { x: 970, y: 230, angle: 180 } },
      { name: 'К604', uuid: 't6-op-1-2', type: WorkplaceType.DEFAULT, position: { x: 1010, y: 230, angle: 180 } },
      {
        name: 'К601',
        textRotate: true,
        uuid: 't6-op-1-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 970, y: 320, angle: 0 }
      },
      {
        name: 'К602',
        textRotate: true,
        uuid: 't6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1010, y: 320, angle: 0 }
      }
    ]
  },
  {
    name: 'T-6-OP-2',
    uuid: 'area-t-6-op-sp-2',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    //todo вставить
    svgPath: '',
    workplaces: [
      { name: 'К622', uuid: 't6-op-2-1', type: WorkplaceType.DEFAULT, position: { x: 1510, y: 220, angle: 90 } },
      { name: 'К621', uuid: 't6-op-2-2', type: WorkplaceType.DEFAULT, position: { x: 1510, y: 260, angle: 90 } },
      { name: 'К620', uuid: 't6-op-2-3', type: WorkplaceType.DEFAULT, position: { x: 1510, y: 300, angle: 90 } },
      {
        name: 'К625',
        textRotate: true,
        uuid: 't6-op-2-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1600, y: 220, angle: 270 }
      },
      {
        name: 'К623',
        textRotate: true,
        uuid: 't6-op-2-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1600, y: 260, angle: 270 }
      },
      { name: 'К624', uuid: 't6-op-2-6', type: WorkplaceType.DEFAULT, position: { x: 1640, y: 220, angle: 90 } },
      { name: 'К626', uuid: 't6-op-2-7', type: WorkplaceType.DEFAULT, position: { x: 1640, y: 260, angle: 90 } },
      {
        name: 'К629',
        textRotate: true,
        uuid: 't6-op-2-8',
        type: WorkplaceType.DEFAULT,
        position: { x: 1740, y: 220, angle: 270 }
      },
      {
        name: 'К628',
        textRotate: true,
        uuid: 't6-op-2-9',
        type: WorkplaceType.DEFAULT,
        position: { x: 1740, y: 260, angle: 270 }
      },
      {
        name: 'К627',
        textRotate: true,
        uuid: 't6-op-1-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 1740, y: 300, angle: 270 }
      }
    ]
  },
  {
    name: 'T-6-OP-4',
    uuid: 'area-t-6-op-sp-2',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    //todo вставить
    svgPath: '',
    workplaces: [
      {
        name: 'К609',
        uuid: 't6-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 40, angle: 270 },
        textRotate: true
      },
      {
        name: 'К607',
        uuid: 't6-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 80, angle: 270 },
        textRotate: true
      },
      {
        name: 'К605',
        uuid: 't6-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 120, angle: 270 },
        textRotate: true
      },
      {
        name: 'К610',
        uuid: 't6-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 40, angle: 90 }
      },
      {
        name: 'К608',
        uuid: 't6-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 80, angle: 90 }
      },
      {
        name: 'К606',
        uuid: 't6-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 120, angle: 90 }
      },
      {
        name: 'К615',
        uuid: 't6-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1600, y: 40, angle: 270 },
        textRotate: true
      },
      {
        name: 'К613',
        uuid: 't6-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1600, y: 80, angle: 270 },
        textRotate: true
      },
      {
        name: 'К611',
        uuid: 't6-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1600, y: 120, angle: 270 },
        textRotate: true
      },
      {
        name: 'К616',
        uuid: 't6-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1640, y: 40, angle: 90 }
      },
      {
        name: 'К614',
        uuid: 't6-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1640, y: 80, angle: 90 }
      },
      {
        name: 'К612',
        uuid: 't6-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1640, y: 120, angle: 90 }
      },
      {
        name: 'К619',
        uuid: 't6-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1720, y: 40, angle: 270 },
        textRotate: true
      },
      {
        name: 'К618',
        uuid: 't6-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1720, y: 80, angle: 270 },
        textRotate: true
      },
      {
        name: 'К617',
        uuid: 't6-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1720, y: 120, angle: 270 },
        textRotate: true
      }
    ]
  },
  {
    name: 'T-6-OP-3',
    uuid: 'area-t-6-op-sp-3',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    //todo вставить
    svgPath: '',
    workplaces: [
      { name: 'К632', uuid: 't6-op-3-1', type: WorkplaceType.DEFAULT, position: { x: 2590, y: 70, angle: 90 } },
      { name: 'К631', uuid: 't6-op-3-2', type: WorkplaceType.DEFAULT, position: { x: 2590, y: 110, angle: 90 } },
      { name: 'К630', uuid: 't6-op-3-3', type: WorkplaceType.DEFAULT, position: { x: 2590, y: 150, angle: 90 } },
      {
        name: 'К637',
        textRotate: true,
        uuid: 't6-op-3-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 2660, y: 70, angle: 270 }
      },
      {
        name: 'К635',
        textRotate: true,
        uuid: 't6-op-3-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 2660, y: 110, angle: 270 }
      },
      {
        name: 'К633',
        textRotate: true,
        uuid: 't6-op-3-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 2660, y: 150, angle: 270 }
      },
      { name: 'К638', uuid: 't6-op-3-7', type: WorkplaceType.DEFAULT, position: { x: 2700, y: 70, angle: 90 } },
      { name: 'К636', uuid: 't6-op-3-8', type: WorkplaceType.DEFAULT, position: { x: 2700, y: 110, angle: 90 } },
      { name: 'К634', uuid: 't6-op-3-9', type: WorkplaceType.DEFAULT, position: { x: 2700, y: 150, angle: 90 } },
      {
        name: 'К643',
        textRotate: true,
        uuid: 't6-op-3-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 2770, y: 70, angle: 270 }
      },
      {
        name: 'К641',
        textRotate: true,
        uuid: 't6-op-3-11',
        type: WorkplaceType.DEFAULT,
        position: { x: 2770, y: 110, angle: 270 }
      },
      {
        name: 'К639',
        textRotate: true,
        uuid: 't6-op-3-12',
        type: WorkplaceType.DEFAULT,
        position: { x: 2770, y: 150, angle: 270 }
      },
      { name: 'К644', uuid: 't6-op-3-13', type: WorkplaceType.DEFAULT, position: { x: 2810, y: 70, angle: 90 } },
      { name: 'К642', uuid: 't6-op-3-14', type: WorkplaceType.DEFAULT, position: { x: 2810, y: 110, angle: 90 } },
      { name: 'К640', uuid: 't6-op-3-15', type: WorkplaceType.DEFAULT, position: { x: 2810, y: 150, angle: 90 } },
      {
        name: 'К649',
        textRotate: true,
        uuid: 't6-op-3-16',
        type: WorkplaceType.DEFAULT,
        position: { x: 2880, y: 70, angle: 270 }
      },
      {
        name: 'К647',
        textRotate: true,
        uuid: 't6-op-3-17',
        type: WorkplaceType.DEFAULT,
        position: { x: 2880, y: 110, angle: 270 }
      },
      {
        name: 'К645',
        textRotate: true,
        uuid: 't6-op-3-18',
        type: WorkplaceType.DEFAULT,
        position: { x: 2880, y: 150, angle: 270 }
      },
      { name: 'К650', uuid: 't6-op-3-19', type: WorkplaceType.DEFAULT, position: { x: 2920, y: 70, angle: 90 } },
      { name: 'К648', uuid: 't6-op-3-20', type: WorkplaceType.DEFAULT, position: { x: 2920, y: 110, angle: 90 } },
      { name: 'К646', uuid: 't6-op-3-21', type: WorkplaceType.DEFAULT, position: { x: 2920, y: 150, angle: 90 } }
    ]
  },
  {
    uuid: 'PS-6',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 1250, y: 347 },
        svgPath:
          'M1250 347H1380H1893V430H1890V440H1880V430H1870V483H1840V485H1810V483H1760V485H1730V483H1670V485H1640V483H1540V485H1510V483H1470V440H1450V430H1430V440H1410V443H1400V445H1370V443H1290V445H1260V443H1250V347Z'
      },
      {
        position: { x: 900, y: 390 },
        svgPath: 'M1000 483H900V390H1030V450H1050V480H1040V483H1030V485H1000V483Z'
      },
      {
        position: { x: 2610, y: 555 },
        svgPath:
          'M2650 840V843H2680V845H2710V843H2790V840H2800V850H2810V840H2940V843H2980V845H3010V840H3045V810H3040V780H3043V770H3045V740H3043V640H3030V620H3043V610H3045V580H3043V557H2967H2850V560H2820V555H2790V560H2740V650H2730V630H2670V640H2640V680H2690V780H2610V850H2630V840H2650Z'
      },
      //Переговорные
      {
        position: { x: 730, y: 390 },
        svgPath: 'M760 390H730V430H760V390Z'
      },
      {
        position: { x: 770, y: 390 },
        svgPath: 'M800 390H770V430H800V390Z'
      },
      //Переговорные
      {
        position: { x: 1110, y: 220 },
        svgPath: 'M1160 220H1110V250H1160V220Z'
      },
      {
        position: { x: 1110, y: 260 },
        svgPath: 'M1160 260H1110V290H1160V260Z'
      },
      {
        position: { x: 1110, y: 300 },
        svgPath: 'M1160 300H1110V330H1160V300Z'
      },
      {
        position: { x: 1250, y: 347 },
        svgPath:
          'M1250 347H1380H1893V430H1890V440H1880V430H1870V483H1840V485H1810V483H1760V485H1730V483H1670V485H1640V483H1540V485H1510V483H1470V440H1450V430H1430V440H1410V443H1400V445H1370V443H1290V445H1260V443H1250V347Z'
      },
      {
        position: { x: 2610, y: 555 },
        svgPath:
          'M1250 347H1380H1893V430H1890V440H1880V430H1870V483H1840V485H1810V483H1760V485H1730V483H1670V485H1640V483H1540V485H1510V483H1470V440H1450V430H1430V440H1410V443H1400V445H1370V443H1290V445H1260V443H1250V347Z'
      },
      //Переговорные
      {
        position: { x: 2920, y: 350 },
        svgPath: 'M2970 350H2920V380H2970V350Z'
      },
      {
        position: { x: 2920, y: 390 },
        svgPath: 'M2970 390H2920V420H2970V390Z'
      },
      //Переговорные
      {
        position: { x: 2640, y: 700 },
        svgPath: 'M2690 700H2640V730H2690V700Z'
      },
      {
        position: { x: 2640, y: 740 },
        svgPath: 'M2690 740H2640V770H2690V740Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];
export const TULSKAYA_FLOOR_5_AREAS = [
  {
    uuid: 'area-502',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '502',
    svgPath:
      'M191.013 82.041C225.608 57.9323 263.862 38.7106 304.776 25.376L364.5 209.5L350 219L325 236.5L313 244.5L202 84.0001L195.5 88.5001L191.013 82.041Z',
    workplaces: [
      { uuid: '502-1', name: '502', position: { x: 228, y: 92, angle: 55 }, type: WorkplaceType.DEFAULT },
      { uuid: '502-2', name: '502', position: { x: 251, y: 125, angle: 55 }, type: WorkplaceType.DEFAULT },
      { uuid: '502-3', name: '502', position: { x: 274, y: 158, angle: 55 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-503',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '503',
    svgPath:
      'M98.2656 169.208C124.134 136.605 154.57 107.791 188.605 83.7305L193 89.9998L186 94.9998L299 255L250 289L98.2656 169.208Z',
    workplaces: [
      { uuid: '503-1', name: '503', position: { x: 177, y: 127, angle: -125 }, type: WorkplaceType.DEFAULT },
      { uuid: '503-2', name: '503', position: { x: 200, y: 160, angle: -125 }, type: WorkplaceType.DEFAULT },
      { uuid: '503-3', name: '503', position: { x: 223, y: 193, angle: -125 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-504',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '504',
    svgPath: 'M96.5 169.5L248.5 288.5L203.5 384L12.7783 338.913C27.5216 276.809 58.3562 218.149 96.5 169.5Z',
    workplaces: [
      { uuid: '504-1', name: '504', position: { x: 75, y: 276, angle: -155 }, type: WorkplaceType.DEFAULT },
      { uuid: '504-2', name: '504', position: { x: 111, y: 293, angle: -155 }, type: WorkplaceType.DEFAULT },
      { uuid: '504-3', name: '504', position: { x: 147, y: 310, angle: -155 }, type: WorkplaceType.DEFAULT },
      { uuid: '504-4', name: '504', position: { x: 92, y: 240, angle: 25 }, type: WorkplaceType.DEFAULT },
      { uuid: '504-5', name: '504', position: { x: 128, y: 257, angle: 25 }, type: WorkplaceType.DEFAULT },
      { uuid: '504-6', name: '504', position: { x: 164, y: 274, angle: 25 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-505',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '505',
    svgPath:
      'M4.46107 462.788C4.05436 455.069 4 447.82 4 440C4 406.116 7.86527 373.134 15.1789 341.473L203 384L206 445.5L11.5 455.5L11.3484 462.475L4.46107 462.788Z',
    workplaces: [
      { uuid: '505-1', name: '505', position: { x: 45, y: 410, angle: -3 }, type: WorkplaceType.DEFAULT },
      { uuid: '505-2', name: '505', position: { x: 85, y: 408, angle: -3 }, type: WorkplaceType.DEFAULT },
      { uuid: '505-3', name: '505', position: { x: 125, y: 406, angle: -3 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-506',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '506',
    svgPath:
      'M29.7282 587.909C15.8568 549.438 7.24235 508.456 4.75195 465.829L11.9995 465.5L12.4995 473.5L206 463L209 523L29.7282 587.909Z',
    workplaces: [
      { uuid: '506-1', name: '506', position: { x: 47, y: 472, angle: 177 }, type: WorkplaceType.DEFAULT },
      { uuid: '506-2', name: '506', position: { x: 87, y: 470, angle: 177 }, type: WorkplaceType.DEFAULT },
      { uuid: '506-3', name: '506', position: { x: 127, y: 468, angle: 177 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-507',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '507',
    svgPath:
      'M130.454 747.046C86.6309 702.869 52.1997 649.361 30.4033 589.767L208.5 523L267.5 614.5L151 731L143 724L130 737L135 742.5L130.454 747.046Z',
    workplaces: [
      { uuid: '507-1', name: '507', position: { x: 88, y: 607, angle: -32 }, type: WorkplaceType.DEFAULT },
      { uuid: '507-2', name: '507', position: { x: 123, y: 585, angle: -32 }, type: WorkplaceType.DEFAULT },
      { uuid: '507-3', name: '507', position: { x: 158, y: 563, angle: -32 }, type: WorkplaceType.DEFAULT },
      { uuid: '507-4', name: '507', position: { x: 110, y: 642, angle: 148 }, type: WorkplaceType.DEFAULT },
      { uuid: '507-5', name: '507', position: { x: 145, y: 620, angle: 148 }, type: WorkplaceType.DEFAULT },
      { uuid: '507-6', name: '507', position: { x: 180, y: 598, angle: 148 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-508',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '508',
    svgPath:
      'M232.145 823.36C195.24 803.308 161.555 778.083 132.062 748.658L137 744.5L143 750L156 737L150.5 731.5L269 615L278.5 620L306 634.5L319.5 641.5L229 811.5L236 816L232.145 823.36Z',
    workplaces: [
      { uuid: '508-1', name: '508', position: { x: 212, y: 740, angle: -62 }, type: WorkplaceType.DEFAULT },
      { uuid: '508-2', name: '508', position: { x: 231, y: 705, angle: -62 }, type: WorkplaceType.DEFAULT },
      { uuid: '508-3', name: '508', position: { x: 250, y: 670, angle: -62 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-509',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '509',
    svgPath:
      'M348.682 866.422C307.998 857.752 269.415 843.407 233.8 824.255L238.5 816.5L245 820L335 650L390 678.5L354 842L346 840L342 858L350 860L348.682 866.422Z',
    workplaces: [
      { uuid: '509-1', name: '509', position: { x: 266, y: 770, angle: 118 }, type: WorkplaceType.DEFAULT },
      { uuid: '509-2', name: '509', position: { x: 284, y: 735, angle: 118 }, type: WorkplaceType.DEFAULT },
      { uuid: '509-3', name: '509', position: { x: 303, y: 700, angle: 118 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-510',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '510',
    svgPath:
      'M544.089 863.498C510.734 871.668 475.873 876 440 876C409.352 876 379.442 872.838 350.579 866.822L352 860L359.5 861.5L363.5 844L356.5 842L390.5 678H430H460H498.5L538 839L530.5 841L535 859L542.5 857L544.089 863.498Z',
    workplaces: [
      { uuid: '510-1', name: '510', position: { x: 405, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '510-2', name: '510', position: { x: 405, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '510-3', name: '510', position: { x: 405, y: 810, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '510-4', name: '510', position: { x: 445, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '510-5', name: '510', position: { x: 445, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '510-6', name: '510', position: { x: 445, y: 810, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-511',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '511',
    svgPath:
      'M656.274 818.664C622.231 838.15 585.308 853.177 546.302 862.949L544.5 856L552.5 854L548.5 836.5L540 838.5L499.5 676.5L551.5 646L646 816L652.5 812.5L656.274 818.664Z',
    workplaces: [
      { uuid: '511-1', name: '511', position: { x: 542, y: 690, angle: -119 }, type: WorkplaceType.DEFAULT },
      { uuid: '511-2', name: '511', position: { x: 561, y: 725, angle: -119 }, type: WorkplaceType.DEFAULT },
      { uuid: '511-3', name: '511', position: { x: 580, y: 760, angle: -119 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-512',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '512',
    svgPath:
      'M754.194 742.288C726.096 771.486 693.967 796.777 658.702 817.264L655 811L661.5 807L567.5 635.5L578 630L604 614.5L613.5 607L754.194 742.288Z',
    workplaces: [
      { uuid: '512-1', name: '512', position: { x: 596, y: 660, angle: 61 }, type: WorkplaceType.DEFAULT },
      { uuid: '512-2', name: '512', position: { x: 616, y: 695, angle: 61 }, type: WorkplaceType.DEFAULT },
      { uuid: '512-3', name: '512', position: { x: 635, y: 730, angle: 61 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-513',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '513',
    svgPath:
      'M755.192 741.249C782.311 712.883 805.639 680.862 824.362 646H848V640H830V630H825L808.5 622L816.5 605.5L820.5 607.5L829 587.5L669 525.5H667L654.5 547.5L653.5 547L637 573.5L615.5 607L755.192 741.249Z',
    workplaces: [
      { uuid: '513-1', name: '513', position: { x: 685, y: 560, angle: 30 }, type: WorkplaceType.DEFAULT },
      { uuid: '513-2', name: '513', position: { x: 720, y: 580, angle: 30 }, type: WorkplaceType.DEFAULT },
      { uuid: '513-3', name: '513', position: { x: 755, y: 600, angle: 30 }, type: WorkplaceType.DEFAULT },
      { uuid: '513-4', name: '513', position: { x: 665, y: 595, angle: -150 }, type: WorkplaceType.DEFAULT },
      { uuid: '513-5', name: '513', position: { x: 700, y: 615, angle: -150 }, type: WorkplaceType.DEFAULT },
      { uuid: '513-6', name: '513', position: { x: 735, y: 635, angle: -150 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-515',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '515',
    svgPath: 'M1030 445H900V490H870V640H850V646H1038V640H1030V620H1040V450H1030V445Z',
    workplaces: [
      { uuid: '515-1', name: '515', position: { x: 980, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-2', name: '515', position: { x: 980, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-3', name: '515', position: { x: 980, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-4', name: '515', position: { x: 940, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-5', name: '515', position: { x: 940, y: 540, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-6', name: '515', position: { x: 940, y: 580, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-7', name: '515', position: { x: 870, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-8', name: '515', position: { x: 870, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '515-9', name: '515', position: { x: 870, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-517',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '517',
    svgPath: 'M1470 475V490H1440V620H1450V640H1442V646H1563V475H1470Z',
    workplaces: [
      { uuid: '517-1', name: '517', position: { x: 1440, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '517-2', name: '517', position: { x: 1440, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '517-4', name: '517', position: { x: 1522, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '517-5', name: '517', position: { x: 1522, y: 540, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '517-6', name: '517', position: { x: 1522, y: 580, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-518',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '518',
    svgPath: 'M1633 475H1567V646H1633V475Z',
    workplaces: [
      { uuid: '518-1', name: '518', position: { x: 1568, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '518-2', name: '518', position: { x: 1568, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '518-3', name: '518', position: { x: 1568, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-519',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '519',
    svgPath: 'M1698 475H1637V646H1698V640H1690V620H1698V475Z',
    workplaces: [
      { uuid: '519-1', name: '519', position: { x: 1656, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '519-2', name: '519', position: { x: 1656, y: 540, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '519-3', name: '519', position: { x: 1656, y: 580, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-520',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '520',
    svgPath: 'M1702 475H1763V646H1702V640H1710V620H1702V475Z',
    workplaces: [
      { uuid: '520-1', name: '520', position: { x: 1704, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '520-2', name: '520', position: { x: 1704, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '520-3', name: '520', position: { x: 1704, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-521',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '521',
    svgPath: 'M1900 646H1767V640V620V475H1870V490H1880V630H1890V640H1900V646Z',
    workplaces: [
      { uuid: '521-1', name: '521', position: { x: 1769, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '521-2', name: '521', position: { x: 1769, y: 540, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '521-3', name: '521', position: { x: 1769, y: 580, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '521-4', name: '521', position: { x: 1839, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '521-5', name: '521', position: { x: 1839, y: 540, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '521-6', name: '521', position: { x: 1839, y: 580, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-524',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '524',
    svgPath: 'M867 20V14H983V20H980V30H970V120H950V145H940H910H870V20H867Z',
    workplaces: [
      { uuid: '524-1', name: '524', position: { x: 870, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '524-2', name: '524', position: { x: 870, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '524-3', name: '524', position: { x: 930, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-526',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '526',
    svgPath: 'M1460 165V30H1450V20H1442V14H1563V20.916V32.4427V165H1523.81H1496.46H1460Z',
    workplaces: [
      { uuid: '526-1', name: '526', position: { x: 1460, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '526-2', name: '526', position: { x: 1460, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '526-3', name: '526', position: { x: 1522, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '526-4', name: '526', position: { x: 1522, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-527',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '527',
    svgPath: 'M1567 20.916V14H1633V20.916V32.4427V165H1607.88H1590.36H1567V20.916Z',
    workplaces: [
      { uuid: '527-1', name: '527', position: { x: 1569, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '527-2', name: '527', position: { x: 1569, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-528',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '528',
    svgPath: 'M1698 20V14H1637V20.916V32.4427V165H1660.21H1676.41H1698V40H1690V20H1698Z',
    workplaces: [
      { uuid: '528-1', name: '528', position: { x: 1657, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '528-2', name: '528', position: { x: 1657, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-529',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '529',
    svgPath: 'M1702 20V14H1763V20.916V32.4427V165H1739.79H1723.59H1702V40H1710V20H1702Z',
    workplaces: [
      { uuid: '529-1', name: '529', position: { x: 1703, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '529-2', name: '529', position: { x: 1703, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-530',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '530',
    svgPath: 'M1767 20.916V14H1890V30H1870V165H1830.81H1803.46H1767V20.916Z',
    workplaces: [
      { uuid: '530-1', name: '530', position: { x: 1768, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '530-2', name: '530', position: { x: 1768, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '530-3', name: '530', position: { x: 1829, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '530-4', name: '530', position: { x: 1829, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-531',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '531',
    svgPath: 'M2230 235V30H2217V14H2230H2333V24.1221V40.9924V235H2293.81H2266.46H2230Z',
    workplaces: [
      { uuid: '531-1', name: '531', position: { x: 2231, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '531-2', name: '531', position: { x: 2231, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '531-3', name: '531', position: { x: 2231, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '531-4', name: '531', position: { x: 2292, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '531-5', name: '531', position: { x: 2292, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '531-6', name: '531', position: { x: 2292, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-532',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '532',
    svgPath: 'M2337 24.1221V14H2453V20H2450V40H2453V210H2450V230H2453V235H2408.86H2378.06H2337V24.1221Z',
    workplaces: [
      { uuid: '532-1', name: '532', position: { x: 2338, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-2', name: '532', position: { x: 2338, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-3', name: '532', position: { x: 2338, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-4', name: '532', position: { x: 2338, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-5', name: '532', position: { x: 2412, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-6', name: '532', position: { x: 2412, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-7', name: '532', position: { x: 2412, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '532-8', name: '532', position: { x: 2412, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-533',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '533',
    svgPath: 'M2493.46 235H2457V230H2470V210H2457V40H2470V20H2457V14H2560H2573V30H2560V40.9924V235H2520.81H2493.46Z',
    workplaces: [
      { uuid: '533-1', name: '533', position: { x: 2458, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-2', name: '533', position: { x: 2458, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-3', name: '533', position: { x: 2458, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-4', name: '533', position: { x: 2520, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-5', name: '533', position: { x: 2520, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-6', name: '533', position: { x: 2520, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '533-7', name: '533', position: { x: 2520, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-534',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '534',
    svgPath: 'M2590 235V30H2577V14H2590H2703V24.1221V40.9924V235H2660H2630H2590Z',
    workplaces: [
      { uuid: '534-1', name: '534', position: { x: 2590, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-2', name: '534', position: { x: 2590, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-3', name: '534', position: { x: 2590, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-4', name: '534', position: { x: 2590, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-5', name: '534', position: { x: 2662, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-6', name: '534', position: { x: 2662, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '534-7', name: '534', position: { x: 2662, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-535',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '535',
    svgPath: 'M2707 23.1679V14H2813L2813 20H2810L2810 40H2813L2813 210H2810V230H2813V235H2772.66H2744.52H2707V23.1679Z',
    workplaces: [
      { uuid: '535-1', name: '535', position: { x: 2708, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-2', name: '535', position: { x: 2708, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-3', name: '535', position: { x: 2708, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-4', name: '535', position: { x: 2708, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-5', name: '535', position: { x: 2772, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-6', name: '535', position: { x: 2772, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-7', name: '535', position: { x: 2772, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '535-8', name: '535', position: { x: 2772, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-536',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '536',
    svgPath: 'M2943 235H2817V230H2830V210H2817V39.8784H2830V19.9685H2817V14H2943V23.1221V39.9924V235Z',
    workplaces: [
      { uuid: '536-1', name: '536', position: { x: 2818, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-2', name: '536', position: { x: 2818, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-3', name: '536', position: { x: 2818, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-4', name: '536', position: { x: 2902, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-5', name: '536', position: { x: 2902, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-6', name: '536', position: { x: 2902, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '536-7', name: '536', position: { x: 2902, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-537',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '537',
    svgPath: 'M2947 24.1221V14H3056V110H3020V235H2947V24.1221Z',
    workplaces: [
      { uuid: '537-1', name: '537', position: { x: 2948, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '537-2', name: '537', position: { x: 2948, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '537-3', name: '537', position: { x: 2948, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '537-4', name: '537', position: { x: 2942, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '537-5', name: '537', position: { x: 3010, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-545',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '545',
    svgPath: 'M2393 650H2280V760H2300V765H2393V650Z',
    workplaces: [
      { uuid: '545-1', name: '545', position: { x: 2280, y: 650, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '545-2', name: '545', position: { x: 2280, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '545-3', name: '545', position: { x: 2352, y: 650, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '545-4', name: '545', position: { x: 2352, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-546',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '546',
    svgPath: 'M2397 655.267V650H2513V655.267V664.046V765H2468.86H2438.06H2397V655.267Z',
    workplaces: [
      { uuid: '546-1', name: '546', position: { x: 2398, y: 650, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '546-2', name: '546', position: { x: 2398, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '546-3', name: '546', position: { x: 2472, y: 650, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '546-4', name: '546', position: { x: 2472, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-547',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '547',
    svgPath: 'M2517 655.267V650H2610V655.267V664.046V765H2574.61H2549.92H2517V655.267Z',
    workplaces: [
      { uuid: '547-1', name: '547', position: { x: 2518, y: 650, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '547-2', name: '547', position: { x: 2518, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '547-3', name: '547', position: { x: 2570, y: 650, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '547-4', name: '547', position: { x: 2570, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-548',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '548',
    svgPath: 'M2280 890H2300V885H2393V1056H2277V1050H2290V1030H2280V890Z',
    workplaces: [
      { uuid: '548-1', name: '548', position: { x: 2280, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '548-2', name: '548', position: { x: 2280, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '548-3', name: '548', position: { x: 2280, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '548-4', name: '548', position: { x: 2352, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '548-5', name: '548', position: { x: 2352, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '548-6', name: '548', position: { x: 2352, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-549',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '549',
    svgPath: 'M2397 892.832V885H2523V892.832V905.885V1056H2475.05H2441.6H2397V892.832Z',
    workplaces: [
      { uuid: '549-1', name: '549', position: { x: 2398, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '549-2', name: '549', position: { x: 2398, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '549-3', name: '549', position: { x: 2482, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '549-4', name: '549', position: { x: 2482, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '549-5', name: '549', position: { x: 2482, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-550',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '550',
    svgPath: 'M2527 1056V885H2610V1040H2630V1050H2638V1056H2527Z',
    workplaces: [
      { uuid: '550-1', name: '550', position: { x: 2569, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '550-2', name: '550', position: { x: 2569, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '550-3', name: '550', position: { x: 2569, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-551',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '551',
    svgPath: 'M2810 1056H2642V1050H2650V1030H2640V860H2650V845H2790V860H2810V1056Z',
    workplaces: [
      { uuid: '551-1', name: '551', position: { x: 2660, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-2', name: '551', position: { x: 2660, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-3', name: '551', position: { x: 2660, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-4', name: '551', position: { x: 2700, y: 910, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-5', name: '551', position: { x: 2700, y: 950, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-6', name: '551', position: { x: 2700, y: 990, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-7', name: '551', position: { x: 2770, y: 910, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-8', name: '551', position: { x: 2770, y: 950, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '551-9', name: '551', position: { x: 2770, y: 990, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-552',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '552',
    svgPath: 'M2940 1056V845H3010V890H3030V960V1050H3050V960H3056V1056H3011.86H2981.06H2940Z',
    workplaces: [
      { uuid: '552-1', name: '552', position: { x: 2989, y: 890, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '552-2', name: '552', position: { x: 2989, y: 930, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: '552-3', name: '552', position: { x: 2989, y: 970, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-554',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '554',
    svgPath:
      'M3045 840V810H3150V786.5H3240L3284.5 811C3284.5 811 3256.22 861.427 3232.5 891C3206.07 923.959 3157 966 3157 966H3060V960H3070V840H3045Z',
    workplaces: [
      { uuid: '554-1', name: '554', position: { x: 3110, y: 920, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '554-2', name: '554', position: { x: 3070, y: 920, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '554-3', name: '554', position: { x: 3175, y: 875, angle: -48 }, type: WorkplaceType.DEFAULT },
      { uuid: '554-4', name: '554', position: { x: 3190, y: 790, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '554-5', name: '554', position: { x: 3150, y: 790, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-556',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '556',
    svgPath:
      'M3045 563V460H3150V427H3346C3346 427 3352.5 463 3355 492.5C3357.28 519.403 3355.44 563 3355.44 563H3238.89H3155.8H3045Z',
    workplaces: [
      { uuid: '556-1', name: '556', position: { x: 3090, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-2', name: '556', position: { x: 3130, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-3', name: '556', position: { x: 3170, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-4', name: '556', position: { x: 3210, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-5', name: '556', position: { x: 3250, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-6', name: '556', position: { x: 3290, y: 520, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-7', name: '556', position: { x: 3160, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-8', name: '556', position: { x: 3200, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-9', name: '556', position: { x: 3240, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '556-10', name: '556', position: { x: 3280, y: 450, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-557',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '557',
    svgPath:
      'M3045 420V306H3308.5C3308.5 306 3323.84 341.076 3331.5 366C3338.42 388.497 3345 423 3345 423H3290H3232H3150V420H3045Z',
    workplaces: [
      { uuid: '557-1', name: '556', position: { x: 3160, y: 382, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-2', name: '556', position: { x: 3200, y: 382, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-3', name: '556', position: { x: 3240, y: 382, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-4', name: '556', position: { x: 3280, y: 382, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-5', name: '556', position: { x: 3120, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-6', name: '556', position: { x: 3160, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-7', name: '556', position: { x: 3200, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '557-8', name: '556', position: { x: 3240, y: 310, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-558',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '558',
    svgPath:
      'M3045 240V303H3307L3286 260C3286 260 3256.34 209.062 3232 180C3183 121.5 3157 104 3157 104H3060V240H3045Z',
    workplaces: [
      { uuid: '558-1', name: '556', position: { x: 3120, y: 160, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-2', name: '556', position: { x: 3160, y: 160, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-3', name: '556', position: { x: 3120, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-3', name: '556', position: { x: 3160, y: 200, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-1', name: '556', position: { x: 3100, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-2', name: '556', position: { x: 3140, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-2', name: '556', position: { x: 3180, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: '558-2', name: '556', position: { x: 3220, y: 260, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'T-5-OP-1',
    uuid: 'area-t-5-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К502',
        textRotate: true,
        uuid: 't5-op-1-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1030, y: 40, angle: -90 }
      },
      {
        name: 'К501',
        textRotate: true,
        uuid: 't5-op-1-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1030, y: 80, angle: -90 }
      },
      { name: 'К503', uuid: 't5-op-1-3', type: WorkplaceType.DEFAULT, position: { x: 1070, y: 40, angle: 90 } },
      { name: 'К504', uuid: 't5-op-1-4', type: WorkplaceType.DEFAULT, position: { x: 1070, y: 80, angle: 90 } },

      {
        name: 'К505',
        textRotate: true,
        uuid: 't5-op-1-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1150, y: 40, angle: -90 }
      },
      {
        name: 'К506',
        textRotate: true,
        uuid: 't5-op-1-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1150, y: 80, angle: -90 }
      },
      { name: 'К507', uuid: 't5-op-1-7', type: WorkplaceType.DEFAULT, position: { x: 1190, y: 40, angle: 90 } },
      { name: 'К508', uuid: 't5-op-1-8', type: WorkplaceType.DEFAULT, position: { x: 1190, y: 80, angle: 90 } }
    ]
  },
  {
    name: 'T-5-OP-2',
    uuid: 'area-t-5-op-sp-2',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К509',
        textRotate: true,
        uuid: 't5-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1270, y: 40, angle: -90 }
      },
      {
        name: 'К510',
        textRotate: true,
        uuid: 't5-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1270, y: 80, angle: -90 }
      },
      {
        name: 'К511',
        textRotate: true,
        uuid: 't5-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1270, y: 120, angle: -90 }
      },
      { name: 'К512', uuid: 't5-op-2-4', type: WorkplaceType.DEFAULT, position: { x: 1310, y: 40, angle: 90 } },
      { name: 'К513', uuid: 't5-op-2-5', type: WorkplaceType.DEFAULT, position: { x: 1310, y: 80, angle: 90 } },
      { name: 'К514', uuid: 't5-op-2-6', type: WorkplaceType.DEFAULT, position: { x: 1310, y: 120, angle: 90 } },
      {
        name: 'К515',
        textRotate: true,
        uuid: 't5-op-2-7',
        type: WorkplaceType.DEFAULT,
        position: { x: 1390, y: 40, angle: -90 }
      },
      {
        name: 'К516',
        textRotate: true,
        uuid: 't5-op-2-8',
        type: WorkplaceType.DEFAULT,
        position: { x: 1390, y: 80, angle: -90 }
      }
    ]
  },
  {
    name: 'T-5-OP-3',
    uuid: 'area-t-5-op-sp-3',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К523',
        uuid: 't5-op-3-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1070, y: 500, angle: 90 }
      },
      {
        name: 'К524',
        uuid: 't5-op-3-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1070, y: 540, angle: 90 }
      },
      {
        name: 'К525',
        uuid: 't5-op-3-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1070, y: 580, angle: 90 }
      },

      {
        name: 'К528',
        textRotate: true,
        uuid: 't5-op-3-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1140, y: 580, angle: -90 }
      },
      {
        name: 'К527',
        textRotate: true,
        uuid: 't5-op-3-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1140, y: 540, angle: -90 }
      },
      {
        name: 'К526',
        textRotate: true,
        uuid: 't5-op-3-7',
        type: WorkplaceType.DEFAULT,
        position: { x: 1140, y: 500, angle: -90 }
      },
      { name: 'К529', uuid: 't5-op-3-8', type: WorkplaceType.DEFAULT, position: { x: 1180, y: 500, angle: 90 } },
      { name: 'К530', uuid: 't5-op-3-9', type: WorkplaceType.DEFAULT, position: { x: 1180, y: 540, angle: 90 } },
      { name: 'К531', uuid: 't5-op-3-10', type: WorkplaceType.DEFAULT, position: { x: 1180, y: 580, angle: 90 } }
    ]
  },
  {
    name: 'T-5-OP-4',
    uuid: 'area-t-5-op-sp-4',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К532',
        textRotate: true,
        uuid: 't5-op-4-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 460, angle: -90 }
      },
      {
        name: 'К533',
        textRotate: true,
        uuid: 't5-op-4-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 500, angle: -90 }
      },
      {
        name: 'К534',
        textRotate: true,
        uuid: 't5-op-4-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 540, angle: -90 }
      },
      {
        name: 'К535',
        textRotate: true,
        uuid: 't5-op-4-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 580, angle: -90 }
      },

      {
        name: 'К536',
        textRotate: true,
        uuid: 't5-op-4-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 460, angle: 90 }
      },
      {
        name: 'К537',
        textRotate: true,
        uuid: 't5-op-3-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 500, angle: 90 }
      },
      {
        name: 'К538',
        textRotate: true,
        uuid: 't5-op-3-7',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 540, angle: 90 }
      },
      {
        name: 'К539',
        textRotate: true,
        uuid: 't5-op-3-8',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 580, angle: 90 }
      },
      {
        name: 'К540',
        textRotate: true,
        uuid: 't5-op-4-9',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 500, angle: -90 }
      },
      {
        name: 'К541',
        textRotate: true,
        uuid: 't5-op-4-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 540, angle: -90 }
      },
      {
        name: 'К542',
        textRotate: true,
        uuid: 't5-op-4-11',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 580, angle: -90 }
      }
    ]
  },
  {
    name: 'T-5-OP-5',
    uuid: 'area-t-5-op-sp-5',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К517',
        uuid: 't5-op-5-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1470, y: 250, angle: 180 }
      },
      {
        name: 'К518',
        uuid: 't5-op-5-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1510, y: 250, angle: 180 }
      },
      {
        name: 'К519',
        uuid: 't5-op-5-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1550, y: 250, angle: 180 }
      },
      {
        name: 'К520',
        uuid: 't5-op-5-4',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1390, y: 320, angle: 0 }
      },
      {
        name: 'К521',
        uuid: 't5-op-5-5',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1430, y: 320, angle: 0 }
      },
      {
        name: 'К522',
        uuid: 't5-op-5-6',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1470, y: 320, angle: 0 }
      }
    ]
  },
  {
    name: 'T-5-OP-6',
    uuid: 'area-t-5-op-sp-6',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К543',
        uuid: 't5-op-6-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 3100, y: 570, angle: 180 }
      },
      {
        name: 'К544',
        uuid: 't5-op-6-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 3140, y: 570, angle: 180 }
      },
      {
        name: 'К545',
        uuid: 't5-op-6-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 3180, y: 570, angle: 180 }
      },
      {
        name: 'К546',
        uuid: 't5-op-6-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 3220, y: 570, angle: 180 }
      },
      {
        name: 'К547',
        uuid: 't5-op-6-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 3260, y: 570, angle: 180 }
      },

      {
        name: 'К548',
        uuid: 't5-op-6-6',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3140, y: 640, angle: 0 }
      },
      {
        name: 'К549',
        uuid: 't5-op-6-7',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3180, y: 640, angle: 0 }
      },
      {
        name: 'К550',
        uuid: 't5-op-6-8',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3260, y: 640, angle: 0 }
      },
      {
        name: 'К551',
        uuid: 't5-op-6-9',
        type: WorkplaceType.DEFAULT,
        position: { x: 3140, y: 680, angle: 180 }
      },
      {
        name: 'К552',
        uuid: 't5-op-6-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 3180, y: 680, angle: 180 }
      },
      {
        name: 'К553',
        uuid: 't5-op-6-11',
        type: WorkplaceType.DEFAULT,
        position: { x: 3220, y: 680, angle: 180 }
      },
      {
        name: 'К554',
        uuid: 't5-op-6-12',
        type: WorkplaceType.DEFAULT,
        position: { x: 3260, y: 680, angle: 180 }
      },
      {
        name: 'К555',
        uuid: 't5-op-6-13',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3070, y: 740, angle: 0 }
      },
      {
        name: 'К556',
        uuid: 't5-op-6-14',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3110, y: 740, angle: 0 }
      },
      {
        name: 'К557',
        uuid: 't5-op-6-15',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3150, y: 740, angle: 0 }
      },
      {
        name: 'К558',
        uuid: 't5-op-6-16',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 3190, y: 740, angle: 0 }
      }
    ]
  },
  {
    uuid: 'PS-5',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 203, y: 10 },
        svgPath:
          'M715.141 111.293C716.708 112.631 718.159 113.871 719.5 115V160H746C746 160 762.813 178.983 772.5 192C781.488 204.078 794 224 794 224L790 226.5L799.5 243.5V260H869.5V390H709.5H689.5V438.5H682.5L668 525L615.5 608L498 678.5H389.5L267 614L208.5 524L203 383.5L249 289L363.5 209L308 31C350.395 17.4042 392.59 10 439.5 10C604.361 16.6707 683.404 84.1853 715.141 111.293ZM600 440C600 528.366 528.366 600 440 600C351.634 600 280 528.366 280 440C280 351.634 351.634 280 440 280C528.366 280 600 351.634 600 440Z'
      },
      //Переговорные
      {
        position: { x: 730, y: 390 },
        svgPath: 'M760 390H730V430H760V390Z'
      },
      {
        position: { x: 770, y: 390 },
        svgPath: 'M800 390H770V430H800V390Z'
      },
      {
        position: { x: 1687, y: 284 },
        svgPath: 'M1735.5 284L1687 294.5L1699.5 353.5L1748.5 343L1735.5 284Z'
      },
      {
        position: { x: 1700, y: 343 },
        svgPath: 'M1748.5 343L1700 353.5L1712.5 412.5L1761.5 402L1748.5 343Z'
      },
      {
        position: { x: 2250, y: 555 },
        svgPath:
          'M2270 850H2250V800H2300V767H2330V765H2360V767H2430V765H2460V767H2540V765H2570V767H2610V800H2690V790H2685V760H2690V750H2685V720H2690V690H2730V680H2710V630H2730V620H2740V560H2790V555H2820V560H2850V557H3043V580H3045V610H3043V620H3030V640H3043V740H3045V770H3043V780H3040V810H3045V840H3010V845H2980V843H2940V840H2810V850H2800V840H2790V843H2710V845H2680V843H2650V840H2630V850H2610V883H2570V885H2540V883H2430V885H2400V883H2360V885H2330V883H2300V850H2290V840H2270V850Z'
      },
      //Переговорные
      {
        position: { x: 2640, y: 720 },
        svgPath: 'M2690 720H2640V750H2690V720Z'
      },
      {
        position: { x: 2640, y: 760 },
        svgPath: 'M2690 760H2640V790H2690V760Z'
      },
      //Переговорные
      {
        position: { x: 2920, y: 350 },
        svgPath: 'M2970 350H2920V380H2970V350Z'
      },
      {
        position: { x: 2920, y: 390 },
        svgPath: 'M2970 390H2920V420H2970V390Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];

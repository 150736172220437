import React from 'react';
import { Details, Transaction } from '../../entities/Payments/model/Payments';
import { colors, fontFamilyBase } from '../../shared/ui/constants/styleConstants';
import dayjs from 'dayjs';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;
export const Container = styled.div`
  color: #24272a;
  background-color: white;
  font-family: Commissioner, sans-serif;
  margin-bottom: 20px;
`;
export const StatusBlock = styled.div`
  font-family: Commissioner, sans-serif;
  margin-bottom: 12px;
`;
export const StatusBar = styled.div`
  color: white;
  & > p {
    margin: 0;
  }
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  background-color: ${props =>
    props.type === 'accepted'
      ? `${colors.workkiGreenSuccess}`
      : props.type === 'rejected'
      ? `${colors.workkiRedFill}`
      : `${colors.workkiDarkMiddle}`};
`;
export const InfoBlock = styled.div`
  display: flex;
  font-family: ${fontFamilyBase};
  flex-direction: column;
  margin-bottom: 12px;
  & > p {
    margin: 0;
  }
  & > p:first-of-type {
    font-weight: ${props => (props.type === 'guestList' ? '400' : '300')};
    margin-bottom: ${props => (props.type === 'guestList' ? '12px' : '0')};
    font-size: ${props => (props.type === 'guestList' ? '16px' : '14px')};
    color: ${props => (props.type === 'guestList' ? `${colors.workkiDarkMain}` : `${colors.workkiDarkSecondary}`)};
  }
  & > p:nth-of-type(2) {
    font-weight: ${props => (props.type === 'guestList' ? '300' : '400')};
    color: ${colors.workkiDarkMain};
  }
`;

const ServiceDetailsWidget = ({ servicesInfo, customer }) => {
  const renderStatusTitle = loadingStatus => {
    const content = {
      Создан: () => (
        <StatusBar type={'default'}>
          <p>Создана</p>
        </StatusBar>
      ),
      Принято: () => (
        <StatusBar type={'accepted'}>
          <p>Принята</p>
        </StatusBar>
      ),
      Отклонено: () => (
        <StatusBar type={'rejected'}>
          <p>Отклонена</p>
        </StatusBar>
      ),
      Закрыто: () => (
        <StatusBar type={'default'}>
          <p>Закрыта</p>
        </StatusBar>
      )
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };
  const renderDocumentType = typeId => {
    const content = {
      2658836543000: () => 'Паспорт',
      2658836619000: () => 'Иностранный паспорт',
      2658836640000: () => 'Водительские права'
    };

    return content[typeId] ? content[typeId]() : '';
  };

  const renderServicesInfo = () => {
    if (servicesInfo) {
      return (
        <Container>
          {servicesInfo.length > 0 &&
            servicesInfo.map(item => (
              <Transaction key={item.id || item.service_request_id}>
                <Details>
                  {item.request_status && <StatusBlock>{renderStatusTitle(item.request_status)}</StatusBlock>}
                  <InfoBlock>
                    <p>Локация</p>
                    <p>
                      {item.location.t_name}
                      {item.housing ? `, Корпус ${item.housing}` : ''}
                    </p>
                  </InfoBlock>
                  <InfoBlock>
                    <p>{item.car_number ? 'Прибытие' : 'Дата визита'}</p>
                    <p>
                      {item.car_number
                        ? dayjs(item.date).format('DD.MM.YYYY HH:MM')
                        : dayjs(item.date).format('DD.MM.YYYY')}
                    </p>
                  </InfoBlock>
                  <InfoBlock>
                    <p>Сотрудник</p>
                    <p>{customer.name}</p>
                  </InfoBlock>
                  {item.name &&
                    item.type !== 'Парковочное место' &&
                    item.type !== 'Внос имущества' &&
                    item.type !== 'Вынос имущества' && (
                      <InfoBlock type={'guestList'}>
                        <p>Список гостей:</p>
                        <p>1. {item.name}</p>
                      </InfoBlock>
                    )}
                  {item.car_number && (
                    <>
                      <InfoBlock>
                        <p>ФИО водителя</p>
                        <p>{item.name}</p>
                      </InfoBlock>
                      {item.documentType && item.document_number && (
                        <InfoBlock>
                          <p>Документ</p>
                          <p>
                            {renderDocumentType(item.documentType)}, {item.document_number}
                          </p>
                        </InfoBlock>
                      )}
                      {item.valueable_data && (
                        <InfoBlock>
                          <p>Данные материальных ценностей</p>
                          <p>{item.valueable_data}</p>
                        </InfoBlock>
                      )}
                      {item.comment && (
                        <InfoBlock>
                          <p>Комментарий</p>
                          <p>{item.comment}</p>
                        </InfoBlock>
                      )}
                      {item.lift === '1' && <p>Нужен грузовой лифт</p>}
                      <InfoBlock>
                        <p>Автомобиль</p>
                        <p>
                          {item.car_brand} {item.car_number}
                        </p>
                      </InfoBlock>
                    </>
                  )}
                  {item.parking_number && (
                    <InfoBlock>
                      <p>Парковочное место</p>
                      <p>№{item.parking_number}</p>
                    </InfoBlock>
                  )}
                  {item.rejection_reason && (
                    <InfoBlock>
                      <p>Причина отказа</p>
                      <p>{item.rejection_reason.replace('.', '')}</p>
                    </InfoBlock>
                  )}
                </Details>
              </Transaction>
            ))}
        </Container>
      );
    }
  };

  return <Wrapper>{renderServicesInfo()}</Wrapper>;
};

export default ServiceDetailsWidget;

import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';

const EmailAuthStep = ({
  entranceFormClass,
  handleAuthSubmit,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  handleClickShowPassword,
  errors,
  formData,
  filled,
  isFocused,
  showPassword,
  isPending,
  disableButton,
  setIsForgetPassword,
  handlePhoneAuthClick,
  divider,
  setRegistrationStep
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Вход по e-mail и паролю</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={handleAuthSubmit}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.email.auth}
          type='email'
          name='email'
          id='outlined-helperText'
          label='E-mail'
          variant='filled'
          value={formData.email.auth}
          onFocus={() => handleFocus('email', 'auth')}
          onBlur={() => handleBlur('email', 'auth')}
          onChange={handleInputChange}
          disabled={isPending}
          helperText={errors.email.auth ? errors.email.auth : ''}
          endAdornmentProps={{
            filled: filled.email.auth,
            isFocused: isFocused.email.auth,
            handleEraseValue: name => handleEraseValue(name, 'auth')
          }}
          autoFocus={true}
        />
        <CustomTextField
          error={errors.password}
          id='outlined-helperText'
          label='Пароль'
          name='password'
          helperText={errors.password ? errors.password : ''}
          variant='filled'
          type={showPassword.password ? 'text' : 'password'}
          value={formData.password}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('password')}
          onBlur={() => handleBlur('password')}
          endAdornmentProps={{
            filled: filled.password,
            isFocused: isFocused.password,
            handleEraseValue: handleEraseValue,
            showPassword: showPassword.password,
            handleClickShowPassword: () => handleClickShowPassword('password')
          }}
          autoFocus={false}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Войти
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
          <div className='authorization__footer__links-wrapper'>
            <a onClick={() => setIsForgetPassword(prevState => ({ ...prevState, open: true }))}>забыли пароль?</a>
            <img src={divider} alt='divider' />
            <a href='#' onClick={handlePhoneAuthClick}>
              Войти по номеру телефона
            </a>
          </div>
          <p className='authorization__footer__policy'>
            Выполняя вход, вы соглашаетесь{' '}
            <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
              с договором публичной оферты
            </a>
            &#160;и&#160;
            <a href=' https://workki.co/upload/iblock/f5f/Personal_data.pdf' target='_blank'>
              правилами обработки данных
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default EmailAuthStep;

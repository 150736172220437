import React, { useMemo } from 'react';

export const FloorWorkplaceDefault = ({
  name,
  onClick,
  onTouchStart,
  workplaceStatus,
  serverData,
  tabIndex,
  textRotate,
  active = false,
  disabled = false,
  showName = false,
  position: { x, y, angle },
  size = { width: 40, height: 40 }
}) => {
  const isDisabled = useMemo(() => (disabled ? 'workplace-template-disabled' : ''), [disabled]);
  const isActive = useMemo(() => (active ? 'workplace-template-active' : ''), [active]);
  const isRotate = useMemo(() => (textRotate ? '' : 'workplace-text-rotate'), [textRotate]);
  return (
    <svg
      x={x}
      y={y}
      data-name={name}
      onClick={onClick}
      onTouchStart={onClick}
      width={size.width}
      height={size.height}
      viewBox='0 0 40 40'
      id={`workplace-${name}`}
      className='workplace-box'
    >
      <g
        tabIndex={tabIndex}
        className={`workplace-template-workplace ${isActive} ${isDisabled}`}
        style={{
          transform: `rotate(${angle}deg)`
        }}
      >
        <rect x='13.5' y='7.5' width='13' height='11' rx='3.5' fill='#707479' stroke='#40464D' />
        <path
          d='M13.5 3C13.5 2.17157 14.1716 1.5 15 1.5H25C25.8284 1.5 26.5 2.17157 26.5 3V4C26.5 5.933 24.933 7.5 23 7.5H17C15.067 7.5 13.5 5.933 13.5 4V3Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M13.5 3C13.5 2.17157 14.1716 1.5 15 1.5H25C25.8284 1.5 26.5 2.17157 26.5 3C26.5 3.82843 25.8284 4.5 25 4.5H15C14.1716 4.5 13.5 3.82843 13.5 3Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M11.5 9C11.5 8.17157 12.1716 7.5 13 7.5C13.8284 7.5 14.5 8.17157 14.5 9V15C14.5 15.8284 13.8284 16.5 13 16.5C12.1716 16.5 11.5 15.8284 11.5 15V9ZM25.5 9C25.5 8.17157 26.1716 7.5 27 7.5C27.8284 7.5 28.5 8.17157 28.5 9V15C28.5 15.8284 27.8284 16.5 27 16.5C26.1716 16.5 25.5 15.8284 25.5 15V9Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path d='M1.5 21.5H38.5V38.5H1.5V21.5Z' fill='#707479' stroke='#40464D' />
        {showName && (
          <g className={`workplace-text ${isRotate}`}>
            <text x='6' y='34'>
              {name}
            </text>
          </g>
        )}
      </g>
    </svg>
  );
};

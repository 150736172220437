import { AreaType } from '../types';

const mapGenerator = clientPlaces => {
  const placeList = new Map();

  clientPlaces.forEach(place => {
    if (place.type === AreaType.CABINET) {
      placeList.set(place.name, place);
    }
    if (place.type === AreaType.OPEN_SPACE) {
      placeList.set(place.name, place);
      place.workplaces.forEach(w => {
        if (w.name) {
          w.officeName = place.name;
          placeList.set(w.name, w);
        }
      });
    }
  });
  return placeList;
};

/** Собирает workplace с сервера (статусы, цена) и клиента (с позиционированием) в один массив */
export const getFloorPlaces = (clientPlaces, serverPlaces, officeId) => {
  const placeList = mapGenerator(clientPlaces);
  serverPlaces.forEach(place => {
    if (place.office_id === officeId) {
      const item = placeList.get(place.name);
      if (item) {
        item.serverData = place;
        const itemName = item.name;
        if (itemName && 'workplace' in item) {
          placeList.set(itemName, item);
        } else if ('officeName' in item && item.officeName) {
          placeList.delete(place.name);
        }
      }
    }
  });

  return Array.from(placeList.values());
};

import React from 'react';
import styled from 'styled-components';
import { LinkArrow } from '../../icons/core/LinkArrow';
import { AlertIcon } from '../../icons/core/AlertIcon';

const Alert = styled.div`
  display: flex;
  padding: 8px;
  margin: 8px 0;
  font-size: 14px;
  width: 100%;
  flex-direction: row;
  background-color: ${props => (props.type === 'info' ? '#F2F8F9' : '#fdf6f6')};
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  color: ${props => (props.type === 'info' ? '#005F7A' : '#ba393a')};
  border-radius: 8px;
  & > svg {
    align-self: flex-start;
    margin-top: -4px;
  }
`;
const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: 300;
    margin: 0;
    font-size: 14px;
  }
  :nth-child(2) {
    margin-bottom: 4px;
    font-size: 14px;
  }
`;
const AlertLink = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > p {
    font-weight: 400;
    margin-bottom: 4px;
    :hover {
      color: ${props => props.hoverOverride};
    }
  }
  & > svg {
    margin-left: 2px;
    align-self: center;
  }
`;
const renderAlertIcon = name => {
  const content = {
    warning: () => {
      return <AlertIcon defaultColor={'#BA393A'} spacing={'6px'} />;
    },
    info: () => {
      return <AlertIcon defaultColor={'#005F7A'} spacing={'6px'} />;
    },

    default: () => {
      return null;
    }
  };

  return (content[name] || content.default)();
};

export const InfoAlert = ({ title, link, type, handleAction, subTitle }) => {
  return (
    <Alert type={type}>
      <div style={{ minWidth: '16px', minHeight: '16px', marginRight: '6px' }}>{renderAlertIcon(type)}</div>
      <AlertContent>
        <p>{title}</p>
        <p>{subTitle}</p>
        {link && (
          <AlertLink hoverOverride={type === 'info' ? '#005F7A' : '#BA393A'} onClick={handleAction}>
            <p>{link}</p>
            <LinkArrow
              type={'small'}
              defaultColor={type === 'info' ? '#005F7A' : '#BA393A'}
              width={'12px'}
              height={'12px'}
            />
          </AlertLink>
        )}
      </AlertContent>
    </Alert>
  );
};

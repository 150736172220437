/* eslint-disable eqeqeq */
import { LOADING_STATUSES } from '../../constants';

class Status {
  constructor(statusName) {
    this.name = statusName;
  }

  toString() {
    return this.name;
  }

  valueOf() {
    return this.name;
  }

  get isInit() {
    return this.name === LOADING_STATUSES.INIT;
  }

  get isSuccess() {
    return this.name === LOADING_STATUSES.SUCCESS;
  }

  get isPending() {
    return this.name === LOADING_STATUSES.PENDING;
  }

  get isFailure() {
    return this.name === LOADING_STATUSES.FAILURE;
  }

  get isEmpty() {
    return this.name === LOADING_STATUSES.EMPTY;
  }
}

const requestStatusSelector = action => state => {
  const { type } = action;
  const [requestName] = type.split(':');

  return new Status(state.requestStatuses[requestName]);
};

export default requestStatusSelector;

import { Model } from 'redux-orm';
import * as actions from '../actions';

class CustomerContract extends Model {
  static reducer(action, CustomerContract) {
    switch (action.type) {
      case actions.requestCustomerContracts.success.toString():
        CustomerContract.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `CustomerContract: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CustomerContract.modelName = 'CustomerContract';

export default CustomerContract;

import ReactGA from 'react-ga';
import ReactYM, { YMInitializer } from 'react-yandex-metrika';
import React from 'react';
import { render as ReactDomRender } from 'react-dom';
import logger from './utils/logger';

const { REACT_APP_DOMAIN } = process.env;

const CONFIGS = {
  'https://api.workki.co': {
    gaTrackingId: 'UA-92750483-1',
    ymTrackingId: 42640294,
    useStatistic: true
  },
  'https://apiworkki.dev.aic.ru': {
    gaTrackingId: 'UA-158827824-1',
    ymTrackingId: 53722429,
    useStatistic: true
  }
};

const currentConfig = CONFIGS[REACT_APP_DOMAIN];

const condition = currentConfig && currentConfig.useStatistic;
logger('analytics')('Use analytics: %o', condition);

if (condition) {
  ReactGA.initialize(currentConfig.gaTrackingId);

  ReactDomRender(
    <YMInitializer
      accounts={[currentConfig.ymTrackingId]}
      options={{
        // defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      }}
      version='2'
    />,
    document.getElementById('yandex-metrika')
  );
}

// Use: analytics.event('Registration');
// Use: analytics.event('EnterMenu');
export const event = eventName => {
  if (condition) {
    ReactGA.event({
      category: eventName,
      action: 'send'
    });

    ReactYM('reachGoal', eventName);
  }
};

// Use: analytics.pageView(window.location);
export const pageView = ({ pathname, search }) => {
  if (condition) {
    ReactYM('hit', pathname);
    ReactGA.pageview(pathname + search);
  }
};

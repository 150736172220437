import React from 'react';
import { Link } from 'react-router-dom';
import { WorkkiLogo } from '../../icons/core/workkiLogo';
import styled from 'styled-components';

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  height: 100%;
  align-items: flex-start;
`;

const SideBar = ({ children }) => {
  return (
    <StyledSideBar>
      <Link className='sidebar__logo' title='Home' to='/'>
        <WorkkiLogo width={'136px'} height={'24px'} />
      </Link>
      {children}
    </StyledSideBar>
  );
};

export default SideBar;

import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import CustomScrollbar from '../../../components/CustomScrollbar';
import ContentLoader from '../../../components/ContentLoader';
import PaymentList from './PaymentList';
import TableDataLoader from '../../../components/TableDataLoader';
import TableDataReloader from '../../../components/TableDataReloader';
import NotHistory from '../../../components/NotHistory';

const TEXT_ON_EMPTY_LIST = 'Список платежей пуст';
const VALUES_TOP_THRESHOLD = 0.98;

const HistoryPayments = ({
  payments,
  isLoading,
  onError,
  canLoadMore,
  loadFirstPage,
  loadNextPage,
  account,
  customer
}) => {
  const isListEmpty = useMemo(() => isEmpty(payments), [payments]);
  const [showCheck] = useState(!customer.is_company);
  const componentRef = useRef(null);
  const tableBodyRef = useRef(null);
  const onUpdate = useCallback(
    values => {
      const hasScrolledToBottom = values.top >= VALUES_TOP_THRESHOLD;
      if (hasScrolledToBottom && !isLoading) {
        loadNextPage();
      }
    },
    [isLoading, loadNextPage]
  );

  useEffect(() => {
    if (isLoading || isListEmpty || !canLoadMore) {
      return;
    }

    if (!tableBodyRef.current || !componentRef.current) {
      return;
    }

    const componentRect = componentRef.current.getBoundingClientRect();
    const tableBodyRect = tableBodyRef.current.getBoundingClientRect();

    if (tableBodyRect.bottom <= componentRect.bottom) {
      loadNextPage();
    }
  }, [canLoadMore, isListEmpty, isLoading, loadNextPage]);

  if (isListEmpty && !canLoadMore) {
    return null;
  }

  if (isListEmpty && isLoading) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (isListEmpty && !canLoadMore) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='page__section page__section--grow scrollable-table scrollable-table--payment'>
      <div className='scrollable-table__title'>История платежей</div>
      <CustomScrollbar className='scrollable-table__scrollbar' onUpdate={onUpdate}>
        <div className='scrollable-table__table' ref={componentRef}>
          <div className='scrollable-table__table-head'>
            <div className='scrollable-table__table-head-row'>
              <div className='scrollable-table__cell'>ID платежа</div>
              <div className='scrollable-table__cell'>Дата и время</div>
              <div className='scrollable-table__cell'>Сумма</div>
              <div className='scrollable-table__cell'>Способ</div>
              <div className='scrollable-table__cell'>Состояние</div>
              <div className='scrollable-table__cell'>ОП?</div>
              <div className='scrollable-table__cell'>Оплата</div>
              {showCheck ? (
                <div className='scrollable-table__cell'>Чек</div>
              ) : (
                <div className='scrollable-table__cell'>Счет</div>
              )}
            </div>
          </div>
          <div className='scrollable-table__table-body' ref={tableBodyRef}>
            <PaymentList payments={payments} showCheck={showCheck} />
          </div>
          {isLoading && <TableDataLoader />}
          {onError && <TableDataReloader reload={loadFirstPage} />}
        </div>
      </CustomScrollbar>
    </section>
  );
};

export default HistoryPayments;

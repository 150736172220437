import React from 'react';
import ReactSelect from 'react-select';

const Select = props => {
  const { disabled, value, placeholder } = props;
  if (disabled) {
    return (
      <input
        className='input'
        value={value && value.label ? value.label : ''}
        type='text'
        placeholder={placeholder}
        disabled
      />
    );
  }
  return (
    <ReactSelect
      classNamePrefix='react-select'
      styles={{
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.hasValue ? '#fff' : '#f5f6f7'
        })
      }}
      {...props}
    />
  );
};

export default Select;

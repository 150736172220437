import React, { Component } from 'react';
import sendError from '../services/workki/sendError';

const useErrorBoundary = AlternativeComponent => ChildComponent => {
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, info) {
      const payload = { error, info };
      const { location } = window;
      if (location) {
        payload.location = location;
      }
      sendError(payload);
    }

    render() {
      const { hasError } = this.state;
      if (hasError) {
        if (!AlternativeComponent) {
          return null;
        }
        return <AlternativeComponent {...this.props} />;
      }
      return <ChildComponent {...this.props} />;
    }
  }

  return ErrorBoundary;
};

export default useErrorBoundary;

import { fk, attr, Model } from 'redux-orm';
import * as actions from '../actions';

class Deposit extends Model {
  static reducer(action, deposit) {
    switch (action.type) {
      case actions.resetDeposits.toString():
        deposit.all().delete();
        break;
      case actions.requestDeposits.success.toString():
        action.payload.map(elem => deposit.upsert(elem));
        break;
      case actions.requestCreateDeposit.success.toString():
        deposit.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `Deposit: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
Deposit.modelName = 'Deposit';

// Declare your related fields.
Deposit.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  //  name: attr(),
  payment_system_id: fk('PaymentSystem', 'ttr'),
  currency_id: fk('CurrencyType'),
  status_id: fk('DepositStatus')
};

export default Deposit;

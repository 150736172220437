import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const { errors: init } = initialState;

const errors = createReducer(initialState.errors, {
  [actions.requestLogin]: state => ({ ...state, auth: init.auth }),
  [actions.requestLogin.success]: state => ({ ...state, auth: init.auth }),
  [actions.requestLogin.failure]: (state, { payload }) => ({ ...state, auth: payload }),

  [actions.openModal]: state => ({ ...state, guestCreate: init.guestCreate }),
  [actions.requestGuestCreate.pending]: state => ({ ...state, guestCreate: init.guestCreate }),
  [actions.requestGuestCreate.failure]: (state, { payload }) => ({ ...state, guestCreate: payload }),

  [actions.openModal]: state => ({ ...state, parkingCreate: init.parkingCreate }),
  [actions.requestParkingCreate.pending]: state => ({ ...state, parkingCreate: init.parkingCreate }),
  [actions.requestParkingCreate.failure]: (state, { payload }) => ({ ...state, parkingCreate: payload }),

  [actions.openModal]: state => ({ ...state, itServiceTaskCreate: init.itServiceTaskCreate }),
  [actions.requestItServiceTaskCreate.pending]: state => ({ ...state, itServiceTaskCreate: init.itServiceTaskCreate }),
  [actions.requestItServiceTaskCreate.failure]: (state, { payload }) => ({ ...state, itServiceTaskCreate: payload }),

  [actions.openModal]: state => ({ ...state, serviceRequestCreate: init.serviceRequestCreate }),
  [actions.requestServiceRequestCreate.pending]: state => ({
    ...state,
    serviceRequestCreate: init.serviceRequestCreate
  }),
  [actions.requestServiceRequestCreate.failure]: (state, { payload }) => ({ ...state, serviceRequestCreate: payload }),

  [actions.openModal]: state => ({ ...state, createTicket: init.createTicket }),
  [actions.requestTicketCreate.pending]: state => ({ ...state, createTicket: init.createTicket }),
  [actions.requestTicketCreate.reset]: state => ({ ...state, createTicket: init.createTicket }),
  [actions.requestTicketCreate.failure]: (state, { payload }) => ({ ...state, createTicket: payload }),

  [actions.openModal]: state => ({ ...state, createTicketComment: init.createTicketComment }),
  [actions.requestTicketCommentCreate.pending]: state => ({ ...state, createTicketComment: init.createTicketComment }),
  [actions.requestTicketCommentCreate.reset]: state => ({ ...state, createTicketComment: init.createTicketComment }),
  [actions.requestTicketCommentCreate.failure]: (state, { payload }) => ({ ...state, createTicketComment: payload }),

  [actions.cleanErrors]: () => init,
  [actions.logout]: () => init
});

export default errors;

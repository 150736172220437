import React from 'react';
import styled from 'styled-components';
import { screenRessolutions } from '../../shared/ui/constants/screenResolutions.constants';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';

const UsefulLinksBox = styled.div`
  margin: 32px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const ContactsAndManagersTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #24272a;
`;

const ContactsLinksList = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'FAQ FAQ'
    'directorMessage directorMessage'
    'rules approval';
  gap: 8px 8px;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'FAQ directorMessage'
      'rules approval';
  }
`;

const ContactsLinksItem = styled.a`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #24272a;

  & > span {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;
  }
`;

const SupportInformationLinks = ({ screenWidthState }) => {
  const links = [
    {
      name: 'Часто задаваемые вопросы',
      url: 'https://workki.co/residents/faq/',
      target: '_blank',
      document: false
    },
    {
      name: 'Написать директору',
      url: 'mailto:director@workki.co',
      target: '_self',
      document: false
    },
    {
      name: 'Правила коворкинга',
      url: 'https://backend.workki.co/storage/media/1/Terms_of_service.pdf',
      target: '_blank',
      document: true
    },
    {
      name: 'Договор обработки персональный данных',
      url: 'https://workki.co/upload/iblock/f5f/Personal_data.pdf',
      target: '_self',
      document: true
    }
  ];

  const getGridAreaName = index => {
    if (index === 0) return 'FAQ';
    if (index === 1) return 'directorMessage';
    if (index === 2) return 'rules';
    if (index === 3) return 'approval';
  };

  const getLinks = () => {
    return links.map((item, index) => {
      return (
        <CardShell
          style={{
            gridArea: getGridAreaName(index)
          }}
          key={index}
        >
          <ContactsLinksItem
            href={item.url}
            target={item.target}
            style={{
              lineHeight: index < 2 ? '' : '16px',
              gap: '12px',
              flexDirection: index < 2 ? '' : 'column'
            }}
          >
            {item.name}
            {index < 2 ? (
              <ArrowBack
                defaultColor='#575B60'
                rotation='180deg'
                style={{ margin: 0, width: '20px', height: '20px' }}
              />
            ) : (
              <span>PDF</span>
            )}
          </ContactsLinksItem>
        </CardShell>
      );
    });
  };

  return (
    <UsefulLinksBox>
      <ContactsAndManagersTitle>Полезные ссылки</ContactsAndManagersTitle>
      <ContactsLinksList>{getLinks()}</ContactsLinksList>
    </UsefulLinksBox>
  );
};

export default SupportInformationLinks;

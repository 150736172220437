import { isNil } from 'lodash';

export const FORM_ID = 'create-ticket-form';

export const FIELD_NAMES = {
  SUPER_CATEGORY: 'super_category_id',
  CATEGORY: 'category_id',
  WORKPLACE: 'service_id',
  DESCRIPTION: 'description',
  FILE: 'file'
};

export const TEXTAREA_MAX_LENGTH = 1000;

export const LABELS = {
  [FIELD_NAMES.WORKPLACE]: 'Выберите рабочее место или кабинет',
  [FIELD_NAMES.SUPER_CATEGORY]: 'Укажите категорию',
  [FIELD_NAMES.CATEGORY]: 'Укажите подкатегорию',
  [FIELD_NAMES.DESCRIPTION]: 'Описание проблемы...',
  [FIELD_NAMES.FILE]: 'Файл'
};

export const FILE_CONSTRAINS = {
  SIZE: 5000000,
  SIZE_TEXT: '5MB',
  MIME_TYPES: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
  EXTENSIONS: ['.jpg', '.jpeg', '.jpe', '.png', '.gif', '.bmp']
};

const isValidFile = file => file.size <= FILE_CONSTRAINS.SIZE && FILE_CONSTRAINS.MIME_TYPES.includes(file.type);
export const VALIDATION_SCHEMA = {
  [FIELD_NAMES.FILE]: [
    {
      check: v => (!v ? false : !isValidFile(v)),
      message: `Размер файла не должен превышать ${
        FILE_CONSTRAINS.SIZE_TEXT
      }, файл долен иметь одно из расширений: ${FILE_CONSTRAINS.EXTENSIONS.join(', ')}`
    }
  ],
  [FIELD_NAMES.WORKPLACE]: [
    {
      check: v => isNil(v.value),
      message: 'Укажите рабочее место'
    }
  ],
  [FIELD_NAMES.SUPER_CATEGORY]: [
    {
      check: v => isNil(v.value),
      message: 'Выберите категорию'
    }
  ],
  [FIELD_NAMES.CATEGORY]: [
    {
      check: v => isNil(v.value),
      message: 'Выберите подкатегорию'
    }
  ],
  [FIELD_NAMES.DESCRIPTION]: [
    {
      check: v => v === '',
      message: 'Добавьте описание проблемы'
    }
  ]
};

export const SUPER_CATEGORIES = [
  {
    id: 1,
    name: 'ИТ поддержка',
    categories: [1, 2, 3, 4, 5]
  },
  {
    id: 2,
    name: 'Служба эксплуатации',
    categories: [6, 7, 8, 9, 10, 11, 12, 13]
  },
  {
    id: 3,
    name: 'Комьюнити',
    categories: [14, 15, 16, 17, 18]
  },
  {
    id: 4,
    name: 'Бухгалтерия',
    categories: [19, 20]
  },
  {
    id: 5,
    name: 'Личный кабинет',
    categories: [21, 22, 23]
  },
  {
    id: 6,
    name: 'Другой запрос',
    categories: [24]
  }
];

export const DEFAULT_SUPER_CATEGORY_ID = 6;

export const getTicketElementId = id => `ticket-${id}`;

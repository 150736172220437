import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/ru';

Moment.globalLocale = 'ru';

moment.tz.setDefault('Europe/Moscow');
moment.updateLocale('ru', {
  relativeTime: {
    m: '1 минута',
    h: '1 час',
    d: '1 день',
    M: '1 месяц',
    y: '1 год'
  }
});

export { Moment, moment };

import { connect } from 'react-redux';
import { officeSelector, customerSelector } from '../../store/selectors/DefaultSelectors';
import { allWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import {
  getServicesFiles,
  getServiceIsActive,
  getActiveServiceRequests,
  getServiceLocationIsAvailable
} from '../../services/workki/ServicesActions';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';

const mapStateToProps = state => {
  const locations = officeSelector(state)
    .filter(location => location.active)
    .filter(function(location) {
      let disabledFloors = location.officeFloors.filter(floor => !floor.active);
      return disabledFloors.length !== location.officeFloors.length;
    });

  const servicesRequestsStatus = requestStatusSelector(actions.requestServicesRequests)(state);
  const serviceRequestCreateStatus = requestStatusSelector(actions.requestServiceRequestCreate)(state);
  return {
    locations,
    rooms: allWorkplacesSelector(state).map(
      ({ id, office_id, name, isFree, servicePrices, tariff, tariffInfo, is_unfixed, status_id }) => ({
        id,
        office_id,
        name,
        tariff,
        status_id,
        is_unfixed,
        tariffInfo,
        status: isFree ? 'free' : 'busy',
        servicePrices
      })
    ),
    customer: customerSelector(state),
    locationsWithLoadedDataIds: state.location.locationsWithLoadedDataIds,
    serviceRequestCreateLoadingStatus: serviceRequestCreateStatus,
    isFetching: () =>
      state.ui.office.isFetching ||
      state.ui.services.isFetching ||
      state.ui.customerCurrent.isFetching ||
      servicesRequestsStatus.isPending,
    isError: () => {
      return (
        state.ui.office.isError ||
        state.ui.services.isError ||
        state.ui.customerCurrent.isError ||
        servicesRequestsStatus.isFailure
      );
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getRoomsFiles: () => dispatch(getServicesFiles()),
    getServiceIsActive: (activeFrom, activeTo) => dispatch(getServiceIsActive(activeFrom, activeTo)),
    getServiceLocationIsAvailable: officeId => dispatch(getServiceLocationIsAvailable(officeId)),
    getActiveServiceRequests: () => dispatch(getActiveServiceRequests())
  };
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component);

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const ErrorIcon = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        d='M11.636 11.636C12.2218 11.0503 13.1716 11.0503 13.7574 11.636L18 15.8787L22.2426 11.636C22.8284 11.0502 23.7782 11.0502 24.364 11.636C24.9498 12.2218 24.9498 13.1716 24.364 13.7574L20.1213 18L24.364 22.2426C24.9497 22.8284 24.9497 23.7782 24.364 24.364C23.7782 24.9497 22.8284 24.9497 22.2426 24.364L18 20.1213L13.7574 24.364C13.1716 24.9497 12.2218 24.9497 11.636 24.364C11.0503 23.7782 11.0503 22.8284 11.636 22.2426L15.8787 18L11.636 13.7574C11.0503 13.1716 11.0503 12.2218 11.636 11.636Z'
        fill='#DB4344'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.27208 30.7279C-1.75736 23.6985 -1.75736 12.3015 5.27208 5.27208C12.3015 -1.75736 23.6985 -1.75736 30.7279 5.27208C37.7574 12.3015 37.7574 23.6985 30.7279 30.7279C23.6985 37.7574 12.3015 37.7574 5.27208 30.7279ZM7.3934 7.3934C1.53553 13.2513 1.53553 22.7487 7.3934 28.6066C13.2513 34.4645 22.7487 34.4645 28.6066 28.6066C34.4645 22.7487 34.4645 13.2513 28.6066 7.3934C22.7487 1.53553 13.2513 1.53553 7.3934 7.3934Z'
        fill='#DB4344'
      />
    </StyledSvg>
  );
};

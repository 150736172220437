import React from 'react';
import { Dialog } from '../../shared/ui/components/Dialog';

export const BalanceDialog = props => {
  const fields = [
    {
      view: 'select',
      type: 'text',
      name: 'destination',
      label: 'Куда зачислить?',
      valueKey: 'destination',
      errorKey: 'destination',
      context: '',
      autoFocus: false,
      complexValueKey: false
    },
    {
      view: 'field',
      type: 'text',
      name: 'amount',
      label: 'Сумма пополнения',
      valueKey: 'amount',
      errorKey: 'amount',
      context: '',
      autoFocus: true,
      placeholder: '',
      complexValueKey: false
    }
  ];

  return (
    <Dialog
      title={'Пополнить баланс'}
      dialogType={props.dialogType}
      fields={fields}
      buttonLabel='Пополнить'
      requestSentMessage=''
      requestSentDescription=''
      {...props}
    />
  );
};

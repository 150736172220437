/* eslint-disable prettier/prettier */
import { createAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import moment from 'moment';
import createRequestActions, { withMetaRequestSuccessHandler } from '../../utils/createRequestActions';

const defaultRequestSuccessHandler = response => ({ payload: response.data });

// Common actions

export const requestLogin = createRequestActions('LOGIN');
export const changePassword = createRequestActions('CHANGE_PASSWORD');

export const logout = createAction('LOGOUT');

export const cleanErrors = createAction('CLEAN_ERRORS'); // TODO check and maybe reset all request statuses (in error state)

export const openModal = createAction('MODAL_WINDOW_OPEN');
export const closeModal = createAction('MODAL_WINDOW_CLOSE');

export const openAvatarEditor = createAction('HIDE_MOBILE_MENU');
export const closeAvatarEditor = createAction('SHOW_MOBILE_MENU');

// Settings actions
export const requestCustomerSettings = createRequestActions('GET_CUSTOMER_SETTINGS');
export const requestChangeProfileIsPublic = createRequestActions('UPDATE_CUSTOMER_PROFILE_IS_PUBLIC');

// References actions
export const requestCurrencyTypes = createRequestActions('GET_CURRENCY_TYPES');
export const requestDepositStatuses = createRequestActions('GET_DEPOSIT_STATUSES');
export const requestDepositBanks = createRequestActions('GET_DEPOSIT_BANKS');
export const requestChangeCredentials = createRequestActions('CHANGE_CREDENTIALS');

// Service requests actions
export const requestGuests = createRequestActions('GET_GUESTS');
export const requestGuestCreate = createRequestActions('CREATE_GUEST');
export const requestParkingList = createRequestActions('GET_PARKING_LIST');
export const requestParkingCreate = createRequestActions('CREATE_PARKING');
export const createUDPRequest = createRequestActions('CREATE_UDP');
export const getUDPDocuments = createRequestActions('GET_UPD_DOCUMENTS');
export const requestServicesFiles = createRequestActions('GET_SERVICES_FILES');
export const requestServicesIsActive = createRequestActions('GET_SERVICES_IS_ACTIVE');
export const requestServicesRequests = createRequestActions('GET_SERVICES_REQUESTS');
export const requestServicesScheduleRequests = createRequestActions('GET_SERVICES_SCHEDULE_REQUESTS');
export const requestServiceLocationIsAvailable = createRequestActions(
         'GET_SERVICES_LOCATION_IS_AVAILABLE',
         (res, id) => ({ payload: { id, rooms: res.data } })
       );
export const requestPublicProfileList = createRequestActions('GET_PUBLIC_PROFILE_LIST');
export const requestItServiceTaskList = createRequestActions('GET_IT_SERVICE_TASK_LIST');
export const requestItServiceTaskCreate = createRequestActions('CREATE_IT_SERVICE_TASK', (res, partOfRequestData) => ({
         payload: res.data.map(task => ({ ...partOfRequestData, ...task }))
       }));
export const requestServiceRequestCreate = createRequestActions('CREATE_SERVICE_REQUEST');
export const requestGuestLimit = createRequestActions('GET_GUEST_LIMIT_REQUEST');

// Children request actions
export const requestChildren = createRequestActions('GET_CHILDREN');
export const requestChildCreate = createRequestActions('CREATE_CHILD');
export const requestChildUpdate = createRequestActions('UPDATE_CHILD');
export const requestChildDelete = createRequestActions('DELETE_CHILD', payload => ({ payload }));
export const requestChildRoles = createRequestActions('GET_CHILD_ROLES');

// Transactions request actions
export const requestTransactions = createRequestActions('GET_TRANSACTION_LIST', withMetaRequestSuccessHandler);
export const resetTransactions = createAction('TRANSACTION_LIST_RESET');

// Deposit request actions
export const requestDeposits = createRequestActions('GET_DEPOSIT_LIST', withMetaRequestSuccessHandler);
export const resetDeposits = createAction('DEPOSIT_LIST_RESET');

export const requestCreateDeposit = createRequestActions('CREATE_DEPOSIT');

// Customer requests actions
export const requestCustomerCurrentPending = createAction('CUSTOMER_CURRENT_PENDING');
export const requestCustomerCurrentSuccess = createAction('CUSTOMER_CURRENT_SUCCESS', defaultRequestSuccessHandler);
export const requestCustomerCurrentFailure = createAction('CUSTOMER_CURRENT_FAILURE');

export const requestActualCustomerCurrentPending = createAction('ACTUAL_CUSTOMER_CURRENT_PENDING');
export const requestActualCustomerCurrentSuccess = createAction('ACTUAL_CUSTOMER_CURRENT_SUCCESS', defaultRequestSuccessHandler);
export const requestActualCustomerCurrentFailure = createAction('ACTUAL_CUSTOMER_CURRENT_FAILURE');

export const requestCreateCustomerIndividualPending = createAction('CREATE_CUSTOMER_INDIVIDUAL_PENDING');
export const requestCreateCustomerIndividualSuccess = createAction('CREATE_CUSTOMER_INDIVIDUAL_SUCCESS', defaultRequestSuccessHandler);
export const requestCreateCustomerIndividualFailure = createAction('CREATE_CUSTOMER_INDIVIDUAL_FAILURE');

export const requestCustomerCompanyNamePending = createAction('GET_CUSTOMER_INFO_COMPANY_NAME_PENDING');
export const requestCustomerCompanyNameSuccess = createAction('GET_CUSTOMER_INFO_COMPANY_NAME_SUCCESS', defaultRequestSuccessHandler);
export const requestCustomerCompanyNameFailure = createAction('GET_CUSTOMER_INFO_COMPANY_NAME_FAILURE');

export const requestCreateCustomerCompanyPending = createAction('CREATE_CUSTOMER_COMPANY_PENDING');
export const requestCreateCustomerCompanySuccess = createAction('CREATE_CUSTOMER_COMPANY_SUCCESS', defaultRequestSuccessHandler);
export const requestCreateCustomerCompanyFailure = createAction('CREATE_CUSTOMER_COMPANY_FAILURE');

export const requestCreateCustomerFilePending = createAction('CREATE_CUSTOMER_FILE_PENDING');
export const requestCreateCustomerFileSuccess = createAction('CREATE_CUSTOMER_FILE_SUCCESS', defaultRequestSuccessHandler);
export const requestCreateCustomerFileFailure = createAction('CREATE_CUSTOMER_FILE_FAILURE');

export const requestCustomerProfileFilesPending = createAction('CUSTOMER_PROFILE_FILES_PENDING');
export const requestCustomerProfileFilesSuccess = createAction('CUSTOMER_PROFILE_FILES_SUCCESS', defaultRequestSuccessHandler);
export const requestCustomerProfileFilesFailure = createAction('CUSTOMER_PROFILE_FILES_FAILURE');

// Tickets requests actions

export const requestTickets = createRequestActions('GET_TICKET_LIST', withMetaRequestSuccessHandler);
export const resetTickets = createAction('TICKET_LIST_RESET');

export const requestTicketCreate = createRequestActions('CREATE_TICKET');
export const requestTicket = createRequestActions('GET_TICKET');
export const requestTicketInfo = createRequestActions('GET_TICKET_INFO', (res, id) => ({ payload: { ...res.data, id } }));

export const requestCheckHasTicketNewComment = createRequestActions('CHECK_NEW_TICKET_COMMENT', (res, ticketId) => ({
  payload: { result: res.data, ticketId }
}));

export const requestTicketComments = createRequestActions('GET_TICKET_COMMENT_LIST', (res, id) => {
  const comments = isArray(res.data) && res.data.map((item, i) => {
    const commentIdDate = moment(item.created, 'DD.MM.YYYY hh:mm:ss');
    const commentId = commentIdDate ? `${id}-${commentIdDate.unix()}` : `${id}-${i}`;

    return { ...item, id: commentId, ticket_id: id };
  });

  return { payload: comments || {} };
});

export const resetTicketComments = createAction('TICKET_COMMENT_LIST_RESET');

export const requestTicketCommentCreate = createRequestActions('CREATE_TICKET_COMMENT');

export const requestTicketCategories = createRequestActions('GET_TICKET_CATEGORY_LIST');

// Another actions

export const requestPaymentSystemPending = createAction('PAYMENT_SYSTEM_PENDING');
export const requestPaymentSystemSuccess = createAction('PAYMENT_SYSTEM_SUCCESS', defaultRequestSuccessHandler);
export const requestPaymentSystemFailure = createAction('PAYMENT_SYSTEM_FAILURE');

export const requestServiceFileTypePending = createAction('SERVICE_FILE_TYPE_PENDING');
export const requestServiceFileTypeSuccess = createAction('SERVICE_FILE_TYPE_SUCCESS', defaultRequestSuccessHandler);
export const requestServiceFileTypeFailure = createAction('SERVICE_FILE_TYPE_FAILURE');

export const requestServiceTypePending = createAction('SERVICE_TYPE_PENDING');
export const requestServiceTypeSuccess = createAction('SERVICE_TYPE_SUCCESS', defaultRequestSuccessHandler);
export const requestServiceTypeFailure = createAction('SERVICE_TYPE_FAILURE');

export const requestOfficePending = createAction('OFFICE_PENDING');
export const requestOfficeSuccess = createAction('OFFICE_SUCCESS', defaultRequestSuccessHandler);
export const requestOfficeFailure = createAction('OFFICE_FAILURE');

export const requestDayPending = createAction('DAY_PENDING');
export const requestDaySuccess = createAction('DAY_SUCCESS', defaultRequestSuccessHandler);
export const requestDayFailure = createAction('DAY_FAILURE');

export const requestServicesPending = createAction('SERVICES_PENDING');
export const requestServicesSuccess = createAction('SERVICES_SUCCESS', defaultRequestSuccessHandler);
export const requestServicesFailure = createAction('SERVICES_FAILURE');
export const requestEmployeesServicesPending = createAction('EMPLOYEES_SERVICES_PENDING');
export const requestEmployeesServicesSuccess = createAction('EMPLOYEES_SERVICES_SUCCESS', defaultRequestSuccessHandler);
export const requestEmployeesServicesFailure = createAction('EMPLOYEES_SERVICES_FAILURE');

export const requestServicesSubscriptionPending = createAction('SERVICES_SUBSCRIPTION_PENDING');
export const requestServicesSubscriptionSuccess = createAction('SERVICES_SUBSCRIPTION_SUCCESS');
export const requestServicesSubscriptionFailure = createAction('SERVICES_SUBSCRIPTION_FAILURE');

export const requestServicesReserveRoomPending = createAction('SERVICES_RESERVE_ROOM_PENDING');
export const requestServicesReserveRoomSuccess = createAction('SERVICES_RESERVE_ROOM_SUCCESS', defaultRequestSuccessHandler);
export const requestServicesReserveRoomFailure = createAction('SERVICES_RESERVE_ROOM_FAILURE');

export const requestServiceSendFileToPrinterPending = createAction('SERVICE_SEND_FILE_TO_PRINTER_PENDING');
export const requestServiceSendFileToPrinterSuccess = createAction('SERVICE_SEND_FILE_TO_PRINTER_SUCCESS', defaultRequestSuccessHandler);
export const requestServiceSendFileToPrinterFailure = createAction('SERVICE_SEND_FILE_TO_PRINTER_FAILURE');

export const requestGetLinkHelpDeskPending = createAction('GET_LINK_HELP_DESK_PENDING');
export const requestGetLinkHelpDeskSuccess = createAction('GET_LINK_HELP_DESK_SAVE_SUCCESS', defaultRequestSuccessHandler);
export const requestGetLinkHelpDeskFailure = createAction('GET_LINK_HELP_DESK_FAILURE');

// Specific response handlers
export const requestAccountPending = createAction('ACCOUNT_PENDING');
export const requestAccountSuccess = createAction('ACCOUNT_SUCCESS', res => ({
  payload: {
    ...res.data,
    amount: Number(res.data.amount),
    security_payment_amount: Number(res.data.security_payment_amount)
  }
}));
export const requestAccountFailure = createAction('ACCOUNT_FAILURE');

export const requestCustomerProfileChangePublicFieldsPending = createAction('CUSTOMER_PROFILE_CHANGE_PUBLIC_FIELDS_PENDING');
export const requestCustomerProfileChangePublicFieldsSuccess = createAction('CUSTOMER_PROFILE_CHANGE_PUBLIC_FIELDS_SUCCESS', (res, id) => ({ payload: { ...res.data, id } }));
export const requestCustomerProfileChangePublicFieldsFailure = createAction('CUSTOMER_PROFILE_CHANGE_PUBLIC_FIELDS_FAILURE');

export const requestProfileChangeNamePending = createAction('PROFILE_CHANGE_NAME_PENDING');
export const requestProfileChangeNameSuccess = createAction('PROFILE_CHANGE_NAME_SUCCESS', (res, id) => ({ payload: { ...res.data, id } }));
export const requestProfileChangeNameFailure = createAction('PROFILE_CHANGE_NAME_FAILURE');

export const requestProfileChangeAvatarPending = createAction('PROFILE_CHANGE_AVATAR_PENDING');
export const requestProfileChangeAvatarSuccess = createAction('PROFILE_CHANGE_AVATAR_SUCCESS', (res, id) => ({ payload: { ...res.data, id } }));
export const requestProfileChangeAvatarFailure = createAction('PROFILE_CHANGE_AVATAR_FAILURE');

export const requestProfileChangeCardPending = createAction('PROFILE_CHANGE_CARD_PENDING');
export const requestProfileChangeCardSuccess = createAction('PROFILE_CHANGE_CARD_SUCCESS', (res, id) => ({ payload: { ...res.data, id } }));
export const requestProfileChangeCardFailure = createAction('PROFILE_CHANGE_CARD_FAILURE');

export const requestCustomerContracts = createRequestActions('GET_CUSTOMER_CONTRACTS', defaultRequestSuccessHandler);

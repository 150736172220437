import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#24272A'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
  transform: rotate(${props => props.rotation || '0deg'});
`;

export const DateIcon = ({ defaultColor, spacing, hoverColor, rotation }) => {
  return (
    <StyledSvg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
      rotation={rotation}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.00025 16.875C3.96471 16.875 3.12524 16.0355 3.12524 15V5C3.12524 3.96447 3.96471 3.125 5.00024 3.125H15.0002C16.0358 3.125 16.8752 3.96447 16.8752 5V6.87377C16.8752 6.87336 16.8752 6.87418 16.8752 6.87377L16.8752 15C16.8752 16.0355 16.0358 16.875 15.0002 16.875H10.0002C9.99986 16.875 10.0006 16.875 10.0002 16.875H5.00025ZM12.7591 15.625H15.0002C15.3454 15.625 15.6252 15.3452 15.6252 15V12.7589L12.7591 15.625ZM15.6252 10C15.6252 10.3452 15.3454 10.625 15.0002 10.625L12.5002 10.625C11.4647 10.625 10.6252 11.4645 10.6252 12.5V15C10.6252 15.3452 10.3454 15.625 10.0002 15.625L5.00025 15.625C4.65507 15.625 4.37524 15.3452 4.37524 15L4.37524 7.5H15.6252V10ZM15.6252 5V6.25H4.37524V5C4.37524 4.65482 4.65507 4.375 5.00024 4.375L15.0002 4.375C15.3454 4.375 15.6252 4.65482 15.6252 5ZM14.7414 11.875L12.5002 11.875C12.1551 11.875 11.8752 12.1548 11.8752 12.5V14.7411L14.7414 11.875Z'
      />
    </StyledSvg>
  );
};

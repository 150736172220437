import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

class CustomScrollbar extends Component {
  renderTrackHorizontal = props => {
    return <div {...props} className='custom-scrollbar__track-horizontal' />;
  };

  renderThumbHorizontal = props => {
    return <div {...props} className='custom-scrollbar__view__thumb-horizontal' />;
  };

  renderTrackVertical = props => {
    return <div {...props} className='custom-scrollbar__track-vertical' />;
  };

  renderThumbVertical = props => {
    return <div {...props} className='custom-scrollbar__thumb-vertical' />;
  };

  renderView = props => {
    return <div {...props} className='custom-scrollbar__view' />;
  };

  render() {
    const { className, ...restProps } = this.props;
    const mergedClassName = cn('custom-scrollbar', className);

    return (
      <Scrollbars
        className={mergedClassName}
        thumbMinSize={15}
        renderTrackHorizontal={this.renderTranckHorizontal}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbVertical={this.renderThumbVertical}
        renderView={this.renderView}
        {...restProps}
      >
        {this.props.children}
      </Scrollbars>
    );
  }
}

CustomScrollbar.propTypes = {
  className: PropTypes.string,
  thumbSize: PropTypes.number
};

export default CustomScrollbar;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuIcon } from '../../icons/core/MenuIcon';

const NavigationElementWrapper = styled.div`
  font-size: 16px;
  & > a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #24272a;
  }
  &:hover {
    background-color: #f4f4f4;
  }
  background-color: ${props => props.activeStyle};
  padding: 10px 18px;
  border-radius: 8px;
  width: 220px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const NavigationParentWrapper = styled.div`
  font-size: 16px;
  & > a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #24272a;
  }
  background-color: ${props => props.activeStyle};
  border-radius: 8px;
  cursor: pointer;
`;

const NavigationMobileWrapper = styled.div`
  font-size: 11px;
  & > a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: ${props => props.activeStyle}; // Убедитесь, что цвет применяется к ссылке
  }
  cursor: pointer;
`;

const UserDescription = styled.div`
  & > p {
    color: inherit; // Использует цвет от родителя
    margin: 0;
  }
`;

export const NavigationElement = ({ role, text, name, route, currentRoute, isMobile }) => {
  const isActive = route === currentRoute;

  return (
    <div>
      {(role === 'child' || role === 'independent') && !isMobile && (
        <NavigationElementWrapper activeStyle={isActive ? '#f4f4f4' : 'initial'}>
          <Link to={route}>
            {role !== 'child' && (
              <MenuIcon name={name} spacing={'16px'} width={'24px'} height={'24px'} defaultColor={'#24272A'} />
            )}
            <UserDescription>
              <p>{text ? text : ''}</p>
            </UserDescription>
          </Link>
        </NavigationElementWrapper>
      )}
      {!isMobile && role === 'parent' && (
        <NavigationParentWrapper activeStyle={isActive ? '#f4f4f4' : 'initial'}>
          <Link to={route}>
            <MenuIcon name={name} spacing={'16px'} width={'24px'} height={'24px'} defaultColor={'#24272A'} />
            <UserDescription>
              <p>{text ? text : ''}</p>
            </UserDescription>
          </Link>
        </NavigationParentWrapper>
      )}
      {isMobile && (
        <NavigationMobileWrapper activeStyle={isActive ? '#24272A' : '#8F9295'}>
          <Link to={route}>
            <MenuIcon
              name={name}
              spacing={'0'}
              width={'24px'}
              height={'24px'}
              defaultColor={isActive ? '#24272A' : '#8F9295'}
            />
            <UserDescription>
              <p>{text ? text : ''}</p>
            </UserDescription>
          </Link>
        </NavigationMobileWrapper>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import 'keen-slider/keen-slider.min.css';
import { useHoverSlider } from '../../../utils/hooks/useHoverSlider';
import { styled } from 'styled-components';
const Wrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto;
  position: relative;
  aspect-ratio: 3 / 2;

  @media only screen and (min-width: 1281px) {
    overflow: visible;
  }

  @media only screen and (min-width: 1300px) {
    overflow: ${props => (props.safari ? 'visible' : 'hidden')};
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  @media (max-width: 599px) {
    display: none;
  }
`;

const ControlButton = styled.button`
  position: absolute;
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  transition: all 0.2s ease-out;

  &:hover {
    background: #fff;
  }

  &:first-of-type {
    left: 60px;
    top: calc(50% - 24px);

    @media (max-width: 1199px) {
      left: 40px;
    }
  }

  &:last-of-type {
    right: 60px;
    top: calc(50% - 24px);
    transform: rotate(180deg);

    @media (max-width: 1199px) {
      right: 40px;
    }
  }
`;

const Slide = styled.div`
  transition: opacity 0.5s ease;
  border-radius: 8px;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};

  @media only screen and (min-width: 1281px) {
    max-width: calc(25% - 40px);
    width: 350px;
    overflow: visible;
  }

  img {
    max-width: 100%;
    object-fit: ${props => (props.safari ? 'contain' : 'cover')};
    object-fit: cover;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    position: ${props => (props.safari ? 'static' : 'absolute')};
    transform: ${props => (props.safari ? 'none' : 'translate(-50%, -50%)')};
  }
`;

const IndicatorWrapper = styled.div`
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  margin: auto;
  height: 10px;
`;

const Indicator = styled.div`
  margin-right: 12px;
  border-radius: 3px;
  height: 6px;
  width: 6px;
  transition: ease-out 0.2s;
  background-color: rgba(255, 255, 255, 0.8);
  &.active {
    width: 24px;
    background-color: #ffffff; /* Цвет активного индикатора */
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const PictureSlider = ({
  images,
  wrapperOverride,
  centeredImages,
  useManualControls,
  handleSlideChange,
  galleryIndex
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [safari, setSafari] = useState(false);
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return /safari/.test(ua) && !/chrome/.test(ua);
  };
  useEffect(() => {
    setSafari(isSafari);
  }, []);
  const options = {
    slideChanged(slider) {
      setCurrentSlide(slider && slider.track && slider.track.details ? slider.track.details.rel : 0);
    },
    ...(useManualControls && {
      loop: true
    })
  };

  useEffect(() => {
    if (galleryIndex !== undefined && galleryIndex !== currentSlide) {
      setCurrentSlide(galleryIndex);
      if (slider && slider.current) {
        // eslint-disable-next-line no-unused-expressions
        slider.current.moveToIdx(galleryIndex);
      }
    }
  }, [galleryIndex]);

  useEffect(() => {
    if (handleSlideChange) {
      handleSlideChange(currentSlide);
    }
  }, [currentSlide, handleSlideChange]);

  const { sliderRef, slider } = useHoverSlider({ ...options, slides: images.length });

  const forceUpdate = () => {
    setTimeout(() => {
      if (slider && slider.current) {
        // eslint-disable-next-line no-unused-expressions
        slider.current.update();
      }
    }, 10);
  };

  useEffect(() => {
    if (slider && slider.current) {
      // eslint-disable-next-line no-unused-expressions
      slider.current.update();
    }
    forceUpdate();
  }, [slider, images]);

  const handleDecreaseSlide = () => {
    if (slider && slider.current) {
      // eslint-disable-next-line no-unused-expressions
      slider.current.prev();
    }
  };

  const handleIncreaseSlide = () => {
    if (slider && slider.current) {
      // eslint-disable-next-line no-unused-expressions
      slider.current.next();
    }
  };

  return (
    <Wrapper safari={safari} className={wrapperOverride}>
      {useManualControls && (
        <ControlsWrapper>
          <ControlButton onClick={handleDecreaseSlide}>Prev</ControlButton>
          <ControlButton onClick={handleIncreaseSlide}>Next</ControlButton>
        </ControlsWrapper>
      )}
      <div ref={sliderRef} className='keen-slider'>
        {images.map((image, index) => (
          <Slide
            safari={safari}
            key={index}
            className={`keen-slider__slide ${centeredImages ? 'centeredImages' : ''}`}
            centered={centeredImages}
          >
            <img src={image} alt={`Slide ${index}`} />
          </Slide>
        ))}
      </div>
      {images.length > 1 && (
        <IndicatorWrapper>
          {images.map((_, idx) => (
            <Indicator key={idx} className={idx === currentSlide ? 'active' : ''} />
          ))}
        </IndicatorWrapper>
      )}
    </Wrapper>
  );
};

export default PictureSlider;

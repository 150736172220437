import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <svg
      className='workplace-floor-shadow'
      width='3360'
      height='1060'
      viewBox='0 0 3360 1060'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M826.748 650C752.194 787.011 606.957 880 440 880C196.995 880 0 683.005 0 440C0 196.995 196.995 0 440 0C472.159 0 503.511 3.44997 533.708 10H1900H3060V100H3158.36C3281.71 204.556 3360 360.635 3360 535C3360 709.365 3281.71 865.444 3158.36 970H3060V1060H1900V650H826.748Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M355 340L381 321L408 312L420 310H470L512 328L534 346L570 405H355V340ZM2552 538.75V490H2595V519.792V555H2552V538.75ZM2885 315H2785V495H2765V555H2845V425H2885V315Z'
        fill='#E1E2E3'
      />
    </svg>
  );
};

export const Tulskaya_5_Floor = memo(SVGComponents);

/* eslint-disable no-shadow */
import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class ServiceType extends Model {
  static reducer(action, serviceType) {
    switch (action.type) {
      case actions.requestServiceTypeSuccess.toString():
        action.payload.map(elem => serviceType.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `ServiceType: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
ServiceType.modelName = 'ServiceType';

// Declare your related fields.
ServiceType.fields = {
  id: attr() // non-relational field for any value; optional but highly recommended
  // status_id:
};

export default ServiceType;

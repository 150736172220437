import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class Account extends Model {
  static reducer(action, account) {
    switch (action.type) {
      case actions.requestAccountSuccess.toString():
        account.upsert(action.payload);
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `Account: ${this.name}`;
  }
}
Account.modelName = 'Account';

Account.fields = {
  agreementNumber: attr()
};

Account.options = {
  idAttribute: 'agreementNumber'
};

export default Account;

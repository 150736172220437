import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' filter='url(#filter0_d_4641_51304)'>
      <path
        d='M30 0H10V10H0V1110H210V1080H230V875H760V850H800V630H400V450H800V230H760V210H220V40H240V0H215V10H185V0H160V10H30V0Z'
        fill='white'
      />
    </g>
  );
};

export const Neo_Geo_2_A_Floor = memo(SVGComponents);

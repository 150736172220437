import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import TransactionsHistoryRow from './TransactionsHistoryRow';
import CustomScrollbar from '../../../components/CustomScrollbar';
import ContentLoader from '../../../components/ContentLoader';
import TableDataLoader from '../../../components/TableDataLoader';
import TableDataReloader from '../../../components/TableDataReloader';
import NotHistory from '../../../components/NotHistory';
import { getUpdData } from '../../../services/workki/TransactionsActions';
import { useDispatch } from 'react-redux';

const TEXT_ON_EMPTY_LIST = 'Список списаний пуст';
const VALUES_TOP_THRESHOLD = 0.98;

const TransactionsHistory = ({ transactions, isLoading, onError, canLoadMore, loadFirstPage, loadNextPage }) => {
  const isListEmpty = useMemo(() => isEmpty(transactions), [transactions]);
  const componentRef = useRef(null);
  const tableBodyRef = useRef(null);
  const dispatch = useDispatch();

  const onUpdate = useCallback(
    values => {
      const hasScrolledToBottom = values.top >= VALUES_TOP_THRESHOLD;
      if (hasScrolledToBottom && !isLoading) {
        loadNextPage();
      }
    },
    [isLoading, loadNextPage]
  );

  useEffect(() => {
    if (isLoading || isListEmpty || !canLoadMore) {
      return;
    }

    if (!tableBodyRef.current || !componentRef.current) {
      return;
    }

    const componentRect = componentRef.current.getBoundingClientRect();
    const tableBodyRect = tableBodyRef.current.getBoundingClientRect();

    if (tableBodyRect.bottom <= componentRect.bottom) {
      loadNextPage();
    }
  }, [canLoadMore, isListEmpty, isLoading, loadNextPage]);

  if (isListEmpty && !canLoadMore) {
    return null;
  }

  if (isListEmpty && isLoading) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (isListEmpty && !canLoadMore) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='page__section page__section--grow scrollable-table scrollable-table--transactions'>
      <div className='scrollable-table__title'>История списаний</div>
      <CustomScrollbar className='scrollable-table__scrollbar' onUpdate={onUpdate}>
        <div className='scrollable-table__table' ref={componentRef}>
          <div className='scrollable-table__table-head'>
            <div className='scrollable-table__table-head-row'>
              <div className='scrollable-table__cell'>ID списания</div>
              <div className='scrollable-table__cell'>Дата и время</div>
              <div className='scrollable-table__cell'>Сумма</div>
              <div className='scrollable-table__cell'>Услуга</div>
              <div className='scrollable-table__cell'>Локация</div>
              <div className='scrollable-table__cell'>УПД</div>
              <div className='scrollable-table__cell'>Договор</div>
            </div>
          </div>
          <div className='scrollable-table__table-body' ref={tableBodyRef}>
            {transactions.map(t => (
              <TransactionsHistoryRow key={t.service_request_id} transaction={t} />
            ))}
          </div>
          {isLoading && <TableDataLoader />}
          {onError && <TableDataReloader reload={loadFirstPage} />}
        </div>
      </CustomScrollbar>
    </section>
  );
};

export default TransactionsHistory;

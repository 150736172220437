import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#24272A'};
    :hover {
      ${props => props.hoverColor || '#8F9295'};
    }
  }
  cursor: pointer;
  margin-right: ${props => props.spacing || '16px'};
`;

export const Delete = ({ defaultColor, spacing, hoverColor, handleAction }) => {
  return (
    <StyledSvg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      onClick={handleAction}
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.25024 6V7.5H4.50024C4.08603 7.5 3.75024 7.83579 3.75024 8.25C3.75024 8.66421 4.08603 9 4.50024 9H5.34368L6.58334 18.2975C6.73238 19.4153 7.6859 20.2501 8.81361 20.2501H15.1871C16.3148 20.2501 17.2683 19.4153 17.4173 18.2975L18.657 9H19.5002C19.9145 9 20.2502 8.66421 20.2502 8.25C20.2502 7.83579 19.9145 7.5 19.5002 7.5H15.7502V6C15.7502 4.75736 14.7429 3.75 13.5002 3.75H10.5002C9.2576 3.75 8.25024 4.75736 8.25024 6ZM10.5002 5.25C10.086 5.25 9.75024 5.58579 9.75024 6V7.5H14.2502V6C14.2502 5.58579 13.9145 5.25 13.5002 5.25H10.5002ZM17.1437 9H6.85696L8.07018 18.0992C8.11987 18.4718 8.4377 18.7501 8.81361 18.7501H15.1871C15.563 18.7501 15.8808 18.4718 15.9305 18.0992L17.1437 9Z'
        fill='#8F9295'
      />
    </StyledSvg>
  );
};

import { useCallback } from 'react';
import { createDeposit as createDepositAction } from '../api/DepositsActions';
import { useDispatch } from 'react-redux';

export const usePaymentController = () => {
  const dispatch = useDispatch();

  const createDeposit = useCallback(
    payment => {
      dispatch(createDepositAction(payment));
    },
    [dispatch]
  );

  const handlePayment = ({ type, depositAmount, isSecurityPayment, checkoutDifference }) => {
    const payment = {
      currency_id: 3,
      is_temporary_booking: false,
      office_id: 1,
      amount: depositAmount === '' ? checkoutDifference : depositAmount,
      payment_system_id: type === 'card' ? 24 : 3,
      payment_system_item_id: type === 'bankAccount' ? 1 : null,
      is_security_payment: Number(isSecurityPayment)
    };

    createDeposit(payment);
  };

  return { handlePayment };
};

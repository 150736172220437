import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' clipPath='url(#clip0_4442_162653)' filter='url(#filter0_d_4442_162653)'>
      <path d='M2240 0V230H2220V390H2880V850H200V700H0V560H200V390H2010V230H1840V140H1750V0H2240Z' fill='white' />
    </g>
  );
};

export const Park_Kultury_Floor = memo(SVGComponents);

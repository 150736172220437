import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import GuestsPanel from '../../Guests/GuestsPanel';
import ParkingPanel from '../../Parking/ParkingPanel';
import {
  guestList,
  parkingList,
  customerSelector,
  officeWithGuestServiceSelector,
  officeWithParkingSelector
} from '../../../store/selectors/DefaultSelectors';
import {
  getGuestsNeoGeo,
  getGuestsCity,
  getGuestsParkKultury,
  getGuestKomsomolskaya,
  getGuestLimit,
  createMultipleGuestsTulskaya,
  getGuestsTulskaya,
  createMultipleGuestsCity,
  createMultipleGuestsNeoGeo,
  createMultipleGuestsParkKultury,
  createMultipleGuestsKomsomolskaya,
  createNeoGeoParking,
  createParkKulturyParking,
  getParkingList,
  getParkKulturyParkingList,
  getTulskayaTMC,
  getParkKulturyTMC,
  createParkKulturyTMC,
  createTulskayaTMC
} from '../../../services/workki/ServicesActions';
import * as actions from '../../../store/actions';
import useModalState from '../../../utils/useModalState';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import queryString from 'query-string';
import { MainWrapper } from '../../Payments/model/Payments';
import HistoryList from '../../../widgets/Services/HistoryList';
import { PrefillForm } from '../../Profile/PrefillProfile/model/PrefillProfileComponent';
import InputTMCPanel from '../../../components/InputTMC/TMCInputPanel';

const guestsAddActionByServiceId = {
  287: createMultipleGuestsCity,
  288: createMultipleGuestsNeoGeo,
  702: createMultipleGuestsParkKultury,
  708: createMultipleGuestsKomsomolskaya,
  921: createMultipleGuestsTulskaya
};

const mapStateToProps = state => ({
  customer: customerSelector(state),
  guestLocations: officeWithGuestServiceSelector(state),
  parkingLocations: officeWithParkingSelector(state),
  guestList: guestList(state),
  parkingList: parkingList(state, { parkingType: true }),
  tmcInputList: parkingList(state, { parkingType: false }),
  guestsRequestStatus: requestStatusSelector(actions.requestGuests)(state),
  guestCreateRequestStatus: requestStatusSelector(actions.requestGuestCreate)(state),
  guestLimits: requestStatusSelector(actions.requestGuestLimit)(state),
  parkingListRequestStatus: requestStatusSelector(actions.requestParkingList)(state),
  parkingCreateRequestStatus: requestStatusSelector(actions.requestParkingCreate)(state),
  tmcInputListRequestStatus: requestStatusSelector(actions.requestParkingList)(state),
  tmcInputCreateRequestStatus: requestStatusSelector(actions.requestParkingCreate)(state),

  getError: () => {
    const { guestCreate: guestError, parkingCreate: parkingError } = state.errors;
    const error = guestError || parkingError;
    return (
      (error.response && error.response.data && Array.isArray(error.response.data) && error.response.data[0].message) ||
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
    );
  }
});

const fetchAllGuestsWithDispatch = dispatch => {
  dispatch(getGuestsNeoGeo());
  dispatch(getGuestsCity());
  dispatch(getGuestsParkKultury());
  dispatch(getGuestsTulskaya());
  dispatch(getGuestKomsomolskaya());
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  resetError: () => dispatch(actions.cleanErrors()),
  getGuests: () => fetchAllGuestsWithDispatch(dispatch),
  loadTMCInputHistory: () => dispatch(getParkingList()),
  loadTulskayaTMCInputHistory: () => dispatch(getTulskayaTMC()),
  loadParkKulturyTMCInputHistory: () => dispatch(getParkKulturyTMC()),
  addTMCInputNeoGeo: parking => dispatch(createNeoGeoParking(parking)), // Легаси - заявка имущества в этой ручке связана с парковкой
  addTMCInputParkKultury: tmcData => dispatch(createParkKulturyTMC(tmcData)),
  addTMCInputTulskaya: tmcData => dispatch(createTulskayaTMC(tmcData)),
  addGuests: guests => {
    if (!guests.length) return;
    const { service_id, start_at, finish_at } = guests[0];
    const guestsAddAction = guestsAddActionByServiceId[service_id];
    if (!guestsAddAction) return;

    dispatch(guestsAddAction({ service_id, start_at, finish_at, guests })).then(() =>
      fetchAllGuestsWithDispatch(dispatch)
    );
  },
  getGuestLimit: date => {
    dispatch(getGuestLimit(date));
  },
  loadParkingHistory: () => {
    dispatch(getParkingList());
    dispatch(getParkKulturyParkingList());
  },
  addParkingNeoGeo: parking => dispatch(createNeoGeoParking(parking)),
  addParkingParkKultury: parking => dispatch(createParkKulturyParking(parking))
});

export const ServicesHistory = props => {
  const loadTmcData = {
    loadTMCInputHistory: props.loadTMCInputHistory,
    loadTulskayaTMCInputHistory: props.loadTulskayaTMCInputHistory,
    loadParkKulturyTMCInputHistory: () => props.loadParkKulturyTMCInputHistory
  };
  const [isModalWindowOpen, setOpenModal, closeModal] = useModalState(false);
  const [defaultLocationIndex, setDefaultLocationIndex] = useState('');
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const closeModalWindow = useCallback(() => {
    props.dispatch(actions.requestGuestCreate.reset());
    props.dispatch(actions.requestParkingCreate.reset());
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (props.guestCreateRequestStatus.isSuccess || props.parkingCreateRequestStatus.isSuccess) {
      closeModalWindow();
    }
  }, [props.guestCreateRequestStatus, props.parkingCreateRequestStatus]);

  useEffect(() => {
    let { locationId } = queryString.parse(window.location.search);
    let locationIndex;
    locationId = Number(locationId) || null;
    props.guestLocations.find((location, index) => {
      if (location.value.office.id === locationId) {
        locationIndex = index;
        return true;
      }
    });
    setDefaultLocationIndex(locationIndex);
    if (locationIndex !== undefined) setOpenModal();
  }, [props.guestLocations]);

  useEffect(() => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, []);

  return (
    <MainWrapper>
      <PrefillForm>
        <GuestsPanel
          outerModalOpen={props.outerModalOpen.guests}
          setOuterModalOpen={props.setOuterModalOpen}
          isTouchDevice={isTouchDevice}
          locations={props.guestLocations}
          setOpenModal={setOpenModal}
          closeModalWindow={closeModalWindow}
          addGuests={props.addGuests}
          guestLimits={props.guestLimits}
          getGuestLimit={props.getGuestLimit}
          guestsRequestStatus={props.guestsRequestStatus}
          guestCreateRequestStatus={props.guestCreateRequestStatus}
          getError={props.getError}
          resetError={props.resetError}
          isModalWindowOpen={isModalWindowOpen}
          defaultLocationIndex={defaultLocationIndex}
          prevWatcher={!!queryString.parse(window.location.search)}
          customer={props.customer}
        />
        <ParkingPanel
          isTouchDevice={isTouchDevice}
          setOuterModalOpen={props.setOuterModalOpen}
          outerModalOpen={props.outerModalOpen.parking}
          locations={props.parkingLocations}
          setOpenModal={setOpenModal}
          closeModalWindow={closeModalWindow}
          isModalWindowOpen={isModalWindowOpen}
          getError={props.getError}
          resetError={props.resetError}
          loadParkingHistory={props.loadParkingHistory}
          addParkingNeoGeo={props.addParkingNeoGeo}
          addParkingParkKultury={props.addParkingParkKultury}
          parkingCreateRequestStatus={props.parkingCreateRequestStatus}
          parkingListRequestStatus={props.parkingListRequestStatus}
        />
        <InputTMCPanel
          isTouchDevice={isTouchDevice}
          setOuterModalOpen={props.setOuterModalOpen}
          outerModalOpen={props.outerModalOpen.tmc}
          locations={props.parkingLocations}
          setOpenModal={setOpenModal}
          closeModalWindow={closeModalWindow}
          isModalWindowOpen={isModalWindowOpen}
          getError={props.getError}
          addTMCInputNeoGeo={props.addTMCInputNeoGeo}
          addTMCInputParkKultury={props.addTMCInputParkKultury}
          resetError={props.resetError}
          tmcInputCreateRequestStatus={props.tmcInputCreateRequestStatus}
          tmcInputListRequestStatus={props.tmcInputListRequestStatus}
        />
        <HistoryList
          customer={props.customer}
          guestList={props.guestList}
          parkingList={props.parkingList}
          tmcList={props.tmcInputList}
          guestsRequestStatus={props.guestsRequestStatus}
          parkingRequestStatus={props.parkingListRequestStatus}
          loadTmcData={loadTmcData}
          loadGuestData={props.getGuests}
          loadParkingData={props.loadParkingHistory}
        />
      </PrefillForm>
    </MainWrapper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesHistory);

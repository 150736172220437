import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <g className='workplace-floor-shadow' clipPath='url(#clip0_4708_51822)' filter='url(#filter0_d_4708_51822)'>
      <path
        d='M1930 20H1950V840H1915V850H1890V840H1480V850H1455V840H1425V850H1400V840H1280V850H1255V840H1225V850H1200V840H880V850H855V840H825V850H800V840H680V850H655V840H625V850H600V840H10V480H0V455H10V425H20V240H10V210H0V45H10V15H0V0H1120V20H1400V0H1425V10H1455V0H1480V20H1600V0H1625V10H1655V0H1680V10H1865V0H1890V25H1930V20Z'
        fill='white'
      />
    </g>
  );
};

export const Neo_Geo_C_Floor = memo(SVGComponents);

import React, { useEffect } from 'react';
import CustomScrollbar from '../components/CustomScrollbar';
import SideBar from '../shared/ui/components/Navigation/SideBar';
import SubmenuWidget from '../widgets/SidebarWidgets/SubmenuWidget';
import MainMenuWidget from '../widgets/SidebarWidgets/MainMenuWidget';
import MobileMenuWidget from '../widgets/MobileNavigationWidgets/MobileMenuWidget';
import { useSelector } from 'react-redux';

const PrimaryLayout = ({ children }) => {
  const isAvatarRedactorOpened = useSelector(state => state.isAvatarRedactorOpened);
  return (
    <div className='page__app-root'>
      <aside className='page__sidebar'>
        <SideBar>
          <MainMenuWidget />
          <SubmenuWidget />
        </SideBar>
      </aside>
      {!isAvatarRedactorOpened && <MobileMenuWidget />}
      <CustomScrollbar className='page__custom-scrollbar'>
        <div className='page__custom-scrollbar-content'>{children}</div>
      </CustomScrollbar>
    </div>
  );
};

export default PrimaryLayout;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const Success = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        d='M43.9475 25.4142C44.7286 24.6332 44.7286 23.3668 43.9475 22.5858C43.1665 21.8047 41.9002 21.8047 41.1191 22.5858L27.0667 37.1716L22.4809 32.5858C21.6998 31.8047 20.4335 31.8047 19.6525 32.5858C18.8714 33.3668 18.8714 34.6332 19.6525 35.4142L25.6525 41.4142C26.4335 42.1953 27.6998 42.1953 28.4809 41.4142L43.9475 25.4142Z'
        fill='#00A676'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8ZM12 32C12 20.9543 20.9543 12 32 12C43.0457 12 52 20.9543 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32Z'
        fill='#00A676'
      />
    </StyledSvg>
  );
};

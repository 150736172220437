import React, { useEffect, useState } from 'react';
import { useResize } from '../../../../shared/utils/hooks/useResize';
import { colors, screenRessolutions } from '../../../../shared/ui/constants/styleConstants';
import { CustomAccordion } from '../../../../shared/ui/components/Profile/Accordion';
import { ArrowBack } from '../../../../shared/ui/icons/core/ArrowBack';
import { PrefillForm, StepLabel } from '../../PrefillProfile/model/PrefillProfileComponent';
import styled from 'styled-components';
import { StepButton } from '../../../../shared/ui/components/StepButton';
import { EmployeesTable } from '../../../../shared/ui/components/Profile/Table';
import { EmployeeActionsDialog } from '../../../../widgets/ProfileWidgets/Employees/EmployeeActionsDialog';
import {
  deleteCustomerChild,
  getCustomerChildren,
  getCustomerChildRoles
} from '../../../../services/workki/ChildActions';
import { useDispatch } from 'react-redux';
import { EmployeeRemoveDialog } from '../../../../widgets/ProfileWidgets/Employees/EmployeeRemoveDialog';
import { ChangeCredentialsDialog } from '../../../../widgets/ProfileWidgets/Employees/ChangeCredentialsDialog';

const EmptyEmployeesBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  & > p {
    margin: 0 0 16px 0;
    font-size: 14px;
    text-align: center;
    color: ${colors.workkiDarkSecondary};
    font-weight: 400;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    width: 350px;
  }
`;

const EmployeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
const EmployeesAdditionBlock = styled.div`
  position: fixed;
  flex-direction: row;
  padding: 10px 16px;
  border-top: 1px solid ${colors.workkiLightLight1};
  border-right: 1px solid ${colors.workkiLightLight1};
  border-left: 1px solid ${colors.workkiLightLight1};
  border-radius: 12px 12px 0 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: white;
  bottom: 60px;
  left: 0;
  width: 100%;
  & > button {
    margin-left: 16px;
    width: 60%;
  }
`;
const EmployeesAdditionBlockDesktop = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
const EmployeesCount = styled.div`
  display: flex;
  @media (min-width: ${screenRessolutions.smallMobile}) {
    width: 40%;
  }
  @media (min-width: ${screenRessolutions.laptop}) {
    width: 300px;
  }
  & > p {
    font-size: 14px;
    font-weight: 300;
  }
`;
const EmployeesTableWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;
const EmployeesTopBlock = styled.div`
  display: flex;
  margin-top: 16px;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    margin: 30px 20px 0 30px;
    width: 100%;
    justify-content: flex-start;
  }
  @media (min-width: ${screenRessolutions.mobile}px) {
    margin: 30px 0 0 0;
    justify-content: center;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    margin: 0;
    padding: 30px 20px 0 20px;
    justify-content: space-between;
  }
`;
const EmployeesTitleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  :first-child {
    margin-left: 12px;
  }
`;

export const EmployeesComponent = () => {
  const dispatch = useDispatch();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState({
    addEmployee: false,
    editEmployee: false,
    deleteEmployee: false,
    changeCredentials: false
  });
  const handleResize = () => {
    if (window.innerWidth < screenRessolutions.laptop) {
      setIsTouchDevice(true);
    } else setIsTouchDevice(false);
  };
  useEffect(() => {
    dispatch(getCustomerChildren()).then(response => setEmployees(response.data));
    dispatch(getCustomerChildRoles());
  }, []);
  useResize(handleResize, 0);
  useEffect(() => {
    if (window.innerWidth < screenRessolutions.laptop) {
      setIsTouchDevice(true);
    } else setIsTouchDevice(false);
  }, [isTouchDevice]);

  const handleDelete = id => {
    dispatch(deleteCustomerChild(id))
      .then(() => {
        dispatch(getCustomerChildren()).then(response => setEmployees(response.data));
      })
      .catch(error => {
        console.error('Ошибка при удалении сотрудника:', error);
      });
  };
  const handleOpenCredentialsDialog = () => {
    setOpen(prevState => ({ ...prevState, editEmployee: false, changeCredentials: true }));
  };

  return (
    <>
      <EmployeesTopBlock>
        {window.innerWidth < screenRessolutions.laptop && (
          <ArrowBack defaultColor={'#24272A'} spacing='75px' hoverColor={'#383c40'} rotation={0} />
        )}
        <EmployeesTitleCountWrapper>
          <StepLabel
            position={window.innerWidth < screenRessolutions.laptop ? 'center' : 'flex-start'}
            onClick={() => (window.location.href = '/profile')}
          >
            {window.innerWidth > screenRessolutions.laptop && (
              <ArrowBack defaultColor={'#24272A'} spacing='20px' hoverColor={'#383c40'} rotation={0} />
            )}
            <p>Сотрудники</p>
          </StepLabel>
          {window.innerWidth > screenRessolutions.laptop && (
            <EmployeesCount>
              <p>{`Активных сотрудников: ${employees.filter(employee => employee.is_active).length}/${
                employees.filter(employee => employee.is_active).length
              }`}</p>
            </EmployeesCount>
          )}
        </EmployeesTitleCountWrapper>
        {!isTouchDevice && employees.filter(employee => employee.is_active).length > 0 && (
          <EmployeesAdditionBlockDesktop>
            <StepButton
              filledType='red'
              type='filled'
              iconColor='white'
              spacing='0'
              fontSizeOverride='14px'
              title='Добавить сотрудника'
              handleAction={() => setOpen(prevState => ({ ...prevState, addEmployee: true }))}
            />
          </EmployeesAdditionBlockDesktop>
        )}
      </EmployeesTopBlock>
      {isTouchDevice ? (
        <PrefillForm>
          {employees && employees.filter(employee => employee.is_active).length > 0 ? (
            <>
              <EmployeesWrapper>
                <CustomAccordion
                  setEmployeeId={id => setEmployeeId(id)}
                  editEmployee={() => setOpen(prevState => ({ ...prevState, editEmployee: true }))}
                  handleDelete={id => handleDelete(id)}
                  employees={employees}
                />
              </EmployeesWrapper>
              <EmployeesAdditionBlock>
                <EmployeesCount>
                  <p>{`Активных сотрудников: ${employees.filter(employee => employee.is_active).length}/${
                    employees.filter(employee => employee.is_active).length
                  }`}</p>
                </EmployeesCount>
                <StepButton
                  filledType='red'
                  type='filled'
                  iconColor='white'
                  spacing='0'
                  fontSizeOverride='14px'
                  title='Добавить сотрудника'
                  handleAction={() => setOpen(prevState => ({ ...prevState, addEmployee: true }))}
                />
              </EmployeesAdditionBlock>
            </>
          ) : (
            <EmptyEmployeesBlock>
              <p>Добавьте сотрудников, чтобы они могли пользоваться услугами вашего тарифного плана</p>
              <StepButton
                filledType='black'
                type='filled'
                iconColor='white'
                spacing='0'
                fontSizeOverride='14px'
                title='Добавить сотрудника'
                handleAction={() => setOpen(prevState => ({ ...prevState, addEmployee: true }))}
              />
            </EmptyEmployeesBlock>
          )}
        </PrefillForm>
      ) : (
        <EmployeesTableWrapper>
          {employees.filter(employee => employee.is_active).length > 0 ? (
            <EmployeesTable
              setEmployeeId={id => setEmployeeId(id)}
              editEmployee={() => setOpen(prevState => ({ ...prevState, editEmployee: true }))}
              handleDelete={id => handleDelete(id)}
              employees={employees}
            />
          ) : (
            <EmptyEmployeesBlock>
              <p>Добавьте сотрудников, чтобы они могли пользоваться услугами вашего тарифного плана</p>
              <StepButton
                filledType='black'
                type='filled'
                iconColor='white'
                spacing='0'
                fontSizeOverride='14px'
                title='Добавить сотрудника'
                handleAction={() => setOpen(prevState => ({ ...prevState, addEmployee: true }))}
              />
            </EmptyEmployeesBlock>
          )}
        </EmployeesTableWrapper>
      )}
      {open.addEmployee && (
        <EmployeeActionsDialog
          title='Добавление сотрудника'
          dialogType='addEmployee'
          handleClose={() => {
            dispatch(getCustomerChildren()).then(response => setEmployees(response.data));
            setOpen(prevState => ({ ...prevState, addEmployee: false }));
          }}
        />
      )}
      {open.editEmployee && (
        <EmployeeActionsDialog
          title='Редактирование сотрудника'
          dialogType='editEmployee'
          dialogData={{ employees, employeeId }}
          handleClose={() => {
            dispatch(getCustomerChildren()).then(response => setEmployees(response.data));
            setOpen(prevState => ({ ...prevState, editEmployee: false }));
          }}
          handleSubmit={handleOpenCredentialsDialog}
        />
      )}
      {open.changeCredentials && (
        <ChangeCredentialsDialog
          title='Заявка на изменение данных'
          dialogType='changeCredentials'
          dialogData={{ employees, employeeId }}
          handleClose={() => {
            setOpen(prevState => ({ ...prevState, changeCredentials: false }));
          }}
          // handleSubmit={() => console.log('Отправка заявки на смену данных')}
        />
      )}
    </>
  );
};

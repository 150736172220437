import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { pick, find } from 'lodash';
import TicketForm from './TicketForm';
import ModalWindowWrapper from '../../hocs/ModalWindowWrapper';
import { getServiceSchedule, serviceRequestWithWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import { officeSelector } from '../../store/selectors/DefaultSelectors';
import { getTickets, createTicket } from '../../services/workki/TicketsActions';
import { ticketCategoriesSelector } from '../../store/selectors/TicketsSelectors';
import * as actions from '../../store/actions';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import Loader from 'react-loader-spinner';

const transformMeetingRoom = meetingRoom => ({
  id: meetingRoom.service_id,
  is_openspace: false,
  name: 'Переговорная ' + meetingRoom.service.name,
  officeName: meetingRoom.office.t_name
});

const getWorkplaces = (activeWorkplaces, activeMeetingRooms, offices) => {
  const workplacesList = activeWorkplaces
    .map(wp => {
      if (!wp.reservation_place) {
        return null;
      }
      const place = pick(wp.reservation_place, ['id', 'name', 'is_openspace']);
      const office = find(offices, o => o.id === wp.reservation_place.office_id);
      return { ...place, officeName: office.t_name };
    })
    .filter(Boolean);
  const meetingRoomsList = activeMeetingRooms.map(transformMeetingRoom);

  return workplacesList.concat(meetingRoomsList);
};

const TicketModal = ({ closeModalWindow, isModalWindowOpen, defaultServiceId, defaultTicketType }) => {
  const dispatch = useDispatch();

  const loadStatus = useSelector(requestStatusSelector(actions.requestServicesScheduleRequests));
  // CLOSE MODAL AFTER CREATE TASK
  const ticketCreateStatus = useSelector(requestStatusSelector(actions.requestTicketCreate));
  useEffect(() => {
    if (ticketCreateStatus.isSuccess) {
      dispatch(actions.requestTicketCreate.reset());
      dispatch(getTickets());
      closeModalWindow();
    }
  }, [closeModalWindow, dispatch, ticketCreateStatus]);

  // MODAL DATA
  const ticketCategories = useSelector(ticketCategoriesSelector);
  const offices = useSelector(officeSelector);
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: moment().subtract(14, 'days'),
      selectorType: 'standard'
    })
  );
  const activeMeetingRooms = useSelector(getServiceSchedule);
  const workplaces = useMemo(() => getWorkplaces(activeWorkplaces, activeMeetingRooms, offices), [
    activeWorkplaces,
    activeMeetingRooms,
    offices
  ]);

  // SEND MODAL FORM
  const ticketCreate = data => dispatch(createTicket(data));
  const resetError = () => dispatch(actions.cleanErrors());

  const error = useSelector(state => state.errors.createTicket);
  const ticketCreateError = useMemo(() => {
    if (!error || error instanceof ErrorWithShowedMessage) {
      return '';
    }
    return (
      (error.response && error.response.data && Array.isArray(error.response.data) && error.response.data[0].message) ||
      'Сервис не доступен. Обратитесь к комьюнити-менеджеру или попробуйте осуществить запрос позже'
    );
  }, [error]);

  return (
    <div>
      {loadStatus.isSuccess ? (
        <TicketForm
          workplaces={workplaces}
          ticketCategories={ticketCategories}
          ticketCreate={ticketCreate}
          ticketCreateStatus={ticketCreateStatus}
          ticketCreateError={ticketCreateError}
          resetError={resetError}
          isModalWindowOpen={isModalWindowOpen}
          closeModalWindow={closeModalWindow}
          defaultServiceId={defaultServiceId}
          defaultTicketType={defaultTicketType}
        />
      ) : (
        <Loader type='ThreeDots' color='#f15a4f' height='400' />
      )}
    </div>
  );
};

export default ModalWindowWrapper(TicketModal);

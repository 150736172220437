import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, get, isEmpty } from 'lodash';
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import { getTicketBinaryFile } from '../../services/workki/TicketsActions';
import { getTicket, getTicketInfo } from '../../services/workki/TicketsActions';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import TicketComments from './TicketComments';

const renderPlace = ticket => {
  if (!ticket.place) {
    return null;
  }
  const content = ticket.office ? `${ticket.place.name} (${ticket.office.t_name})` : ticket.place.name;
  return (
    <div className='ticket-details__info-item'>
      <span className='ticket-details__label'>рабочее место/кабинет:</span>
      {content}
    </div>
  );
};

const renderCategory = ticket => {
  if (!ticket.category) {
    return null;
  }
  return (
    <div className='ticket-details__info-item'>
      <span className='ticket-details__label'>категория:</span>
      {ticket.category.name}
    </div>
  );
};

const renderDateTime = ticket => {
  if (!ticket.created_at) {
    return null;
  }
  const time = moment(ticket.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm');
  return <div className='ticket-details__time'>{time}</div>;
};

const renderStatus = ticket => {
  if (!ticket || !ticket.status) {
    return null;
  }

  return (
    <div className='ticket-details__status'>
      <span className='ticket-details__label'>статус:</span>
      {ticket.status.name}
    </div>
  );
};

const renderAssign = ticket => {
  if (!ticket || !ticket.assign || isEmpty(ticket.assign.displayName)) {
    return null;
  }

  return (
    <div className='ticket-details__assign'>
      <span className='ticket-details__label'>ответственный:</span>
      {ticket.assign.displayName}
    </div>
  );
};

const renderFileLink = (ticket, saveFile) => {
  const fileId = get(ticket, 'files[0].id', null);
  const fileName = get(ticket, 'files[0].filename', 'файл');

  if (isNull(fileId)) {
    return null;
  }

  return (
    <span className='ticket-details__file-link link' onClick={saveFile(fileId, fileName)}>
      Прикрепленный файл
    </span>
  );
};

const TicketDetails = ({ ticket, showTicketsList }) => {
  const dispatch = useDispatch();

  const ticketLoadStatus = useSelector(requestStatusSelector(actions.requestTicket));
  const ticketInfoLoadStatus = useSelector(requestStatusSelector(actions.requestTicketInfo));

  useEffect(() => {
    dispatch(getTicket(ticket.id));
    dispatch(getTicketInfo(ticket.id));
  }, [dispatch, ticket.id]);

  useEffect(() => {
    if (ticketLoadStatus.isFailure || ticketInfoLoadStatus.isFailure) {
      showTicketsList();
    }
  }, [showTicketsList, ticketInfoLoadStatus.isFailure, ticketLoadStatus.isFailure]);

  const [isFileLoading, setIsFileLoading] = useState(false);
  const saveFile = useCallback(
    (id, name) => evt => {
      evt.preventDefault();
      if (isFileLoading) {
        return;
      }
      setIsFileLoading(true);
      dispatch(getTicketBinaryFile(id))
        .then(binaryFile => {
          saveAs(binaryFile.data, name);
        })
        .catch(() => {})
        .finally(() => setIsFileLoading(false));
    },
    [dispatch, isFileLoading]
  );

  if (!ticket) {
    return;
  }

  return (
    <section className='page__section page__section--grow ticket-details'>
      <div className='ticket-details__header'>
        <div className='ticket-details__header-row'>
          <div className='ticket-details__info'>
            {renderPlace(ticket)}
            {renderCategory(ticket)}
          </div>
          {renderStatus(ticket)}
        </div>
        <div className='ticket-details__header-row'>{renderAssign(ticket)}</div>
      </div>
      <div className='ticket-details__description-container'>
        <div className='ticket-details__description'>
          <div className='ticket-details__description-header'>
            <div className='ticket-details__description-author'>Ваше обращение:</div>
            {renderDateTime(ticket)}
          </div>
          <div className='ticket-details__description-text'>{ticket.description}</div>
          <div className='ticket-details__description-footer'>{renderFileLink(ticket, saveFile)}</div>
        </div>
      </div>
      <TicketComments ticket={ticket} />
    </section>
  );
};

TicketDetails.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketDetails;

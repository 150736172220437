import React from 'react';
import PropTypes from 'prop-types';
import { Moment } from '../../utils/moment';
import 'moment/locale/ru';

const TMCInputsList = ({ tmcInputList }) =>
  tmcInputList &&
  tmcInputList.map((TMCItem, key) => {
    const carNumberId = `parking_car_${key}`;
    const TMCItemNumberId = `parking_tmc_${key}`;
    const statusId = `parking_status_${key}`;

    return (
      <tr key={TMCItem.id} className='std-table__row'>
        <td className='std-table__cell'>{TMCItem.location.t_name}</td>
        <td className='std-table__cell std-table__cell--date'>
          <Moment locale='ru' parse='YYYY-MM-DD HH:mm:ss' format='DD.MM.YYYY HH:mm'>
            {TMCItem.date}
          </Moment>
        </td>
        <td className='std-table__cell'>{TMCItem.type || TMCItem.request_type}</td>
        <td className='std-table__cell std-table__cell--paid'>
          <span data-tip data-for={statusId}>
            {TMCItem.status}
          </span>
        </td>
        <td className='std-table__cell std-table__cell--paid'>
          <span data-tip data-for={carNumberId}>
            {TMCItem.car_number}
          </span>
        </td>
        <td className='std-table__cell std-table__cell--paid'>
          <span data-tip data-for={TMCItemNumberId}>
            {TMCItem.parking_number}
          </span>
        </td>
      </tr>
    );
  });

TMCInputsList.propTypes = {
  tmcInputList: PropTypes.array.isRequired
};

export default TMCInputsList;

import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import InputTMC from '../components/InputTMC';
import { Loader } from '../shared/ui/components/Loader';

const InputTMCPage = props => (
  <PrimaryLayout>
    <main className='main'>{props.isLoading ? <Loader /> : <InputTMC {...props} />}</main>
  </PrimaryLayout>
);

export default InputTMCPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import MeetingRoomCardContent from './MeetingRoomCardContent';
import { roomReservationSelector } from '../../store/selectors/ServicesSelector';
import { accountSelector } from '../../store/selectors/DefaultSelectors';
import { connect } from 'react-redux';
import Container from './RoomReservation.container';

class RoomCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listServiceTypes: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.listServiceTypes, state.listServiceTypes)) {
      const listServiceTypes = [];
      props.listServiceTypes.forEach(item =>
        listServiceTypes.push({ ...item, isReset: false, element: React.createRef() })
      );
      return {
        listServiceTypes
      };
    }
    return null;
  }

  outerResetCalendar = key => {
    const { listServiceTypes } = this.state;
    const { setSelectedReservation } = this.props;
    listServiceTypes.forEach(item => {
      if (item.serviceTypeId !== key) {
        item.element.current && item.element.current.resetSelectedAll();
      }
    });
    setSelectedReservation(null, null, null);
  };
  handleSlideChange = slideIndex => {
    this.setState({ activeSlide: slideIndex });
  };

  render() {
    const { location, setSelectedReservation, workingHoursStart, workingHoursEnd, blockDay, dateTime } = this.props;
    const { listServiceTypes } = this.state;
    return (
      <div className='hours-picker'>
        {listServiceTypes.map(serviceType => (
          <>
            <MeetingRoomCardContent
              blockDay={blockDay}
              location={location}
              account={this.props.account}
              name={serviceType.name}
              key={serviceType.name}
              uniqueKey={serviceType.name}
              dateFrom={this.props.dateFrom}
              dateTo={this.props.dateTo}
              workingHoursStart={workingHoursStart}
              workingHoursEnd={workingHoursEnd}
              roomReservationTypeId={serviceType.serviceTypeId}
              setSelectedReservation={setSelectedReservation}
              outerResetCalendar={this.outerResetCalendar}
              ref={serviceType.element}
              //чипсы
              chipData={this.props.chipData}
              setChipData={this.props.setChipData}
              //Пропсы для слайдера
              handleSlideChange={this.handleSlideChange}
              //Бронирование
              pickedDate={this.props.pickedDate}
              setPickedDate={this.props.setPickedDate}
              reserveRoom={this.props.reserveRoom}
              selectedService={this.props.selectedService}
              reserveRoomUI={this.props.reserveRoomUI}
              getError={this.props.getError}
              dateTime={dateTime}
              selectedServiceFreeHours={this.props.selectedServiceFreeHours}
              isSelected={this.props.isSelected}
              cardPrice={this.props.cardPrice}
              getCurrentPrice={this.props.getCurrentPrice}
              openDialog={this.openDialog}
              setOpenDialog={this.setOpenDialog}
              locationFreeHours={this.props.locationFreeHours}
            />
          </>
        ))}
      </div>
    );
  }
}

RoomCard.propTypes = {
  location: PropTypes.object.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  listServiceTypes: PropTypes.array.isRequired,
  setSelectedReservation: PropTypes.func.isRequired,
  blockDay: PropTypes.bool.isRequired
};

export default RoomCard;

import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const isAuthenticated = createReducer(initialState.isAuthenticated, {
  [actions.requestLogin]: () => false,
  [actions.requestLogin.success]: () => true,
  [actions.requestLogin.failure]: () => false,
  [actions.logout]: () => false
});

export default isAuthenticated;

import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const LogoutIcon = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.25 4.0835C5.90482 4.0835 5.625 4.36332 5.625 4.7085V15.3335C5.625 15.6787 5.90482 15.9585 6.25 15.9585H12.5C12.8452 15.9585 13.125 15.6787 13.125 15.3335C13.125 14.9883 13.4048 14.7085 13.75 14.7085C14.0952 14.7085 14.375 14.9883 14.375 15.3335C14.375 16.369 13.5355 17.2085 12.5 17.2085H6.25C5.21447 17.2085 4.375 16.369 4.375 15.3335V4.7085C4.375 3.67296 5.21447 2.8335 6.25 2.8335H12.5C13.5355 2.8335 14.375 3.67296 14.375 4.7085V5.3335C14.375 5.67867 14.0952 5.9585 13.75 5.9585C13.4048 5.9585 13.125 5.67867 13.125 5.3335V4.7085C13.125 4.36332 12.8452 4.0835 12.5 4.0835H6.25ZM13.5581 8.01655C13.8021 7.77248 14.1979 7.77248 14.4419 8.01655L16.3169 9.89155C16.561 10.1356 16.561 10.5314 16.3169 10.7754L14.4419 12.6504C14.1979 12.8945 13.8021 12.8945 13.5581 12.6504C13.314 12.4064 13.314 12.0106 13.5581 11.7666L14.3661 10.9585H8.125C7.77982 10.9585 7.5 10.6787 7.5 10.3335C7.5 9.98832 7.77982 9.7085 8.125 9.7085H14.3661L13.5581 8.90044C13.314 8.65636 13.314 8.26063 13.5581 8.01655Z'
        fill='#24272A'
      />
    </StyledSvg>
  );
};

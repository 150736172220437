import React from 'react';
import styled from 'styled-components';
import { NavigationElement } from '../../components/Navigation/NavigationElement';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
    width: ${props => props.width || '24px'};
    height: ${props => props.height || '24px'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const MenuIcon = ({ defaultColor, spacing, width, height, name }) => {
  const defineMenuItem = name => {
    const content = {
      Main: () => {
        return (
          <>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M12.4775 5.07216C12.2 4.84248 11.7984 4.84248 11.521 5.07216L5.22098 10.287C5.04885 10.4295 4.94922 10.6413 4.94922 10.8648V18.7027C4.94922 19.1169 5.28501 19.4527 5.69922 19.4527H8.08831C8.50252 19.4527 8.83831 19.1169 8.83831 18.7027V15.9636C8.83831 14.7209 9.84567 13.7136 11.0883 13.7136H12.9101C14.1528 13.7136 15.1601 14.7209 15.1601 15.9636V18.7027C15.1601 19.1169 15.4959 19.4527 15.9101 19.4527H18.2992C18.7134 19.4527 19.0492 19.1169 19.0492 18.7027V10.8648C19.0492 10.6413 18.9496 10.4295 18.7775 10.287L12.4775 5.07216ZM10.5645 3.91666C11.3969 3.22765 12.6015 3.22765 13.4339 3.91666L19.7339 9.13155C20.2503 9.55899 20.5492 10.1944 20.5492 10.8648V18.7027C20.5492 19.9453 19.5419 20.9527 18.2992 20.9527H15.9101C14.6675 20.9527 13.6601 19.9453 13.6601 18.7027V15.9636C13.6601 15.5493 13.3243 15.2136 12.9101 15.2136H11.0883C10.6741 15.2136 10.3383 15.5493 10.3383 15.9636V18.7027C10.3383 19.9453 9.33095 20.9527 8.08831 20.9527H5.69922C4.45658 20.9527 3.44922 19.9453 3.44922 18.7027V10.8648C3.44922 10.1944 3.74812 9.55899 4.26451 9.13155L10.5645 3.91666Z'
              fill='#24272A'
            />
          </>
        );
      },
      Reservation: () => {
        return (
          <>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M18.3272 8.92722C18.3272 7.57161 17.2283 6.47267 15.8727 6.47267C14.5171 6.47267 13.4181 7.57161 13.4181 8.92722C13.4181 10.2828 14.5171 11.3818 15.8727 11.3818C17.2283 11.3818 18.3272 10.2828 18.3272 8.92722ZM15.8727 8.10903C16.3246 8.10903 16.6909 8.47535 16.6909 8.92722C16.6909 9.37908 16.3246 9.7454 15.8727 9.7454C15.4208 9.7454 15.0545 9.37908 15.0545 8.92722C15.0545 8.47535 15.4208 8.10903 15.8727 8.10903Z'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M8.50906 9.7454C8.50906 6.13045 11.4396 3.19995 15.0545 3.19995C18.6695 3.19995 21.5999 6.13045 21.5999 9.7454C21.5999 13.3603 18.6695 16.2908 15.0545 16.2908C14.6483 16.2908 14.2507 16.2538 13.8649 16.183L12.3603 17.6876C12.2069 17.841 11.9988 17.9272 11.7818 17.9272H10.9636V18.7454C10.9636 19.1973 10.5973 19.5636 10.1454 19.5636H9.32724V20.3818C9.32724 20.8336 8.96093 21.2 8.50906 21.2H4.41816C3.96629 21.2 3.59998 20.8336 3.59998 20.3818V16.2909C3.59998 16.0739 3.68618 15.8658 3.83962 15.7123L8.6169 10.935C8.54607 10.5493 8.50906 10.1517 8.50906 9.7454ZM15.0545 4.83631C12.3433 4.83631 10.1454 7.03418 10.1454 9.7454C10.1454 12.4566 12.3433 14.6545 15.0545 14.6545C17.7657 14.6545 19.9636 12.4566 19.9636 9.7454C19.9636 7.03418 17.7657 4.83631 15.0545 4.83631ZM9.19682 12.6693L5.23634 16.6298V19.5636H7.69088V18.7454C7.69088 18.2935 8.05719 17.9272 8.50906 17.9272H9.32724V17.109C9.32724 16.6572 9.69355 16.2909 10.1454 16.2909H11.4429L12.1306 15.6031C10.8634 14.9693 9.83058 13.9365 9.19682 12.6693Z'
            />
          </>
        );
      },
      Services: () => {
        return (
          <>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M6.00024 3.75C4.7576 3.75 3.75024 4.75736 3.75024 6V9C3.75024 10.2426 4.7576 11.25 6.00024 11.25H9.00024C10.2429 11.25 11.2502 10.2426 11.2502 9V6C11.2502 4.75736 10.2429 3.75 9.00024 3.75H6.00024ZM5.25024 6C5.25024 5.58579 5.58603 5.25 6.00024 5.25H9.00024C9.41446 5.25 9.75024 5.58579 9.75024 6V9C9.75024 9.41421 9.41446 9.75 9.00024 9.75H6.00024C5.58603 9.75 5.25024 9.41421 5.25024 9V6Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M6.00024 12.75C4.7576 12.75 3.75024 13.7574 3.75024 15V18C3.75024 19.2426 4.7576 20.25 6.00024 20.25H9.00024C10.2429 20.25 11.2502 19.2426 11.2502 18V15C11.2502 13.7574 10.2429 12.75 9.00024 12.75H6.00024ZM5.25024 15C5.25024 14.5858 5.58603 14.25 6.00024 14.25H9.00024C9.41446 14.25 9.75024 14.5858 9.75024 15V18C9.75024 18.4142 9.41446 18.75 9.00024 18.75H6.00024C5.58603 18.75 5.25024 18.4142 5.25024 18V15Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M12.7502 6C12.7502 4.75736 13.7576 3.75 15.0002 3.75H18.0002C19.2429 3.75 20.2502 4.75736 20.2502 6V9C20.2502 10.2426 19.2429 11.25 18.0002 11.25H15.0002C13.7576 11.25 12.7502 10.2426 12.7502 9V6ZM15.0002 5.25C14.586 5.25 14.2502 5.58579 14.2502 6V9C14.2502 9.41421 14.586 9.75 15.0002 9.75H18.0002C18.4145 9.75 18.7502 9.41421 18.7502 9V6C18.7502 5.58579 18.4145 5.25 18.0002 5.25H15.0002Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M15.0002 12.75C13.7576 12.75 12.7502 13.7574 12.7502 15V18C12.7502 19.2426 13.7576 20.25 15.0002 20.25H18.0002C19.2429 20.25 20.2502 19.2426 20.2502 18V15C20.2502 13.7574 19.2429 12.75 18.0002 12.75H15.0002ZM14.2502 15C14.2502 14.5858 14.586 14.25 15.0002 14.25H18.0002C18.4145 14.25 18.7502 14.5858 18.7502 15V18C18.7502 18.4142 18.4145 18.75 18.0002 18.75H15.0002C14.586 18.75 14.2502 18.4142 14.2502 18V15Z'
              fill='#24272A'
            />
          </>
        );
      },
      Payments: () => {
        return (
          <>
            <path
              d='M18.75 12C18.75 12.8284 18.0784 13.5 17.25 13.5C16.4216 13.5 15.75 12.8284 15.75 12C15.75 11.1716 16.4216 10.5 17.25 10.5C18.0784 10.5 18.75 11.1716 18.75 12Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.25 4.5C4.00736 4.5 3 5.50736 3 6.75V17.25C3 18.4926 4.00736 19.5 5.25 19.5H18C19.2426 19.5 20.25 18.4926 20.25 17.25V15.622C21.1239 15.3131 21.75 14.4797 21.75 13.5V10.5C21.75 9.52034 21.1239 8.68691 20.25 8.37803V6.75C20.25 5.50736 19.2426 4.5 18 4.5H5.25ZM18.75 8.25V6.75C18.75 6.33579 18.4142 6 18 6H5.25C4.83579 6 4.5 6.33579 4.5 6.75V17.25C4.5 17.6642 4.83579 18 5.25 18H18C18.4142 18 18.75 17.6642 18.75 17.25V15.75H15C13.7574 15.75 12.75 14.7426 12.75 13.5V10.5C12.75 9.25736 13.7574 8.25 15 8.25H18.75ZM15 9.75C14.5858 9.75 14.25 10.0858 14.25 10.5V13.5C14.25 13.9142 14.5858 14.25 15 14.25H19.5C19.9142 14.25 20.25 13.9142 20.25 13.5V10.5C20.25 10.0858 19.9142 9.75 19.5 9.75H15Z'
              fill='#24272A'
            />
          </>
        );
      },
      Support: () => {
        return (
          <>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M9 1.42857C4.81842 1.42857 1.42857 4.81842 1.42857 9C1.42857 13.1816 4.81842 16.5714 9 16.5714C10.2988 16.5714 11.5192 16.245 12.5858 15.6703C12.7427 15.5858 12.9255 15.5629 13.0984 15.6063L15.9908 16.332L15.5148 13.1724C15.4911 13.0148 15.5207 12.8538 15.599 12.715C16.218 11.6181 16.5714 10.3514 16.5714 9C16.5714 4.81842 13.1816 1.42857 9 1.42857ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 10.5145 17.6253 11.9434 16.9632 13.1972L17.5632 17.1793C17.5988 17.4155 17.5139 17.6539 17.337 17.8145C17.1602 17.9751 16.9148 18.0367 16.683 17.9785L13.0131 17.0578C11.8042 17.6609 10.4408 18 9 18C4.02944 18 0 13.9706 0 9Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M8.99972 13.2857C9.39421 13.2857 9.71401 12.9659 9.71401 12.5714C9.71401 12.1769 9.39421 11.8571 8.99972 11.8571C8.60523 11.8571 8.28543 12.1769 8.28543 12.5714C8.28543 12.9659 8.60523 13.2857 8.99972 13.2857Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M7.57115 7.5721L7.57113 7.57642L7.57114 7.57385L7.57115 7.5721ZM6.14258 7.5707L6.14258 7.56822L6.14261 7.56421L6.14274 7.55376L6.1435 7.52357C6.1443 7.49945 6.1458 7.46737 6.14858 7.42846C6.15412 7.35088 6.16486 7.24471 6.18578 7.11918C6.22706 6.87151 6.31095 6.53036 6.48584 6.18056C6.66214 5.82797 6.9378 5.45365 7.36467 5.16907C7.796 4.88151 8.33902 4.71429 8.99972 4.71429C9.65681 4.71429 10.1979 4.87638 10.6291 5.15801C11.0568 5.43731 11.3342 5.80648 11.512 6.15783C11.8569 6.83913 11.8569 7.51178 11.8569 7.57125C11.8569 8.67456 11.0154 9.23154 10.5304 9.5525C10.5086 9.56691 10.4876 9.58084 10.4674 9.59432C9.86678 9.99471 9.71401 10.1528 9.71401 10.4286C9.71401 10.8231 9.39421 11.1429 8.99972 11.1429C8.60523 11.1429 8.28543 10.8231 8.28543 10.4286C8.28543 9.32526 9.12687 8.76845 9.61189 8.44749C9.63366 8.43309 9.65471 8.41916 9.67493 8.40568C10.2755 8.00529 10.4283 7.84717 10.4283 7.57143L10.4283 7.5692L10.4281 7.5593C10.4278 7.54957 10.4272 7.5336 10.4257 7.51234C10.4228 7.46954 10.4168 7.40658 10.4043 7.33062C10.3787 7.17533 10.3291 6.98407 10.2374 6.80299C10.1474 6.62516 10.023 6.46841 9.84797 6.35408C9.67649 6.24208 9.41406 6.14286 8.99972 6.14286C8.58899 6.14286 8.32844 6.24349 8.15709 6.35771C7.98128 6.47492 7.85516 6.63632 7.7636 6.81944C7.67064 7.00536 7.6206 7.19992 7.59491 7.35403C7.58235 7.4294 7.57635 7.49064 7.57352 7.53024C7.57211 7.54992 7.57139 7.56483 7.57115 7.5721L7.57113 7.57642C7.56845 7.96861 7.24969 8.28571 6.85686 8.28571C6.46237 8.28571 6.14258 7.96519 6.14258 7.5707Z'
              fill='#24272A'
            />
          </>
        );
      },
      Community: () => {
        return (
          <>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M17.2502 3.75C15.1792 3.75 13.5002 5.42893 13.5002 7.5C13.5002 9.57107 15.1792 11.25 17.2502 11.25C19.3213 11.25 21.0002 9.57107 21.0002 7.5C21.0002 5.42893 19.3213 3.75 17.2502 3.75ZM15.0002 7.5C15.0002 6.25736 16.0076 5.25 17.2502 5.25C18.4929 5.25 19.5002 6.25736 19.5002 7.5C19.5002 8.74264 18.4929 9.75 17.2502 9.75C16.0076 9.75 15.0002 8.74264 15.0002 7.5Z'
              fill='#24272A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M6.75024 8.25C4.67918 8.25 3.00024 9.92893 3.00024 12C3.00024 14.0711 4.67918 15.75 6.75024 15.75C8.82131 15.75 10.5002 14.0711 10.5002 12C10.5002 9.92893 8.82131 8.25 6.75024 8.25ZM4.50024 12C4.50024 10.7574 5.5076 9.75 6.75024 9.75C7.99289 9.75 9.00024 10.7574 9.00024 12C9.00024 13.2426 7.99289 14.25 6.75024 14.25C5.5076 14.25 4.50024 13.2426 4.50024 12Z'
              fill='#24272A'
            />
            <path
              d='M6.80306 18C5.10891 18 3.67416 18.7973 2.92677 19.9165C2.69673 20.261 2.231 20.3537 1.88654 20.1237C1.54207 19.8937 1.44931 19.4279 1.67935 19.0835C2.72843 17.5126 4.65144 16.5 6.80306 16.5C8.95468 16.5 10.8777 17.5126 11.9268 19.0835C12.1568 19.4279 12.064 19.8937 11.7196 20.1237C11.3751 20.3537 10.9094 20.261 10.6793 19.9165C9.93195 18.7973 8.4972 18 6.80306 18Z'
              fill='#24272A'
            />
            <path
              d='M13.4268 15.4165C14.1742 14.2973 15.6089 13.5 17.3031 13.5C18.9972 13.5 20.432 14.2973 21.1793 15.4165C21.4094 15.761 21.8751 15.8537 22.2196 15.6237C22.564 15.3937 22.6568 14.9279 22.4268 14.5835C21.3777 13.0125 19.4547 12 17.3031 12C15.1514 12 13.2284 13.0125 12.1793 14.5835C11.9493 14.9279 12.0421 15.3937 12.3865 15.6237C12.731 15.8537 13.1967 15.761 13.4268 15.4165Z'
              fill='#24272A'
            />
          </>
        );
      },
      Profile: () => {
        return (
          <>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.0006 4.3501C7.77561 4.3501 4.35059 7.77512 4.35059 12.0001C4.35059 14.0103 5.12595 15.8395 6.39393 17.2048C6.43273 17.1391 6.47945 17.0649 6.53493 16.9841C6.72854 16.7021 7.02977 16.3385 7.47369 15.9792C8.37289 15.2515 9.80847 14.5811 12.0011 14.5811C14.1937 14.5811 15.629 15.2515 16.5279 15.9793C16.9716 16.3387 17.2726 16.7023 17.4661 16.9843C17.5215 17.0651 17.5682 17.1394 17.607 17.205C18.8751 15.8397 19.6506 14.0105 19.6506 12.0001C19.6506 7.77512 16.2256 4.3501 12.0006 4.3501ZM16.4387 18.2318C15.1871 19.1248 13.6552 19.6501 12.0006 19.6501C10.3457 19.6501 8.8136 19.1246 7.56194 18.2315L7.62208 18.0855L7.62328 18.083C7.62662 18.0758 7.63392 18.0606 7.64561 18.0386C7.66903 17.9943 7.70984 17.9229 7.77153 17.8331C7.89488 17.6534 8.10074 17.4014 8.41736 17.1452C9.03925 16.6419 10.1385 16.0811 12.0011 16.0811C13.8636 16.0811 14.9625 16.6419 15.5839 17.1451C15.9003 17.4013 16.1059 17.6532 16.2291 17.8328C16.2908 17.9226 16.3315 17.994 16.3549 18.0382C16.3666 18.0603 16.3738 18.0755 16.3772 18.0826L16.3784 18.0852L16.4387 18.2318ZM2.85059 12.0001C2.85059 6.94669 6.94718 2.8501 12.0006 2.8501C17.054 2.8501 21.1506 6.94669 21.1506 12.0001C21.1506 17.0535 17.054 21.1501 12.0006 21.1501C6.94718 21.1501 2.85059 17.0535 2.85059 12.0001ZM12.0003 7.97079C10.7348 7.97079 9.70892 8.99667 9.70892 10.2622C9.70892 11.5277 10.7348 12.5535 12.0003 12.5535C13.2658 12.5535 14.2917 11.5277 14.2917 10.2622C14.2917 8.99667 13.2658 7.97079 12.0003 7.97079ZM8.20892 10.2622C8.20892 8.16824 9.90638 6.47079 12.0003 6.47079C14.0942 6.47079 15.7917 8.16824 15.7917 10.2622C15.7917 12.3561 14.0942 14.0535 12.0003 14.0535C9.90638 14.0535 8.20892 12.3561 8.20892 10.2622Z'
              fill='#24272A'
            />
          </>
        );
      },
      default: () => {
        // Обработка случая, если имя пункта меню не найдено
        return null;
      }
    };

    return (content[name] || content.default)();
  };

  return (
    <StyledSvg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      width={width}
      height={height}
    >
      {defineMenuItem(name)}
    </StyledSvg>
  );
};

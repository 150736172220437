import React from 'react';
import cn from 'classnames';
export const FloorAreaTemplate = ({ drawn, tabIndex, filtersActive, isPublicSpace }) => {
  return (
    <path
      d={drawn}
      tabIndex={tabIndex}
      className={cn('workplace-template-path', { 'filters-active': filtersActive && isPublicSpace })}
    ></path>
  );
};

/* eslint-disable no-shadow */
import { attr, Model, fk } from 'redux-orm';
import * as actions from '../actions';

class Guest extends Model {
  static reducer(action, guest) {
    switch (action.type) {
      case actions.requestGuests.success.toString():
        action.payload.map(elem => guest.upsert(elem));
        break;
      case actions.requestGuestCreate.success.toString():
        guest.upsert(action.payload);
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `Guest: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
Guest.modelName = 'Guest';

// Declare your related fields.
Guest.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  // status_id:
  service_id: fk('Service', 'guests_to_service')
};

export default Guest;

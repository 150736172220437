import React, { useCallback } from 'react';
import { StyledChip } from '../../shared/ui/components/Chip';
import { TimeSlotsTitle } from './MeetingRoomInfo';

const MeetingRoomChip = ({
  marker,
  type,
  isMouseDown,
  changeMarker,
  resetSelectedAll,
  setSelectedReservation,
  changeIsMouseDown,
  isCorrectedSelected,
  setOverflowStatus,
  markerList
}) => {
  const isFree = useCallback(marker => marker.status === null || marker.status === 'current', []);

  const handleMouseDown = useCallback(
    (marker, evt) => {
      setOverflowStatus();
      changeIsMouseDown(true);
      resetSelectedAll(true);
      if (isFree(marker)) {
        changeMarker(marker, { status: 'current' });
      }
    },
    [setOverflowStatus, changeIsMouseDown, resetSelectedAll, isFree, changeMarker]
  );

  const handleMouseUp = useCallback(
    (marker, evt) => {
      setOverflowStatus();
      changeIsMouseDown(false);
      if (!isCorrectedSelected()) {
        resetSelectedAll();
        setSelectedReservation();
        return;
      }
      setSelectedReservation(marker.service_id);
    },
    [setOverflowStatus, changeIsMouseDown, resetSelectedAll, setSelectedReservation, isCorrectedSelected]
  );
  //TODO - оптимизировать - неэффективный алгоритм
  const displayValidTimeline = interval => {
    let validTime = 0;
    let validTimeTo = 1;
    if (interval.includes('Переговорные_')) {
      validTime = Number(interval.replace('Переговорные_', '')) + 9;
    } else if (interval.includes('Ивент_')) {
      validTime = Number(interval.replace('Ивент_', '')) + 9;
    } else if (interval.includes('Конференц-зал_')) {
      validTime = Number(interval.replace('Конференц-зал_', '')) + 9;
    }
    validTimeTo = validTime + 1;
    return 'c ' + validTime + ' до ' + validTimeTo;
  };

  return (
    <>
      {marker.status !== 'busy' && marker.status !== 'paid' && marker.status !== 'book' && (
        <StyledChip
          id={marker.id}
          label={displayValidTimeline(marker.time_from)}
          isChecked={marker.status === 'current'}
          onMouseDown={evt => handleMouseDown(marker, evt)}
          onMouseUp={evt => handleMouseUp(marker, evt)}
          onTouchStart={evt => handleMouseDown(marker, evt)}
          onTouchEnd={evt => handleMouseUp(marker, evt)}
        />
      )}
    </>
  );
};

export default MeetingRoomChip;

import * as actions from '../../store/actions';
import api from './Request';
import { roles } from '../../roles';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getCurrencyTypes = () => {
  return api.get(
    '/currency',
    { params: { _disablePagination: 1 } },
    getRequestStatusActions(actions.requestCurrencyTypes)
  );
};

export const getPaymentSystem = () => {
  return api.get('/payment/system', {}, [
    actions.requestPaymentSystemPending,
    actions.requestPaymentSystemSuccess,
    actions.requestPaymentSystemFailure
  ]);
};

export const getServiceFileType = () => {
  return api.get('/service/file/type', {}, [
    actions.requestServiceFileTypePending,
    actions.requestServiceFileTypeSuccess,
    actions.requestServiceFileTypeFailure
  ]);
};

export const getServiceType = () => {
  return api.get('/service/type', {}, [
    actions.requestServiceTypePending,
    actions.requestServiceTypeSuccess,
    actions.requestServiceTypeFailure
  ]);
};

export const getOffice = () => {
  return api.get('/office', {}, [
    actions.requestOfficePending,
    actions.requestOfficeSuccess,
    actions.requestOfficeFailure
  ]);
};

export const getAccount = () => {
  const permissions = { denyTo: [roles.guest] };
  return api.get('/account/current', { params: { _disableCache: 1 }, permissions }, [
    actions.requestAccountPending,
    actions.requestAccountSuccess,
    actions.requestAccountFailure
  ]);
};

export const getDay = () => {
  return api.get('/custom-day', {}, [actions.requestDayPending, actions.requestDaySuccess, actions.requestDayFailure]);
};

import { useState, useEffect, useCallback } from 'react';
import { isUndefined } from 'lodash';
import useScript from './useScript';

// copy with rewriting from https://github.com/hupe1980/react-recaptcha-hook

const isBrowser = !isUndefined(window) && !isUndefined(window.document);

const injectStyle = rule => {
  const styleEl = document.createElement('style');
  document.head.appendChild(styleEl);

  if (styleEl.sheet) {
    styleEl.sheet.insertRule(rule, styleEl.sheet.cssRules.length);
  }
};

const addQueryParamsToUrlFactory = (token, action) => {
  return url => {
    const tokenParam = `recaptcha_token=${encodeURIComponent(token)}`;
    const actionParam = `recaptcha_action=${encodeURIComponent(action)}`;

    const separator = url.indexOf('?') !== -1 ? '&' : '?';

    return `${url}${separator}${tokenParam}&${actionParam}`;
  };
};

const useRecaptcha = ({ siteKey, hideDefaultBadge = false }) => {
  const [recaptcha, setRecaptcha] = useState();

  useEffect(() => {
    if (isBrowser && hideDefaultBadge) {
      injectStyle('.grecaptcha-badge { visibility: hidden; }');
    }
  }, [hideDefaultBadge]);

  const [isReady] = useScript({
    src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`
  });

  useEffect(() => {
    if (isReady && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        setRecaptcha(window.grecaptcha);
      });
    }
  }, [isReady]);

  const execute = useCallback(
    (action = '') => {
      return new Promise((resolve, reject) => {
        if (!recaptcha) {
          reject(Error('Grecaptcha is not defined!'));
        }

        resolve(recaptcha.execute(siteKey, { action }));
      });
    },
    [recaptcha, siteKey]
  );

  return [recaptcha, execute, addQueryParamsToUrlFactory];
};

export default useRecaptcha;

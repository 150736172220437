import React from 'react';
import styled from 'styled-components';
import { colors, fontFamilyBase, screenRessolutions } from '../../shared/ui/constants/styleConstants';
import DashboardBookingListItem from './DashboardBookingListItem';

const BookingHistoryBox = styled.div`
  margin: 40px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin-top: 64px;
  }
`;

const BookingListTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-family: ${fontFamilyBase};
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${colors.workkiDarkMain};
  text-align: start;
  width: 100%;
`;

const BookingList = styled.ul`
  margin: 14px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;

  &:after {
    content: '';
    height: 60px;
  }

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    &:after {
      content: '';
      height: 0;
    }
  }
`;

const DashboardBookingList = ({ bookingPlacesList, bookingMeetingRoomsList, changeSubscription }) => {
  const getBookingPlaces = () => {
    // получение возможности подписки на автопродление и всего прочего reservation_place
    return bookingPlacesList.map((item, index) => {
      return (
        <DashboardBookingListItem
          key={index}
          item={item}
          isMeetingRoom={false}
          changeAutoProlongation={changeSubscription}
        />
      );
    });
  };

  const getBookingMeetingRooms = () => {
    return bookingMeetingRoomsList.map((item, index) => {
      return <DashboardBookingListItem key={index} item={item} isMeetingRoom={true} />;
    });
  };

  return (
    <BookingHistoryBox>
      <BookingListTitle>История бронирований</BookingListTitle>
      <BookingList>
        {bookingPlacesList.length > 0 && getBookingPlaces()}
        {bookingMeetingRoomsList.length > 0 && getBookingMeetingRooms()}
      </BookingList>
    </BookingHistoryBox>
  );
};

export default DashboardBookingList;

export const FORM_ID = 'create-comment-form';

export const FIELD_NAMES = {
  TICKET_ID: 'ticket_id',
  DESCRIPTION: 'text',
  FILE: 'file'
};

export const TEXTAREA_MAX_LENGTH = 1000;

export const LABELS = {
  [FIELD_NAMES.DESCRIPTION]: 'Комментарий...',
  [FIELD_NAMES.FILE]: 'Файл'
};

export const FILE_CONSTRAINS = {
  SIZE: 5000000,
  SIZE_TEXT: '5MB',
  MIME_TYPES: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
  EXTENSIONS: ['.jpg', '.jpeg', '.jpe', '.png', '.gif', '.bmp']
};

const isValidFile = file => file.size <= FILE_CONSTRAINS.SIZE && FILE_CONSTRAINS.MIME_TYPES.includes(file.type);
export const VALIDATION_SCHEMA = {
  [FIELD_NAMES.FILE]: [
    {
      check: v => (!v ? false : !isValidFile(v)),
      message: `Размер файла не должен превышать ${
        FILE_CONSTRAINS.SIZE_TEXT
      }, файл долен иметь одно из расширений: ${FILE_CONSTRAINS.EXTENSIONS.join(', ')}`
    }
  ],
  [FIELD_NAMES.DESCRIPTION]: [
    {
      check: v => v === '',
      message: 'Добавьте описание проблемы'
    }
  ]
};

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as types from '../services/workki/TypesActions';
import { getSchedule, getServices } from '../services/workki/ServicesActions';
import { getCustomerCurrent } from '../services/workki/CustomerActions';
import { getDepositStatuses } from '../entities/Payments/api/DepositsActions';
import { getTicketCategories } from '../services/workki/TicketsActions';
import orm from '../store/models/orm';
import * as analytics from '../analytics';
import requestStatusSelector from '../store/selectors/requestStatusSelector';
import * as actions from '../store/actions';

// WithPreloadDataContainer
function WithPreloadDataContainer(WrappedComponent) {
  // ...and returns another component...
  class Container extends Component {
    componentDidMount() {
      const {
        dispatch,
        ServiceType,
        ServiceFileType,
        PaymentSystem,
        Customer,
        DepositStatus,
        CurrencyType,
        Office,
        Service,
        ServiceSchedule,
        TicketCategories,
        Day
      } = this.props;

      analytics.pageView(window.location);

      if (ServiceType.length === 0) {
        dispatch(types.getServiceType());
      }
      if (ServiceFileType.length === 0) {
        dispatch(types.getServiceFileType());
      }
      if (PaymentSystem.length === 0) {
        dispatch(types.getPaymentSystem());
      }
      if (CurrencyType.length === 0) {
        dispatch(types.getCurrencyTypes());
      }
      if (Office.length === 0) {
        dispatch(types.getOffice());
      }
      if (DepositStatus.length === 0) {
        dispatch(getDepositStatuses());
      }
      if (Service.length === 0) {
        dispatch(getServices());
      }
      if (ServiceSchedule.length === 0) {
        dispatch(getSchedule());
      }
      if (TicketCategories.length === 0) {
        dispatch(getTicketCategories());
      }
      if (Day.length === 0) {
        dispatch(types.getDay());
      }
      if (Customer.length === 0) {
        dispatch(getCustomerCurrent()).then(() => {
          dispatch(types.getAccount());
        });
      }
    }

    render() {
      const { props } = this;
      return <WrappedComponent {...props} />;
    }
  }
  Container.displayName = `WithPreloadDataContainer(${WrappedComponent.name})`;
  return Container;
}

const mapStateToProps = state => {
  const session = orm.session(state.entities);
  const getCurrencyTypesStatus = requestStatusSelector(actions.requestCurrencyTypes)(state);
  const getDepositStatusesStatus = requestStatusSelector(actions.requestDepositStatuses)(state);

  const isReferencesPending =
    getCurrencyTypesStatus.isPending ||
    getCurrencyTypesStatus.isInit ||
    getDepositStatusesStatus.isPending ||
    getDepositStatusesStatus.isInit;

  const isLoading = ({ ui }) => {
    return (
      ui.customerCurrent.isFetching ||
      ui.customerFiles.isFetching ||
      ui.paymentSystem.isFetching ||
      ui.serviceFileType.isFetching ||
      ui.serviceType.isFetching ||
      ui.office.isFetching ||
      ui.services.isFetching ||
      // ui.day.isFetching ||
      ui.customerCurrent.initialData ||
      ui.paymentSystem.initialData ||
      ui.serviceType.initialData ||
      ui.office.initialData ||
      // ui.day.initialData ||
      ui.services.initialData
    );
  };
  return {
    auth: state.auth,
    isLoading: isLoading(state) || isReferencesPending,

    Account: session.Account.all().toRefArray(),
    CurrencyType: session.CurrencyType.all().toRefArray(),
    Customer: session.Customer.all().toRefArray(),
    ServiceFileType: session.ServiceFileType.all().toRefArray(),
    DepositStatus: session.DepositStatus.all().toRefArray(),
    PaymentSystem: session.PaymentSystem.all().toRefArray(),
    ServiceType: session.ServiceType.all().toRefArray(),
    Office: session.Office.all().toRefArray(),
    Day: session.Day.all().toRefArray(),
    Service: session.Service.all().toRefArray(),
    ServiceSchedule: session.ServiceSchedule.all().toRefArray(),
    Files: session.CustomerFile.all().toRefArray(),
    TicketCategories: session.TicketCategory.all().toRefArray()
  };
};

const test = state => {
  const session = orm.session(state.entities);
  console.log(session);
};

const WithPreloadData = compose(
  connect(mapStateToProps),
  // connect(test),
  WithPreloadDataContainer
);

export default WithPreloadData;

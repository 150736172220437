import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';
import { confirmEmail, loginUser } from '../../../entities/Authentification/api/AuthActions';
import { useDispatch } from 'react-redux';

const RegistrationCompleteStep = ({
  entranceFormClass,
  handleAuthSubmit,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  handleConfirmEmail,
  handleClickShowPassword,
  errors,
  formData,
  filled,
  isFocused,
  showPassword,
  isPending,
  disableButton,
  handleInstantAuth,
  setLoadingStatus,
  setErrors
}) => {
  //TODO - не валидировать пароль
  // const dispatch = useDispatch();
  // const handleCompleteRegistration = evt => {
  //   return dispatch(confirmEmail(params.token));
  // };
  return (
    <div>
      <div className='authorization__caption'>
        <p>Завершение регистрации</p>
        <p>Вставьте логин и пароль из письма</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={event => {
          handleInstantAuth(event);
        }}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.email.confirm}
          type='email'
          name='email'
          id='outlined-helperText'
          label='E-mail'
          variant='filled'
          value={formData.email.confirm}
          onFocus={() => handleFocus('email', 'confirm')}
          onBlur={() => handleBlur('email', 'confirm')}
          onChange={handleInputChange}
          disabled={isPending}
          helperText={errors.email.confirm ? errors.email.confirm : ''}
          endAdornmentProps={{
            filled: filled.email.confirm,
            isFocused: isFocused.email.confirm,
            handleEraseValue: name => handleEraseValue(name, 'confirm')
          }}
          autoFocus={true}
        />
        <CustomTextField
          error={errors.password}
          id='outlined-helperText'
          label='Пароль'
          name='password'
          helperText={errors.password ? errors.password : ''}
          variant='filled'
          type={showPassword.password ? 'text' : 'password'}
          value={formData.password}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('password')}
          onBlur={() => handleBlur('password')}
          endAdornmentProps={{
            filled: filled.password,
            isFocused: isFocused.password,
            handleEraseValue: handleEraseValue,
            showPassword: showPassword.password,
            handleClickShowPassword: () => handleClickShowPassword('password')
          }}
          autoFocus={false}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Войти
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
          <p className='authorization__footer__policy'>
            Выполняя вход, вы соглашаетесь{' '}
            <a href='https://workki.co/docs/Public-offer.pdf' target='_blank'>
              с договором публичной оферты
            </a>
            &#160;и&#160;
            <a href=' https://workki.co/upload/iblock/f5f/Personal_data.pdf' target='_blank'>
              правилами обработки данных
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default RegistrationCompleteStep;

import React from 'react';
import { styled } from '@mui/material/styles';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { InputAdornment, TextField } from '@mui/material';
import { colors } from '../constants/styleConstants';
import { DateIcon } from '../icons/core/DateIcon';
import { GlobalStyles } from '@mui/material';
import dayjs from 'dayjs';
const GlobalStyleOverrides = () => (
  <GlobalStyles
    styles={{
      '.MuiButtonBase-root.MuiPickersDay-root': {
        '&:hover': {
          backgroundColor: `${colors.workkiRedLight} !important` // Цвет при наведении
        },
        '&.Mui-selected': {
          'backgroundColor': `${colors.workkiRedLine} !important`,
          'font-weight': `500 !important`, // Цвет фона для выбранного дня
          'color': colors.workkiDarkMain, // Цвет текста выбранного дня,
          '&:hover': {
            backgroundColor: `${colors.workkiRedLine} !important` // Цвет при наведении
          }
        }
      }
    }}
  />
);

const StyledDatePicker = styled(DatePicker)(({ withLabel, type }) => ({
  '& .MuiInputBase-root': {
    'backgroundColor': colors.workkiLightLight1,
    '&.Mui-focused': {
      borderRadius: 8,
      backgroundColor: `${colors.workkiLightLight05} !important`
    },
    '&:hover': {
      backgroundColor: `${colors.workkiLightLight05} !important`
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
      'borderRadius': 8,
      'paddingRight': 0,
      'paddingLeft': withLabel && type !== 'parking' ? '12px' : '4px',
      'cursor': 'pointer',
      '&::before': {
        border: 'transparent 1px solid',
        borderRadius: 8
      },
      '&::after': {
        border: '1px solid transparent',
        borderRadius: 8
      }
    }
  },
  '& .MuiIconButton-root': {
    padding: '0',
    margin: '12px 20px 0 0'
  },
  '& .MuiInputLabel-root': {
    'paddingLeft': 0,
    '&.Mui-focused': {
      color: `${colors.workkiDarkMiddle} !important`
    },
    '&.Mui-error': {
      color: `${colors.workkiDarkMiddle} !important`
    }
  }
}));

const displayAdditionalLabel = date => {
  const newDate = dayjs(date);
  const dateDifference = newDate.diff(dayjs(), 'day', true);

  if (dateDifference <= 0) {
    return 'Сегодня,';
  } else if (0 < dateDifference && dateDifference < 1) {
    return 'Завтра,';
  } else if (1 < dateDifference && dateDifference < 2) {
    return 'Послезавтра,';
  } else {
    return '';
  }
};

const CustomDatePicker = ({ pickedDate, setPickedDate, customLabel, type, isDayDisabled }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickCalendarIcon = () => {
    setOpen(prevState => !prevState);
  };

  return (
    <>
      <GlobalStyleOverrides />
      <StyledDatePicker
        label={customLabel ? customLabel : 'День начала аренды'}
        value={pickedDate}
        type={type}
        shouldDisableDate={isDayDisabled}
        isDayDisabled={isDayDisabled}
        disablePast={true}
        withLabel={!(displayAdditionalLabel(pickedDate) === '')}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={newValue => {
          setPickedDate(newValue);
          setOpen(false);
        }}
        slotProps={{
          popper: { placement: 'bottom-end', marginTop: '4px' },
          day: { selectedDay: pickedDate }
        }}
        slots={{
          openPickerIcon: props => <DateIcon spacing={'16px'} />,
          textField: props => (
            <TextField
              {...props}
              variant='filled'
              InputProps={{
                ...props.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    {type !== 'parking' && (
                      <span
                        style={{
                          color: 'black',
                          display: displayAdditionalLabel(pickedDate) === '' ? 'none' : 'flex',
                          marginRight: displayAdditionalLabel(pickedDate) === '' ? '0' : '-4px'
                        }}
                      >
                        {displayAdditionalLabel(pickedDate)}
                      </span>
                    )}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <DateIcon spacing={'16px'} />
                  </InputAdornment>
                ),
                onClick: handleClickCalendarIcon
              }}
            />
          )
        }}
      />
    </>
  );
};

export default CustomDatePicker;

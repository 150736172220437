import 'es6-promise/auto';

import React from 'react';
import { render as ReactDomRender } from 'react-dom';

import './scss/main.scss';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

import AppRoot from './app/AppRoot';

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: process.env.GTM_ID || 'GTM-KLSFGFW'
};

TagManager.initialize(tagManagerArgs);
ClassNameGenerator.configure(componentName => componentName);

ReactDomRender(<AppRoot />, document.getElementById('root'));

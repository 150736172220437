import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const isAvatarRedactorOpened = createReducer(initialState.isAvatarRedactorOpened, {
  [actions.openAvatarEditor]: () => true,
  [actions.closeAvatarEditor]: () => false
});

export default isAvatarRedactorOpened;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Moment } from '../../../utils/moment';
import 'moment/locale/ru';
import saveAs from 'file-saver';
import { getBinaryFileDeposit, getBinaryFileDepositReceipt } from '../../../entities/Payments/api/DepositsActions';
import formatAmount from '../../../utils/formatAmount';

class PaymentList extends Component {
  saveFile = deposit => evt => {
    const { dispatchGetBinaryFileDeposit, dispatchGetBill } = this.props;
    evt.preventDefault();
    if (this.props.showCheck) {
      dispatchGetBinaryFileDeposit(deposit.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, 'payment.pdf');
        })
        .catch(() => {});
    } else {
      dispatchGetBill(deposit.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, 'payment.pdf');
        })
        .catch(() => {});
    }
  };

  getButton = deposit =>
    deposit.payment_system_id === 24 ? (
      <a href={deposit.payment_url} className='scrollable-table__link-payment'>
        Оплатить
      </a>
    ) : (
      <span className='scrollable-table__link-payment'>Создан</span>
    );

  renderStatusTitle = loadingStatus => {
    const content = {
      deposit_status_done: () => {
        return 'Проведен';
      },
      deposit_status_new: () => {
        return 'Новый';
      },
      deposit_status_cancelled: () => {
        return 'Отменен';
      },
      deposit_status_in_progress: () => {
        return 'В обработке';
      },
      deposit_status_user_cancelled: () => {
        return 'Отменен клиентом';
      }
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };

  render() {
    const { payments } = this.props;
    return payments.map(deposit => {
      const paidClassName = `scrollable-table__cell scrollable-table__cell--${
        deposit.status_id === 4 ? 'paid' : 'not-paid'
      }`;
      return (
        <div key={deposit.id} className='scrollable-table__row'>
          <div className='scrollable-table__cell'>#{deposit.id}</div>
          <div className='scrollable-table__cell scrollable-table__cell--date'>
            <Moment locale='ru' parse='YYYY-MM-DD HH:mm:ss' format='DD.MM.YYYY HH:mm'>
              {deposit.created_at}
            </Moment>
          </div>
          <div className={paidClassName}>
            {formatAmount(deposit.amount)} <span>&#8381;</span>
          </div>
          <div className='scrollable-table__cell'> {deposit.payment ? deposit.payment.t_name : ''}</div>
          <div className={paidClassName}>
            <p className='scrollable-table__loading-status'>
              {this.renderStatusTitle(deposit.deposit_status ? deposit.deposit_status.name : '')}
            </p>
          </div>
          <div className='scrollable-table__cell'> {deposit.is_security_payment ? 'да' : 'нет'}</div>
          <div className='scrollable-table__cell'>
            {deposit.status_id !== 4 ? (
              this.getButton(deposit)
            ) : (
              <p className='scrollable-table__link-payment'>Оплачен</p>
            )}
          </div>
          <div className='scrollable-table__cell'>
            {(deposit.status_id == 4 && this.props.showCheck) || !this.props.showCheck ? (
              <p className='scrollable-table__link-payment' onClick={this.saveFile(deposit)}>
                Скачать
              </p>
            ) : (
              <p className='scrollable-table__link-payment'>-</p>
            )}
          </div>
        </div>
      );
    });
  }
}

PaymentList.propTypes = {
  payments: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  dispatchGetBinaryFileDeposit: id => dispatch(getBinaryFileDepositReceipt(id)),
  dispatchGetBill: id => dispatch(getBinaryFileDeposit(id))
});

export default connect(
  () => ({}),
  mapDispatchToProps
)(PaymentList);

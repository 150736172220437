// import { pick, isInteger, pickBy, isNil, keys } from 'lodash';
import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';
import { ALL_LOCATIONS_ID } from '../../components/Community/Community';

export const getPublicProfileList = (locationId = null) => {
  // TODO remove _disablePagination
  if (locationId === ALL_LOCATIONS_ID) {
    locationId = null;
  }
  const query = { params: { _disableCache: 1, _disablePagination: 1, locationId: locationId } };
  return api.get('public/profile', query, getRequestStatusActions(actions.requestPublicProfileList));
};

import React from 'react';
import styled from 'styled-components';

const PhotosBox = styled.div`
  margin: 0;
  padding: 12px 0 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
`;

const SvgBox = styled.div`
  margin: 0;
  paddiing: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const SvgTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #24272a;
`;

const StyledSvg = styled.svg`
  margin: 0;
  padding: 0;
  border-radius: 65px;
  fill-color: ${props => props.svgBackGround};
  & > path {
    fill: ${props => props.fillColor || '#383C40'};
    :hover {
      ${props => props.hoverColor || '#383C40'};
    }
  }
  cursor: pointer;
`;

const UserPhotoBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border-radius: 70px;
  overflow: hidden;
  background-color: #f4f4f4;
`;

const EmtpyPhotoBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  & > p {
    margin: 0;
    padding: 0;
    font-family: Commissioner, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #8f9295;
  }
`;

const ProfilePhoto = ({
  onChange,
  onPhotoClick,
  onRemove,
  svgBackGround = '#F4F4F4',
  onEmptyPhotoClick,
  children,
  ...props
}) => {
  return children ? (
    <PhotosBox {...props}>
      <SvgBox onClick={onChange}>
        <StyledSvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
          <rect width='40' height='40' rx='20' fill='#F4F4F4' />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M21.875 15.125C20.4943 15.125 19.375 16.2443 19.375 17.625C19.375 19.0057 20.4943 20.125 21.875 20.125C23.2557 20.125 24.375 19.0057 24.375 17.625C24.375 16.2443 23.2557 15.125 21.875 15.125ZM20.625 17.625C20.625 16.9346 21.1846 16.375 21.875 16.375C22.5654 16.375 23.125 16.9346 23.125 17.625C23.125 18.3154 22.5654 18.875 21.875 18.875C21.1846 18.875 20.625 18.3154 20.625 17.625Z'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M13.125 14.5C13.125 13.4645 13.9645 12.625 15 12.625H25C26.0355 12.625 26.875 13.4645 26.875 14.5V24.5C26.875 25.5355 26.0355 26.375 25 26.375H15C13.9645 26.375 13.125 25.5355 13.125 24.5V14.5ZM14.375 24.5V22.2588L16.4331 20.2008C16.6771 19.9567 17.0729 19.9567 17.3169 20.2008L22.2412 25.125H15C14.6548 25.125 14.375 24.8452 14.375 24.5ZM25 25.125H24.0089L18.2008 19.3169C17.4686 18.5847 16.2814 18.5847 15.5492 19.3169L14.375 20.4911V14.5C14.375 14.1548 14.6548 13.875 15 13.875H25C25.3452 13.875 25.625 14.1548 25.625 14.5V24.5C25.625 24.8452 25.3452 25.125 25 25.125Z'
          />
        </StyledSvg>
        <SvgTitle>Изменить</SvgTitle>
      </SvgBox>
      <UserPhotoBox onClick={onPhotoClick}>{children}</UserPhotoBox>
      <SvgBox onClick={onRemove}>
        <StyledSvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
          <rect width='40' height='40' rx='20' fill='#F4F4F4' />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M16.875 14.5V15.75H13.75C13.4048 15.75 13.125 16.0298 13.125 16.375C13.125 16.7202 13.4048 17 13.75 17H14.4529L15.4859 24.7479C15.6101 25.6794 16.4047 26.3751 17.3445 26.3751H22.6557C23.5954 26.3751 24.39 25.6794 24.5142 24.7479L25.5473 17H26.25C26.5952 17 26.875 16.7202 26.875 16.375C26.875 16.0298 26.5952 15.75 26.25 15.75H23.125V14.5C23.125 13.4645 22.2855 12.625 21.25 12.625H18.75C17.7145 12.625 16.875 13.4645 16.875 14.5ZM18.75 13.875C18.4048 13.875 18.125 14.1548 18.125 14.5V15.75H21.875V14.5C21.875 14.1548 21.5952 13.875 21.25 13.875H18.75ZM24.2862 17H15.7139L16.725 24.5827C16.7664 24.8932 17.0312 25.1251 17.3445 25.1251H22.6557C22.9689 25.1251 23.2338 24.8932 23.2752 24.5827L24.2862 17Z'
            fill='#383C40'
          />
        </StyledSvg>
        <SvgTitle>Удалить</SvgTitle>
      </SvgBox>
    </PhotosBox>
  ) : (
    <EmtpyPhotoBox onClick={onEmptyPhotoClick}>
      <StyledSvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'>
        <rect width='80' height='80' rx='40' fill='#F4F4F4' />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M43.375 32.125C40.8897 32.125 38.875 34.1397 38.875 36.625C38.875 39.1103 40.8897 41.125 43.375 41.125C45.8603 41.125 47.875 39.1103 47.875 36.625C47.875 34.1397 45.8603 32.125 43.375 32.125ZM41.125 36.625C41.125 35.3824 42.1324 34.375 43.375 34.375C44.6176 34.375 45.625 35.3824 45.625 36.625C45.625 37.8676 44.6176 38.875 43.375 38.875C42.1324 38.875 41.125 37.8676 41.125 36.625Z'
          fill='#8F9295'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M27.625 31C27.625 29.136 29.136 27.625 31 27.625H49C50.864 27.625 52.375 29.136 52.375 31V49C52.375 50.864 50.864 52.375 49 52.375H31C29.136 52.375 27.625 50.864 27.625 49V31ZM29.875 49V44.9659L33.5795 41.2614C34.0188 40.8221 34.7312 40.8221 35.1705 41.2614L44.0341 50.125H31C30.3787 50.125 29.875 49.6213 29.875 49ZM49 50.125H47.2161L36.7615 39.6704C35.4435 38.3524 33.3065 38.3524 31.9885 39.6704L29.875 41.7839V31C29.875 30.3787 30.3787 29.875 31 29.875H49C49.6213 29.875 50.125 30.3787 50.125 31V49C50.125 49.6213 49.6213 50.125 49 50.125Z'
          fill='#8F9295'
        />
      </StyledSvg>
      <p>Добавить фото</p>
    </EmtpyPhotoBox>
  );
};

export default ProfilePhoto;

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import cn from 'classnames';
import { isEmpty, keys, isNil } from 'lodash';
import Loader from 'react-loader-spinner';
import AutosizeTextarea from 'react-autosize-textarea';
import getFormikValidate from '../../../utils/getFormikValidate';
import { FORM_ID, FIELD_NAMES, TEXTAREA_MAX_LENGTH, VALIDATION_SCHEMA, LABELS } from './constants';

const formData = (id, values) => {
  const properties = {
    [FIELD_NAMES.TICKET_ID]: id,
    [FIELD_NAMES.DESCRIPTION]: values[FIELD_NAMES.DESCRIPTION]
  };

  return properties;
};

const TicketForm = ({
  ticket,
  ticketCommentCreate,
  ticketCommentCreateStatus,
  ticketCommentCreateError,
  resetError,
  isModalWindowOpen
}) => {
  const formik = useFormik({
    initialValues: {
      [FIELD_NAMES.DESCRIPTION]: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: getFormikValidate(VALIDATION_SCHEMA),
    onSubmit: values => {
      const data = formData(ticket.id, values);
      ticketCommentCreate(data);
    }
  });

  // ERRORS AND VALIDATIONS
  const [formError, setFormError] = useState(false);

  const validationError = useMemo(() => {
    if (isEmpty(formik.errors)) {
      return null;
    }

    const messageKey = keys(formik.errors).find(key => !!formik.touched[key]);

    if (isNil(messageKey)) {
      return null;
    }

    return formik.errors[messageKey];
  }, [formik.errors, formik.touched]);

  useEffect(() => {
    if (!!validationError) {
      return setFormError(validationError);
    }

    if (!!ticketCommentCreateError && ticketCommentCreateStatus.isFailure) {
      return setFormError(ticketCommentCreateError);
    }

    setFormError(false);
  }, [validationError, ticketCommentCreateError, ticketCommentCreateStatus]);

  const isLoading = useMemo(() => ticketCommentCreateStatus.isPending, [ticketCommentCreateStatus]);

  useEffect(() => {
    if (!isModalWindowOpen) {
      formik.resetForm();
    }
  }, [formik, isModalWindowOpen]);

  useEffect(() => {
    if (!!ticketCommentCreateError) {
      resetError();
    }
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderButton = () => {
    const btnClass = cn({
      'btn': true,
      'fill-payments-modal__refill-btn': true,
      'btn--solid': true,
      'fill-payments-modal__refill-btn--with-preloader': isLoading
    });
    return (
      <button className={btnClass} type='submit' disabled={isLoading}>
        {isLoading ? <Loader type='ThreeDots' color='white' height='25' width='40' /> : <span>Отправить</span>}
      </button>
    );
  };

  return (
    <form
      className={cn('fill-payments-modal', { 'has-error': !!formError })}
      id={FORM_ID}
      onSubmit={formik.handleSubmit}
      autoComplete='off'
      noValidate
    >
      <div className='fill-payments-modal__body'>
        <div className='fill-payments-modal__row'>
          <AutosizeTextarea
            rows={3}
            maxRows={6}
            className={cn('textarea textarea--with-autosize', {
              'has-error': formik.errors[FIELD_NAMES.DESCRIPTION] && formik.touched[FIELD_NAMES.DESCRIPTION]
            })}
            type='text'
            name={FIELD_NAMES.DESCRIPTION}
            placeholder={LABELS[FIELD_NAMES.DESCRIPTION]}
            maxLength={TEXTAREA_MAX_LENGTH}
            value={formik.values[FIELD_NAMES.DESCRIPTION]}
            onChange={formik.handleChange}
            disabled={isLoading}
            onBlur={e => {
              e.preventDefault();
              formik.setFieldError(FIELD_NAMES.DESCRIPTION);
            }}
          />
        </div>
        <div className='fill-payments-modal__row'>{renderButton()}</div>
      </div>
      {formError && (
        <div className='fill-payments-modal__notification'>
          <div className='notification notification--error notification--fill-payment-modal'>{formError}</div>
        </div>
      )}
    </form>
  );
};

TicketForm.propTypes = {
  ticket: PropTypes.PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  ticketCommentCreate: PropTypes.func.isRequired,
  ticketCommentCreateStatus: PropTypes.object.isRequired,
  ticketCommentCreateError: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
  isModalWindowOpen: PropTypes.bool.isRequired
};

export default TicketForm;

import React, { useState } from 'react';
import BookingItemActionsDialog from '../../widgets/DashboardWidgets/BookingItemActionsDialog';
import PriceInfoDialog from '../../widgets/DashboardWidgets/PriceInfoDialog';

const DashboardBookingItemModals = ({ state, handleCloseModal }) => {
  const [modalState, setModalState] = useState({ ...state });

  const handleChangeInfoTypeState = infoType => setModalState({ ...modalState, infoType });

  return (
    <>
      {(modalState.infoType === 'booking' || modalState.infoType === 'prolongation') && (
        <PriceInfoDialog infoType={modalState.infoType} handleClose={handleCloseModal} price={modalState.price} />
      )}
      {(modalState.infoType === 'dashboardActions' ||
        modalState.infoType === 'bookingCancel' ||
        modalState.infoType === 'confirmed') && (
        <BookingItemActionsDialog
          infoType={modalState.infoType}
          handleClose={handleCloseModal}
          handleChangeInfoTypeState={handleChangeInfoTypeState}
        />
      )}
    </>
  );
};

export default DashboardBookingItemModals;

import React from 'react';
import { Button, FormControl } from '@mui/material';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Warning } from '@mui/icons-material';
import { PhoneMask } from '../../../shared/ui/icons/core/PhoneMask';

const RegistrationFailedStep = ({
  entranceFormClass,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  errors,
  formData,
  filled,
  isFocused,
  isPending,
  disableButton
}) => {
  return (
    <div>
      <div className='authorization__caption'>
        <p>Не можете зарегистрироваться?</p>
        <p>Оставьте свои контактные данные, мы с вами свяжемся, чтобы решить проблему.</p>
      </div>
      <form
        className={entranceFormClass}
        onSubmit={() => console.log('Отправляем запрос на новый эндпоинт - которого еще нет')}
        autoComplete='false'
        id='authorization-login-form'
        noValidate
      >
        <CustomTextField
          error={errors.email.register}
          type='email'
          name='email'
          id='outlined-helperText'
          label='E-mail'
          variant='filled'
          value={formData.email.register}
          onFocus={() => handleFocus('email', 'register')}
          onBlur={() => handleBlur('email', 'register')}
          onChange={handleInputChange}
          disabled={isPending}
          helperText={errors.email.register ? errors.email.register : ''}
          endAdornmentProps={{
            filled: filled.email.register,
            isFocused: isFocused.email.register,
            handleEraseValue: name => handleEraseValue(name, 'register')
          }}
          autoFocus={true}
        />
        <CustomTextField
          error={errors.firstName}
          id='outlined-helperText'
          label='Имя'
          name='firstName'
          helperText={errors.firstName ? errors.firstName : ''}
          variant='filled'
          type='text'
          value={formData.firstName}
          onChange={handleInputChange}
          disabled={isPending}
          onFocus={() => handleFocus('firstName')}
          onBlur={() => handleBlur('firstName')}
          endAdornmentProps={{
            filled: filled.firstName,
            isFocused: isFocused.firstName,
            handleEraseValue: handleEraseValue
          }}
          autoFocus={false}
        />
        <CustomTextField
          error={errors.phone}
          id='outlined-helperText'
          label='Телефон'
          name='phone'
          helperText={errors.phone ? errors.phone : ''}
          variant='filled'
          type='tel'
          value={formData.phone}
          onChange={handleInputChange}
          disabled={isPending}
          placeholder={'999 999-99-99'}
          onFocus={() => handleFocus('phone')}
          onBlur={() => handleBlur('phone')}
          endAdornmentProps={{
            filled: filled.phone,
            isFocused: isFocused.phone,
            handleEraseValue: handleEraseValue
          }}
          startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
          autoFocus={false}
        />
        <div className='authorization__footer'>
          <FormControl fullWidth={true}>
            <Button type='submit' variant='contained' disabled={disableButton} disableElevation>
              Отправить
            </Button>
            {errors.commonError !== '' && (
              <div className='authorization__common-error'>
                <span>
                  <Warning spacing='8px' defaultColor='#DB4344' />
                </span>
                <span>{errors.commonError}</span>
              </div>
            )}
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default RegistrationFailedStep;

import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Community from '../components/Community/Community';
import PageLoader from '../components/PageLoader';
import styled from 'styled-components';

const CommunityMainBox = styled.div`
  margin: 14px 0 0 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const CommunityPage = ({ isLoading }) => {
  return (
    <PrimaryLayout>
      <main className='main'>
        <CommunityMainBox>{isLoading ? <PageLoader /> : <Community />}</CommunityMainBox>
      </main>
    </PrimaryLayout>
  );
};

export default CommunityPage;

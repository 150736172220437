import { getCustomerCurrent } from '../../services/workki/CustomerActions';
import { getAccount } from '../../services/workki/TypesActions';
import * as actions from '../actions';

const createCustomerTypes = new Set([
  actions.requestCreateCustomerIndividualSuccess.toString(),
  actions.requestCreateCustomerCompanySuccess.toString()
]);

export default store => next => action => {
  if (createCustomerTypes.has(action.type)) {
    store.dispatch(getCustomerCurrent());
  }
  if (action.type === actions.requestCreateDeposit.success.toString() && action.payload.payment_system_id === 24) {
    window.location = action.payload.payment_url;
  }
  if (action.type === actions.requestServiceRequestCreate.success.toString()) {
    store.dispatch(getAccount());
  }
  return next(action);
};

import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Dashboard from '../entities/Dashboard';
import PageLoader from '../components/PageLoader';

const DashboardPage = ({ isLoading }) => {
  return (
    <PrimaryLayout>
      <main className='main'>{isLoading ? <PageLoader /> : <Dashboard />}</main>
    </PrimaryLayout>
  );
};

export default DashboardPage;

import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class DepositStatus extends Model {
  static reducer(action, depositStatus) {
    switch (action.type) {
      case actions.requestDepositStatuses.success.toString():
        action.payload.map(elem => depositStatus.upsert(elem));
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `DepositStatus: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
DepositStatus.modelName = 'DepositStatus';

// Declare your related fields.
DepositStatus.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  name: attr()
};

export default DepositStatus;

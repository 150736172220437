import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  :hover {
    cursor: pointer;
  }
  fill: ${props => props.fillColor || '#C8C9CA'};
  &:hover {
    fill: ${props => props.hoverColor || '#C8C9CA'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const AddFile = ({ defaultColor, spacing, hoverColor }) => {
  return (
    <StyledSvg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
      hoverColor={hoverColor}
    >
      <rect width='48' height='48' rx='24' />
      <path
        d='M23 33C23 33.5523 23.4477 34 24 34C24.5523 34 25 33.5523 25 33V25H33C33.5523 25 34 24.5523 34 24C34 23.4477 33.5523 23 33 23H25V15C25 14.4477 24.5523 14 24 14C23.4477 14 23 14.4477 23 15V23H15C14.4477 23 14 23.4477 14 24C14 24.5523 14.4477 25 15 25H23V33Z'
        fill='white'
      />
    </StyledSvg>
  );
};

import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommentItem from './CommentItem';
import TableDataLoader from '../../TableDataLoader';
import TableDataReloader from '../../TableDataReloader';
import CommentModal from './CommentModal';
import useModalState from '../../../utils/useModalState';
import { getTicketComments, checkHasTicketNewComment } from '../../../services/workki/TicketsActions';
import * as actions from '../../../store/actions';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';

const CHECK_COMMENTS_TIMEOUT = 60000;

const Ticket = ({ ticket }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [comments, setComments] = useState([]);

  const loadStatus = useSelector(requestStatusSelector(actions.requestTicketComments));

  const loadComments = useCallback(() => {
    dispatch(getTicketComments(ticket.id)).then(response => {
      setComments(response.data);
    });
  }, [dispatch, ticket.id]);

  useEffect(() => {
    loadComments();
    return () => dispatch(actions.requestTicketComments.reset());
  }, [dispatch, loadComments]);

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(checkHasTicketNewComment(ticket.id)).catch(e => {
        console.warn(e);
        interval && clearInterval(interval);
      });
    }, CHECK_COMMENTS_TIMEOUT);
    return () => interval && clearInterval(interval);
  }, [dispatch, ticket.id]);

  // MODAL
  const [isModalWindowOpen, openModalWindow, closeModalWindow] = useModalState(false);

  return (
    <div className='ticket-details__comments' ref={containerRef}>
      <div className='ticket-details__comments-action'>
        <button
          type='button'
          className='ticket-details__comments-action-link btn btn--ghost btn--sm'
          onClick={openModalWindow}
        >
          <span className='ticket-details__comments-action-plus'>+</span>комментарий
        </button>
      </div>
      <div className='ticket-details__comments-list'>
        {comments.map(comment => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </div>
      {loadStatus.isPending && <TableDataLoader />}
      {loadStatus.isFailure && <TableDataReloader reload={loadComments} />}
      <CommentModal
        ticket={ticket}
        isModalWindowOpen={isModalWindowOpen}
        closeModalWindow={closeModalWindow}
        additionalClasses={['modal-window--fill-payments']}
        modalWindowTitle='Добавление комментария'
      />
    </div>
  );
};

export default Ticket;

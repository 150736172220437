import { attr, Model } from 'redux-orm';
import * as actions from '../actions';

class CustomerChildRole extends Model {
  static reducer(action, customerChildRole) {
    switch (action.type) {
      case actions.requestChildRoles.success.toString():
        action.payload.forEach(item => customerChildRole.upsert(item));
        break;
      default:
    }
    return undefined;
  }

  toString() {
    return `CustomerChildRoles: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}
CustomerChildRole.modelName = 'CustomerChildRole';

// Declare your related fields.
CustomerChildRole.fields = {
  id: attr() // non-relational field for any value; optional but highly recommended
};

export default CustomerChildRole;

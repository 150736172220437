import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { FileUploadPreview } from '../../../shared/ui/components/Field/FileUploadField';
import { useDispatch } from 'react-redux';
// import { createCustomerChild, updateCustomerChild } from '../../../services/workki/ChildActions';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';
import { FileContainer } from '../../Services/Print/PrintFileWidget';
import { colors } from '../../../shared/ui/constants/styleConstants';
import { styled } from 'styled-components';
import { changeCredentialsRequest } from '../../../services/workki/ChildActions';
export const Caption = styled.div`
  margin: 0 0 16px 0;
  font-weight: 400;
  color: ${colors.workkiDarkFill};
`;

const ChangeCredentialsWidget = ({ handleClose, type, dialogData, handleSubmit }) => {
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    middleName: '',
    lastName: ''
  });
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({
    firstName: '',
    middleName: '',
    lastName: ''
  });
  const [filled, setFilled] = useState({
    firstName: false,
    middleName: false,
    lastName: false
  });
  const [isFocused, setIsFocused] = useState({
    firstName: false,
    middleName: false,
    lastName: false
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = (name, inputValue) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));

    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: inputValue !== ''
    }));
    setEmployeeData(prevState => {
      return {
        ...prevState,
        [name]: inputValue
      };
    });
  };

  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };
  const handleRemoveFile = fileName => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };
  const handleEraseValue = fieldName => {
    setEmployeeData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  useEffect(() => {
    const allFieldsFilled = employeeData.firstName && employeeData.middleName && employeeData.lastName;

    const hasErrors = Object.values(errors).some(error => error !== '');

    setIsSubmitDisabled(!allFieldsFilled || hasErrors);
  }, [employeeData, errors]);
  const dispatch = useDispatch();

  const handleCreateRequest = useCallback(
    async event => {
      event.preventDefault();

      const { firstName, middleName, lastName } = employeeData;
      const name = lastName + ' ' + firstName + ' ' + middleName;

      try {
        await dispatch(changeCredentialsRequest(name, files));
        handleClose();
      } catch (error) {
        const serverErrors =
          error.response && error.response.data && Array.isArray(error.response.data) ? error.response.data : [];
        const newErrors = {};

        serverErrors.forEach(err => {
          if (err.field === 'first_name') {
            newErrors.firstName = err.message;
          } else if (err.field === 'last_name') {
            newErrors.lastName = err.message;
          } else if (err.field === 'middle_name') {
            newErrors.middleName = err.message;
          }
        });

        setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
      }
    },
    [employeeData, files, dispatch, handleClose]
  );
  useEffect(() => {
    console.log(files, 'файлы');
  }, [files]);

  return (
    <>
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleCreateRequest}
        autoComplete='off'
        noValidate
      >
        <Caption>Пожалуйста, укажите какие данные изменились</Caption>
        <FormControl fullWidth={true}>
          <CustomTextField
            error={''}
            type='text'
            name='lastName'
            label='Фамилия'
            variant='filled'
            disabled={type === 'editEmployee'}
            value={employeeData.lastName}
            required={true}
            onFocus={() => handleFocus('lastName')}
            onBlur={() => handleBlur('lastName')}
            onChange={e => handleInputChange('lastName', e.target.value)}
            helperText={errors.lastName ? errors.lastName : ''}
            endAdornmentProps={{
              filled: filled.lastName,
              isFocused: isFocused.lastName,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          <CustomTextField
            error={''}
            type='text'
            name='firstName'
            label='Имя'
            variant='filled'
            disabled={type === 'editEmployee'}
            value={employeeData.firstName}
            required={true}
            onFocus={() => handleFocus('firstName')}
            onBlur={() => handleBlur('firstName')}
            onChange={e => handleInputChange('firstName', e.target.value)}
            helperText={errors.firstName ? errors.firstName : ''}
            endAdornmentProps={{
              filled: filled.firstName,
              isFocused: isFocused.firstName,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          <CustomTextField
            error={''}
            type='text'
            name='middleName'
            label='Отчество'
            required={true}
            variant='filled'
            disabled={type === 'editEmployee'}
            value={employeeData.middleName}
            onFocus={() => handleFocus('middleName')}
            onBlur={() => handleBlur('middleName')}
            onChange={e => handleInputChange('middleName', e.target.value)}
            helperText={errors.middleName ? errors.middleName : ''}
            endAdornmentProps={{
              filled: filled.middleName,
              isFocused: isFocused.middleName,
              handleEraseValue: name => handleEraseValue(name)
            }}
            autoFocus={false}
          />
          <FileUploadPreview fieldType='changeCredentials' files={files} setFiles={newFiles => setFiles(newFiles)} />
          {files.length > 0 && (
            <FileContainer>
              <p>Прикрепленные файлы</p>
              {files.map((file, index) => (
                <div key={index}>
                  <span>{file.name}</span>
                  <IconComponent type={'remove'} handleAction={() => handleRemoveFile(file.name)} />
                </div>
              ))}
            </FileContainer>
          )}
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title={'Отправить'}
              handleAction={handleCreateRequest}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default ChangeCredentialsWidget;

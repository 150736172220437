import React, { Component } from 'react';
import { isNull } from 'lodash';
import AvatarEditor from 'react-avatar-editor';

const isValidFile = file => {
  const maxFileSize = 5000000; // 5MB 'image/gif',
  const validType = ['image/jpeg', 'image/png'];
  return file && file.size <= maxFileSize && validType.includes(file.type);
};

class ProfileAvatarModal extends Component {
  state = {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotateAngle: 0,
    borderRadius: 1000000,
    preview: null,
    fileInputText: 'Добавьте логотип компании'
  };

  fileInput = React.createRef();
  editorRef = React.createRef();

  componentWillUnmount() {
    const { closeModalWindow } = this.props;
    closeModalWindow();
  }

  handleScale = evt => {
    const scale = parseFloat(evt.target.value);
    this.setState({ scale });
  };

  rotateLeft = evt => {
    evt.preventDefault();

    this.setState(prevState => ({
      rotateAngle: prevState.rotateAngle - 90
    }));
  };

  rotateRight = evt => {
    evt.preventDefault();

    this.setState(prevState => ({
      rotateAngle: prevState.rotateAngle + 90
    }));
  };

  onPositionChange = position => {
    this.setState({ position });
  };

  onImageReady = () => {
    if (!this.editorRef || !this.editorRef.current) {
      return;
    }

    const img = this.editorRef.current.getImageScaledToCanvas().toDataURL();
    const rect = this.editorRef.current.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect
      }
    });
  };

  onImageChange = () => {
    const { scale, width, height, borderRadius } = this.state;

    if (!this.editorRef || !this.editorRef.current) {
      return;
    }

    const img = this.editorRef.current.getImageScaledToCanvas().toDataURL();
    const rect = this.editorRef.current.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect,
        scale,
        width,
        height,
        borderRadius
      }
    });
  };

  fileChangedHandler = evt => {
    evt.preventDefault();

    const file = this.fileInput.current.files[0];

    if (!isValidFile(file)) {
      return;
    }

    this.setState({
      fileInputText: file.name,
      position: { x: 0.5, y: 0.5 },
      rotateAngle: 0,
      preview: null
    });

    evt.currentTarget.classList.remove('is-empty');
  };

  saveFile = () => {
    const { sendCustomerFile, closeModalWindow, saveCallback } = this.props;
    const img = this.editorRef.current.getImageScaledToCanvas();

    img.toBlob(blob => {
      console.log('saveCallback', saveCallback, blob);
      const file = new File([blob], blob.name, { type: 'image/jpeg' });
      saveCallback && saveCallback(file);
      sendCustomerFile(blob);
    }, 'image/jpeg');

    closeModalWindow();
  };

  render() {
    const { scale, rotateAngle, position, borderRadius, fileInputText, allowZoomOut, preview } = this.state;
    const { image, isModalWindowOpen, closeModalWindow } = this.props;
    return isModalWindowOpen ? (
      <div className='profile-avatar-editor'>
        <div className='profile-avatar-editor__container'>
          <button className='profile-avatar-editor__close' onClick={closeModalWindow} />
          <div className='profile-avatar-editor__area'>
            {!isNull(image) ? (
              <AvatarEditor
                transparentImage
                className='profile-avatar-editor__canvas'
                image={image}
                width={400}
                height={400}
                ref={this.editorRef}
                scale={parseFloat(scale)}
                rotate={parseFloat(rotateAngle)}
                position={position}
                color={[36, 39, 42, 0.7]}
                borderRadius={borderRadius}
                onPositionChange={this.onPositionChange}
                onImageChange={this.onImageChange}
                onImageReady={this.onImageReady}
              />
            ) : (
              <div className='profile-avatar-editor__editor-placeholder' />
            )}
          </div>
          <div className='profile-avatar-editor__action'>
            <div className='profile-avatar-editor__controllers'>
              <div className='profile-avatar-editor__controllers-row'>
                <div className='profile-avatar-editor__controllers-range'>
                  <input
                    className='range'
                    name='scale'
                    type='range'
                    onChange={this.handleScale}
                    min={allowZoomOut ? '0.1' : '1'}
                    max='3'
                    step='0.01'
                    defaultValue='1'
                  />
                </div>
              </div>
              <div className='profile-avatar-editor__controllers-row'>
                <div className='profile-avatar-editor__controllers-rotate'>
                  <div onClick={this.rotateLeft} className='profile-avatar-editor__rotate-control'>
                    <button className='profile-avatar-editor__controllers-rotate-btn btn' type='button' />
                    <span>
                      Повернуть
                      <br />
                      налево
                    </span>
                  </div>
                  <div onClick={this.rotateRight} className='profile-avatar-editor__rotate-control'>
                    <button className='profile-avatar-editor__controllers-rotate-btn btn' type='button' />
                    <span>
                      Повернуть
                      <br />
                      направо
                    </span>
                  </div>
                </div>
              </div>
              <div className='profile-avatar-editor__controllers-row'>
                <button
                  className='btn profile-avatar-editor__controllers-preview'
                  onClick={closeModalWindow}
                  type='button'
                >
                  Отмена
                </button>
                <button
                  className='btn profile-avatar-editor__controllers-preview profile-avatar-editor__controllers-preview_white'
                  onClick={this.saveFile}
                  type='button'
                >
                  Применить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default ProfileAvatarModal;

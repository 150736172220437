import React, { createRef, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors, fontFamilyBase, screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { useResize } from '../../shared/utils/hooks/useResize';
import { TabsComponent } from '../../shared/ui/components/Tabs/Tabs';
import { getCustomerFiles } from '../../services/workki/CustomerActions';
import { useDispatch } from 'react-redux';

const Title = styled.h2`
  margin: 32px 0 12px 0;
  padding: 0;
  width: 100%;
  font-family: ${fontFamilyBase};
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${colors.workkiDarkMain};

  @media screen and (min-width: ${screenRessolutions.desktopPlus}px) {
    margin-top: 56px;
  }
`;

const HistoryList = styled.ul`
  margin: 20px 0 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  list-style-type: none;
`;

const HistoryItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 32px;
  position: relative;
`;

const HistoryItemTextBox = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  gap: 2px;
  box-sizing: border-box;
  width: 100%;

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: ${fontFamilyBase};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const HistoryItemTitle = styled.h4`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #24272a;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const HistoryItemTimeBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  box-sizing: border-box;

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: ${fontFamilyBase};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8f9295;

    @media screen and (min-width: ${screenRessolutions.laptop}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const ShowMoreText = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #575b60;
  cursor: pointer;
`;

const MessageOnNullServices = styled.p`
  margin: 8px 0 0 0;
  padding: 0;
  font-family: Commissioner, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #8f9295;
`;

const PrintHistory = ({ historyRef, customer }) => {
  const defaultTabValues = {
    all: false,
    my: false,
    staff: false,
    value: 0
  };
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState({ ...defaultTabValues, all: true });

  const tabs = [{ label: 'Все' }, { label: 'Мои' }, { label: 'Сотрудников' }];

  const handleTabChange = (event, value) => {
    if (value === 0) {
      setTabValue({ ...defaultTabValues, all: true, value: 0 });
    }
    if (value === 1) {
      setTabValue({ ...defaultTabValues, my: true, value: 1 });
    }
    if (value === 2) {
      setTabValue({ ...defaultTabValues, staff: true, value: 2 });
    }
  };

  const getDate = string => {
    let startString = string.split('').splice(0, 10);
    startString.splice(0, 2);
    startString = startString
      .join('')
      .split('-')
      .reverse()
      .join('.');

    return startString;
  };

  const getTime = string => {
    return string.substring(string.indexOf(' '), string.lastIndexOf(':'));
  };

  const defaultApplicationNum = 7;
  const [servicesNumState, setServicesNumState] = useState(defaultApplicationNum);

  // // Замоканные данные для теста вёрстки
  // // const mockArr = [...printData.history.rows];
  // const mockArr = [];
  const [printHistory, setPrintHistory] = useState([]);

  const getTitle = () => {
    refs.forEach((item, index) => {
      if (
        !item.current ||
        (item.current.clientWidth > item.current.children[0].clientWidth &&
          !item.current.children[0].textContent.includes('...'))
      ) {
        return;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const font = '500 14px Commisioner, sans-serif';
      ctx.font = font;

      for (let i = 0; i < printHistory[index].name.length; i++) {
        const startLetter = Math.floor(item.current.children[0].textContent.length / 2);

        const symbolsArr = printHistory[index].name.split('');

        if (i >= 1) symbolsArr.splice(startLetter, i + 1, '...');

        const text = symbolsArr.join('');
        const canvasTextWidth = Math.round(ctx.measureText(text).width);

        item.current.children[0].textContent = text;
        if (
          canvasTextWidth <= item.current.clientWidth &&
          item.current.children[0].clientWidth <= item.current.clientWidth
        ) {
          break;
        }
      }

      canvas.remove();
    });
  };

  const refs = useMemo(() => printHistory.map(() => createRef()), [servicesNumState]);
  useEffect(() => {
    dispatch(getCustomerFiles()).then(response => {
      setPrintHistory(response.data);
    });
  }, []);

  const getHistory = () => {
    return (
      printHistory &&
      printHistory.map((item, index) => {
        if (index <= servicesNumState)
          return (
            <HistoryItem key={index}>
              <HistoryItemTextBox>
                <HistoryItemTitle ref={refs[index]}>
                  <span>{item.filename}</span>
                </HistoryItemTitle>
                <p>{item.user_id === customer.id ? `Я, ${customer.name}` : item.user_name}</p>
              </HistoryItemTextBox>
              <HistoryItemTimeBox>
                <p>{getDate(item.created_at)}</p>
                <p>{getTime(item.created_at)}</p>
              </HistoryItemTimeBox>
            </HistoryItem>
          );
      })
    );
  };

  useResize(getTitle, 0);

  useEffect(() => {
    getTitle();
  }, [servicesNumState]);

  return (
    <>
      <Title>История печати</Title>
      {printHistory.length > 0 && (
        <>
          <TabsComponent
            tabsContainerStyle={{ height: '41px' }}
            tabStyle={{ height: '31px' }}
            tabValue={tabValue.value}
            handleTabChange={handleTabChange}
            tabs={tabs}
          />
          <HistoryList ref={historyRef}>
            {getHistory()}
            {printHistory.length > servicesNumState && (
              <ShowMoreText onClick={() => setServicesNumState(servicesNumState + 8)}>Показать ещё</ShowMoreText>
            )}
          </HistoryList>
        </>
      )}
      {(printHistory.length === 0 || !printHistory) && (
        <MessageOnNullServices>За последние 30 дней ничего не печатали</MessageOnNullServices>
      )}
    </>
  );
};

export default PrintHistory;

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import middlewares from './middlewares';

import thunk from 'redux-thunk';

const initAppStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, ...middlewares],
    devTools: process.env.NODE_ENV !== 'production'
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
};

export default initAppStore();

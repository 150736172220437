import React, { useState } from 'react';
import { styled } from 'styled-components';
import { FileUploadPreview } from '../../../shared/ui/components/Field/FileUploadField';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { FormControl } from '@mui/material';
import { getBalancePrinter, sendFileToPrintDirectly } from '../../../services/workki/CustomerActions';
import { error, info, success } from 'react-toastify-redux';
import ErrorWithShowedMessage from '../../../errors/ErrorWithShowedMessage';
import { isArray } from 'lodash';
import { connect } from 'react-redux';

export const PrintServiceRules = styled.div`
  font-size: 14px;
  font-weight: 400;
  & > ol {
    & > li {
      & > span {
        font-weight: 600;
      }
    }
  }
`;
export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  & > div {
    width: 100%;
    font-weight: 300;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;
const mapDispatchToProps = dispatch => ({
  sendToFile: async file => {
    try {
      await dispatch(sendFileToPrintDirectly(file));
      dispatch(success('Файл готов к печати', { hideProgressBar: true }));
    } catch (err) {
      if (err instanceof ErrorWithShowedMessage) {
        throw err;
      }

      let message = 'Ошибка печати';

      if (err && err.response && err.response.data && isArray(err.response.data)) {
        message = err.response.data[0].message;
      }

      dispatch(error(message, { hideProgressBar: true }));
      throw new Error(message);
    }
  },
  showInfoToast: msg => dispatch(info(msg, { hideProgressBar: true }))
});
const PrintFileWidget = props => {
  const { dialogData, handleSubmit } = props;
  const [files, setFiles] = useState([]);
  const handlePrint = evt => {
    evt.preventDefault();
    const { sendToFile } = props;
    sendToFile(files[0])
      .then(() => {
        handleSubmit();
      })
      .catch(() => {});
  };
  return (
    <>
      <PrintServiceRules>
        <p>После отправки файла:</p>
        <ol>
          <li>Подойдите к любому принтеру на локации.</li>
          <li>
            Введите ваш ID: <span>{dialogData.id}.</span>
          </li>
          <li>
            Введите пинкод печати <span>{dialogData.printer && dialogData.printer.pin}.</span>
          </li>
        </ol>
        {files.length < 1 ? (
          <FileUploadPreview setFiles={value => setFiles(value)} files={files} fieldType='print' />
        ) : (
          <FileContainer>
            <p>Прикрепленный файл</p>
            <div>
              <span>{files[0].name}</span>
              <IconComponent type={'remove'} handleAction={() => setFiles([])} />
            </div>
          </FileContainer>
        )}
        <FormControl fullWidth={true} style={{ marginTop: '32px' }}>
          <StepButton
            disabled={files.length < 1}
            filledType={'black'}
            type={'filled'}
            title={'Отправить'}
            handleAction={handlePrint}
          />
        </FormControl>
      </PrintServiceRules>
    </>
  );
};
export default connect(
  null,
  mapDispatchToProps
)(PrintFileWidget);

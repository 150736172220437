import React from 'react';
import Loader from 'react-loader-spinner';

const TableDataLoader = () => (
  <div className='table-data-loader'>
    <Loader type='ThreeDots' color='#555' height='25' width='40' />
  </div>
);

export default TableDataLoader;

import { ORM } from 'redux-orm';
import DepositStatus from './DepositStatus';
import PaymentSystem from './PaymentSystem';
import CurrencyType from './CurrencyType';
import DepositBank from './DepositBank';
import Deposit from './Deposit';
import Customer from './Customer';
import Account from './Account';
import Office from './Office';
import ServiceType from './ServiceType';
import Service from './Service';
import ServiceSchedule from './ServiceSchedule';
import ServiceFile from './ServiceFile';
import ServiceFileType from './ServiceFileType';
import ServiceRequest from './ServiceRequest';
import CustomerFile from './CustomerFile';
import CustomerChild from './CustomerChild';
import CustomerChildRole from './CustomerChildRole';
import Guest from './Guest';
import GuestLimit from './GuestLimit';
import Parking from './Parking';
import PublicProfiles from './PublicProfiles';
import ItServiceTask from './ItServiceTask';
import Transaction from './Transaction';
import Ticket from './Ticket';
import TicketCategory from './TicketCategory';
import CustomerContract from './CustomerContract';
import Day from './Day';

const orm = new ORM();
orm.register(
  DepositStatus,
  PaymentSystem,
  CurrencyType,
  DepositBank,
  Deposit,
  Customer,
  Account,
  Office,
  ServiceType,
  Service,
  ServiceSchedule,
  ServiceFile,
  ServiceFileType,
  ServiceRequest,
  CustomerFile,
  CustomerContract,
  CustomerChild,
  CustomerChildRole,
  Guest,
  GuestLimit,
  Parking,
  PublicProfiles,
  ItServiceTask,
  Transaction,
  Ticket,
  TicketCategory,
  Day
);

export default orm;

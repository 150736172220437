import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SliderArrow } from '../../icons/core/SliderArror';
import { useKeenSlider } from 'keen-slider/react';
import CardShell from './CardShell';
import saveAs from 'file-saver';
import { customerContractsSelector } from '../../../../store/selectors/DefaultSelectors';
import { connect } from 'react-redux';
import { UploadFile } from '../../icons/core/UploadFile';
import {
  getBinaryFileContract,
  getBinaryFileSubleaseContract,
  getCustomerContracts
} from '../../../../services/workki/CustomerActions';
import { colors } from '../../constants/styleConstants';

const DocumentsBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DocumentsTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #24272a;
`;

const DocumentsListBox = styled.ul`
  margin: 0;
  padding: 12px 0 0 0;
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 8px;
  list-style-type: none;
`;

const DocumentItemTitleBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DocumentItemTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #24272a;
`;

const DocumentItemInfo = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: ${props => (props.type === 'extension' ? 'row' : 'column')};
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: #8f9295;
  }
`;

const DocumentsListArrowBox = styled.li`
  margin: 0;
  padding: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #fff;
  position: absolute;
  top: 61px;
  cursor: pointer;
`;

const mapStateToProps = state => ({
  contract: state.contract,
  subleaseContracts: state.subleaseContracts,
  contracts: customerContractsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  getContracts: () => dispatch(getCustomerContracts()),
  dispatchGetBinaryFileContract: id => dispatch(getBinaryFileContract(id)),
  dispatchGetBinaryFileSubleaseContract: id => dispatch(getBinaryFileSubleaseContract(id))
});

const DocumentsList = ({
  spacing,
  contracts,
  getContracts,
  dispatchGetBinaryFileContract,
  dispatchGetBinaryFileSubleaseContract
}) => {
  const [subleaseContractsArray, setSubleaseContractsArray] = useState([]);
  const [singleContract, setSingleContract] = useState([]);
  useEffect(() => {
    if (!contracts) {
      getContracts();
      return;
    }
    const { contract, subleaseContracts } = contracts;
    if (contract) {
      contract.type = 'single';
      setSingleContract([contract]);
    }
    if (subleaseContracts) {
      const fullContracts = subleaseContracts;
      setSubleaseContractsArray(fullContracts);
    }
  }, [contracts]);
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: { perView: 3, spacing: 10 },
    breakpoints: {
      '(min-width: 1366px)': {
        slides: { perView: 3, spacing: 12 },
        drag: false
      },
      '(min-width: 1920px)': {
        slides: { perView: 4, spacing: 12 },
        drag: false
      }
    }
  });

  const getDocument = (item, index) => {
    const saveContract = evt => {
      evt.preventDefault();
      dispatchGetBinaryFileContract(item.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, `contract_${item.id}.pdf`);
        })
        .catch(() => {});
    };

    const saveSubleaseContract = evt => {
      evt.preventDefault();
      dispatchGetBinaryFileSubleaseContract(item.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, `sublease_contract_${item.id}.pdf`);
        })
        .catch(() => {});
    };

    return (
      <div onClick={saveContract}>
        <CardShell className='keen-slider__slide' key={index} style={{ height: '132px' }}>
          <DocumentItemTitleBox>
            {item.type === 'single' && <DocumentItemTitle>Договор оферты</DocumentItemTitle>}
            {item.type === 'sublease' && <DocumentItemTitle>Договор</DocumentItemTitle>}
            <DocumentItemInfo>
              {!!item.number && <p>№{item.number}</p>}
              {!!item.date && <p>от {new Date(item.date).toLocaleDateString()}</p>}
              {item.type === 'sublease' && <p>от {new Date(item.created_at).toLocaleDateString()}</p>}
            </DocumentItemInfo>
          </DocumentItemTitleBox>
          <DocumentItemInfo type='extension'>
            <UploadFile spacing={'4px'} defaultColor={colors.workkiDarkMiddle} />
            <p>PDF</p>
          </DocumentItemInfo>
        </CardShell>
      </div>
    );
  };

  return (
    <DocumentsBox style={spacing}>
      <DocumentsTitle>Документы</DocumentsTitle>
      <DocumentsListBox ref={sliderRef}>
        {[...subleaseContractsArray, ...singleContract] &&
          [...subleaseContractsArray, ...singleContract].map((item, index) => getDocument(item, index))}
        <DocumentsListArrowBox onClick={() => instanceRef.current.prev()}>
          <SliderArrow rotate={true} />
        </DocumentsListArrowBox>
        <DocumentsListArrowBox onClick={() => instanceRef.current.next()}>
          <SliderArrow rotate={false} />
        </DocumentsListArrowBox>
      </DocumentsListBox>
    </DocumentsBox>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsList);

import React, { Component } from 'react';
import cn from 'classnames';
import ModalWindowWrapper from '../../../hocs/ModalWindowWrapper';
import moment from 'moment';

class FillPaymentsModal extends Component {
  state = {
    replenishment: '',
    hasError: false,
    isSecurityPayment: '',
    customer: this.props.customer,
    checkoutDifference: this.props.checkoutDifference
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { handler, closeModalWindow } = this.props;
    if (this.state.replenishment === '' && this.props.checkoutDifference) {
      this.setState({ replenishment: this.state.checkoutDifference });
      handler(this.state);
      this.setState({ hasError: false });
      closeModalWindow();
    } else if (Number.isNaN(+this.state.replenishment)) {
      this.setState({ hasError: true });
      return;
    } else {
      if (!evt.target.checkValidity()) {
        this.setState({ hasError: true });
        return;
      }
      handler(this.state);
      this.setState({ hasError: false });
      closeModalWindow();
    }
  };

  handleInputChange = evt => {
    // evt.preventDefault();
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  get contractsForSelect() {
    const { customer } = this.state;
    if (!customer) return '';

    return {
      label: `Договор № ${customer.contract.number} от ${moment(customer.contract.date, 'YYYY-MM-DD HH:mm:ss').format(
        'DD.MM.YYYY'
      )}`
    };
  }

  render() {
    const { replenishment, hasError, isSecurityPayment } = this.state;
    const fillPaymentsFormClass = cn({
      'fill-payments-modal': true,
      'has-error': hasError
    });

    return (
      <form
        className={fillPaymentsFormClass}
        id='fill-payments-modal-form'
        onSubmit={this.handleSubmit}
        autoComplete='off'
        noValidate
      >
        <div className='fill-payments-modal__body'>
          <div className='fill-payments-modal__row'>
            <input
              className={cn({
                input: true,
                input__placeholder: !!this.props.checkoutDifference
              })}
              name='replenishment'
              type='text'
              value={replenishment}
              onChange={this.handleInputChange}
              placeholder={
                this.props.checkoutDifference ? this.props.checkoutDifference.toLocaleString('ru') : 'Сумма пополнения'
              }
              required
            />
          </div>

          <div className='fill-payments-modal__row'>
            <div className='fill-payments-modal__row'>
              <input
                className='input'
                name='replenishment'
                type='text'
                value={this.contractsForSelect.label}
                required
                disabled
              />
            </div>
          </div>
          <div className='fill-payments-modal__row'>
            <div className='fill-payments-modal__checkbox checkbox'>
              <input
                className='checkbox__input'
                id='auto-prolongation-booking'
                name='isSecurityPayment'
                type='checkbox'
                value={isSecurityPayment}
                onChange={this.handleInputChange}
              />
              <label className='checkbox__label' htmlFor='auto-prolongation-booking'>
                Обеспечительный платеж
              </label>
              <label className='checkbox__caption'>*При заключении договора субаренды на 11 месяцев</label>
            </div>
          </div>
          <div className='fill-payments-modal__row'>
            <button className='fill-payments-modal__refill-btn btn btn--ghost' type='submit'>
              Пополнить
            </button>
          </div>
        </div>
        <div className='fill-payments-modal__notification'>
          <div className='notification notification--error notification--fill-payment-modal'>
            Неверно заполнена сумма пополнения
          </div>
        </div>
      </form>
    );
  }
}

export default ModalWindowWrapper(FillPaymentsModal);

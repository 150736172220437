import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
`;

export const UploadFile = ({ defaultColor, spacing }) => {
  return (
    <StyledSvg
      width='20'
      height='25'
      viewBox='0 0 20 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fillColor={defaultColor}
      spacing={spacing}
    >
      <path
        d='M7.30951 2.64901C7.13866 2.47816 6.86165 2.47816 6.69079 2.64901L4.94079 4.39901C4.76994 4.56987 4.76994 4.84688 4.94079 5.01773C5.11165 5.18859 5.38866 5.18859 5.55951 5.01773L6.56265 4.01459V9.95837C6.56265 10.2 6.75853 10.3959 7.00015 10.3959C7.24178 10.3959 7.43765 10.2 7.43765 9.95837V4.01459L8.44079 5.01773C8.61165 5.18859 8.88866 5.18859 9.05951 5.01773C9.23037 4.84688 9.23037 4.56987 9.05951 4.39901L7.30951 2.64901Z'
        fill='#8F9295'
      />
      <path
        d='M3.06265 9.08337C3.06265 8.84175 2.86678 8.64587 2.62515 8.64587C2.38353 8.64587 2.18765 8.84175 2.18765 9.08337V10.8334C2.18765 11.5582 2.77528 12.1459 3.50015 12.1459H10.5002C11.225 12.1459 11.8127 11.5582 11.8127 10.8334V9.08337C11.8127 8.84175 11.6168 8.64587 11.3752 8.64587C11.1335 8.64587 10.9377 8.84175 10.9377 9.08337V10.8334C10.9377 11.075 10.7418 11.2709 10.5002 11.2709H3.50015C3.25853 11.2709 3.06265 11.075 3.06265 10.8334V9.08337Z'
        fill='#8F9295'
      />
    </StyledSvg>
  );
};
